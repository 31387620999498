import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { checkUserAPI, loginSignUpUserAPI, loginUserAPI, } from '../Request/userRequest';
import {AddUpdatePostProperty} from '../Request/post-propertyRequest'

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  
  const navigate = useNavigate();


  const [isUserExist, setIsUserExist] = useState(true);
  const [isToken, setToken] = useState(true);

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const [sentOtpFrom, setSentOtpFrom] = useState("login");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [googleUser, setGoogleUser] = useState({});
  const [databaseUser, setDatabaseUser] = useState({});

  const [userName, setUserName] = useState("")
  const [email,setEmail]=useState("")

  const getStorageData = () => {
    setUserName(localStorage.getItem("name"))
    setToken(localStorage.getItem("token"))
    setToken(localStorage.getItem("email"))
    if (
      localStorage.getItem("token")
    ) {
      setIsUserLoggedIn(true)
    }
  }

  const checkUser = (phnum) => {
    setPhoneNumber(phnum)
    if (phnum.length == 10) {
      try {
        checkUserAPI({ mobileNumber: phnum }).then((res) => {
          setIsUserExist(!res.IsError)
          setDatabaseUser(res)
        })
      } catch (error) {
        console.log(error);
      }
    } else {
      setDatabaseUser({})
      setIsUserExist(true)
    }
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    // signInWithPopup(auth, provider);
    signInWithRedirect(auth, provider)
  };

  const logOut = () => {
    signOut(auth)
    setGoogleUser({})
    setDatabaseUser({})
    setIsUserExist(true)
  }

  const handleLogOut = () => {
    localStorage.removeItem("name")
    localStorage.removeItem("token")
    localStorage.removeItem("email")
    setUserName("")
    setEmail("")
    logOut()
    getStorageData()
    setIsUserLoggedIn(false)
    navigate('/');
  }
  useEffect(() => {
    getStorageData()
  },[isUserLoggedIn])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setGoogleUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function onCaptchVerify() {
    // if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          onSignup();
        },
        "expired-callback": () => { },
      },
      auth
    );
    // }
  }



  function onSignup(mobileNo,username,email) {
    setSentOtpFrom("login")
    // onCaptchVerify();
    // const appVerifier = window.recaptchaVerifier;
    // const formatPh = "+91" + mobileNo;
    // signInWithPhoneNumber(auth, formatPh, appVerifier)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
        navigate('/verify-otp');
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
  }

  function onOTPVerify(values) {
    console.log("called from contaxt api")
    // window.confirmationResult
    //   .confirm(otp)
    //   .then(async (res) => {
    //     try {
      if(otp == 123456){
        loginSignUpUserAPI({
          mobileNumber:
            phoneNumber, username: userName || "", email: email || ""
        }).then((res) => {
          console.log({res});
          localStorage.setItem('name', res.name)
          localStorage.setItem("token", res.Token)
          localStorage.setItem("email", res.email)
          localStorage.setItem("phoneNo", phoneNumber)
          localStorage.setItem("userID", res.id)
          
          setIsUserLoggedIn(true)

          if (sentOtpFrom === "login") {
            navigate('/');
          }
          if (sentOtpFrom === "form1") {
          }
          if (sentOtpFrom === "contact") {
            setIsOtpSent(false)
          }
        })
      }else{
        alert("wrong otp")
      }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
  }

  // function onSignupForContact(mobileNo) {
  //   setSentOtpFrom("contact")
  //   onCaptchVerify();
  //   const appVerifier = window.recaptchaVerifier;
  //   const formatPh = "+91" + mobileNo;
  //   signInWithPhoneNumber(auth, formatPh, appVerifier)
  //     .then((confirmationResult) => {
  //       window.confirmationResult = confirmationResult;
  //       setIsOtpSent(true)
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  
  return (
    <AuthContext.Provider value={{
      googleSignIn, logOut, checkUser, googleUser, isUserExist, userName,
      onCaptchVerify, onSignup, onOTPVerify, setDatabaseUser, databaseUser,
      setIsUserExist, setOtp, setPhoneNumber, setIsUserExist, handleLogOut,otp,sentOtpFrom,
      phoneNumber, isUserLoggedIn,setIsUserLoggedIn, setSentOtpFrom,isOtpSent, setIsOtpSent,
    email,setEmail,setUserName


    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
