import React from 'react';
import Back from "../../Assests/Images/Back.png";
import Apple from "../../Assests/Images/Apple.png";
import Phone from "../../Assests/Images/Phone.png";
import GooglePlay from "../../Assests/Images/GooglePlay.png";


export default function Application() {
    return (
        <>
            <body className="Home-Page">

                <section className="App row g-0" id="App">
                    <img src={Back} alt="Back" className="Back" />
                    <div className="left col-md">
                        <h1>Download Our App</h1>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec. Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit.
                        </p>
                        <div className="Images btn-group w-100 justify-content-start">
                            <img src={Apple} alt="Apple" />
                            <img src={GooglePlay} alt="Play" />
                        </div>
                    </div>
                    <div className="right col-md">
                        <img src={Phone} alt="Phone" className="Mobile-img" />
                    </div>
                </section>

            </body>
        </>
    )
}
