import React, { useState, useRef } from "react";
import Edit from "../../Assests/Images/Edit.png";
import TryAgain from "../../Assests/Images/try-again.png";
import Progressbar from "../../Assests/Images/Progressbar.png";
import Step2 from "../../Assests/Images/step-2-bg.png";
import { UserAuth } from "../../context/AuthContext";
import { UserProperty } from "../../context/PropertyContext";
import { useNavigate } from "react-router-dom";
import { loginSignUpUserAPI } from "../../Request/userRequest";
import { AddUpdatePostProperty } from "../../Request/post-propertyRequest";

export default function OTPverifyScreen() {
  // const searchParams = new URLSearchParams(window.location.search);
  const Searchget = new URLSearchParams(window.location.search);
  const cityID = Searchget.get("cityID");
  const PropertyFor = Searchget.get("propertyForId");
  const property_types_id = Searchget.get("propertyTypeId");

  const {
    otp,
    setOtp,
    setIsOtpSent,
    sentOtpFrom,
    googleUser,
    phoneNumber,
    databaseUser,
    userName,
    email,
    setIsUserLoggedIn,
  } = UserAuth();
  const { setUpdateProperties, setPropertyID } = UserProperty();

  const [tempOtp, setTempOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    let otpChange = [...tempOtp];
    otpChange[index] = value;
    setTempOtp(otpChange);
    console.log(otpChange.join(""));
    setOtp(otpChange.join(""));

    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !tempOtp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  function onOTPVerify(values) {
    console.log(otp, "called from otp varification page");
    // window.confirmationResult
    //   .confirm(otp)
    //   .then(async (res) => {
    //     try {
    if (otp == 123456) {
      loginSignUpUserAPI({
        mobileNumber: phoneNumber,
        username: userName || "",
        email: email || "",
      }).then((res) => {
        console.log({ res });
        localStorage.setItem("name", res.username);
        localStorage.setItem("token", res.Token);
        localStorage.setItem("email", res.email);
        localStorage.setItem("phoneNo", phoneNumber);
        localStorage.setItem("userID", res.id);

        setIsUserLoggedIn(true);
        console.log("outer", sentOtpFrom);
        if (sentOtpFrom === "login") {
          navigate("/");
        }
        if (sentOtpFrom === "form1") {
          const { cityID, property_types_id, PropertyFor } = values;
          console.log(cityID);
          console.log(property_types_id);
          console.log(PropertyFor);

          AddUpdatePostProperty({
            city_id: cityID,
            property_types_id: PropertyFor,
            building_types_id: property_types_id,
            user_id: localStorage.getItem("userID"),
            property_statusID: 3,
            is_draft: true,
            Step: 1,
          }).then((res) => {
            setUpdateProperties({
              city_id: cityID,
              property_types_id: PropertyFor,
              building_types_id: property_types_id,
              user_id: localStorage.getItem("userID"),
              property_statusID: 3,
            });
            if (res.IsError != true) {
              setPropertyID(res.id);
              navigate(
                `/post-property-form3?PropertyId=${
                  res.id
                }&Cities=${cityID}&PropertyForId=${PropertyFor.toString()}&buildingType=${property_types_id.toString()}`
              );
            } else {
              alert("Something went wrong !please try again later");
            }
          });
        }
        if (sentOtpFrom === "contact") {
          setIsOtpSent(false);
        }
      });
    } else {
      alert("wrong otp");
    }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  return (
    <>
      <body>
        <section class="Step-2" id="Step-2">
          <div class="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  navigate(`/login-signup`);
                }}
                // onClick={() => {
                //   navigate(`/post-property-form1?PropertyId=${
                //     PropertyId
                //     }&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`);
                // }}
              ></div>
            </div>
            <img src={Progressbar} class="ProgressBar" alt="Progressbar" />
            <div class="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={Step2} alt="Step-2" />
            </div>
          </div>

          <div class="right">
            
          <div className="PostBackContainer">
            <div
              className="Post-Property-Back mobileonly "
              // onClick={() => {
              //   navigate(`/post-property-form1?PropertyId=${
              //     PropertyId
              //     }&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`);
              // }}
              onClick={() => {
                navigate(`/login-signup`);
              }}
            >
              <span className="fa fa-chevron-left  postBack" style={{
                color:"#777777"
              }}></span>
            </div>
            <div class="Title">
              <h1
                style={{
                  color: "#8B3D88",
                }}
              >
                Verify your mobile No.
              </h1>
              {/* <a
                onClick={() => {
                  navigate("/login-signup");
                }}
              >
                Change
              </a> */}
            </div>
            </div>

            <span style={{
                marginTop:"10px"
            }}>
              Hello {databaseUser?.username || googleUser?.displayName}
            </span>
            <p class="p">
              Your number is going to be registered with us. Please add 6 number
              OTP we sent you
            </p>
            <h1 class="Phone-Number">
              +91 - {phoneNumber}
              {/* <a>
                                <img src={Edit} alt="Edit"  onClick={()=>{ navigate('/login-signup')}}/>
                            </a> */}
            </h1>
            <p>Enter OTP that sent on your phone</p>
            <form
              class="Serial needs-validation"
              data-send="Post-Form3.html"
              novalidate
            >
              <div
                class="Inputs btn-group"
                style={{ width: "100%", margin: 0, padding: 0, gap: 0 }}
              >
                {[...Array(6)].map((_, index) => (
                  <input
                    inputmode="numeric"
                    key={index}
                    type="number"
                    maxLength="1"
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className={`otp-input-${index}`}
                    required
                    style={{ width: 30, height: 60 }}
                  />
                ))}
              </div>

              <p>
                Didn’t receive the code? <a href="#">Resend OTP</a>
              </p>

              <button
                className="Submit Style"
                onClick={(e) => {
                  e.preventDefault();
                  onOTPVerify({ cityID, property_types_id, PropertyFor });
                }}
              >
                Verify
              </button>
            </form>

            <div
              class="modal fade"
              id="ModalID"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title text-danger" id="exampleModalLabel">
                      Number Verified Failed
                    </h5>
                    <img
                      src={TryAgain}
                      alt="Close-Image"
                      data-bs-dismiss="modal"
                    />
                  </div>
                  <div class="modal-body">
                    Please check the number or try it after sometime.
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-light text-dark d-block w-100 p-2"
                      data-bs-dismiss="modal"
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
