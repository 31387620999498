import React from 'react'
import Header from '../Components/Common/header'
import Home from '../Components/Landing Page/home'
import Services from '../Components/Landing Page/services'
import Quarter from '../Components/Landing Page/quarter'
import Handpicked from '../Components/Landing Page/handpicked'
import Cities from '../Components/Landing Page/cities'
import Reviews from '../Components/Landing Page/reviews'
import Application from '../Components/Landing Page/application'
import Footer from '../Components/Common/footer'

export default function LandingPage({selectedItems, setSelectedItems,selectedCityID, setSelectedCityID}) {
    return (
        <>
            <Header />
            <Home selectedCityID={selectedCityID} setSelectedCityID={setSelectedCityID} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
            <Services selectedCityID={selectedCityID} setSelectedCityID={setSelectedCityID} selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>
            <Quarter />
            <Handpicked />
            <Cities />
            <Reviews />
            <Application />
            <Footer />
        </>
    )
}

