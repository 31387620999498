import React from 'react';

export function getInitials(username) {
  if (!username) {
    return
  }
  const words = username.split(' ');

  let initials = '';

  for (const word of words) {
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  }

  return initials;
}
 function UsernameInitials({ username }) {
  const initials = getInitials(username);

  return <div>{initials}</div>;
}

export default UsernameInitials;