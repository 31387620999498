import React from 'react'

export default function ContactPersonDetails() {
    return (
        <>
            <div className="modal fade"
                id="Contact-Details"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                <span>Contact Details</span>
                                {/* <span>Report</span> */}
                                <span></span>
                            </h5>
                            <button type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-0">
                                <div className="col-6">
                                    <h3>Owner</h3>
                                    <p>Raju Pandey</p>
                                    <p>+91 333 123456789</p>
                                </div>
                                <div className="col-6">
                                    <h3>Email</h3>
                                    <p>email@xyz.com</p>
                                </div>
                                <div className="col-6">
                                    <h3>Rent</h3>
                                    <p>₹20,000</p>
                                </div>
                                <div className="col-6">
                                    <h3>Posted Date</h3>
                                    <p>Raju Pandey</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="Style">Similar Properties</button>
                            <button type="button" className="Style">Other Properties</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
