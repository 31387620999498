import React, { useState } from 'react'


const AmanatiesContainer = ({amanatie}) => {

  return (
    <div class="type">
    {amanatie?.slice(0,4).map((amanity,i)=> 
       <div key={i} class="tag btn-group align-items-center col-xl-3 col-sm-auto">
        <i className={amanity.icon} ></i>
        <p>{amanity.name}</p>
    </div> )}
    </div>
  )
}

export default AmanatiesContainer
