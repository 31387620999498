import React from "react";
import Login from "../../Assests/Images/Login.png";
import India from "../../Assests/Images/india.png";
import Google from "../../Assests/Images/Google.png";
import { UserAuth } from "../../context/AuthContext";

export default function LoginComponent({ }) {

  const { googleSignIn, googleUser, logOut,
    onSignup, setPhoneNumber, databaseUser, checkUser, phoneNumber,isUserExist } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSingUp =(phoneNumber)=>{
    if(phoneNumber.length > 9){
      onSignup(phoneNumber)
    }
    else{
      alert("Mobile No cannot be empty")
      return
    }

  }

  return (
    <>
      <body className="Login">
        <section className="Login" id="Login">
          {googleUser || databaseUser != null ? (
            <h1
              className="title"
              style={{ color: "#8b3d88", fontWeight: "400" }}
            >
              <span style={{ fontWeight: "700" }}>
                Hello {databaseUser?.username || googleUser?.displayName}{" "}
              </span>
              {(databaseUser?.username || googleUser?.displayName) ? "Welcome Back To True Square Feet" : "Please Enter Mobile Number"}
            </h1>
          ) : (
            <h1
              className="title"
              style={{ color: "#8b3d88", fontWeight: "400" }}
            >

              {(databaseUser?.username || googleUser?.displayName) && <span style={{ fontWeight: "700" }}>Welcome Back </span>}
              <span style={{ fontWeight: "700" }}>{databaseUser?.username || googleUser?.displayName || "Login"} </span>
              to True Squarefeet
            </h1>
          )}

          <img style={{ width: "150px" }} src={Login} alt="Login" />

          <div className="Number-Phone">
            <div className="Phone">
              <div className="btn-group w-100">
              <input
  type="number"
  className="form-control"
  placeholder="Phone"
  required
  onChange={(e) => {
    checkUser(e.target.value);
    setPhoneNumber(e.target.value);
    console.log(phoneNumber)
  }}
  onInput={(e) => {
    if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10);
  }}
/>

                <span className="input-group-text">
                  <img src={India} />
                </span>
              </div>
            </div>

           {isUserExist ? <button style={{ marginLeft: '15px', padding: '15px', marginTop: '0px' }}
              onClick={(e) => {
                e.preventDefault();
                handleSingUp(phoneNumber)
              }}
              className="fa fa-chevron-right Style"
            ></button> : ""}
            { }
          </div>

          {/* <footer className="Footer">
            <p className="OR">OR</p>
            {googleUser ? (
              <>
                <button className="Style"
                  onClick={logOut}>Logout</button>
              </>
            ) : (
              <>
                <button style={{ backgroundColor: 'white' }}>
                  <img onClick={handleGoogleSignIn} src={Google} alt="Google" />
                </button>
              </>
            )}
          </footer> */}
        </section>
      </body>
    </>
  );
}
