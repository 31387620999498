import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/login";
import LandingPage from "./Pages/landingPage";
import OTPverifyScreen from "./Components/Login/OTPverify";
import { AuthContextProvider } from "./context/AuthContext";
import Protected from "./Components/Common/Protected";
import PostProperty from "./Pages/postProperty";
import PostForm3 from "./Components/Post-Property/post-form3";
import PostForm4 from "./Components/Post-Property/post-form4";
import PostForm5 from "./Components/Post-Property/post-form5";
import PostForm6 from "./Components/Post-Property/post-form6";
import PostForm7 from "./Components/Post-Property/post-form7";
import ListingPage from "./Pages/listingPage";
import { PropertyContextProvider } from "./context/PropertyContext";
import { SearchContextProvider } from './context/SearchContext'
import ListingPage2 from "./Pages/listingPage2";
import ManageProperty from "./Pages/manageProperty";
import UpdateForm3 from "./Components/Update Property/update-form3";
import UpdateForm4 from "./Components/Update Property/update-form4";
import UpdateForm5 from "./Components/Update Property/update-form5";
import UpdateForm6 from "./Components/Update Property/update-form6";
import UpdateForm7 from "./Components/Update Property/update-form7";
import UpdateForm1 from "./Components/Update Property/update-form1";
import ContactedProperty from "./Components/Contacted Property/ContactedProperty";
import SelectLocalityPage from "./Pages/selectLocalityPage";

function App() {

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCityID, setSelectedCityID] = useState();


  return (
    <>
      <AuthContextProvider>
        <SearchContextProvider>
        <PropertyContextProvider>
          <Routes>
            <Route path="/" element={<LandingPage selectedCityID={selectedCityID} setSelectedCityID={setSelectedCityID} selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>} />
            <Route path="/login-signup" element={<Login />} />
            <Route path="/verify-otp" element={<OTPverifyScreen />} />
            <Route path="/post-property-form1" element={<PostProperty />} />
            <Route path="/post-property-form3" element={<PostForm3 />} />
            <Route path="/post-property-form4" element={<PostForm4 />} />
            <Route path="/post-property-form5" element={<PostForm5 />} />
            <Route path="/post-property-form6" element={<PostForm6 />} />
            <Route path="/post-property-form7" element={<PostForm7 />} />
            {/* <Route path="/result-search" element={<Protected><ResultSearch /></Protected>} /> */}
            <Route path="/listing-page" element={<ListingPage selectedCityID={selectedCityID} setSelectedCityID={setSelectedCityID}  selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>} />
            <Route path="/select-locality" element={<SelectLocalityPage selectedCityID={selectedCityID} setSelectedCityID={setSelectedCityID} selectedItems={selectedItems} setSelectedItems={setSelectedItems}/>} />
            <Route path="/listing-page2" element={<ListingPage2 />} />
            <Route path="/manageproperty" element={<ManageProperty />} />
            <Route path="/contactedproperty" element={<ContactedProperty/>} />

            <Route path="/update-property-form1" element={<UpdateForm1 />} />
            <Route path="/update-property-form3" element={<UpdateForm3 />} />
            <Route path="/update-property-form4" element={<UpdateForm4 />} />
            <Route path="/update-property-form5" element={<UpdateForm5/>} />
            <Route path="/update-property-form6" element={<UpdateForm6 />} />
            <Route path="/update-property-form7" element={<UpdateForm7 />} />
          </Routes>
        </PropertyContextProvider>
        </SearchContextProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
