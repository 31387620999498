// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDmNtHsEvaaE_Xnxm7wEIdOPNM9UB33uuI",
  authDomain: "truesquarefeet-a2c71.firebaseapp.com",
  projectId: "truesquarefeet-a2c71",
  storageBucket: "truesquarefeet-a2c71.appspot.com",
  messagingSenderId: "130422358305",
  appId: "1:130422358305:web:c17d917c33aa266e7fbe60",
  measurementId: "G-LFZDZM2C4W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
