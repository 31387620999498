import React, { useEffect, useState } from "react";
import SearchBar from "../../Assests/Images/search-bar.png";
import { getCitiesLocalityListForSearch } from "../../Request/post-propertyRequest";
import { SearchProperty } from "../../context/SearchContext";

const MultipleDropdown = ({handleSearch}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const { state, setState } = SearchProperty();

    const { selectedItems = [], selectedCityID } = state;
  
    const getData = async (inputText) => {
      try {
        const res = await getCitiesLocalityListForSearch({
          cityid: selectedCityID || 0,
          search: inputText,
        });
        setResults(res);
      } catch (error) {
        console.error("Failed to fetch locality list:", error);
      }
    };
  
    const handleInputChange = (e) => {
      const inputText = e.target.value.toLowerCase();
      setSearchTerm(inputText);
      if (inputText.length < 2) {
        setShowDropdown(false);
        return;
      }
      getData(inputText);
      setShowDropdown(true);
    };
  
    const handleSelectItem = (item) => {
      if (!item) return;
      setState((prevState) => ({
        ...prevState,
        selectedItems: [...prevState.selectedItems, item],
        selectedCityID: item.city_id || prevState.selectedCityID,
      }));
      setSearchTerm("");
      setShowDropdown(false);
    };
    const handleRemoveItem = (itemToRemove) => {
        setState((prevState) => {
          const updatedItems = prevState.selectedItems.filter(
            (item) => item !== itemToRemove
          );
          return {
            ...prevState,
            selectedItems: updatedItems,
            selectedCityID: updatedItems.length === 0 ? 0 : prevState.selectedCityID,
          };
        });
      };

    return (
        <div style={{ width: "100%" }}>
            <div className="selected-items">
                {selectedItems?.map((item, index) => (
                    <span key={index} className="selected-item">
                        {item.id > 0 ? item.locality_name : item.city_name}
                        <span
                            className="remove-button"
                            onClick={() => handleRemoveItem(item)}
                        >
                            &#10006;
                        </span>
                    </span>
                ))}
            </div>

            <input
                style={{ width: "100%" }}
                type="text"
                placeholder="Search By Location & City"
                value={searchTerm}
                onChange={handleInputChange}
            />
            <button
                onClick={() => handleSearch()}
                className="Style btn-group align-items-center"
                id="SearchBtn"
                style={{ position: "absolute", right: "0px", marginTop: "-14px" }}
                disabled={state?.selectedItems?.length == 0}
            >
                <img src={SearchBar} alt="search" /> Search
            </button>
            {(results?.length > 0) && showDropdown && (
                <div id="result-dropdown" className="scrollable-dropdown">
                   <ul>
  {results
    ?.filter((item) => {
      let index = state?.selectedItems?.findIndex(item2 => item2.id === item.id);
      return index === -1;
    })
    .sort((a, b) => {
        // Check if all locality_name fields are empty
        const allLocalitiesEmpty = results.every(item => item.locality_name === "");
        if (allLocalitiesEmpty) {
          return 0; // keep the original order
        }
        if (a.locality_name === "" && b.locality_name !== "") {
          return -1; // a comes before b
        }
        if (a.locality_name !== "" && b.locality_name === "") {
          return 1; // b comes before a
        }
        return a.locality_name.localeCompare(b.locality_name); // alphabetical order
    })
    .map((result, index) => (
      <li key={index} onClick={() => handleSelectItem(result)}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>
            {result.id > 0 ? result.locality_name : result.city_name}
          </span>
          <span>
            {result.id > 0 ? "Locality" : "City"}
          </span>
        </div>
      </li>
    ))}
</ul>

                </div>
            )}
        </div>
    );
};

export default MultipleDropdown;
