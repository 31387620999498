import React, { useState, useEffect } from "react";
import Header from "../Common/header";
import GreenHouse from "../../Assests/Images/Green-House.png";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import TryAgain from "../../Assests/Images/try-again.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserProperty } from "../../context/PropertyContext";
import { AddUpdatePostProperty } from "../../Request/post-propertyRequest";

export default function PostForm5() {
  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();
  let navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const Cities = searchParams.get("Cities");
  const PropertyForID = searchParams.get("PropertyForId");
  const BuildingType = searchParams.get("buildingType");
  const PropertyId = searchParams.get("PropertyId");
  const localityID = searchParams.get("localityID");
  const apartment_id = searchParams.get("apartment_id");
  const carpet_area = searchParams.get("carpet_area");
  const builtup_area = searchParams.get("builtup_area");
  const floor_total = searchParams.get("floor_total");
  const floor_no = searchParams.get("floor_no");
  const bhk_type = searchParams.get("bhk_type");
  const super_builtup = searchParams.get("super_builtup");
  const maintainance_cost = searchParams.get("maintainance_cost");
  const number_bathroom = searchParams.get("number_bathroom");
  const expectedYear = searchParams.get("expectedYear");
  const expectedDate = searchParams.get("expectedDate");
  const number_balconies = searchParams.get("number_balconies");
  const property_age = searchParams.get("property_age");
  const expectedBy = searchParams.get("expectedBy");
  const price_negotiable = searchParams.get("price_negotiable");
  const under_loan = searchParams.get("under_loan");
  const rent_amount = searchParams.get("rent_amount");
  const rent_negotiable = searchParams.get("rent_negotiable");
  const rent_deposite = searchParams.get("rent_deposite");
  const agreement_duration = searchParams.get("agreement_duration");
  const plot_area = searchParams.get("plot_area");
  const price = searchParams.get("price");
  const kitchen_type = searchParams.get("kitchen_type")
  const flooring_type = searchParams.get("flooring_type")
  const water_supply = searchParams.get("water_supply")
  const property_facing = searchParams.get("property_facing")
  const furnish_type = searchParams.get("furnish_type")
  const eating_habits = searchParams.get("eating_habits")
  const prefered_tenants = searchParams.get('prefered_tenants')
  const coveredParkingCountparams = searchParams.get("coverd_parking")
  const openParkingCountparams = searchParams.get("open_parking")
  const readytomove_underconstruction = searchParams.get("readytomove_underconstruction")


  const [coveredParkingCount, setCoveredParkingCount] = useState(coveredParkingCountparams || 0);

  useEffect(() => {
    setCityID(Cities);
    setPropertyID(PropertyId);
    setPropertyForID(PropertyForID);
    setPropertyTypeID(BuildingType);
    setUpdateProperties({
      ...updateProperties,
      // form3
      locality_id: localityID,
      apartment_id,
      carpet_area,
      builtup_area,
      floor_total,
      floor_no,
      bhk_type,
      super_builtup,
      plot_area,
      // form 4
      maintainance_cost,
      expectedYear,
      expectedDate,
      number_bathroom,
      number_balconies,
      property_age,
      expectedBy,
      price_negotiable,
      under_loan,
      rent_amount,
      rent_negotiable,
      rent_deposite,
      agreement_duration,
      price,
    });
  }, [
    Cities,
    PropertyId,
    BuildingType,
    PropertyForID,
    localityID,
    apartment_id,
    maintainance_cost,
    number_bathroom,
    number_balconies,
  ]);

  const incrementCoveredPariking = () => {
    setCoveredParkingCount(coveredParkingCount + 1);
    formik.setFieldValue("CoveredParking", coveredParkingCount + 1);
  };

  const decrementCoveredParking = () => {
    if (coveredParkingCount > 0) {
      setCoveredParkingCount(coveredParkingCount - 1);
      // Update Formik field when the value changes
      formik.setFieldValue("CoveredParking", coveredParkingCount - 1);
    }
  };

  const [openParkingCount, setOpenParkingCount] = useState(openParkingCountparams || 0);

  const incrementOpenPariking = () => {
    setOpenParkingCount(openParkingCount + 1);
    formik.setFieldValue("OpenParking", openParkingCount + 1);
  };

  const decrementOpenParking = () => {
    if (openParkingCount > 0) {
      setOpenParkingCount(openParkingCount - 1);
      // Update Formik field when the value changes
      formik.setFieldValue("OpenParking", openParkingCount - 1);
    }
  };

  const Firsturl = `?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`;
  const secondUrl = `&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`;

  const thirdUrl = `&maintainance_cost=${maintainance_cost}&number_bathroom=${number_bathroom}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&number_balconies=${number_balconies}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}&readytomove_underconstruction=${readytomove_underconstruction}`;
  const RentthirdUrl = `&maintainance_cost=${maintainance_cost}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}&readytomove_underconstruction=${readytomove_underconstruction}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      propertyForID == 1
        ? {
            kitchen_type: kitchen_type || [],
            flooring_type: flooring_type || [],
            water_supply: water_supply || [],
            property_facing: property_facing || [],
            furnish_type: furnish_type || [],
            eating_habits: eating_habits || "",
            prefered_tenants: prefered_tenants || "",
            coverd_parking: 0,
            open_parking:0,
          }
        : {
            kitchen_type: kitchen_type || [],
            flooring_type: flooring_type || [],
            water_supply: water_supply || [],
            property_facing: property_facing || [],
            furnish_type: furnish_type || [],

            eating_habits: eating_habits || "",
            number_bathroom: number_bathroom || "1",
            number_balconies: number_balconies || "0",
            prefered_tenants: prefered_tenants || "",
            coverd_parking:  0,
            open_parking: 0,
          },

    validationSchema: Yup.object({
      kitchen_type: Yup.string().required("Please select an option"),
    }),

    onSubmit: async (data) => {
      try {
        let finalData;
        if (propertyForID == 1) {
          const {
            furnish_type,
            coverd_parking,
            open_parking,
            kitchen_type,
            water_supply,
            flooring_type,
            property_facing,
          } = data;
          finalData = {
            // ...updateProperties,
            // ...data,
            id: propertyID || PropertyId,
            property_statusID: 3,
            // coverd_parking: coveredParkingCount,
            // open_parking: openParkingCount,
            furnish_type,
            coverd_parking: coveredParkingCount,
            open_parking: openParkingCount,
            kitchen_type,
            water_supply,
            flooring_type,
            property_facing,
            Step: 4,
            is_draft: true,
          };
        } else {
          const {
            furnish_type,
            kitchen_type,
            number_bathroom,
            number_balconies,
            water_supply,
            prefered_tenants,
            eating_habits,
            flooring_type,
            property_facing,
          } = data;
          finalData = {
            // ...updateProperties,
            // ...data,
            id: propertyID || PropertyId,
            property_statusID: 3,
            coverd_parking: coveredParkingCount,
            open_parking: openParkingCount,
            furnish_type,
            kitchen_type,
            number_bathroom,
            number_balconies,
            water_supply,
            prefered_tenants,
            eating_habits,
            flooring_type,
            property_facing,
            Step: 4,
            is_draft: true,
          };
        }

        await AddUpdatePostProperty(finalData).then((resp) => {
          setUpdateProperties(finalData);
          const saleUrl = `/post-property-form6${Firsturl}${secondUrl}${thirdUrl}&kitchen_type=${data.kitchen_type}&flooring_type=${data.flooring_type}&water_supply=${data.water_supply}&property_facing=${data.property_facing}&furnish_type=${data.furnish_type}&eating_habits=${data.eating_habits}&prefered_tenants=${data.prefered_tenants}&coverd_parking=${coveredParkingCount}&open_parking=${openParkingCount}`;
          const rentUrl = `/post-property-form6${Firsturl}${secondUrl}${RentthirdUrl}&kitchen_type=${data.kitchen_type}&flooring_type=${data.flooring_type}&water_supply=${data.water_supply}&property_facing=${data.property_facing}&furnish_type=${data.furnish_type}&eating_habits=${data.eating_habits}&number_bathroom=${data.number_bathroom}&number_balconies=${data.number_balconies}&prefered_tenants=${data.prefered_tenants}&coverd_parking=${coveredParkingCount}&open_parking=${openParkingCount}`;

          navigate(propertyForID == 1 ? saleUrl : rentUrl);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });
  const url = (`?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`)


  return (
    <>
      <body>
        <Header />
        {/* <Link to="/post-property-form5" className="fa fa-chevron-left Post-Property-Back"></Link> */}

        {/* --------------------------- STEP 5 -------------------------------------- */}
        <section className="Step-5" id="Step-5">
          <div className="left">
          <div className="Post-Property-Back" >
        <div
          className="fa fa-chevron-left postBack desktoponly"
          onClick={() => {
            navigate(propertyForID == 1 ? `/post-property-form4${Firsturl}${secondUrl}${thirdUrl} `: `/post-property-form4${Firsturl}${secondUrl}${RentthirdUrl}`);
            // navigate(`/post-property-form4${url}&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`);
          }}
        ></div>
        </div>
            <img src={ProgressBar} className="ProgressBar" alt="Progressbar" />
            <div className="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>
          <form
            className="right needs-validation"
            data-send="Post-Form6.html"
            novalidate
            onSubmit={formik.handleSubmit}
          >
            <div className="Title">
              <div className="PostBackContainer">
            <div
              className="Post-Property-Back mobileonly"
              onClick={() => {
                navigate(propertyForID == 1 ? `/post-property-form4${Firsturl}${secondUrl}${thirdUrl} `: `/post-property-form4${Firsturl}${secondUrl}${RentthirdUrl}`);
                // navigate(`/post-property-form4${url}&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`);
              }}
            >
              <span className="fa fa-chevron-left  postBack"></span>
            </div>
            <h1 className="Title postFormFont" >A Bit More Information</h1>
            </div>
            </div>
            <div className="Parent">
              <div className="Property">
                <p>Furnishing Types</p>
                <div
                  className="btn-group gap-1 scroll_Class"
                  role="group"
                  aria-required="true"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    id="btnradio1"
                    autocomplete="off"
                    name="furnish_type"
                    value="furnished"
                    onChange={formik.handleChange}
                    defaultChecked = {furnish_type == "furnished"}
                  />
                  <label for="btnradio1" style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}>
                    <i className="fas fa-circle"></i>Furnished
                  </label>
                  <input
                    type="radio"
                    onChange={formik.handleChange}
                    className="btn-check"
                    name="furnish_type"
                    value="semifurnished"
                    id="btnradio2"
                    autocomplete="off"
                    defaultChecked = {furnish_type == "semifurnished"}
                  />
                  <label for="btnradio2" style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}>
                    <i className="fas fa-circle"></i> Semi&nbsp;Furnished
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="furnish_type"
                    id="btnradio3"
                    autocomplete="off"
                    value="unfurnished"
                    onChange={formik.handleChange}
                    defaultChecked = {furnish_type == "unfurnished"}
                  />
                  <label for="btnradio3" style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}>
                    <i className="fas fa-circle"></i> Unfurnished
                  </label>
                </div>
              </div>

              {/*------------------------------- Kitchen Type section------------------ */}
              {(propertyForID || PropertyForID) == 2 ? (
                <div className="Property mb-3">
                  <p>Kitchen Type</p>
                  <div
                    className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                    role="group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn1"
                      autocomplete="off"
                      name="kitchen_type"
                      value="modular"
                      onChange={formik.handleChange}
                      defaultChecked = { kitchen_type == "modular"}
                    />
                    <label
                      for="kitchen_typebtn1"
                      style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Modular{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn2"
                      autocomplete="off"
                      name="kitchen_type"
                      value="coveredshelves"
                      onChange={formik.handleChange}
                      defaultChecked = { kitchen_type == "coveredshelves"}
                    />
                    <label
                      for="kitchen_typebtn2"
                      style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Covered&nbsp;Shelves{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn3"
                      autocomplete="off"
                      name="kitchen_type"
                      value="openshelves"
                      onChange={formik.handleChange}
                      defaultChecked = { kitchen_type == "openshelves"}
                    />
                    <label
                      for="kitchen_typebtn3"
                      style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i>Open&nbsp;Shelves{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/*------------------------------- Kitchen Type section------------------ */}

              {/* ---------------------Reserved parking section--------------- */}
              <div className="Property">
                <p>Reserved parking</p>

                <div
                  className="p1-form col-12 d-flex parking-parent"
                  style={{
                    width: "214.54px",
                    height: "17px",
                    fontSize: "14px",
                    margin: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="couter-css">Open parking</span>
                  <div
                    className="Counter parking-child"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={decrementOpenParking}
                      className="Decreament"
                      data-name="open_parking"
                      // style={{ cursor: 'pointer', margin: '0 5px' }}
                    >
                      -
                    </div>
                    <input
                      type="hidden"
                      name="open_parking"
                      value={openParkingCount}
                    />
                    <span
                      className="Count open_parking"
                      style={{ margin: "0 5px", width: "8px" }}
                    >
                      <b>{openParkingCount}</b>
                    </span>
                    <div
                      onClick={incrementOpenPariking}
                      className="Increament"
                      data-name="open_parking"
                      // style={{ cursor: 'pointer', margin: '0 5px' }}
                    >
                      +
                    </div>
                  </div>
                </div>

                <div
                  className="p1-form col-12 d-flex parking-parent"
                  style={{
                    width: "214.54px",
                    height: "17px",
                    fontSize: "14px",
                    margin: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="couter-css">Covered parking</span>
                  <div
                    className="Counter parking-child"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={decrementCoveredParking}
                      className="Decreament"
                      data-name="covered_parking"
                      // style={{ cursor: 'pointer', margin: '0 5px' }}
                    >
                      -
                    </div>
                    <input
                      type="hidden"
                      name="covered_parking"
                      value={coveredParkingCount}
                    />
                    <span
                      className="Count covered_parking"
                      style={{ margin: "0 5px", width: "8px" }}
                    >
                      <b>{coveredParkingCount}</b>
                    </span>
                    <div
                      onClick={incrementCoveredPariking}
                      className="Increament"
                      data-name="covered_parking"
                      // style={{ cursor: 'pointer', margin: '0 5px' }}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>

              {/* -----------------------------Reserved parking section--------------- */}

              {/*------------------------------- Kitchen Type section------------------ */}
              {(propertyForID || PropertyForID) != 2 ? (
                <div className="Property mb-3">
                  <p>Kitchen Type</p>
                  <div
                    className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                    role="group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn1"
                      autocomplete="off"
                      name="kitchen_type"
                      value="modular"
                      onChange={formik.handleChange}
                      defaultChecked = {kitchen_type == "modular"}
                    />
                    <label
                      for="kitchen_typebtn1"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Modular{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn2"
                      autocomplete="off"
                      name="kitchen_type"
                      value="coveredshelves"
                      onChange={formik.handleChange}
                      defaultChecked = {kitchen_type == "coveredshelves"}
                    />
                    <label
                      for="kitchen_typebtn2"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Covered&nbsp;Shelves{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn3"
                      autocomplete="off"
                      name="kitchen_type"
                      value="openshelves"
                      onChange={formik.handleChange}
                      defaultChecked = {kitchen_type == "openshelves"}
                    />
                    <label
                      for="kitchen_typebtn3"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i>Open&nbsp;Shelves{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/*------------------------------- Kitchen Type section------------------ */}

              {/*------------------------------ total bathroom Section for rent only------------  */}
              {(propertyForID || PropertyForID) == 2 && (
                <div>
                  <div className="Property mb-3">
                    <p>No. of Bathrooms</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="1"
                        autocomplete="off"
                        name="number_bathroom"
                        value="1"
                        onChange={formik.handleChange}
                        defaultChecked = {number_bathroom == 1}
                      />
                      <label
                        for="1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> One{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="2"
                        autocomplete="off"
                        name="number_bathroom"
                        value="2"
                        onChange={formik.handleChange}
                        defaultChecked = {number_bathroom == 2}
                      />
                      <label
                        for="2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Two{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="3"
                        autocomplete="off"
                        name="number_bathroom"
                        value="3"
                        onChange={formik.handleChange}
                        defaultChecked = {number_bathroom == 3}
                      />
                      <label
                        for="3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Three{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn4"
                        autocomplete="off"
                        name="number_bathroom"
                        value="4"
                        onChange={formik.handleChange}
                        defaultChecked = {number_bathroom == 4}
                      />
                      <label
                        for="btn4"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Four{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn5"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5"
                        onChange={formik.handleChange}
                        defaultChecked = {number_bathroom == 5}
                      />
                      <label
                        for="btn5"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {}
                        <i className="fas fa-circle"></i> Five{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn5+"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5+"
                        onChange={formik.handleChange}
                        defaultChecked = {number_bathroom == "5+"}
                      />
                      <label
                        for="btn5+"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five+{" "}
                      </label>
                    </div>
                  </div>

                  <div className="Property mb-3">
                    <p>No. of Balconies</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn0"
                        autocomplete="off"
                        name="number_balconies"
                        value="0"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 0}
                      />
                      <label
                        for="number_balconiesbtn0"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Zero{" "}
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn1"
                        autocomplete="off"
                        name="number_balconies"
                        value="1"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 1}
                      />
                      <label
                        for="number_balconiesbtn1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> One{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn2"
                        autocomplete="off"
                        name="number_balconies"
                        value="2"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 2}
                      />
                      <label
                        for="number_balconiesbtn2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Two{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn3"
                        autocomplete="off"
                        name="number_balconies"
                        value="3"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 3}
                      />
                      <label
                        for="number_balconiesbtn3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Three{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn4"
                        autocomplete="off"
                        name="number_balconies"
                        value="4"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 4}
                      />
                      <label
                        for="number_balconiesbtn4"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Four{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn5"
                        autocomplete="off"
                        name="number_balconies"
                        value="5"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 5}
                      />
                      <label
                        for="number_balconiesbtn5"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn5+"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5+"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == "5+"}
                      />
                      <label
                        for="number_balconiesbtn5+"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five+{" "}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/*------------------------------ total bathroom Section------------  */}

              {/*------------------------------- Kitchen Type section------------------ */}
              {/* <div className="Property mb-3">
                                <p>Kitchen Type</p>
                                <div className="input-group gap-3 gap-md-2 gap-lg-3" role="group">
                                    <input type="radio" className="btn-check" id="kitchen_typebtn1" autocomplete="off"
                                        name="kitchen_type" value="1" onChange={formik.handleChange}
                                    />
                                    <label for="kitchen_typebtn1"> <i className="fas fa-circle"></i> Modular </label>

                                    <input type="radio" className="btn-check" id="kitchen_typebtn2" autocomplete="off"
                                        name="kitchen_type" value="2"
                                        onChange={formik.handleChange}
                                    />
                                    <label for="kitchen_typebtn2"> <i className="fas fa-circle"></i> Covered Shelves </label>

                                    <input type="radio" className="btn-check" id="kitchen_typebtn3" autocomplete="off"
                                        name="kitchen_type" value="3" onChange={formik.handleChange}
                                    />
                                    <label for="kitchen_typebtn3"> <i className="fas fa-circle"></i>Open Shelves </label>

                                </div>
                            </div> */}
              {/*------------------------------- Kitchen Type section------------------ */}

              <div className="Property mb-3">
                <p>Water Supply</p>
                <div
                  className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                  role="group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    id="btn1"
                    autocomplete="off"
                    name="water_supply"
                    value="corporation"
                    onChange={formik.handleChange}
                    defaultChecked = {water_supply == "corporation"}
                    // defaultChecked
                  />
                  <label
                    for="btn1"
                    style={{
                      borderRadius: "66px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> Corporation{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="btn2"
                    autocomplete="off"
                    name="water_supply"
                    value="boring"
                    onChange={formik.handleChange}
                    defaultChecked = {water_supply == "boring"}
                  />
                  <label
                    for="btn2"
                    style={{
                      borderRadius: "66px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> Boring{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="btn3"
                    autocomplete="off"
                    name="water_supply"
                    value="both"
                    onChange={formik.handleChange}
                    defaultChecked = {water_supply == "both"}
                  />
                  <label
                    for="btn3"
                    style={{
                      borderRadius: "66px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> Both{" "}
                  </label>
                </div>
              </div>

              {/* ################################## Rent ###################################### */}

              {(propertyForID || PropertyForID) == 2 && (
                <>
                  <div className="Facing">
                    <p>Prefered Tenant</p>
                    <select
                      onChange={formik.handleChange}
                      className="form-select"
                      id="prefered_tenants"
                      name="prefered_tenants"
                      fdprocessedid="01d1u"
                      value={formik.values.prefered_tenants}
                    >
                      <option selected>Select Prefered Tenant </option>
                      <option value="Family">Family </option>
                      <option value="Bachelor's">Bachelor's </option>
                      <option value="Company">Company </option>
                      <option value="Doesn't matter">Doesn't matter </option>
                    </select>
                  </div>

                  <div className="Property mb-3">
                    <p>Eating Habits</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="eating_habits1"
                        autocomplete="off"
                        name="eating_habits"
                        value="veg"
                        onChange={formik.handleChange}
                        defaultChecked = {eating_habits == "veg"}
                      />
                      <label
                        for="eating_habits1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Veg{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="eating_habits2"
                        autocomplete="off"
                        name="eating_habits"
                        value="nonveg"
                        onChange={formik.handleChange}
                        defaultChecked = {eating_habits == "nonveg"}
                      />
                      <label
                        for="eating_habits2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Non-Veg{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="eating_habits3"
                        autocomplete="off"
                        name="eating_habits"
                        value="doesntmatter"
                        onChange={formik.handleChange}
                        defaultChecked = {eating_habits == "doesntmatter"}
                      />
                      <label
                        for="eating_habits3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i>Doesn't&nbsp;Matter{" "}
                      </label>
                    </div>
                  </div>
                </>
              )}

              {/* -----------------------------------Flooring Type Section------------- */}
              <div className="Flooring">
                <p>Flooring Type</p>
                <select
                  className="form-select"
                  id="Flooring-Box"
                  // multiple
                  name="flooring_type"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.flooring_type}
                >
                  <option selected>Select Options</option>
                  <option value="marbel">Marbel</option>
                  <option value="concrete">Concrete</option>
                  <option value="granite">Granite</option>
                  <option value="ceramic">Ceramic</option>
                  <option value="mosaic">Mosaic</option>
                  <option value="cement">Cement</option>
                  <option value="stone">Stone</option>
                  <option value="vinyl">Vinyl</option>
                  <option value="wood">Wood</option>
                  <option value="virtified">Virtified</option>
                </select>
              </div>

              {/*---------------------------- Property Facing Section-----------------  */}
              <div className="Facing">
                <p>Property Facing</p>
                <select
                  className="form-select"
                  id="Facing-Box"
                  // multiple
                  name="property_facing"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.property_facing}
                >
                  <option selected>Select Options</option>
                  <option value="east">East</option>
                  <option value="west">West</option>
                  <option value="north">North</option>
                  <option value="south">South</option>
                  <option value="northeast">North East</option>
                  <option value="southeast">South East</option>
                  <option value="southwest">South West</option>
                  <option value="idontknow">I dont know</option>
                </select>
              </div>

              {/* ################################## Rent ###################################### */}

              {/* {propertyForID == 2 && (
                                <>
                                    <div className="Facing">
                                        <p>Prefered Tenant</p>
                                        <select
                                            onChange={formik.handleChange}
                                            className="form-select"
                                            id="prefered_tenants"
                                            name="prefered_tenants"
                                            fdprocessedid="01d1u">
                                            <option value="1">Family </option>
                                            <option value="2">Bachelor's </option>
                                            <option value="3">Company </option>
                                            <option value="4">Doesn't matter </option>
                                        </select>
                                    </div>

                                    <div className="Property mb-3">
                                        <p>Eating Habits</p>
                                        <div className="input-group gap-3 gap-md-2 gap-lg-3" role="group">
                                            <input type="radio" className="btn-check" id="eating_habits1" autocomplete="off"
                                                name="eating_habits" value="1" onChange={formik.handleChange}
                                            />
                                            <label for="eating_habits1"> <i className="fas fa-circle"></i> Veg </label>

                                            <input type="radio" className="btn-check" id="eating_habits2" autocomplete="off"
                                                name="eating_habits" value="2"
                                                onChange={formik.handleChange}
                                            />
                                            <label for="eating_habits2"> <i className="fas fa-circle"></i> Non-Veg </label>

                                            <input type="radio" className="btn-check" id="eating_habits3" autocomplete="off"
                                                name="eating_habits" value="3" onChange={formik.handleChange}
                                            />
                                            <label for="eating_habits3"> <i className="fas fa-circle"></i>Doesn't Matter </label>

                                        </div>
                                    </div>
                                </>)} */}

              <button
                type="submit"
                className="Style Next"
                data-bs-target="#ModalID"
                // pop-up validation model
                // data-bs-toggle="modal"
                style={{ float: "right" }}
              >
                {/* <Link className="Style" style={{ margin: 0, padding: 0 }}
                                    to="/post-property-form6">
                                    Next</Link> */}
                Next
              </button>
              <div
                className="modal fade"
                id="ModalID"
                tabindex="-1"
                aria-labelledby="S-Button"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLabel"
                      >
                        Number Verified Failed
                      </h5>
                      <img
                        src={TryAgain}
                        alt="Close-Image"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      Please check the number or try it after sometime.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light text-dark d-block w-100 p-2"
                        data-bs-dismiss="modal"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </body>
    </>
  );
}
