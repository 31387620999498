import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Tag from "../../Assests/Images/tag.png";
import { Button } from "react-bootstrap";
import No_More_images from "../../Assests/Images/No_more_images.png";

export default function SimilarProperties({ similarProperty, setPropertyID }) {
  const [pause, setPause] = useState(false);
  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = price / 10000000;
      return `₹${inCrores} crore`;
    } else if (price >= 100000) {
      const inLakhs = price / 100000;
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };
  function handlepagenavigate(id) {
    setPropertyID(id);
    window.open(`/listing-page2?propertyid=${id}`, "_blank");
  }
  const [isSlidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    const calculateSlidesToShow = () => {
      if (window.innerWidth >= 1000) {
        setSlidesToShow(4);
      } else if (window.innerWidth < 1000 && window.innerWidth >= 600) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(1);
      }
    };
    calculateSlidesToShow();
    window.addEventListener("resize", calculateSlidesToShow);
    return () => {
      window.removeEventListener("resize", calculateSlidesToShow);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isSlidesToShow,
    slidesToScroll: 1,
    autoplay: false, // Disable autoplay
    speed: 300,
    cssEase: "linear",
    pauseOnHover: true,
    swipe: true,
    draggable: true, // Ensure dragging is enabled
    swipeToSlide: true, // Allows swiping to change slides
    beforeChange: (currentSlide, nextSlide) => {
      setPause(true);
      setTimeout(() => setPause(false), 1000);
    },
  };

  return (
    <>
      <body className="Home-Page" >
        <section>
        <div className="container-fluid">
  <div className="row">
    <div className="col">
      <Slider {...settings}>
        {similarProperty.slice(0, 10)?.map((property, key) => (
          <div key={key}>
            <div className="card m-4" style={{ minWidth: "2rem", maxWidth: "100%" , height:430 }}>
              {/* Your card content */}
              {property?.image[0]?.src == null ? (
  <>
    <img
      className="card-img-top object"
      src={No_More_images}
      alt="img"
      style={{
        height: "200px",
        objectFit: "cover",
        width: "100%",
      }}
    />
    <div
      style={{
        position: "absolute",
        top: "-10px",
        right: "-10px",
        backgroundColor: "#8B3D88",
        color: "white",
        padding: "2px",
        borderRadius: "5px",
      }}
    >
      {property.property_types_id === 1
        ? "For Sale"
        : "For Rent"
        }
    </div>
  </>
) : (
  <div style={{ position: "relative" }}>
    <img
      src={property?.image[0]?.src}
      alt="img"
      className="card-img-top"
      style={{
        height: "200px",
        objectFit:
          property?.image[0]?.width >
          property?.image[0]?.height
            ? "cover"
            : "contain",
        width: "100%",
        backgroundColor: "#b8b6b6",
      }}
    />
    <div
      style={{
        position: "absolute",
        top: "-10px",
        right: "-10px",
        backgroundColor: "#8B3D88",
        color: "white",
        padding: "2px",
        borderRadius: "5px",
      }}
    >
      {property.property_types_id === 1
        ? "For Sale"
        : "For Rent"}
    </div>
  </div>
)}

<div className="card-body" >
  <h5 className="card-title d-flex">
    {property.property_types_id === 1 ? formatPrice(property.price) : formatPrice(property.rent_amount)} {" "}
    <img src={Tag} alt="tag" />
    {/* {formatPrice(propertyDetails.price)} */}
  </h5>
  <p className="card-text">
    {" "}
    {`${property?.bhk_type || " "} ${
      property?.building_type || " "
    } ${property?.property_type || " "} in ${
      property?.locality || " "
    } ${property?.city || " "}`}
  </p>
  {/* <p className="card-text">{property.city}</p> */}
  <Button
    className="btn text-white"
    style={{ backgroundColor: "#8B3D88" , position:"fixed" , bottom:50 }}
    key={key}
    onClick={() => {
      handlepagenavigate(property.id);
    }} 
  >
    View property
  </Button>
</div>
</div>
            </div>
        ))}
      </Slider>
    </div>
  </div>
</div>
        </section>
      </body>
    </>
  );
}


