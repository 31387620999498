import React from "react";
import Accord1 from "../../Assests/Images/Accord-1.png";
import { getNearByPlaces } from "../../Request/post-propertyRequest";
import { useEffect, useState } from "react";

export default function ListingPage2Accords(propertyID) {
  const searchParams = new URLSearchParams(window.location.search);
  const userIdParam = searchParams.get("propertyid");

  const [nearByPlacesList, setNearByPlacesList] = useState([]);

  useEffect(() => {
    let data = { propertyid: userIdParam };
    getNearByPlaces(data).then((res) => {
      setNearByPlacesList(res);
    });
  }, []);

  // Create an array to store the transformed data
  const transformedData = [];

  // Iterate through the original data
  nearByPlacesList?.forEach((item) => {
    const { title, name, nearby_index } = item;

    // Check if the title already exists in the transformedData (case-insensitive)
    const existingTitle = transformedData.find(
      (dataItem) => dataItem.title.toLowerCase() === title.toLowerCase()
    );

    // If the title doesn't exist, create a new object and push it to the transformedData
    if (!existingTitle) {
      transformedData.push({
        title,
        data: [
          {
            name,
            distance: nearby_index
              ? `Distance ${nearby_index}`
              : "No nearby index",
            time: "", // Add your time logic here
          },
        ],
      });
    } else {
      existingTitle.data.push({
        name,
        distance: nearby_index ? `Distance ${nearby_index}` : "No nearby index",
        time: "", // Add your time logic here
      });
    }
  });

  // Now, transformedData will contain the desired structure

  return (
    <>
      <body>
        <section className="Accords" id="RailWay">
          {/* Nav tabs */}
          <ul className="btns nav nav-tabs" role="tablist">
            {transformedData.map((item, index) => (
              <li key={index} className="btn nav-item">
                <a
                  className={`nav-link dropdown-toggle ${
                    index === 0 ? "active" : ""
                  }`}
                  data-bs-toggle="tab"
                  href={`#Accord-${index + 1}`}
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          {/* Tab panes */}
          <div className="tab-content Accords">
            {transformedData.map((a, index) => (
              <div
                key={index}
                className={`tab-pane fade ${index === 0 ? "active show" : ""}`}
                id={`Accord-${index + 1}`}
              >
                <h3 className="title">
                  <img src={Accord1} alt="Accord" />
                  {a.title}
                </h3>

                <div className="Catagory">
                  <div className="box row">
                    {a.data.map((item,key)=>(
                        <div key={key} className="col-md-4 col-sm-12">
                      <h3 >{item.name}</h3>
                      <p >{item.distance}</p>
                    </div>
                    ))}
                    </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </body>
    </>
  );
}
