import React from 'react';
import SearchBar2 from "../../Assests/Images/search-bar2.png";
import MultipleDropdownNavMoblie from '../Common/multipleDropdownNavMobile';
import BackArrow from  '../../Assests/Images/BackArrow.png'
import { useNavigate } from 'react-router-dom';

export default function SearchNavbarMobile({handleSearch,state2, Setstate2}) {
    const searchParams = new URLSearchParams(window.location.search);
    const Cities = searchParams.get('Cities');
    const PropertyForID = searchParams.get('property_for');

    const navigate = useNavigate()
    return (
        <>
            <body class="Result-Search">

                {/* ============================================= Search-Navbar ====================================== */}
                <section class="Search-Navbar" id="Search-Navbar">
                <div className="BackArrow" onClick={()=>{
                    navigate("/")
                }} ><img src={BackArrow}/></div>
                    <div class="SearchBar btn-group w-100 p-3" style={{
                        boxShadow:"none"
                    }}>
                        <div class="btn-group w-100 align-items-center">
                           <MultipleDropdownNavMoblie handleSearch={handleSearch} state2={state2} Setstate2={Setstate2} />
                        </div>
                        

                    </div>
                </section>

            </body>
        </>
    )
}
