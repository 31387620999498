import axios from "axios";
import MAIN_URL from "./apiConfig";
import UseStorage from "../Hooks/useStorage";

// Check All Users
export const checkUserAPI = async (params) => {

    try {
        const res = await axios.get(`${MAIN_URL}/User/CheckMobileNumber`, {
            params: params,

        });
        return res?.data;
    } catch (error) {
        console.log(error);
    }
};

// Login User API
export const loginUserAPI = async (params) => {
    try {
        const res = await axios.get(`${MAIN_URL}/Login/UserLogin`, {
            params: params,

        });
        return res?.data;
    } catch (error) {
        console.log(error);
    }
};
// Login User API
export const loginSignUpUserAPI = async (params) => {
    try {
        const res = await axios.get(`${MAIN_URL}/User/LoginUserandRegisterUser`, {
            params: params,

        });
        return res?.data;
    } catch (error) {
        console.log(error);
    }
};


// NearByPlaces Api
export const SaveNearByPlaces = async (data) => {
    try {
      const res = await axios.get(`${MAIN_URL}/PropertyNearBy/GetPropertyNearByPlaces`,{
        params: data,
      });
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

// Add / Save User
export const addUser = async (data) => {
    const { token } = UseStorage();
    if (!data || !token) {
        return;
    }
    try {
        const res = await axios.post(`${MAIN_URL}/User/SaveUser`, data, {
            headers: {
                Authorization: "Bearer " + token
                ,
            },
        });
        return res.data
    } catch (error) {
        console.log(error);
        throw error;
    }
};


