import React, { useState } from "react";
import Header from "../Components/Common/header";
import SideBar_MainSection from "../Components/Listing Pages/sidebar_mainSection";
import filter from "../Assests/Images/filter.png";
import sort from "../Assests/Images/Untitled_design-removebg-preview.png";
import ThreeLine from "../Assests/Images/ThreeLineSort.png";

export default function ListingPage({
  selectedCityID,
  setSelectedCityID,
  selectedItems,
  setSelectedItems,
}) {
  const [Showfilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter(!Showfilter);
  const [showSort, setShowSort] = useState(false);

  const toggleSort = () => setShowSort(!showSort);
  return (
    <>
      <Header />{" "}
      {!Showfilter && !showSort && (
        <>
          <img
            src={filter}
            alt="filter"
            style={{ position: "fixed", zIndex: 10000, bottom: 0, width: 150 ,left:"40px"}}
            onClick={toggleShowFilter}
            className="mobileonly"
          />
        </>
      )}
      {!showSort && !Showfilter && (
        //  <img
        //   src={sort}
        //   alt="filter"
        //   style={{ position: "fixed", zIndex: 10000, bottom: 0, width: 150, right:0,}}
        //   onClick={toggleSort}
        //   className="mobileonly"
        // />
        <button
          style={{
            position: "fixed",
            zIndex: 10000,
            bottom: "9px",
            width: 150,
            right: "40px",
            background: "#FFFF",
            borderRadius: "57.5px",
            border: "2px solid #C4C4C4",
            height: "47px",
            color: "#777777",
            fontSize: "16.33px",
            lineHeight: "19.6px",
            fontWeight: 700,
          }}
          className="mobileonly"
          onClick={toggleSort}
        >
          Sort <img src={ThreeLine}/>
        </button>
      )}
      <SideBar_MainSection
        Showfilter={Showfilter}
        toggleShowFilter={toggleShowFilter}
        showSort={showSort}
        setShowSort={setShowSort}
        toggleSort={toggleSort}
      />
      {/* 
{    showSort &&   <SortScreen 
       showSort ={showSort}
       setShowSort = {setShowSort} />} */}
    </>
  );
}
