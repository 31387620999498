import React from "react";
import Banner2 from "../../Assests/Images/Banner-2.png";
import { useEffect, useState } from "react";
import { getPropertiesVieswersList } from "../../Request/post-propertyRequest";
import { UserDetailsByID } from "../../Request/search-propertyRequests";
import Contact from "../Listing Pages 2/contact";

export default function Accords1() {
  const [viewes, setViewers] = useState();
  const [userDetails, setUserDetails] = useState([]);
  const [noDetails,setNoDetails]=useState(false)

  const style = {
    text: {
      fontSize: "12px",
    },
    btn1: {
      background: "#8b3d88",
      color: "white",
    },
  };

  // Formatting options
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  useEffect(() => {
    let userId = localStorage.getItem("userID");

    getPropertiesVieswersList({ user_id: userId })?.then((res) => {
      if (res == "No data found for selected user.") {
        setNoDetails(true)
      }else{
        setViewers(res);
      }  
    });
  }, []);

  const handleContactDetils = (id) => {
    UserDetailsByID(id).then((res) => {
      setUserDetails(res);
    });
  };

  return (
    <>
      <div className="tab-pane fade show bg-white rounded-4 my-4" id="Accord-1">

        {noDetails ? (
          <div className="container p-5 m-4">
            <h4>You have not contacted any property.</h4>
          </div>) :
                  (<div className="container">
                  <div className="row  m-2 p-2">
                    {/* <div className="col-6 align-items-center">
                      <b>Properties</b>
                    </div> */}
                    {/* <div className="col-4">
        
                      <b>Visited At</b>
        
                    </div> */}
                    {/* <span className="col-4">
                        <b>Views</b>
                      </span> */}
                    {/* <div className="col-2">
        
                      <b>Contact Details</b>
        
                    </div> */}
                  </div>
        
        
                  {/* {viewes?.map((view, i) => (
                    <div className="row col-12 m-2 ">
                      <div className="col-4 d-flex  p-0">
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={Banner2}
                          alt="img"
                        />
                        <div className="m-1">
                          <span style={style.text}>
                            42, Regal Colony Model Town, Mumbai
                          </span>
                          <p style={style.text}>ID: ARZ-32445</p>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-4  p-0">
                            <span>
                              {new Date(view.TimeStamp).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </span>
                          </div>
                          {/* <div className="col-3 ms-4">
                            <span>
                              <img src={Eye} alt="eye" /> 200
                            </span>
                          </div> */}
                  {/* </div>
                      </div>
                      <div className="col-2 p-1 rounded-5">
                        <button
                          className="btn"
                          style={style.btn1}
                          data-bs-toggle="modal"
                          data-bs-target="#contactinfo"
                          onClick={() => handleContactDetils(view?.Propertydetails[0].user_id)}
                        >
                          <span>Contact Owner</span>
                        </button>
                      </div>
                    </div> */}
        
                  {/* <div
                    className="table-responsive"
                    style={{ overflowX: "hidden", overflowY: "hidden" }}
                  > */}
                  <table className="table " style={{ color: "LightGray" }}>
                    <thead >
                      <tr className="bg-primary">
                        <th>Properties ㅤ ㅤ ㅤ ㅤ</th>
                        <th>Visited At  ㅤ ㅤ ㅤ ㅤ </th>
                        <th>Contact Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {viewes?.map((view, i) => (
                <tr className="row col-12 m-2">
                    <td className="col-4 d-flex p-0">
                      <img
                        style={{ width: "50px", height: "50px" }}
                        src={Banner2}
                        alt="img"
                      />
                      <div className="m-1">
                        <span style={style.text}>
                          {`${view.Propertydetails[0]?.bhk_type} ${view.Propertydetails[0]?.building_type} ${view.Propertydetails[0]?.property_type} in ${view.Propertydetails[0]?.city}`}
                        </span>
                        <p style={style.text}>ID: ARZ-32445</p>
                      </div>
                    </td>
                    <td className="col-4">
                      <div className="row">
                        <div className="col-4 p-0">
                          <span>
                            {new Date(view.TimeStamp).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="col-2">
                      <button
                        className="btn"
                        style={style.btn1}
                        data-bs-toggle="modal"
                        data-bs-target="#contactinfo"
                        onClick={() => handleContactDetils(view?.Propertydetails[0].user_id)}
                      >
                        <span>Contact Owner</span>
                      </button>
                    </td>
                  </tr>
                  ))} */}
        
                      {[
                        ...new Set(
                          viewes?.map((view) => view.Propertydetails[0]?.id)
                        ),
                      ]?.map((propertyId, i) => {
                        const viewsWithSameId = viewes?.filter(
                          (view) => view.Propertydetails[0]?.id === propertyId
                        );
                        viewsWithSameId.sort((a, b) => new Date(b.TimeStamp) - new Date(a.TimeStamp)); // Sort by date
        
        
                        const firstView = viewsWithSameId[0]; // You can choose any view from the filtered list
        
                        return (
                          <tr key={i} className="">
                            <td className="" >
                              <img
                                style={{ width: "70px", height: "50px", objectFit: "contain" }}
                                src={`${firstView.Propertydetails[0]?.image[0]?.src}`}
                                alt={`${firstView.Propertydetails[0]?.image[0]?.img_name}`}
                              />
                              <div className="display-flex">
                                <p> {`${firstView.Propertydetails[0]?.bhk_type} ${firstView.Propertydetails[0]?.building_type} ${firstView.Propertydetails[0]?.property_type} in ${firstView.Propertydetails[0]?.city}`}</p>
        
                                <p style={style.text}>ID: ARZ-32445</p>
                              </div>
        
                            </td>
                            <td className="col-4 pt-5">
                              {new Date(firstView.TimeStamp).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </td>
                            <td className="pt-5">
                              <button
                                className="btn btn-sm"
                                style={style.btn1}
                                data-bs-toggle="modal"
                                data-bs-target="#contactinfo"
                                onClick={() =>
                                  handleContactDetils(
                                    firstView?.Propertydetails[0].user_id
                                  )
                                }
                              >
                                <span>Contact Owner</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>)
        }

      </div>
      <Contact userDetails={userDetails} />
    </>
  );
}
