import React from 'react';
import QuarterLeft from "../../Assests/Images/Quarter-left.png";
import Quarter1 from "../../Assests/Images/Quarter-1.png";
import Quarter2 from "../../Assests/Images/Quarter-2.png";
import Quarter3 from "../../Assests/Images/Quarter-3.png";
import Quarter4 from "../../Assests/Images/Quarter-4.png";

export default function Quarter() {
    return (
        <>
            <body className="Home-Page">
                <section className="Quarter" id="Quarter">
                    <div className="left">
                        <img src={QuarterLeft} />
                    </div>
                    <div className="right" style={{ marginLeft: "10px"}}>
                        <p ><b style={{ fontWeight: "bold", color: "#000", fontSize:"19px"}}>Sell or Rent your property faster with</b> Our client<span>centric approach and easy-to-use platform is
                            what makes us stand from the crowd</span></p>
                       
                        
                        <div className="Catagory row">
                            <div className="box col-6" style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:"center",
                                flexDirection:"column"
                            }}>
                                <img src={Quarter1} alt="img" />
                                <h4>Customer centric</h4>
                                <p>Tailored your convience.</p>
                            </div>
                            <div className="box col-6" style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:"center",
                                flexDirection:"column"
                            }}>
                                <img src={Quarter2} alt="img" />
                                <h4>Low Comission</h4>
                                <p>Tailored your convience.</p>
                            </div>
                            <div className="box col-6" style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:"center",
                                flexDirection:"column",
                            }}>
                                <img src={Quarter3} alt="img" />
                                <h4>No Hidden Charges</h4>
                                <p>Tailored your convience.</p>
                            </div>
                            <div className="box col-6" style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:"center",
                                flexDirection:"column"
                            }}>
                                <img src={Quarter4} alt="img" style={{ height: "50px"}} />
                                <h4>Customer centric</h4>
                                <p>Tailored your convience.</p>
                            </div>
                        </div>
                    </div>
                </section>

            </body>
        </>
    )
}
