import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ServicesBg from "../../Assests/Images/Services-bg.svg";

import Label1 from "../../Assests/Images/Label1.png";
import Label2 from "../../Assests/Images/Label2.png";
import Label3 from "../../Assests/Images/Label3.png";
import Label4 from "../../Assests/Images/Label4.png";
import Property1 from "../../Assests/Images/Properties-1.png";
import Property2 from "../../Assests/Images/Properties-2.png";
import Property3 from "../../Assests/Images/Properties-3.png";
import Property4 from "../../Assests/Images/Properties-4.png";
import MultipleDropdown from "../Common/multipleDropdown";
import { useNavigate } from "react-router-dom";
import { getPropertyFor } from "../../Request/post-propertyRequest";
import { SearchProperty } from "../../context/SearchContext";

export default function Services({
  selectedItems,
}) {
  const propertyObject = {
    Locality: [],
    PropertyType: [],
    Cities: [],
    BHK: [],
    MinPrice: 0,
    MaxPrice: 0,
    Ready_Underconstruction: "",
    PropertyFacing: "",
    PropertyFor: "",
  };

  const propertyTypes = [
    { name: "Residential Apartment", id: 1 },
    { name: "Independent house/Villa", id: 2 },
    { name: "Standalone Building", id: 3 },
  ];
  const [selectedPropertyFor, setSelectedPropertyFor] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFacing, setSelectedFacing] = useState("");
  const [furnishingType,setFurhishingType] = useState("")
  const [preTenent,setpreTenent]= useState("")

  // const [selectedCityID, setSelectedCityID] = useState(0);

  const [isOpenPropertyType, setIsOpenPropertyType] = useState(false);
  const [isOpenBHKType, setIsOpenBHKType] = useState(false);
  const [isOpenconstructiontype, setIsOpenconstructiontype] = useState(false);
  const [isOpenfacing, setIsOpenfacing] = useState(false);
  const [selectedBHKTypes, setSelectedBHKTypes] = useState([]);

  const [isOpen2, setIsOpen2] = useState(false);
  const [MinPrice, setMinPrice] = useState(500000);
  const [MaxPrice, setMaxPrice] = useState(1000000000);
  // const [minArea2, setMinArea2] = useState(0);
  // const [maxArea2, setMaxArea2] = useState(100);


  const { state, setState,handleSearch } = SearchProperty();


  const handleDropdownChangeFurnishing =(value)=>{
    setFurhishingType(value)
  }
  const handleDropdownChangeTenant = (value)=>{
    setpreTenent(value)
  }

  const toggleFilter2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpenPropertyType(false);
    setIsOpenBHKType(false);
    setIsOpenfacing(false);
  };

  const handleMinPriceChange = (event) => {
    const newMinPriceChange = parseInt(event.target.value);
    //   setMinPrice(event.target.value);
    if (newMinPriceChange <= MaxPrice) {
      setMinPrice(newMinPriceChange);
      updateSliderTrack(newMinPriceChange,MaxPrice)
    }
  };

  const handleMaxPriceChange = (event) => {
    const newMaxPriceChange = parseInt(event.target.value);
    //   setMaxPrice(event.target.value);
    if (newMaxPriceChange >= MinPrice) {
      setMaxPrice(newMaxPriceChange);
      updateSliderTrack(MinPrice,newMaxPriceChange)
    }
  };

  const updateSliderTrack = (minValue, maxValue) => {
    const dropdownMenu = document.querySelector('.dropdown-menu.show');

    if (dropdownMenu) {
      const minInput = dropdownMenu.querySelector('input[type="range"]:first-of-type');
      const maxInput = dropdownMenu.querySelector('input[type="range"]:last-of-type');
      const track = dropdownMenu.querySelector('.slider-track');
  
      const minPercent = ((minValue - parseInt(minInput.min)) / (parseInt(minInput.max) - parseInt(minInput.min))) * 100;
      const maxPercent = ((maxValue - parseInt(minInput.min)) / (parseInt(minInput.max) - parseInt(minInput.min))) * 100;
  
      track.style.left = `${minPercent}%`;
      track.style.width = `${maxPercent - minPercent}%`;
    }
  };

  const navigate = useNavigate();

  const handleSearch2 = () => {
    console.log("Services");

    let url = `/listing-page?property_for=${selectedPropertyFor}`;

    if (selectedTypes?.length > 0) {
      url = url + `&PropertyType=${selectedTypes.toString()}`;
    }
    if (selectedBHKTypes?.length > 0) {
      url = url + `&BHKType=${selectedBHKTypes.toString()}`;
    }
    if (selectedType != "") {
      url = url + `&Ready_Underconstruction=${selectedType}`;
    }
    if (selectedFacing != "") {
      url = url + `&PropertyFacing=${selectedFacing}`;
    }
    if (state.selectedCityID > 0) {
      url = url + `&Cities=${state.selectedCityID}`;
    }
    if (furnishingType != "") {
      url = url + `&furnishingType=${furnishingType}`;
    }
    if (preTenent != "") {
      url = url + `&preferedtenent=${preTenent}`;
    }
    if (
      state.selectedItems
        ?.filter((item) => item.id != 0)
        ?.map((item) => item?.id)
        ?.filter((item) => item.id != 0)
        .toString().length > 0
    ) {
      url =
        url +
        `&Locality=${state.selectedItems
          ?.filter((item) => item.id != 0)
          ?.map((item) => item?.id)
          ?.filter((item) => item.id != 0)
          .toString()}`;
    }

    if (MaxPrice > 0) {
      url = url + `&MaxPrice=${MaxPrice}`;
    }
    url = url + `&MinPrice=${MinPrice}`;

    navigate(url);
  };

  const toggleSelection = (typeName) => {
    const isSelected = selectedTypes.includes(typeName);
    if (isSelected) {
      setSelectedTypes(selectedTypes.filter((name) => name !== typeName));
    } else {
      setSelectedTypes([...selectedTypes, typeName]);
    }
  };
  const handleBHKTypeSelection = (value) => {
    const isSelected = selectedBHKTypes.includes(value);
    if (isSelected) {
      setSelectedBHKTypes(selectedBHKTypes.filter((type) => type !== value));
    } else {
      setSelectedBHKTypes([...selectedBHKTypes, value]);
    }
  };
  const toggleDropdown = () => {
    setIsOpenPropertyType(!isOpenPropertyType);
    setIsOpenBHKType(false);
    setIsOpenconstructiontype(false);
    setIsOpenfacing(false);
    setIsOpen2(false);
  };

  const toggleDropdownTenant =()=>{
    setIsOpenPropertyType(false);
    setIsOpenBHKType(false);
    setIsOpenconstructiontype(false);
    setIsOpenfacing(false);
    setIsOpen2(false);
  }

  const toggleDropdownBHK = () => {
    setIsOpenBHKType(!isOpenBHKType);
    setIsOpenPropertyType(false);
    setIsOpenconstructiontype(false);
    setIsOpenfacing(false);
    setIsOpen2(false);
  };
  const toggleDropdownfacing = () => {
    setIsOpenfacing(!isOpenfacing);
    setIsOpenPropertyType(false);
    setIsOpenconstructiontype(false);
    setIsOpenBHKType(false);
    setIsOpen2(false);
  };
  const toggleDropdownconst = () => {
    setIsOpenBHKType(!isOpenconstructiontype);
    setIsOpenPropertyType(false);
    setIsOpenBHKType(false);
    setIsOpenfacing(false);
    setIsOpen2(false);
  };

  const toggleDropdownFurnishing = ()=>{
    setIsOpenBHKType(false);
    setIsOpenPropertyType(false);
    setIsOpenfacing(false);
    setIsOpen2(false);
  }

  const handleDropdownChange = (value) => {
    setSelectedType(value);
  };

  const handleDropdownChangefacing = (value) => {
    setSelectedFacing(value);
  };
  const [slidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    const calculateSlidesToShow = () => {
      if (window.innerWidth >= 1000) {
        setSlidesToShow(5);
      } else if (window.innerWidth < 1000 && window.innerWidth >= 600) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(2);
      }
    };
    calculateSlidesToShow();

    window.addEventListener("resize", calculateSlidesToShow);

    return () => {
      window.removeEventListener("resize", calculateSlidesToShow);
    };
  }, []);

  const [pause, setPause] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slickPause: true,
    pauseOnHover: true,
    beforeChange: (currentSlide, nextSlide) => {
      setPause(true);
      setTimeout(() => setPause(false), 2000);
    },
  };

  const slider = [
    {
      propertyType: "Luxury Ville on Rent",
      image: Property1,
      rent1: "₹12L/month",
      rent2: "₹1.250 sq ft 2.8KH",
      locality: " North Area",
      city: "Mumbai",
    },
    {
      propertyType: "Apartment on Rent",
      image: Property2,
      rent1: "₹25L/month",
      rent2: "₹5.150 sq ft 2.8KH",
      locality: "Camp",
      city: "Pune",
    },
    {
      propertyType: "Luxury House on Rent",
      image: Property3,
      rent1: "₹10L/month",
      rent2: "₹1.150 sq ft 2.8KH",
      locality: "South East",
      city: "Delhi",
    },
    {
      propertyType: "Luxury Ville on Rent",
      image: Property4,
      rent1: "₹20L/month",
      rent2: "₹3.150 sq ft 2.8KH",
      locality: "South East",
      city: "Delhi",
    },
    {
      propertyType: "Luxury Ville on Rent",
      image: Property2,
      rent1: "₹12L/month",
      rent2: "₹1.250 sq ft 2.8KH",
      locality: " North Area",
      city: "Mumbai",
    },
    {
      propertyType: "Luxury Home on Rent",
      image: Property1,
      rent1: "₹25L/month",
      rent2: "₹5.150 sq ft 2.8KH",
      locality: "Camp",
      city: "Pune",
    },
    {
      propertyType: "Luxury House on Rent",
      image: Property4,
      rent1: "₹10L/month",
      rent2: "₹1.150 sq ft 2.8KH",
      locality: "South East",
      city: "Delhi",
    },
    {
      propertyType: "Luxury Ville on Rent",
      image: Property3,
      rent1: "₹20L/month",
      rent2: "₹3.150 sq ft 2.8KH",
      locality: "South East",
      city: "Delhi",
    },
  ];

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = (price / 10000000).toFixed(2);
      return `₹${inCrores} cr`;
    } else if (price >= 100000) {
      const inLakhs = (price / 100000).toFixed(2);
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };

  return (
    <>
      <body className="Home-Page">
        <section className="Services" id="Services">
          <img src={ServicesBg} alt="Back" className="Services-bg" />
          <div className="Search">
            <div className="TopIn">
              <div className="Buttons">
                <ul className="btns nav nav-tabs border-0" role="tablist">
                  <li
                    className="btn nav-item"
                    onClick={() => {
                      setSelectedPropertyFor(1);
                      setMinPrice(500000)
                      setMaxPrice(1000000000)
                    }}
                  >
                    <a
                      // className="nav-link Style active Buy"
                      className={`nav-link Style ${
                        selectedPropertyFor === 1 ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                    >
                      Buy
                    </a>
                  </li>
                  <li
                    className="btn nav-item"
                    onClick={() => {
                      setSelectedPropertyFor(2);
                      setMinPrice(2000)
                      setMaxPrice(2000000)
                    }}
                  >
                    <a
                      // className="nav-link Style Rent"
                      className={`nav-link Style ${
                        selectedPropertyFor === 2 ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                    >
                      Rent
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="Mid">
              <div className="Catagory btn-group w-100 justify-content-between align-items-end">
                <MultipleDropdown
                  handleSearch={handleSearch2}
                  // selectedCityID={selectedCityID}
                  // setSelectedCityID={setSelectedCityID}
                  // selectedItems={selectedItems}
                  // setSelectedItems={setSelectedItems}
                />
              </div>
            </div>

            <div className="Down">
              <li className="box Buy">
                <p>Property Type</p>
                <a
                  onClick={toggleDropdown}
                  className="dropdown-toggle"
                  data-tab=".One"
                >
                  Select (<span>{selectedTypes.length}</span>)
                </a>
                {isOpenPropertyType && (
                  <div className="Show-Drob-Down horizontal-dropdown">
                    <div className="box Check-Box One Show">
                      {propertyTypes.map((type, index) => (
                        <div key={index} className="horizontal-checkbox">
                          <input
                            type="checkbox"
                            className="btn-check ptype"
                            value={type.id}
                            id={`btncheck${type.name}`}
                            autoComplete="off"
                            checked={selectedTypes.includes(type.id)}
                            onChange={() => toggleSelection(type.id)}
                          />
                          <label
                            className="btn btn-outline-primary handle-filter"
                            data-index="ptype"
                            htmlFor={`btncheck${type.name}`}
                            data-label="y"
                          >
                            {type.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>

              {/* <li className="box BKH">
                                <p>BKh Type</p>
                                <a className="dropdown-toggle" data-tab=".Two">BKH</a>
                            </li> */}

              <li className={`box Buy`}>
                <p>BHK Type</p>
                <div className="dropdown">
                  <a
                    onClick={toggleDropdownBHK}
                    className="dropdown-toggle"
                    data-tab=".Two"
                  >
                    BHK
                  </a>
                  {isOpenBHKType && (
                    <div className="Show-Drob-Down">
                      <div
                        className="box Check-Box Two BKH Show"
                        style={{ width: "335px" }}
                      >
                         <input
                          type="checkbox"
                          className="btn-check bhk_type bhk_type_desk"
                          id="deskbhk"
                          value="RK"
                          autoComplete="off"
                          checked={selectedBHKTypes.includes("RK")}
                          onChange={() => handleBHKTypeSelection("RK")}
                        />
                        <label
                          className="btn btn-outline-primary handle-filter"
                          data-index="bhk_type"
                          data-value=""
                          for="deskbhk"
                        >
                          &nbsp; 1 RK &nbsp;
                        </label>
                        <input
                          type="checkbox"
                          className="btn-check bhk_type bhk_type_desk"
                          id="deskbhk1"
                          value="1BHK"
                          autoComplete="off"
                          checked={selectedBHKTypes.includes("1BHK")}
                          onChange={() => handleBHKTypeSelection("1BHK")}
                        />
                        <label
                          className="btn btn-outline-primary handle-filter"
                          data-index="bhk_type"
                          data-value=""
                          for="deskbhk1"
                        >
                          1 BHK
                        </label>

                        <input
                          type="checkbox"
                          className="btn-check bhk_type bhk_type_desk"
                          id="deskbhk2"
                          value="2BHK"
                          autoComplete="off"
                          checked={selectedBHKTypes.includes("2BHK")}
                          onChange={() => handleBHKTypeSelection("2BHK")}
                        />
                        <label
                          className="btn btn-outline-primary handle-filter"
                          data-index="bhk_type"
                          data-value=""
                          for="deskbhk2"
                        >
                          2 BHK
                        </label>
                        <input
                          type="checkbox"
                          className="btn-check bhk_type bhk_type_desk"
                          id="deskbhk3"
                          value="3BHK"
                          autoComplete="off"
                          checked={selectedBHKTypes.includes("3BHK")}
                          onChange={() => handleBHKTypeSelection("3BHK")}
                        />
                        <label
                          className="btn btn-outline-primary handle-filter"
                          data-index="bhk_type"
                          data-value=""
                          for="deskbhk3"
                        >
                          3 BHK
                        </label>
                        <input
                          type="checkbox"
                          className="btn-check bhk_type bhk_type_desk"
                          id="deskbhk4"
                          value="4BHK"
                          autoComplete="off"
                          checked={selectedBHKTypes.includes("4BHK")}
                          onChange={() => handleBHKTypeSelection("4BHK")}
                        />
                        <label
                          className="btn btn-outline-primary handle-filter"
                          data-index="bhk_type"
                          data-value=""
                          for="deskbhk4"
                        >
                          4 BHK
                        </label>
                       
                        {/* Repeat this pattern for other BHK types */}
                      </div>
                    </div>
                  )}
                </div>
              </li>

              {/* <li className="box Budget"> */}
              {/* <p>Budget</p>
                                <a className="dropdown-toggle" id="Budget" data-tab=".Three">
                                    <span className="Min-Budget" data-tab=".Three">
                                        15,000
                                    </span>
                                    <span className="Max-Budget" data-tab=".Three">
                                        {" "}
                                        - 20,000{" "}
                                    </span>
                                </a> */}

              {/*========================================== Pricing Range Bar ================================   */}
              {/* <li className={`box Budget Pricing ${isOpen2 ? 'open' : ''}`}>
                    <div className="filter-title" onClick={toggleFilter2}>
                        <p>Pricing</p>
                        <i className={`fas ${isOpen2 ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                    </div>
                    {isOpen2 && (
                        <div className="Catagory">
                            <div className="Range-Box">
                                <div className="values">
                                    <span>{MinPrice}</span>
                                    <span> - </span>
                                    <span>{MaxPrice}</span>
                                </div>
                                <div className="Range-container">
                                    <div className="slider-track" style={{ background: 'purple' }}></div>
                                    <input
                                        type="range"
                                        min="0"
                                        max="5000"
                                        value={MinPrice}
                                        onChange={handleMinPriceChange}
                                    />
                                    <input
                                        type="range"
                                        min="0"
                                        max="5000"
                                        value={MaxPrice}
                                        onChange={handleMaxPriceChange}
                                    />
                                </div>
                            </div>
                            <div className="btn-group w-100 justify-content-between mt-3">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>Min Budget</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownMenuButton2">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                {MinPrice}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>Max Budget</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownMenuButton2">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                {MaxPrice}
                                            </a>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </li> */}

              <li className="box Buy">
                <p>Budget</p>
                {/* <p>Price Range</p> */}
                <div className="dropdown">
                  <a
                    className="dropdown-toggle"
                    onClick={toggleFilter2}
                    href="#"
                    role="button"
                    id="DropdownPriceToggle"
                    data-bs-toggle="dropdown"
                    aria-expanded={isOpen2}
                  >
                    {formatPrice(MinPrice)} - {formatPrice(MaxPrice)}
                  </a>
                  <ul
                    className={`dropdown-menu dropdown-menu-start ${
                      isOpen2 ? "show" : ""
                    }`}
                    id="doroppeee"
                    aria-labelledby="DropdownPriceToggle"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      marginLeft: "40%",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      zIndex: 1,
                      padding: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      minWidth: "250px",
                    }}
                  >
                    <li className="Range-Box">
                      <div className="values">
                        <span>{formatPrice(MinPrice)}</span>
                        <span> - </span>
                        <span>{formatPrice(MaxPrice)}</span>
                      </div>
                      <div className="Range-container" style={{
                        position:"relative",
                    background:"#EFE4EE",
                    height:"13px",
                    borderRadius:"32px"
                  }}>
                        <div
                          className="slider-track"
                          style={{ background: "purple",
                            height:"13px"
                           }}></div>
                        {selectedPropertyFor == 1 ? (
                          <>
                            <input
                              type="range"
                              min="500000"
                              max="1000000000"
                              value={MinPrice}
                              onChange={handleMinPriceChange}
                              step="500000"
                            />
                            <input
                              type="range"
                              min="0"
                              max="1000000000"
                              value={MaxPrice}
                              onChange={handleMaxPriceChange}
                              step="500000"
                            />
                          </>
                        ) : (
                          <>
                            <input
                              type="range"
                              min="2000"
                              max="2000000"
                              value={MinPrice}
                              onChange={handleMinPriceChange}
                              step="1000"
                            />
                            <input
                              type="range"
                              min="0"
                              max="2000000"
                              value={MaxPrice}
                              onChange={handleMaxPriceChange}
                              step="1000"
                            />
                          </>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              {/*========================================== Pricing Range Bar ================================   */}

              {/* </li> */}
            { selectedPropertyFor == 1 ? ( <><li className="box Buy">
            <p>Construction Type</p>
            <div className="dropdown">
                <a
                className="dropdown-toggle"
                onClick={toggleDropdownconst}
                href="#"
                role="button"
                id="DropdownIDToggle"
                data-bs-display="static"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >
                {selectedType == 1
                    ? "Ready To Move"
                    : selectedType == 2
                    ? "Under Construction"
                    : "Select Option"}
                </a>
                <ul
                // className="dropdown-menu dropdown-menu-start"
                className={`dropdown-menu dropdown-menu-start ${
                    isOpenconstructiontype ? "show" : ""
                }`}
                aria-labelledby="DropdownIDToggle"
                >
                <li onClick={() => handleDropdownChange("1")}>
                    Ready To Move
                </li>
                {selectedPropertyFor === 1 && (
                    <li onClick={() => handleDropdownChange("2")}>
                    Under Construction
                    </li>
                )}
                </ul>
            </div>
            </li>
            <li className="box Buy">
            <p>Property Facing</p>
            <div className="dropdown">
                <a
                className="dropdown-toggle"
                onClick={toggleDropdownfacing}
                href="#"
                role="button"
                id="DropdownIDToggle2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-display="static"
                >
                {selectedFacing || "none"}
                </a>
                <ul
                className="dropdown-menu"
                aria-labelledby="DropdownIDToggle2"
                >
                <li onClick={() => handleDropdownChangefacing("East")}>
                    East
                </li>
                <li onClick={() => handleDropdownChangefacing("West")}>
                    West
                </li>
                <li onClick={() => handleDropdownChangefacing("North")}>
                    North
                </li>
                <li onClick={() => handleDropdownChangefacing("South")}>
                    South
                </li>
                <li
                    onClick={() => handleDropdownChangefacing("North East")}
                >
                    North East
                </li>
                <li
                    onClick={() => handleDropdownChangefacing("South East")}
                >
                    South East
                </li>
                <li
                    onClick={() => handleDropdownChangefacing("South West")}
                >
                    South West
                </li>
                <li
                    onClick={() => handleDropdownChangefacing("North West")}
                >
                    North West
                </li>
                </ul>
            </div>
            </li></>) : <>
            <li className="box Buy">
  <p>Furnishing Type</p>
  <div className="dropdown">
    <a
      className="dropdown-toggle"
      onClick={toggleDropdownFurnishing}
      href="#"
      role="button"
      id="DropdownIDToggleFurnishing"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-display="static"
    >
      {furnishingType || "Select Furnishing"}
      {/* select furnishing */}
    </a>
    <ul
      className="dropdown-menu"
      aria-labelledby="DropdownIDToggleFurnishing"
    >
      <li 
      onClick={() => handleDropdownChangeFurnishing("furnished")}
      >
        Furnished
      </li>
      <li 
      onClick={() => handleDropdownChangeFurnishing("unfurnished")}
      >
        Unfurnished
      </li>
      <li 
      onClick={() => handleDropdownChangeFurnishing("semifurnished")}
      >
        Semi Furnished
      </li>
    </ul>
  </div>
</li>
<li className="box Buy">
  <p>Preferred Tenant</p>
  <div className="dropdown">
    <a
      className="dropdown-toggle"
      onClick={toggleDropdownTenant}
      href="#"
      role="button"
      id="DropdownIDToggleTenant"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-display="static"
    >
      {preTenent || "Select Tenant"}
      {/* Select Tenant */}
    </a>
    <ul
      className="dropdown-menu"
      aria-labelledby="DropdownIDToggleTenant"
    >
      <li 
      onClick={() => handleDropdownChangeTenant("Bachelor's")}
      >
        Bachelors
      </li>
      <li 
      onClick={() => handleDropdownChangeTenant("family")}
      >
        Family
      </li>
      <li 
      onClick={() => handleDropdownChangeTenant("Company")}
      >
        Company
      </li>
      <li 
      onClick={() => handleDropdownChangeTenant("Doesn't matter")}
      >
Doesn't matter
      </li>
    </ul>
  </div>
</li>
            </> }
            </div>

            <div className="Show-Drob-Down">
              <div className="box Check-Box One">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="btncheck1"
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary"
                  for="btncheck1"
                  data-label="y"
                >
                  Apartment
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="btncheck2"
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary"
                  for="btncheck2"
                  data-label="y"
                >
                  Indipendet house/Villa
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="btncheck3"
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary"
                  for="btncheck3"
                  data-label="y"
                >
                  Standalone Building
                </label>
              </div>

              <div className="box Check-Box Two BKH">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="BKH1"
                  value="1"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="BKH1">
                  1 BKH
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="BKH2"
                  value="2"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="BKH2">
                  2 BKH
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="BKH3"
                  value="3"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="BKH3">
                  3 BKH
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="BKH4"
                  value="4"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="BKH4">
                  4 BKH
                </label>
              </div>

              <div className="box Range-Box w-50 Three">
                <div className="values">
                  <p className="m-0">Select Price Range</p>
                  <span id="Range-input-1">15000</span>
                  <span> - </span>
                  <span id="Range-input-2">20000</span>
                </div>
                <div className="Range-container">
                  <div className="slider-track"></div>
                  <input
                    type="range"
                    min="0"
                    max="5000"
                    value="1000"
                    id="slider-1"
                  />
                  <input
                    type="range"
                    min="0"
                    max="5000"
                    value="4000"
                    id="slider-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
