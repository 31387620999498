import React, { useEffect } from "react";
import OneMainSection from "./mainSection-one";
import TwoMainSection from "./mainSection-two";
import BudgetMainSection from "./mainSection-budget";
import ThreeMainSection from "./mainSection-three";
import FourMainSection from "./mainSection-four";
import FiveMainSection from "./mainSection-five";
import { SearchProperty } from "../../context/SearchContext";
import { useNavigate } from "react-router-dom";

export default function MainSection() {
  const searchParams = new URLSearchParams(window.location.search);

  const PropertyForID = searchParams.get("property_for");
  const PropertyType = searchParams.get("PropertyType");
  const Cities = searchParams.get("Cities");
  const Locality = searchParams.get("Locality");
  const BHKType = searchParams.get("BHKType");
  const Ready_Underconstruction = searchParams.get("Ready_Underconstruction");
  const PropertyFacing = searchParams.get("PropertyFacing");
  const MinPrice = searchParams.get("MinPrice");
  const MaxPrice = searchParams.get("MaxPrice");
  const FurnishingType = searchParams.get("furnishingType");
  const preferedtenent = searchParams.get("preferedtenent");

  const { state, setState,  handleSearch } = SearchProperty();

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedPropertyFor: PropertyForID === "null" ? null : (PropertyForID === "" ? null : PropertyForID),
      selectedTypes: PropertyType === "null" ? null : (PropertyType === "" ? null : PropertyType),
      selectedCityID: Cities === "null" ? null : (Cities === "" ? null : Cities),
      Locality: Locality === "null" ? null : (Locality === "" ? null : Locality),
      selectedBHKTypes: BHKType === "null" ? null : (BHKType === "" ? null : BHKType),
      Ready_Underconstruction: Ready_Underconstruction === "null" ? null : (Ready_Underconstruction === "" ? null : Ready_Underconstruction),
      selectedFacing: PropertyFacing === "null" ? null : (PropertyFacing === "" ? null : PropertyFacing),
      MinPrice: MinPrice === "" ? null : MinPrice,
      MaxPrice: MaxPrice === "" ? null : MaxPrice,
      furnishingType: FurnishingType === "null" ? null : (FurnishingType === "" ? null : FurnishingType),
      preTenent: preferedtenent === "null" ? null : (preferedtenent === "" ? null : preferedtenent),
    }));
  }, [
    PropertyForID,
    PropertyType,
    Cities,
    Locality,
    BHKType,
    Ready_Underconstruction,
    PropertyFacing,
    MinPrice,
    MaxPrice,
    FurnishingType,
    preferedtenent,
  ]);

  useEffect(() => {
    handleSearch();
  }, [
    state.selectedPropertyFor,
    state.selectedTypes,
    state.Cities,
    state.Locality,
    state.selectedBHKTypes,
    state.Ready_Underconstruction,
    state.selectedFacing,
    state.MinPrice,
    state.MaxPrice,
    state.furnishingType,
    state.preTenent,
    state.selectedItems
  ]);

  return (
    <>
      <section className="Parent col-md-7 justify-content-center">
        <OneMainSection />
        {/* Uncomment other sections as needed */}
        {/* <TwoMainSection />
        <BudgetMainSection />
        <ThreeMainSection />
        <FourMainSection />
        <FiveMainSection /> */}
      </section>
    </>
  );
}
