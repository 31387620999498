import React from 'react';
import Logo from "../../Assests/Images/Logo.png";
import Iphone from "../../Assests/Images/Iphone.svg";
import Googleplay from "../../Assests/Images/Googleplay.svg";

export default function Footer() {
    return (
        <>
            <body className="Home-Page">

                <footer className="Footer" id="Footer">
                    <div className="Catagory row">
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                        </div>
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                        </div>
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                        </div>
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                            <p>Real estate in Mumbai</p>
                        </div>
                    </div>
                    <hr />
                    <div className="Catagory row">
                        <div className="box col-6 col-sm-4 col-md-3">
                            <img src={Logo} alt="Logo" />
                            <a href="mailto:info@arzepak.com.com?subject=Content" className="text-primary">
                                <i className="fa fa-envelope"></i>
                                info@arzepak.com
                            </a>
                            <p>
                                <i className="fa fa-phone"></i>+92 42 111 00 3636
                            </p>
                        </div>
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>About Us</p>
                            <p>Terms &Conditions</p>
                            <p>Events</p>
                            <p>Testimonials</p>
                            <p>Blogs</p>
                        </div>
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>Plot Finder</p>
                            <p>Help & Support</p>
                            <p>Advertise </p>
                            <p>Privacy policy</p>
                        </div>
                        <div className="box col-6 col-sm-4 col-md-3">
                            <p>Download Our App </p>
                            <img src={Iphone} alt="Phone" />
                            <img src={Googleplay} alt="Phone" />
                        </div>
                    </div>
                </footer>

            </body>
        </>
    )
}
