import React from "react";
import Header from "../Common/header";
import GreenHouse from "../../Assests/Images/Green-House.png";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import India from "../../Assests/Images/india.png";
import TryAgain from "../../Assests/Images/try-again.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserProperty } from "../../context/PropertyContext";
import {
  AddUpdatePostProperty,
  getAmenitiesListByPropertyId,
} from "../../Request/post-propertyRequest";
import {
  getAllAmanatiesList,
  saveAmaneties,
} from "../../Request/post-propertyRequest";
import { useEffect, useState } from "react";

export default function UpdateForm6() {
  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();
  const [allAmanatiesList, setAllAmanatiesList] = useState([]);
  const [amanaties, setAmanaties] = useState([]);
  const [filteredAmenities, setFilteredAmenities] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const PropertyDetailsByParams = JSON.parse(
    decodeURIComponent(searchParams.get("PropertyDetails"))
  );

  const [selectedAmanites, setSelectedAmanaties] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    setCityID(PropertyDetailsByParams.city_id);
    setPropertyID(PropertyDetailsByParams.id);
    setPropertyForID(PropertyDetailsByParams.propertyForID);
    setPropertyTypeID(PropertyDetailsByParams.BuildingType);
  }, [
    PropertyDetailsByParams.city_id,
    PropertyDetailsByParams.PropertyId,
    PropertyDetailsByParams.BuildingType,
    PropertyDetailsByParams.PropertyForID,
  ]);

  useEffect(() => {
    getAllAmanatiesList().then((res) => {
      setAllAmanatiesList(res);
      let tempfilteredAmenities = res.filter((amenity) => {
        return !selectedAmanites?.some(
          (selectedAmenity) => selectedAmenity.amenity_id === amenity.id
        );
      });
      // const filteredSecondData = res.filter(amenity => amenity.id !== selectedAmanites.amenity_id);
      setFilteredAmenities(tempfilteredAmenities);
    });
  }, [selectedAmanites]);

  useEffect(() => {
    let property = {
      propertyID: PropertyDetailsByParams.id,
    };
    getAmenitiesListByPropertyId(property).then((res) => {
      if (res == "No Record Found.") {
        setSelectedAmanaties([]);
      }else{
        let SelectedAmanateisID = res.map(amenity => amenity.amenity_id )
        setSelectedAmanaties(res)
        setAmanaties(SelectedAmanateisID)
      }
    });
  }, [propertyID]);

  const handleCheckboxChange = (e) => {
    const id = parseInt(e.target.value, 10); // Convert value to integer
    setAmanaties(prevAmanaties => {
      if (prevAmanaties.includes(id)) {
        return prevAmanaties.filter(amenityId => amenityId !== id);
      } else {
        return [...prevAmanaties, id];
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amenites:
        updateProperties?.ameneties || PropertyDetailsByParams?.ameneties,
      alternate_mobile_no:
        updateProperties?.alternate_mobile_no ||
        PropertyDetailsByParams?.alternate_mobile_no,
      who_will_show:
        updateProperties?.who_will_show ||
        PropertyDetailsByParams?.who_will_show,
      description:
        updateProperties?.description || PropertyDetailsByParams?.description,
    },

    validationSchema: Yup.object({
      description: Yup.string().required("Please select an option"),

      description: Yup.string()
        .required("Description is required")
        .min(30, "Description must be at least 30 characters"),
    }),

    onSubmit: async (data) => {
      try {
        const { amenites, who_will_show, alternate_mobile_no, description } =
          data;
        let finalData = {
          // ...updateProperties,
          ...data,
          id: propertyID || PropertyDetailsByParams.id,
          amenites,
          description,
          who_will_show,
          alternate_mobile_no,
          property_statusID: 3,
          Step: 5,
          is_draft: false,
        };
        await AddUpdatePostProperty(finalData).then((resp) => {
          setUpdateProperties(finalData);
          amanaties?.map((amenityId) => {
            saveAmaneties({
              property_id: propertyID,
              amenity_id: amenityId,
            });
          });
          const queryParams = JSON.stringify(PropertyDetailsByParams);
          navigate(`/update-property-form7?PropertyDetails=${queryParams}`);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <body className="Post-Property-6">
        <Header />

        {/* <Link to="/post-property-form6" className="fa fa-chevron-left Post-Property-Back"></Link> */}

        {/* =============================== STEP 6 ========================================= */}
        <section className="Step-6" id="Step-6">
          <div className="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  const queryParams = JSON.stringify(PropertyDetailsByParams);
                  navigate(
                    `/update-property-form5?PropertyDetails=${queryParams}`
                  );
                }}
              ></div>
            </div>
            <img src={ProgressBar} className="ProgressBar" alt="Progressbar" />
            <div className="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>
          <form
            className="right needs-validation"
            // novalidate
            onSubmit={formik.handleSubmit}
          >
            <div className="PostBackContainer">
              <div
                className="Post-Property-Back mobileonly "
                onClick={() => {
                  const queryParams = JSON.stringify(PropertyDetailsByParams);
                  navigate(
                    `/update-property-form5?PropertyDetails=${queryParams}`
                  );
                }}
              >
                <span className="fa fa-chevron-left  postBack"></span>
              </div>
              <h1 className="Title  postFormFont">Amenities & Description</h1>
            </div>
            <p className="Title">Add Amenities of property</p>
            <div className="Parent">
              <div  className="Checks-boxes"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1px",
                }} >
              {allAmanatiesList?.map((a, i) => (
                <div className="form-check form-check-inline mb-3" key={i}>
                  <input
                    className="form-check-input"
                    style={{
                      border: "0.79px solid #8B3D88",
                    }}
                    type="checkbox"
                    id={`Checkbox${a.id}`}
                    name="amenities"
                    value={a.id}
                    checked={amanaties.includes(a.id)}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={`Checkbox${a.id}`} style={{
                    fontSize:"14px"
                  }}>{a.name}</label>
                </div>
              ))}
              </div>
              <div className="Description">
                <p>Property Description</p>
                <textarea
                  className="form-control"
                  placeholder="Write description which best describes your property and its feature."
                  required
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                ></textarea>
                <span className="formik-validation">
                  {formik.touched.description && formik.errors.description}
                </span>
              </div>
              <div className="Phone">
                <p className="pt-4">Secondary Number</p>
                <div className="btn-group w-100">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter 10 digits"
                    required
                    name="alternate_mobile_no"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.alternate_mobile_no}
                    onInput={(e) => {
                      if (e.target.value.length > 10)
                        e.target.value = e.target.value.slice(0, 10);
                    }}
                  />
                  <span className="input-group-text">
                    <img src={India} />
                  </span>
                </div>
              </div>
              <div className="House ">
              <p>Who will show the house</p>
                <select
                  className="form-select dropdown-toggle"
                  id="House-Box"
                  // multiple
                  name="who_will_show"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.who_will_show}
                >
                  <option value="">Select Options</option>
                  <option value="myself">Myself</option>
                  <option value="Neighbour">Neighbour</option>
                  <option value="Securityguard">Security Guard</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <button
                type="submit"
                className="Style Next"
                // pop-up validation model
                // data-bs-toggle="modal"
                data-bs-target="#ModalID"
                style={{ float: "right" }}
              >
                {/* <Link classNameName="Style" style={{ margin: 0, padding: 0, color: 'white' }}
                                    to="/post-property-form7">
                                    Next</Link> */}
                Next
              </button>
              <div
                className="modal fade"
                id="ModalID"
                tabindex="-1"
                aria-labelledby="S-Button"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLabel"
                      >
                        Number Verified Failed
                      </h5>
                      <img
                        src={TryAgain}
                        alt="Close-Image"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      Please check the number or try it after sometime.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light text-dark d-block w-100 p-2"
                        data-bs-dismiss="modal"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </body>
    </>
  );
}
