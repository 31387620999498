import React, { useReducer, useState, useEffect } from "react";
import Header from "../Common/header";
import GreenHouse from "../../Assests/Images/Green-House.png";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import { Link, useNavigate } from "react-router-dom";
import logopng from "../../Assests/Images/logo-medium.png";
import { v4 as uuidv4 } from "uuid";
import uploadFileToBlob, {
  deleteBlob,
  handlePriview,
} from "../../Utils/azureBlob";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserProperty } from "../../context/PropertyContext";
import { saveImageApi } from "../../Request/post-propertyRequest";
import { AddUpdatePostProperty } from "../../Request/post-propertyRequest";

export default function UpdateForm7() {
  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const searchParams = new URLSearchParams(window.location.search);
  const PropertyDetailsByParams = JSON.parse(
    decodeURIComponent(searchParams.get("PropertyDetails"))
  );

  let navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},

    validationSchema: Yup.object({}),

    onSubmit: async (data) => {
      try {
        console.log({
          ...data,
          propertyImages: uploadedPropertyImageName,
          propertyID,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    setCityID(PropertyDetailsByParams.cityID);
    setPropertyID(PropertyDetailsByParams.id);
    setPropertyForID(PropertyDetailsByParams.property_types_id);
    setPropertyTypeID(PropertyDetailsByParams.building_types_id);
    setUpdateProperties({
      ...updateProperties,
      // form3
      locality_id: PropertyDetailsByParams.locality_id,
      apartment_id: PropertyDetailsByParams.apartment_id,
      carpet_area: PropertyDetailsByParams.carpet_area,
      builtup_area: PropertyDetailsByParams.builtup_area,
      floor_total: PropertyDetailsByParams.floor_total,
      floor_no: PropertyDetailsByParams.floor_no,
      bhk_type: PropertyDetailsByParams.bhk_type,
      super_builtup: PropertyDetailsByParams.super_builtup,
      plot_area: PropertyDetailsByParams.plot_area,
      // form 4
      maintainance_cost: PropertyDetailsByParams.maintainance_cost,
      number_bathroom: PropertyDetailsByParams.number_bathroom,
      expectedYear: PropertyDetailsByParams.expectedYear,
      expectedDate: PropertyDetailsByParams.expectedDate,
      number_balconies: PropertyDetailsByParams.number_balconies,
      property_age: PropertyDetailsByParams.property_age,
      expectedBy: PropertyDetailsByParams.expectedBy,
      price_negotiable: PropertyDetailsByParams.price_negotiable,
      under_loan: PropertyDetailsByParams.under_loan,
      rent_amount: PropertyDetailsByParams.rent_amount,
      rent_negotiable: PropertyDetailsByParams.rent_negotiable,
      rent_deposite: PropertyDetailsByParams.rent_deposite,
      agreement_duration: PropertyDetailsByParams.agreement_duration,
      price: PropertyDetailsByParams.price,
      //form 5
      kitchen_type: PropertyDetailsByParams.kitchen_type,
      flooring_type: PropertyDetailsByParams.flooring_type,
      water_supply: PropertyDetailsByParams.water_supply,
      property_facing: PropertyDetailsByParams.property_facing,
      furnish_type: PropertyDetailsByParams.furnish_type,
      eating_habits: PropertyDetailsByParams.eating_habits,
      RentNumber_bathroom: PropertyDetailsByParams.RentNumber_bathroom,
      Rentnumber_balconies: PropertyDetailsByParams.Rentnumber_balconies,
      prefered_tenants: PropertyDetailsByParams.prefered_tenants,
      coveredParkingCount: PropertyDetailsByParams.coveredParkingCount,
      openParkingCount: PropertyDetailsByParams.openParkingCount,

      //form 6
      description: PropertyDetailsByParams.description,
      amenites: PropertyDetailsByParams.amenites,
      myPhoneNo: PropertyDetailsByParams.myPhoneNo,
      who_will_show: PropertyDetailsByParams.who_will_show,
    });
  }, [cityID, propertyID, propertyForID, propertyTypeID]);

  //   upload image
  const [loading, setLoading] = useState();

  const [uploadedPropertyImage, setUploadedPropertyImage] = useState(false);
  const [uploadedPropertyImageName, setUploadedPropertyImageName] = useState(
    []
  );

  const [selectedPropertyImages, setSelectedPropertyImages] = useState([]);
  const [selectedPropertyImageNames, setSelectedPropertyImageNames] = useState(
    []
  );
  const [selectedPropertyWaterMarkImages, setSelectedPropertyWaterMarkImages] =
    useState([]);

  const [uploadedPropertyImages, setUploadedPropertyImages] = useState([]);
  const [uploadButtonClicked, setUploadButtonClicked] = useState(false);

  const onFileChange = (e) => {
    if (e.target.name == "DocumentPropertyImageOrgClub") {
      setUploadedPropertyImage(false);
      setSelectedPropertyImages(e.target.files);

      let files = e.target.files;

      const updatedFiles = Array.from(files).map((file) => {
        const uniqueFileName = `${uuidv4()}_${file.name}`;
        setSelectedPropertyImageNames((oldArray) => [
          ...oldArray,
          uniqueFileName,
        ]);
        return new File([file], uniqueFileName, { type: file.type });
      });

      const updatedWaterMarkFiles = Array.from(updatedFiles).map((file) => {
        let watermarkedFile;
        const img = new Image();
        const logo = new Image();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const scaleFactor = Math.min(1, 300 / img.width, 300 / img.height); // Adjust the max width and height as needed
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
          const fontSize = Math.max(14, Math.min(30, canvas.width / 15)); // Adjust the min and max font size as needed
          context.font = `${fontSize}px Arial`;
          context.fillStyle = "rgba(69, 69, 69, 1)";
          context.fillText(
            "TrueSqureFeet",
            canvas.width * 0.05,
            canvas.height * 0.05
          ); // Adjust the text position as needed
          logo.src = logopng;
          logo.onload = function () {
            const logoSize = Math.min(canvas.width * 0.2, canvas.height * 0.2); // Adjust the logo size as needed
            context.drawImage(
              logo,
              canvas.width - logoSize,
              canvas.height - logoSize,
              logoSize,
              logoSize
            );
            canvas.toBlob((blob) => {
              watermarkedFile = new File([blob], file.name, {
                type: file.type,
              });
              setSelectedPropertyWaterMarkImages((oldArray) => [
                ...oldArray,
                watermarkedFile,
              ]);
            }, file.type);
          };
        };
        img.src = URL.createObjectURL(file);
        return watermarkedFile;
      });
      setSelectedPropertyImages(updatedFiles);
    }
  };

  const onFileUpload = async () => {
    setLoading(true);

    for (
      let index = 0;
      index < selectedPropertyWaterMarkImages.length;
      index++
    ) {
      uploadFileToBlob(selectedPropertyWaterMarkImages[index]).then(() => {
        saveImageApi({
          property_id: propertyID,
          src: handlePriview(selectedPropertyWaterMarkImages[index].name),
          thumbnail: handlePriview(selectedPropertyWaterMarkImages[index].name),
          img_name: selectedPropertyWaterMarkImages[index].name,
        });

        setUploadedPropertyImages((oldArray) => [
          ...oldArray,
          selectedPropertyWaterMarkImages[index].name,
        ]);
        setUploadedPropertyImageName((oldArray) => [
          ...oldArray,
          selectedPropertyWaterMarkImages[index].name,
        ]);
      });
    }

    // Wait for all uploads to complete
    setSelectedPropertyImageNames([]);
    setSelectedPropertyImages([]);
    setUploadedPropertyImage(true);
    setLoading(false);
  };

  const onDeleteFile = (fileName) => {
    deleteBlob(fileName).then(() => {
      setUploadedPropertyImageName((prevState) => {
        const index = prevState.indexOf(fileName);
        if (index > -1) {
          prevState.splice(index, 1);
        }
        if (prevState?.length < 1) {
          setUploadedPropertyImage(false);
          setSelectedPropertyImages([]);
        }
        return prevState;
      });
    });
    forceUpdate();
  };
  // const onDeleteFile = (fileName) => {
  //   // Create a new array without the image to be deleted
  //   const updatedImages = selectedPropertyImages.filter(
  //     (image) => image.name !== fileName
  //   );

  //   // Update the state with the new array of images
  //   setSelectedPropertyImages(updatedImages);
  // };

  const handleDeleteClick = () => {
    // Handle delete logic here
  };

  const handlefinalSubmit = async () => {
    try {
      let finalData = {
        // ...updateProperties,
        id: propertyID || PropertyDetailsByParams.id,
        property_statusID: 3,
        Step: 6,
        is_draft: false,
      };
      await AddUpdatePostProperty(finalData).then((resp) => {
        setUpdateProperties(finalData);
        navigate("/");
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <body>
        <Header />

        {/* ((((((((((((((((((((((((((((((((((((((( STEP 7 ))))))))))))))))))))))))))))))))))))))) */}
        <section class="Step-7" id="Step-7">
          <div class="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  const queryParams = JSON.stringify(PropertyDetailsByParams);
                  navigate(
                    `/update-property-form6?PropertyDetails=${queryParams}`
                  );
                }}
              ></div>
            </div>
            <img src={ProgressBar} class="ProgressBar" alt="Progressbar" />
            <div class="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>
          <div class="right">
            {/* <div class="Hidden-Parking">
              <h1 class="Parking-Title">Parking spot</h1>
              <div
                class="Type btn-group gap-3"
                role="group"
                aria-required="true"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autocomplete="off"
                  checked
                />
                <label for="btnradio1">
                  <i class="fas fa-circle"></i> Sell
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  autocomplete="off"
                />
                <label for="btnradio2">
                  <i class="fas fa-circle"></i> Rent/Lease
                </label>
                
              </div>
              <div class="Parking-Count input-group justify-content-between">
                <h5>Parking spot...</h5>
                <div class="Counter">
                  <i class="fas fa-chevron-up up"></i>
                  <span class="Count">0</span>
                  <i class="fas fa-chevron-down down"></i>
                </div>
              </div>
              <div class="Parking-Count input-group justify-content-between">
                <h5>Parking spot...</h5>
                <div class="Counter">
                  <i class="fas fa-chevron-up up"></i>
                  <span class="Count">0</span>
                  <i class="fas fa-chevron-down down"></i>
                </div>
              </div>
            </div> */}
            <div className="PostBackContainer">
              <div
                className="Post-Property-Back mobileonly "
                onClick={() => {
                  const queryParams = JSON.stringify(PropertyDetailsByParams);
                  navigate(
                    `/update-property-form4?PropertyDetails=${queryParams}`
                  );
                }}
              >
                <span className="fa fa-chevron-left  postBack"></span>
              </div>
              <h1 className="Title postFormFont">Upload Property Images</h1>
            </div>
            {uploadedPropertyImageName.length > 0 ? (
              <div
                id="result"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {uploadedPropertyImageName.map((name, index) => (
                  <div className="box thumbnail-JqfG3XK" key={index}>
                    <img
                      style={{
                        display: "block",
                        height: "20px",
                        weight: "20px",
                      }} // src="http://3.109.90.125/front-assets/Images/Close-Image.png"
                      src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-close-512.png"
                      data-key="JqfG3XK"
                      className="remove-image"
                      id="Close"
                      onClick={() => onDeleteFile(name)}
                    />
                    <img
                      src={`${handlePriview(name)}`}
                      width={100}
                      height={100}
                      alt={name}
                    />
                  </div>
                ))}
              </div>
            ) : null}
            <div>
              <p>Add best images of your property</p>
              <div
                style={{
                  maxWidth: "422px",
                  height: "174px",
                  background: "#DF2DFC1F",
                  borderRadius: "3px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  border: "1px solid #FC67FF",
                  padding: "10px",
                }}
              >
                <input
                  style={{ display: "none" }}
                  id="uploadDocumentPropertyImageOrgClub"
                  margin="dense"
                  name="DocumentPropertyImageOrgClub"
                  onChange={onFileChange}
                  type="file"
                  value={formik.values.DocumentPropertyImageOrgClub}
                  variant="outlined"
                  multiple
                />
                <button
                  className="Style Next "
                  onClick={() => {
                    document
                      .getElementById("uploadDocumentPropertyImageOrgClub")
                      .click();
                  }}
                  style={{
                    width: "108px",
                    height: "42px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                    flexDirection: "row",
                    lineHeight: "19.5px",
                    alignItems: "center",
                  }}
                >
                  + Upload
                </button>
                <div
                  style={{
                    width: "258px",
                    height: "35px",
                    fontSize: "9px",
                    lineHeight: "10.9px",
                  }}
                >
                  Upload atleast 5 images , max image size accepted is 10 mb
                  Image format should be of png, jpg, jpeg, gif, webp, heic,
                  heif.
                </div>
              </div>

              {/* {selectedPropertyImageNames?.map((fileName, index) => (
                <p key={index}>{fileName}</p>
              ))} */}

              {uploadedPropertyImage ? (
                <button
                style={{display:'none'}} 
                  className="Style Next mx-3"
                  disabled
                  variant="contained"
                >
                  Uploaded &#10004;{" "}
                </button>
              ) : (
                <button
                  variant="contained"
                  style={{display:'none'}}
                  id="uploadbtn"
                  className="Style Next mx-3"
                  disabled={!selectedPropertyImages.length > 0}
                  onClick={() => {
                    onFileUpload();
                    setUploadButtonClicked(true);
                  }}
                >
                  Add
                </button>
              )}
            </div>


            <div style={{
              marginTop:"100px",
              display:"flex",
                flexDirection:"column",
                alignItems:"center"
            }}>
            <h5 className="Form6_submitButton_Heading">Your images will be continue to upload in background</h5>
            <Link to={"/"}>
              <button
              style={{
                margin:"3px",
                width:"300px"
              }}
                className="Style Next mx-1"
                // onClick={formik.handleSubmit}
                onClick={handlefinalSubmit}
              >
                Update
              </button>
            </Link>
            </div>
            {/* <div className="w-full d-flex justify-content-end float-right fixed-bottom">
            <Link to={"/"}>
              <button
                style={{ float: "right" }}
                className="Style Next mx-2 "
                // onClick={formik.handleSubmit}
              >
                Submit
              </button>
            </Link>
            <a href="">
            <button
                style={{ float: "right" }}
                className="Style Next "
                // onClick={formik.handleSubmit}
                // onClick={handleSubmit}
              >
              Do it later
              </button>
              </a>
              </div> */}
          </div>
        </section>
      </body>
    </>
  );
}
