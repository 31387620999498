import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import {userFeedBack} from '../../Request/post-propertyRequest'



export default function Feature() {
  const { isUserLoggedIn, isOtpSent, onOTPVerify, setPhoneNumber,
    phoneNumber, otp, setOtp,setSentOtpFrom ,setIsOtpSent} = UserAuth();

    const [lookingFor,setLookingFor] = useState("")
 

    const handleSave = ()=>{
      const clsbrn = document.getElementById("close_btn")
      const data = {
      userid:localStorage.getItem("userID"),
      description: lookingFor,
      }
      userFeedBack(data).then((res)=>{
        clsbrn.click()
        setLookingFor("")
      }).catch((err)=>{
        alert("Alert",err)
      })

    }
 
    function onCaptchVerify() {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-containerContact",
          {
            size: "invisible",
            callback: (response) => {
              onSignupForContact(phoneNumber);
            },
            "expired-callback": () => { },
          },
          auth
        );
      }
    }
  
    function onSignupForContact(mobileNo) {
      setSentOtpFrom("contact")
      onCaptchVerify();
      const appVerifier = window.recaptchaVerifier;
      const formatPh = "+91" + mobileNo;
  
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setOtp("")
          setIsOtpSent(true)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(()=>{
      setPhoneNumber("")
    },[])

    const handleTextAreaChange = (e)=>{
      setLookingFor(e.target.value)
      console.log(e.target.value)
    }
  return (
    <>
          <div id="recaptcha-containerContact"></div>

     <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style={{marginTop:60}}>
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">User FeedBack</h1>
        <button type="button" id="close_btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className="row">
                <div className="col-sm-12">
                  <div className="input-block">
                    <label className="col-form-label">What are you looking for</label>
                    <textarea
                      className="form-control"
                      type="text"
                      value={lookingFor}
                      onChange={handleTextAreaChange}
                    />
                  </div>
                </div>  
              </div> 
     { !isUserLoggedIn ?
               <>
              <div >
                  {!isOtpSent ? <>
                    <label className="col-form-label">Please Login To See Contact</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone"
                      required
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      name='mobile_no'
                      value={phoneNumber}
                    /><br />
                    <button onClick={() => onSignupForContact(phoneNumber)} type="button" className="form-control Mobile-Loc" >
                      Send OTP
                    </button>
                  </>
                    : <>
                      <input
                        type="number"
                        className="form-control m-3"
                        placeholder="otp"
                        required
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        name='otp'
                        value={otp} /><br />
                      <button onClick={() => onOTPVerify(otp)} type="button" className="form-control Mobile-Loc" >
                        Verify OTP
                      </button>
                    </>}

                </div>
              </> : <></>
     
    }
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button 
                      type="button"
                      style={{                       
                        backgroundColor: "#8B3D88",
                        color: "white",
                        padding: "6px",
                        borderRadius: "5px",
                      }}
                      onClick={handleSave}
        >Submit</button>
      </div>
    </div>
  </div>
</div>   </>
  )
}