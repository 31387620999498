import CetiesBg1 from "../../Assests/Images/Ceties-bg-1.png";
import CetiesBg2 from "../../Assests/Images/Ceties-bg-2.png";
import ContactPersonDetails from './contactPersonDetails';
import Accords1 from './accords1';
import Accord2 from './WishListAccord2'



export default function AccordsManageProperty() {
    
    return (
        <>
            <body className="Manage-Properties">
                <section className="Menage-Properties" id="Menage-Properties">
                    <h1 className="title">Contacted Properties</h1>
                    <ul className="btns nav nav-tabs" role="tablist">
                        <li className="btn nav-item">
                            <a className="nav-link dropdown-toggle active" data-bs-toggle="tab" href="#Accord-1">
                                Contacted Property
                            </a>
                        </li>
                        {/* <li className="btn nav-item">
                            <a className="nav-link dropdown-toggle" data-bs-toggle="tab" href="#Accord-2">
                                Wish List
                            </a>
                        </li> */}
                    </ul>

                    <div className="tab-content">
                          <div className="tab-pane fade show active" id="Accord-1">
                            <Accords1/>
                        </div>
                        {/* <div className="tab-pane fade" id="Accord-2">
                            <Accord2/>
                        </div> */}
                    </div>
                    <div className="bg">
                        <img src={CetiesBg1} alt="Ceties-bg" />
                        <img src={CetiesBg2} alt="Ceties-bg" />
                    </div>

                </section>
                <ContactPersonDetails />
            </body>
        </>
    )
}
