import axios from "axios";
import MAIN_URL from "./apiConfig";
 
// AddUpdate Post Property
export const searchProperty = async (data) => {

    try {
        const res = await axios.post(`${MAIN_URL}/Re_Properties/GetRe_Properties_SearchList`, data, {
            headers: {
                //   Authorization: "Bearer " + token,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const PopertiesDetails = async (data) => {

    try {
        const res = await axios.post(`${MAIN_URL}/Re_Properties/GetRe_PropertiesDetails`, data, {
            headers: {
                //   Authorization: "Bearer " + token,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const UserDetailsByID = async (data) => {
    
    try {
        const res = await axios.get(`${MAIN_URL}/User/GetUserDetails?oUserModel.id=${data}`, {
            headers: {
                //   Authorization: "Bearer " + token,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const SavePropertyViewer = async (data) => {

    try {
        const res = await axios.post(`${MAIN_URL}/PropertyViewer/SaveRe_PropertyViewer`, data, {
            headers: {
                //   Authorization: "Bearer " + token,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};