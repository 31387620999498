import { useContext, createContext, useState } from 'react';
import { searchProperty } from '../Request/search-propertyRequests';

const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  const [propertyLists, setPropertyLists] = useState([]);

  const [state, setState] = useState({
    selectedPropertyFor: 1,
    selectedCityID: 0,
    selectedItems: [],
    selectedTypes: [],
    Locality: null,
    Cities: null,
    selectedType: null,
    selectedFacing: null,
    furnishingType: null,
    preTenent: null,
    selectedBHKTypes: [],
    Ready_Underconstruction: null,
    MinPrice: "500000",
    MaxPrice: "1000000000",
  });

  const handleSearch = (orderBy) => {
    const { selectedCityID, selectedItems } = state;

    if (selectedCityID.length > 0) {
      setState((prevState) => ({
        ...prevState,
        Cities: selectedCityID,
      }));
    }

    const localities = selectedItems
      .filter((item) => item.id !== 0)
      .map((item) => item.id)
      .filter((id) => id !== 0)
      .toString();

    if (localities.length > 0) {
      setState((prevState) => ({
        ...prevState,
        Locality: localities,
      }));
    }

    const data = {
      PropertyType: state.selectedTypes?.length == 0 ? null : state.selectedTypes,
      Cities: state.selectedCityID,
      Locality: state.Locality,
      BHKType: state.selectedBHKTypes?.length == 0 ? null :state.selectedBHKTypes,
      MinPrice: state.MinPrice,
      MaxPrice: state.MaxPrice,
      Ready_Underconstruction: state.Ready_Underconstruction,
      PropertyFacing: state.selectedFacing,
      PropertyFor: state.selectedPropertyFor,
      furnish_type: state.furnishingType,
      prefered_tenants: state.preTenent,
    };

    getSearchPropertyList(data, orderBy);
  };

  const getSearchPropertyList = (data, orderBy) => {
    console.log(data, "from context Main Data");
    searchProperty(data).then((res) => {
      if (orderBy === "1") {
        const sortedData = res.sort(
          (a, b) => (a.price - b.price) || (a.rent_amount - b.rent_amount)
        );
        setPropertyLists(sortedData);
      } else if (orderBy === "2") {
        const sortedData = res.sort(
          (a, b) => (b.price - a.price) || (b.rent_amount - a.rent_amount)
        );
        setPropertyLists(sortedData);
      } else {
        setPropertyLists(res);
      }
      // console.log(res, "Property results");
    });
  };

  return (
    <SearchContext.Provider value={{ state, setState, propertyLists, handleSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const SearchProperty = () => {
  return useContext(SearchContext);
};
