import { useContext, createContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

const PropertyContext = createContext();

export const PropertyContextProvider = ({ children }) => {

  const navigate = useNavigate();


  const [propertyForID, setPropertyForID] = useState();
  const [propertyTypeID, setPropertyTypeID] = useState();
  const [propertyID, setPropertyID] = useState();
  const [cityID, setCityID] = useState();
  const [updateProperties , setUpdateProperties]=useState({})

  // search context
  const [selectedItems, setSelectedItems] = useState([]);


  return (
    <PropertyContext.Provider value={{
      propertyForID, setPropertyForID, setPropertyTypeID, setPropertyID,
      propertyTypeID, propertyID, cityID, setCityID,updateProperties,setUpdateProperties,selectedItems, setSelectedItems
    }}>
      {children}
    </PropertyContext.Provider>
  );
};

export const UserProperty = () => {
  return useContext(PropertyContext);
};
