import React from "react";
import Properties4 from "../../Assests/Images/Properties-4.png";
import Banner2 from "../../Assests/Images/Banner-2.png";
import Properties1 from "../../Assests/Images/Properties-1.png";
import Banner5 from "../../Assests/Images/Banner-5.png";
import Properties3 from "../../Assests/Images/Properties-3.png";
import Banner1 from "../../Assests/Images/Banner-1.png";
import Properties2 from "../../Assests/Images/Properties-2.png";
import No_More_images from "../../Assests/Images/No_more_images.png";
import Banner3 from "../../Assests/Images/Banner-3.png";
import Banner4 from "../../Assests/Images/Banner-4.png";
import { useEffect, useState } from "react";
import { PopertiesDetails } from "../../Request/search-propertyRequests";
import { getPropertyImagesByID } from "../../Request/post-propertyRequest";

export default function ListingPage2Banner(propertyID) {
  const searchParams = new URLSearchParams(window.location.search);
  const userIdParam = searchParams.get("propertyid");
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [getImages, setGetImages] = useState({});
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    let data = {
      id: userIdParam,
    };
    PopertiesDetails(data).then((res) => {
      setPropertyDetails(res);
    });
  }, [propertyID]);

  useEffect(() => {
    let data = {
      propertyID: userIdParam,
    };

    getPropertyImagesByID(data).then((res) => {
      if (res && res.length > 0) {
        setGetImages(res);
        setSelectedImage(res[0].src);
      } else {
      }
    });
  }, [propertyID]);
  return (
    <>
      <body>
        <div className="Banner" style={{ height: "40%" }} id="Banner">
          <div>
            <h1 style={{zIndex:100 , position:"absolute" ,
               backgroundColor: "#F6E6F5",
               color: "#8E258F",
               borderRadius: "5px",
                fontSize: "15px",
                fontWeight: "500px",
                top:100,
                marginLeft:10,
                padding: "5px",
            }} className="mobileonly">{propertyDetails.property_types_id == 1 ? "For Sale" : "For Rent"}</h1>
          </div>
          <div className="swiper Content-Slider" style={{ marginLeft: -15 , zIndex:0  }}>
            <div
              className="swiper-wrapper"
              style={{ overflow: "scroll", objectFit: "contain" }}
            >
              {getImages && Object.keys(getImages).length > 0 ? (
                getImages?.map((img, i) => (
                  <img
                    src={img.src}
                    className=" rounded-3 mx-2"
                    style={{ height: "30vh", width: "45vh" }}
                    key={img.id}
                    alt={`Banner-${i + 2}`}
                    onClick={() => setSelectedImage(img.src)}
                  />
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <img src={No_More_images} />
                </div>
              )}
            </div>
            <div className="swiper-pagination"></div>
          </div>
          {/*  */}
          <div className="left">
            <div
              id="carouselExampleControls"
              style={{ width: "100%" }}
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {getImages.length > 0 ? (
                  getImages.map((img, i) => (
                    <div
                      className={`carousel-item ${i === 0 ? "active" : ""}`}
                      key={img.id}
                    >
                      <img
                        src={img.src}
                        className="d-block w-100"
                        style={{ height: "60vh", objectFit: "cover",borderRadius:"22.98px" }}
                        alt={`Banner-${i + 2}`}
                      />
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <p>No images available</p>
                  </div>
                )}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <div
              className="sale-tag"
              style={{
                width: "69px",
                height: "22px",
                position: "absolute",
                backgroundColor: "#F6E6F5",
                color: "#8E258F",
                padding: "5px",
                top: "10px",
                marginLeft: "10px",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "500px",
                display: "flex",
                lineHeight: "14.63px",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {propertyDetails.property_types_id == 1 ? "For Sale" : "For Rent"}
            </div>
          </div>

          {/*  */}
          {/* <div className="left">
            <div className="Absolute input-group gap-3">
              <p>Sponsored</p>
              <p>Trending</p>
            </div>
            <div style={{ position: "relative" }}>
              <img
                style={{ height: "50vh", maxWidth: "50vw" }}
                className="rounded-3"
                src={selectedImage}
                alt="Main-Banner"
              />
              <span
                style={{ position: "absolute", bottom: 30, right: 70 }}
                className="Carpet text-dark"
              >
                LOGO
              </span>
            </div>
          </div>
          <div className="right" style={{ overflow: "scroll", height: "52vh" }}>
            {getImages && Object.keys(getImages).length > 0 ? (
              getImages?.map((img, i) => (
                <img
                  src={img.src}
                  className=" rounded-3 my-2"
                  style={{ height: "20vh" }}
                  key={img.id}
                  alt={`Banner-${i + 2}`}
                  onClick={() => setSelectedImage(img.src)}
                />
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <img src={No_More_images} />
              </div>
            )}
          </div> */}
        </div>
      </body>
    </>
  );
}
