import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import Cities1 from "../../Assests/Images/Cities-1.png";
import Cities2 from "../../Assests/Images/Cities-2.png";
import Cities3 from "../../Assests/Images/Cities-3.png";
import Cities4 from "../../Assests/Images/Cities-4.png";
import CitiesBg1 from "../../Assests/Images/Ceties-bg-1.png";
import CitiesBg2 from "../../Assests/Images/Ceties-bg-2.png";
import Wave from "../../Assests/Images/Wave.png";

export default function Cities() {
    const [pause, setPause] = useState(false);

    const slider = [
        {
            city: 'Chennai',
            image: Cities1,
            description: '19,200+ Properties',
        },
        {
            city: 'Hyderabad',
            image: Cities2,
            description: '20,200+ Properties',
        },
        {
            city: 'Nagpur',
            image: Cities3,
            description: '15,200+ Properties',
        },
        {
            city: 'Delhi',
            image: Cities4,
            description: '20,200+ Properties',
        },
        {
            city: 'Punjab',
            image: Cities2,
            description: '18,200+ Properties',
        },
        {
            city: 'Delhi',
            image: Cities1,
            description: '41,200+ Properties',
        },
        {
            city: 'Delhi',
            image: Cities4,
            description: '36,200+ Properties',
        },
        {
            city: 'Delhi',
            image: Cities3,
            description: '74,200+ Properties',
        }
    ]

    const [isSlidesToShow, setSlidesToShow] = useState(5)

    useEffect(() => {
        const calculateSlidesToShow = () => {
            if (window.innerWidth >= 1000) {
                setSlidesToShow(5)
            }
            else if (window.innerWidth < 1000 && window.innerWidth >= 600) {
                setSlidesToShow(3)
            }
            else {
                setSlidesToShow(2)
            }
        }
        calculateSlidesToShow();
        window.addEventListener("resize", calculateSlidesToShow)
        return () => {
            window.removeEventListener("resize", calculateSlidesToShow)
        }
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: isSlidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
        beforeChange: (currentSlide, nextSlide) => {
            setPause(true);
            setTimeout(() => setPause(false), 2000); // Pause for 2 second
        },
    };

    return (
        <>
            <body className="Home-Page">
                <section className="Cities" id="Cities">
                    <h3 className="title">Cities We Serve</h3>
                    <div className="swiper Catagory">
                        <Slider {...settings}>
                            {slider?.map((item, key) => (
                                <div key={key}>
                                    <div style={{ width: "95%" }} className="swiper-slide box">
                                        <img src={item.image} alt="Cities" />
                                        <img src={Wave} alt="Wave" />
                                        <div className="Content">
                                            <p>{item.city}</p>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="swiper-pagination"></div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                    <div className="bg">
                        <img src={CitiesBg1} alt="Ceties-bg-1" />
                        <img src={CitiesBg2} alt="Ceties-bg-2" />
                    </div>
                </section>

            </body>
        </>
    )
}
