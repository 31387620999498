import axios from "axios";
import MAIN_URL, { ADMIN_URL } from "./apiConfig";
import UseStorage from "../Hooks/useStorage";

// AddUpdate Post Property
export const AddUpdatePostProperty = async (data) => {

    try {
        const res = await axios.post(`${MAIN_URL}/Re_Properties/SaveRe_Properties`, data, {
            headers: {
                //   Authorization: "Bearer " + token,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const addNearByPlaces = async (data) => {
    if (!data) {
      return;
    }
    try {
      const res = await axios.post(`${ADMIN_URL}/api/savenearbyplaces`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  export const saveNearByPlaces = async (data) => {
    if (!data) {
      return;
    }
    try {
      const res = await axios.post(`${MAIN_URL}/PropertyNearBy/SavePropertyNearBy`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


  // Get NearBy Places 

  export const getNearByPlaces = async(data)=>{
     try {
        const res = await axios.post(`${MAIN_URL}/PropertyNearBy/GetPropertyNearByPropertyId?propertyid=${data.propertyid}`,data);
        return res?.data
     } catch (error) {
        console.log(error)
        throw error;
     }
  }

//GET All Cities/Localities (Form1)
export const getCitiesLocalityListForSearch = async (params) => {
    try {
        let res = await axios.get(`${MAIN_URL}/Localities/SearchByCity_ByLocality`, {
            params: params,
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};



//GET All Cities/Localities (Form1)
export const getAllCitiesList = async (params) => {

    try {
        let res = await axios.get(`${MAIN_URL}/Cities/GetCitiesList`, {
            params: params,
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

//GET Property For
export const getPropertyFor = async (params) => {
    try {
        let res = await axios.get(`${MAIN_URL}/PropertyTypes/GetPropertyTypesList`, {
            params: params,
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};


//GET Localities by city ID
export const getLocalitiesByCityID = async (params) => {
    try {
        let res = await axios.get(`${MAIN_URL}/localities/GetLocalitiesByCity`, {
            params: params,
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

//GET apartmentlist by locality ID
export const getApartmentListByLocalityID = async (params) => {
    try {
        let res = await axios.get(`${MAIN_URL}/Apartment/GetApartmentlist_CityWise`, {
            params: params,
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

//Get All Amanaties List 

export const getAllAmanatiesList = async()=>{
    try{
        const res = await axios.get(`${MAIN_URL}/Amenites/GetAmeniteslist`)
        return res?.data
    }catch(error){
        console.log(error);
        throw error;
    }
}

//GET amanities by Property Id
export const getAmenitiesListByPropertyId = async(params)=>{
    try{
        let res = await axios.get(`${MAIN_URL}/Property_amenites/GetPropertyAmenitesByPropertyID`,{
            params:params,
        })
        return res?.data;
    } catch (error){
        console.log(error)
        throw error
    }
}

// Get Images By Property ID
export const getPropertyImagesByID = async(params)=>{
    try {
        let res = await axios.get(`${MAIN_URL}/Propertyimg/GetImageListByPropertyID`,{
            params:params,
        })
        return res?.data;
    } catch (error) {
        console.log(error)
        throw error
    }
}


//post Amanaties

export const saveAmaneties = async (data)=>{
    try{
        let res = await axios.post(`${MAIN_URL}/Property_amenties/SavePropertyAmenities`,data
        )
        return res?.data
    }catch(error){
        throw error
    }
}

//Post Create Post Property
export const CreatePostProperty = async (data) => {
    try {
        let res = await axios.post(`${MAIN_URL}/Re_Properties/SaveRe_Properties`, {
            data: data,
            headers: {
                Authorization: "Bearer "
            },
        });
        return res?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// POST Feedback 
export const userFeedBack = async (data)=>{
    try {
        let res = await axios.post(`${MAIN_URL}/Userfeedback/Saveuserfeedback`,data);
        return res?.data
    } catch (error) {
        console.log(error);
        throw error;
    }
}

// POST Feedback 

//ADD Locality
export const addLocality = async (data) => {
    if (!data) {
        return;
    }
    try {
        const res = await axios.post(`${MAIN_URL}/Localities/SaveLocalities`, data, {
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data
    } catch (error) {
        console.log(error);
        throw error;
    }
};

//ADD Apartment
export const addAppartment = async (data) => {
    if (!data) {
        return;
    }
    try {
        const res = await axios.post(`${MAIN_URL}/Apartment/SaveApartment`, data, {
            headers: {
                Authorization: "Bearer "
            },
        });
        return res.data
    } catch (error) {
        console.log(error);
        throw error;
    }
};






export const getPropertiesUserWiseList = async (data) => {
    if (!data) {
        return;
    }
    try {
        const res = await axios.post(`${MAIN_URL}/Re_Properties/GetRe_PropertiesUserWiseList`, data);
        return res.data
    } catch (error) {
        console.log(error);
    }
};

export const getViewersPropertyWiseList = async (data)=>{
        if(!data){
            return;
        }
        try{
            const res = await axios.post(`${MAIN_URL}/PropertyViewer/GetProperties_Viewer_PropertyWiseList`,data)
            return res.data
        }catch(error){
            console.log(error)
            throw error;
        }
} 


// property Viwers List 

export const getPropertiesVieswersList = async (data)=>{
    if(!data){
        return;
    }
    try{
        const res = await axios.post(`${MAIN_URL}/PropertyViewer/GetProperties_Viewer_UserWiseList`,data)
        return res?.data
    }catch (error){
        console.log(error)
        throw error;
    }
}

export const saveImageApi = async (data) => {
    if (!data) {
        return;
    }
    try {
        const res = await axios.post(`${MAIN_URL}/Propertyimg/SavePropertyimg`, data);
        return res.data
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// /Similar Property

export const getSimilarProperty = async (data)=>{
    if(!data){
        return;
    }
    try{
        const res = await axios.post(`${MAIN_URL}/Re_Properties/GetSimilar_Property?propertyid=${data.propertyid}`,data)
        return res?.data
    }catch (error){
        console.log(error)
        throw error;
    }
}