import React from 'react';
import SearchBar2 from "../../Assests/Images/search-bar2.png";
import { Link } from 'react-router-dom';


export default function SmallReturnManageProperty() {
    return (
        <>
            <body class="Manage-Properties">
                <div class="Small-Return">
                    <div>
                        <Link to="/"><i class="fa fa-chevron-left"></i></Link>
                        <p class="m-0 d-inline-block">My Properties</p>
                    </div>
                    {/* <div class="img">
                        <a href="./Contact.html">
                            <img src={SearchBar2} alt="search" /></a>
                    </div> */}
                </div>
            </body>
        </>
    )
}
