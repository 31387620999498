import React, { useEffect, useState } from "react";
import Header from "../Common/header";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import GreenHouse from "../../Assests/Images/Green-House.png";
import TryAgain from "../../Assests/Images/try-again.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserProperty } from "../../context/PropertyContext";
import { AddUpdatePostProperty } from "../../Request/post-propertyRequest";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function PostForm4() {
  const searchParams = new URLSearchParams(window.location.search);
  const Cities = searchParams.get("Cities");
  const PropertyForID = searchParams.get("PropertyForId");
  const BuildingType = searchParams.get("buildingType");
  const PropertyId = searchParams.get("PropertyId");
  const localityID = searchParams.get("localityID");
  const apartment_id = searchParams.get("apartment_id");
  const carpet_area = searchParams.get("carpet_area");
  const builtup_area = searchParams.get("builtup_area");
  const floor_total = searchParams.get("floor_total");
  const floor_no = searchParams.get("floor_no");
  const bhk_type = searchParams.get("bhk_type");
  const super_builtup = searchParams.get("super_builtup");
  const plot_area = searchParams.get("plot_area");
  const maintainance_cost = searchParams.get("maintainance_cost");
  const number_bathroom = searchParams.get("number_bathroom");
  const expectedYear = searchParams.get("expectedYear");
  const expectedDate = searchParams.get("expectedDate");
  const number_balconies = searchParams.get("number_balconies");
  const property_age = searchParams.get("property_age");
  const expectedBy = searchParams.get("expectedBy");
  const price_negotiable = searchParams.get("price_negotiable");
  const under_loan = searchParams.get("under_loan");
  const rent_amount = searchParams.get("rent_amount");
  const rent_negotiable = searchParams.get("rent_negotiable");
  const rent_deposite = searchParams.get("rent_deposite");
  const agreement_duration = searchParams.get("agreement_duration");
  const price = searchParams.get("price");
  const readytomove_underconstruction = searchParams.get(
    "readytomove_underconstruction"
  );

  const [startDate, setStartDate] = useState(new Date());

  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();

  let navigate = useNavigate();

  useEffect(() => {
    setCityID(Cities);
    setPropertyID(PropertyId);
    setPropertyForID(PropertyForID);
    setPropertyTypeID(BuildingType);
    setUpdateProperties({
      ...updateProperties,
      // form2
      locality_id: localityID,
      apartment_id,
      carpet_area,
      builtup_area,
      floor_total,
      floor_no,
      bhk_type,
      super_builtup,
      plot_area,
      //
    });
  }, [
    Cities,
    PropertyId,
    BuildingType,
    PropertyForID,
    localityID,
    apartment_id,
  ]);

  const [propertyDetail, setPropertyDetail] = useState(
    readytomove_underconstruction || "1"
  );

  const [showNegotiablePrice, setShowNegotiablePrice] = useState(false);
  const [showCurrentlyUnderloan, setShowCurrentlyUnderloan] = useState(false);

  const handleNegotiableCheckboxChange = () => {
    setShowNegotiablePrice(!showNegotiablePrice);
  };

  const handleUnderloanCheckboxChange = () => {
    setShowCurrentlyUnderloan(!showCurrentlyUnderloan);
  };

  const handlePropertyDetailChange = (event) => {
    setPropertyDetail(event.target.value);
  };

  const Firsturl = `?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`;
  const secondUrl = `&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`;

  // const Backurl = (`?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      propertyForID === 1
        ? {
            price: price || "",
            maintainance_cost: maintainance_cost || "",
            expectedYear: expectedYear || "",
            expectedDate: expectedDate || "",
            property_age: property_age || "",
            expectedBy: expectedBy || "",
            price_negotiable: price_negotiable || "",
            under_loan: under_loan || "",
            number_bathroom: number_bathroom || "1",
            number_balconies: number_balconies || "0",
            rent_amount: rent_amount || "",
            rent_negotiable: rent_negotiable || "",
            rent_deposite: rent_deposite || "",
            agreement_duration: agreement_duration || "",
            readytomove_underconstruction: readytomove_underconstruction || "",
          }
        : {
            price: price || "",
            maintainance_cost: maintainance_cost || "",
            expectedYear: expectedYear || "",
            expectedDate: expectedDate || "",
            property_age: property_age || "",
            expectedBy: expectedBy || "",
            price_negotiable: price_negotiable || "",
            under_loan: under_loan || "",
            rent_amount: rent_amount || "",
            rent_negotiable: rent_negotiable || "",
            rent_deposite: rent_deposite || "",
            agreement_duration: agreement_duration || "",
            readytomove_underconstruction: readytomove_underconstruction || "",
          },

    validationSchema: Yup.object({
      // price: Yup.number()
      //     .required('required'),
      // maintainance_cost: Yup.number()
      //     .required('Floor number is required')
      //     .max(Yup.ref('price'), 'Not possible'),
      // negotiablePrice: Yup.number()
      //     .required('required')
      //     .max(Yup.ref('price'), 'Not possible'),
      // currentlyUnderloan: Yup.number()
      //     .required('required')
      //     .max(Yup.ref('price'), 'Not possible'),
    }),

    onSubmit: async (data) => {
      try {
        let finalData;
        console.log(data);
        if (PropertyForID == 1) {
          const {
            number_balconies,
            number_bathroom,
            property_age,
            price,
            price_negotiable,
            maintainance_cost,
            readytomove_underconstruction,
            under_loan,
          } = data;
          finalData = {
            // ...updateProperties,
            // ...data,
            id: propertyID || PropertyId,
            // propertyDetail,
            expectedBy: selectedYear,
            number_balconies,
            number_bathroom,
            property_age,
            price,
            price_negotiable,
            under_loan,
            maintainance_cost,
            // available_from: startDate,
            readytomove_underconstruction: propertyDetail,
            property_statusID: 3,
            Step: 3,
            is_draft: true,
          };
        } else {
          console.log(data);
          const {
            agreement_duration,
            maintainance_cost,
            rent_amount,
            rent_deposite,
            price_negotiable,
            readytomove_underconstruction,
            under_loan,
          } = data;
          finalData = {
            // ...updateProperties,
            // ...data,
            id: propertyID || PropertyId,
            available_from: startDate,
            rent_amount,
            rent_deposite,
            agreement_duration,
            under_loan,
            readytomove_underconstruction: propertyDetail,
            maintainance_cost,
            price_negotiable,
            property_statusID: 3,
            Step: 3,
            is_draft: true,
          };
        }

        await AddUpdatePostProperty(finalData).then((resp) => {
          setUpdateProperties(finalData);

          const saleUrl = `/post-property-form5${Firsturl}${secondUrl}&maintainance_cost=${data.maintainance_cost}&number_bathroom=${data.number_bathroom}&price=${data.price}&expectedYear=${data.expectedYear}&expectedDate=${data.expectedDate}&number_balconies=${data.number_balconies}&property_age=${data.property_age}&expectedBy=${data.expectedBy}&price_negotiable=${data.price_negotiable}&under_loan=${data.under_loan}&rent_amount=${data.rent_amount}&rent_negotiable=${data.rent_negotiable}&rent_deposite=${data.rent_deposite}&agreement_duration=${data.agreement_duration}&readytomove_underconstruction=${propertyDetail}`;
          const rentUrl = `/post-property-form5${Firsturl}${secondUrl}&maintainance_cost=${data.maintainance_cost}&price=${data.price}&expectedYear=${data.expectedYear}&expectedDate=${data.expectedDate}&property_age=${data.property_age}&expectedBy=${data.expectedBy}&price_negotiable=${data.price_negotiable}&under_loan=${data.under_loan}&rent_amount=${data.rent_amount}&rent_negotiable=${data.rent_negotiable}&rent_deposite=${data.rent_deposite}&agreement_duration=${data.agreement_duration}&readytomove_underconstruction=${propertyDetail}`;
          navigate(propertyForID == 1 ? saleUrl : rentUrl);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [selectedYear, setSelectedYear] = useState("");
  const yearsToDisplay = 6;
  const currentYear = new Date().getFullYear();

  const currentMonth = new Date().getMonth() + 1;
  // Adding 1 because getMonth returns a 0-based index
  // Check if the current month is greater than or equal to 7
  if (currentMonth >= 10) {
    // Current month is July or later, so show the next 6 years
    var years = Array.from(
      { length: yearsToDisplay },
      (_, index) => currentYear + index + 1
    );
  } else {
    // Current month is before July, so show the current year and the next 5 years
    var years = Array.from(
      { length: yearsToDisplay + 1 },
      (_, index) => currentYear + index
    );
  }

  const handleDropdownChange = (event) => {
    setSelectedYear(event.target.value);
  };

  var setyear = () => {
    if (propertyForID || PropertyForID == 1) {
      setSelectedYear(currentYear);
    }
  };
  useEffect(() => {
    setyear();
  }, []);

  function numberToWords(number) {
    const units = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];

    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const scales = ["", "Thousand", "Million", "Billion"];

    function convertThreeDigits(num) {
      let words = "";
      if (num >= 100) {
        words += units[Math.floor(num / 100)] + " Hundred";
        num %= 100;
        if (num > 0) {
          words += " and ";
        }
      }
      if (num >= 11 && num <= 19) {
        words += teens[num - 11];
        num = 0;
      } else if (num >= 20) {
        words += tens[Math.floor(num / 10)];
        num %= 10;
        if (num > 0) {
          words += " ";
        }
      }
      if (num > 0) {
        words += units[num];
      }
      return words;
    }

    if (number === 0) {
      return "Zero Dollars";
    }

    let words = "";
    for (let i = 0; number > 0; i++) {
      if (number % 1000 !== 0) {
        words =
          convertThreeDigits(number % 1000) + " " + scales[i] + " " + words;
      }
      number = Math.floor(number / 1000);
    }

    return words.trim() + "";
  }

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <>
      <body className="Post-Property-4">
        <Header />

        {/* <Link to="/post-property-form4" className="fa fa-chevron-left Post-Property-Back"></Link> */}

        {/* ------------------------------------ STEP 4 -------------------------------------- */}
        <section className="Step-4" id="Step-4">
          <div className="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  // navigate(`/post-property-form3?PropertyId=${PropertyId}&Cities=${cityID}&PropertyForId=${propertyForID.toString()}&buildingType=${propertyTypeID.toString()}`);
                  navigate(`/post-property-form3${Firsturl}${secondUrl}`);
                }}
              ></div>
            </div>
            <img src={ProgressBar} className="ProgressBar" alt="Progressbar" />
            <div className="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="right needs-validation"
            data-send="Post-Form5.html"
            novalidate
          >
            <div className="Title">
              {/* <h1 style={{ marginTop: "30px" }}>A bit more information</h1> */}
              {/* <Link className="Style" to="/post-property-form3">
                                Change</Link> */}
            </div>
            <div className="PostBackContainer">
              <div
                className="Post-Property-Back mobileonly "
                onClick={() => {
                  navigate(`/post-property-form3${Firsturl}${secondUrl}`);
                }}
              >
                <span className="fa fa-chevron-left  postBack"></span>
              </div>
              <h1 className="Title postFormFont">Price & Property Status</h1>
            </div>
            <div className="Parent">
              {(propertyForID == "1" || PropertyForID == "1") && (
                <div className="Property">
                  <h5 className="postForm_4_title">Property Status</h5>
                  <div
                    className="btn-group gap-3"
                    role="group"
                    aria-required="true"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="btnradio1"
                      name="propertyDetail"
                      value="1"
                      onChange={() => setPropertyDetail("1")}
                      checked={propertyDetail === "1"}
                    />
                    <label htmlFor="btnradio1">
                      <i className="fas fa-circle"></i> Ready to move
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btnradio3"
                      name="propertyDetail"
                      value="2"
                      onChange={() => setPropertyDetail("2")}
                      checked={propertyDetail === "2"}
                    />
                    <label htmlFor="btnradio3">
                      <i className="fas fa-circle" /> Under Construction
                    </label>
                  </div>

                  {propertyDetail === "1" && (
                    <div className="Expected mt-2">
                      <p>Property Age</p>
                      <div className="input-group align-items-center justify-content-between mt-2">
                        <div className="Year">
                          <select
                            className="labelUI"
                            id="ApartMent-Select"
                            name="property_age"
                            value={formik.values.property_age}
                            onChange={formik.handleChange}
                            fdprocessedid="01d1u"
                          >
                            <option value="">Select Property Age</option>
                            <option value="0">Less than 1 year old </option>
                            <option value="1">1 year old </option>
                            <option value="2">2 year old </option>
                            <option value="3">3 year old </option>
                            <option value="4">4 year old </option>
                            <option value="5">5 year old </option>
                            <option value="6">6 year old </option>
                            <option value="7">7 year old </option>
                            <option value="8">8 year old </option>
                            <option value="9">9 year old </option>
                            <option value="10">10 year old </option>
                            <option value="11">
                              Between 10 - 15 years old{" "}
                            </option>
                            <option value="12">
                              Between 15 - 20 years old{" "}
                            </option>
                            <option value="13">
                              Between 20 - 30 years old{" "}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {propertyDetail === "2" && (
                    <div className="Expected">
                      <p>Expected by</p>
                      <div className="input-group align-items-center justify-content-between mt-3">
                        <div className="Year">
                          {/* <select
                                                    style={{ width: "315.775px" }}
                                                    className="form-select labelUI"
                                                    id="ApartMent-Select"
                                                    name="property_age"
                                                    fdprocessedid="01d1u"
                                                    value={selectedYear}
                                                    onChange={handleDropdownChange}>
                                                    <option value="">Select a year</option>
                                                    {years.map((year) => (
                                                        <option key={year} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                                </select> */}
                          {/* <select
                          style={{ width: "315.775px" }}
                          className="form-select labelUI"
                          id="expectedBy"
                          name="expectedBy"
                          value={formik.values.expectedBy}
                          onChange={formik.handleChange}
                          fdprocessedid="01d1u"
                        >
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                          <option value="2028">2028</option>
                          <option value="2029">2029</option>
                          <option value="2029">2030</option>
                        </select> */}

                          <select
                            className="labelUI"
                            id="ApartMent-Select"
                            name="property_age"
                            fdprocessedid="01d1u"
                            value={selectedYear}
                            onChange={handleDropdownChange}
                          >
                            <option value="">Select a year</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(propertyForID == "2" || PropertyForID == "2") && (
                <div>
                  <p>Available From</p>
                  <div style={{ margin: "1px" }}>
                    <DatePicker
                      className="rentInputUI"
                      selected={startDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              )}

              {(propertyForID == 1 || PropertyForID == 1) && (
                <div>
                  <div className="Price mt-3">
                    <h5 className="postForm_4_title">Property Price</h5>
                    <div className="Floors mb-3">
                      <span className="fw-normal">Expected price</span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="20,000 INR"
                        min="0"
                        required
                        name="price"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.price}
                      />
                      {numberToWords(formik.values.price)}
                      <span className="formik-validation">
                        {formik.touched.price && formik.errors.price}
                      </span>
                      <div className="valid-feedback">Good!</div>
                      <div className="invalid-feedback">
                        Please Enter Your Total Floors.
                      </div>
                    </div>
                    <div className="Last mb-3">
                      <span className="fw-normal">
                        Maintenance Charges (Monthly)
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="20,000 INR"
                        min="0"
                        required
                        name="maintainance_cost"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.maintainance_cost}
                      />
                      {numberToWords(formik.values.maintainance_cost)}
                      <span
                        style={{ color: "red" }}
                        className="formik-validation"
                      >
                        {formik.touched.maintainance_cost &&
                          formik.errors.maintainance_cost}
                      </span>
                      <div className="valid-feedback">Good!</div>
                      <div className="invalid-feedback">
                        Please Enter Your Builtup Area.
                      </div>
                    </div>
                  </div>

                  <div className="Check input-group align-items-center justify-content-between">
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Checkbox1"
                        name="price_negotiable"
                        // onChange={handleNegotiableCheckboxChange}    // comment it
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "price_negotiable",
                            e.target.checked ? 1 : 0
                          );
                        }}
                        // checked={showNegotiablePrice}  // comment it
                        checked={formik.values.price_negotiable}
                      />
                      <label className="form-check-label" htmlFor="Checkbox1">
                        Price negotiable
                      </label>
                    </div>
                    {showNegotiablePrice && (
                      <div className="form-check form-check-inline mb-3">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="20,000 INR"
                          required
                          name="negotiablePrice"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.negotiablePrice}
                        />
                        <span className="formik-validation">
                          {formik.touched.negotiablePrice &&
                            formik.errors.negotiablePrice}
                        </span>
                      </div>
                    )}

                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Checkbox2"
                        // onChange={handleUnderloanCheckboxChange} currentlyUnderloan price_negotiable
                        name="under_loan"
                        // onChange={handleNegotiableCheckboxChange}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "under_loan",
                            e.target.checked ? 1 : 0
                          );
                        }}
                        // checked={showNegotiablePrice}
                        checked={formik.values.under_loan}
                      />
                      <label className="form-check-label" htmlFor="Checkbox2">
                        Currently Underloan
                      </label>
                    </div>
                    {/* {showCurrentlyUnderloan && (
                                    <div className="form-check form-check-inline mb-3">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="20,000 INR"
                                            required
                                            name="currentlyUnderloan"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.currentlyUnderloan}
                                        />
                                        <span className="formik-validation">
                                            {formik.touched.currentlyUnderloan && formik.errors.currentlyUnderloan}
                                        </span>
                                    </div>
                                )} */}
                  </div>
                  <div className="Property mb-3">
                    <p>No. of Bathrooms</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="btn1"
                        autocomplete="off"
                        name="number_bathroom"
                        value="1"
                        onChange={formik.handleChange}
                        defaultChecked={number_bathroom == 1}
                      />
                      <label
                        for="btn1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> One{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn2"
                        autocomplete="off"
                        name="number_bathroom"
                        value="2"
                        onChange={formik.handleChange}
                        defaultChecked={number_bathroom == 2}
                      />
                      <label
                        for="btn2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Two{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn3"
                        autocomplete="off"
                        name="number_bathroom"
                        value="3"
                        onChange={formik.handleChange}
                        defaultChecked={number_bathroom == 3}
                      />
                      <label
                        for="btn3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Three{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn4"
                        autocomplete="off"
                        name="number_bathroom"
                        value="4"
                        onChange={formik.handleChange}
                        defaultChecked={number_bathroom == 4}
                      />
                      <label
                        for="btn4"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Four{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn5"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5"
                        onChange={formik.handleChange}
                        defaultChecked={number_bathroom == 5}
                      />
                      <label
                        for="btn5"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn5+"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5+"
                        onChange={formik.handleChange}
                        defaultChecked={number_bathroom == "5+"}
                      />
                      <label
                        for="btn5+"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five+{" "}
                      </label>
                    </div>
                  </div>

                  <div className="Property mb-3">
                    <p>No. of Balconies</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn0"
                        autocomplete="off"
                        name="number_balconies"
                        value="0"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 0}
                      />
                      <label
                        for="number_balconiesbtn0"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Zero{" "}
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn1"
                        autocomplete="off"
                        name="number_balconies"
                        value="1"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 1}
                      />
                      <label
                        for="number_balconiesbtn1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> One{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn2"
                        autocomplete="off"
                        name="number_balconies"
                        value="2"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 2}
                      />
                      <label
                        for="number_balconiesbtn2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Two{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn3"
                        autocomplete="off"
                        name="number_balconies"
                        value="3"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 3}
                      />
                      <label
                        for="number_balconiesbtn3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Three{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn4"
                        autocomplete="off"
                        name="number_balconies"
                        value="4"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 4}
                      />
                      <label
                        for="number_balconiesbtn4"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Four{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn5"
                        autocomplete="off"
                        name="number_balconies"
                        value="5"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == 5}
                      />
                      <label
                        for="number_balconiesbtn5"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn5+"
                        autocomplete="off"
                        name="number_balconies"
                        value="5+"
                        onChange={formik.handleChange}
                        defaultChecked={number_balconies == "5+"}
                      />
                      <label
                        for="number_balconiesbtn5+"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five+{" "}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* ################################## Rent ###################################### */}

              {(propertyForID == 2 || PropertyForID == 2) && (
                <div className="Property mb-3">
                  <div className="Builtup mb-3">
                    <p>Rent Amount</p>
                    <input
                      type="number"
                      className="rentInputUI"
                      placeholder="Rent Amount"
                      name="rent_amount"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.rent_amount}
                      required
                    />
                    {numberToWords(formik.values.rent_amount)}
                    <span className="formik-validation">
                      {formik.touched.rent_amount && formik.errors.rent_amount}
                    </span>
                    <div className="valid-feedback"> Good! </div>
                    <div className="invalid-feedback">
                      {" "}
                      Please Enter Your Rent Amount.{" "}
                    </div>
                  </div>

                  <div className="Check input-group align-items-center justify-content-between">
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="rent_negotiable"
                        onChange={formik.handleChange}
                        checked={formik.values.rent_negotiable}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="rent_negotiable"
                      >
                        Price negotiable
                      </label>
                    </div>
                  </div>

                  <div className="Builtup mb-3">
                    <p>Deposit Amount</p>
                    <input
                      type="number"
                      className="rentInputUI"
                      placeholder="Deposit Amount"
                      name="rent_deposite"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.rent_deposite}
                      required
                    />
                    {numberToWords(formik.values.rent_deposite)}
                    <span className="formik-validation">
                      {formik.touched.rent_deposite &&
                        formik.errors.rent_deposite}
                    </span>
                    <div className="valid-feedback"> Good! </div>
                    <div className="invalid-feedback">
                      {" "}
                      Please Enter Your Deposit Amount.{" "}
                    </div>
                  </div>

                  <div className="Facing">
                    <p>Agreement Duration</p>
                    <select
                      className="rentInputUI"
                      id="agreement_duration"
                      name="agreement_duration"
                      fdprocessedid="01d1u"
                      value={formik.values.agreement_duration}
                      onChange={formik.handleChange}
                    >
                      <option value="">Select Agreement </option>
                      <option value="3Months">3 months </option>
                      <option value="6Months">6 months</option>
                      <option value="11Months">11 months </option>
                      <option value="2Years">2 years </option>
                      <option value="2+years">2+ years </option>
                    </select>
                  </div>

                  {/* -----------------------------------maintenance Cost -------------------- */}
                  <div className="maintenance mb-3">
                    <p>Maintenance Amount</p>
                    <input
                      type="number"
                      className="rentInputUI"
                      placeholder="maintenance Amount"
                      name="maintainance_cost"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.maintainance_cost}
                      required
                    />
                    {numberToWords(formik.values.maintainance_cost)}
                    <span className="formik-validation">
                      {formik.touched.maintainance_cost &&
                        formik.errors.maintainance_cost}
                    </span>
                    <div className="valid-feedback"> Good! </div>
                    <div className="invalid-feedback">
                      {" "}
                      Please Enter Your Maintenance Amount.{" "}
                    </div>
                  </div>
                </div>
              )}

              {/* <button type="submit" data-bs-target="#ModalID"
                                className="Style Next"
                                // pop-up validation model
                                // data-bs-toggle="modal"
                                style={{ float: 'right' }}
                            >
                                <Link className="Style" style={{ margin: 0, padding: 0 }}
                                    to="/post-property-form5">
                                    Next</Link>
                                Next
                            </button> */}

              <button
                type="submit"
                className="Style Next"
                data-bs-target="#ModalID"
                // pop-up validation model
                // data-bs-toggle="modal"
                style={{ float: "right" }}
              >
                {/* <Link className="Style"
                                    style={{ margin: 0, padding: 0 }}
                                    to="/post-property-form4">
                                    Next</Link> */}
                Next
              </button>

              <div
                className="modal fade"
                id="ModalID"
                tabindex="-1"
                aria-labelledby="S-Button"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLabel"
                      >
                        Number Verified Failed
                      </h5>
                      <img
                        src={TryAgain}
                        alt="Close-Image"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      Please check the number or try it after sometime.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light text-dark d-block w-100 p-2"
                        data-bs-dismiss="modal"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* ################################## Rent ###################################### */}

              {/* <div className="Property mb-3">
                                <div className="Builtup mb-3">
                                    <p>Rent Amount</p>
                                    <input
                                        type="number"
                                        className="form-control rentInputUI"
                                        placeholder="Rent Amount"
                                        name="rent"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.rent}
                                        required
                                    />
                                    <span className="formik-validation">
                                        {formik.touched.rent && formik.errors.rent}
                                    </span>
                                    <div className="valid-feedback"> Good! </div>
                                    <div className="invalid-feedback">
                                        {" "}
                                        Please Enter Your Rent Amount.{" "}
                                    </div>
                                </div>

                                <div className="Check input-group align-items-center justify-content-between">
                                    <div className="form-check form-check-inline mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="rent_negotiable"
                                            onChange={formik.handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="rent_negotiable">
                                            Price negotiable
                                        </label>
                                    </div>
                                </div>

                                <div className="Builtup mb-3">
                                    <p>Deposit Amount</p>
                                    <input
                                        type="number"
                                        className="form-control rentInputUI"
                                        placeholder="Deposit Amount"
                                        name="rent_deposite"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.rent_deposite}
                                        required
                                    />
                                    <span className="formik-validation">
                                        {formik.touched.rent_deposite && formik.errors.rent_deposite}
                                    </span>
                                    <div className="valid-feedback"> Good! </div>
                                    <div className="invalid-feedback">
                                        {" "}
                                        Please Enter Your Deposit Amount.{" "}
                                    </div>
                                </div>

                                <div className="Expected">
                                    <p>Agreement Duration</p>
                                    <div className="input-group align-items-center justify-content-between mt-3">
                                        <div className="Year ">
                                            <select
                                                style={{ width: "315.775px" }}
                                                className="form-select labelUI"
                                                id="agreement_duration"
                                                name="agreement_duration"
                                                fdprocessedid="01d1u">
                                                <option value="1">1 </option>
                                                <option value="2">2 </option>
                                                <option value="3">3 </option>
                                                <option value="4">4 </option>
                                                <option value="5">5 </option>
                                                <option value="6">6 </option>
                                                <option value="7">7 </option>
                                                <option value="8">8 </option>
                                                <option value="9">9 </option>
                                                <option value="10">10 </option>
                                                <option value="11">11</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="Builtup mb-3">
                                    <p>Maintenance Cost</p>
                                    <input
                                        type="number"
                                        className="form-control rentInputUI"
                                        placeholder="Maintenance Cost"
                                        name="maintainance_cost"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.maintainance_cost}
                                        required
                                    />
                                    <span className="formik-validation">
                                        {formik.touched.maintainance_cost && formik.errors.maintainance_cost}
                                    </span>
                                    <div className="valid-feedback"> Good! </div>
                                    <div className="invalid-feedback">
                                        {" "}
                                        Please Enter Your Maintenence Cost.{" "}
                                    </div>
                                </div>

                                <div className="Expected">
                                    <p>Prefered Tenant</p>
                                    <div className="input-group align-items-center justify-content-between mt-3">
                                        <div className="Year ">
                                            <select
                                                style={{ width: "315.775px" }}
                                                className="form-select labelUI"
                                                id="prefered_tenants"
                                                name="prefered_tenants"
                                                fdprocessedid="01d1u">
                                                <option value="1">Family </option>
                                                <option value="2">Bachelor's </option>
                                                <option value="3">Company </option>
                                                <option value="4">Doesn't matter </option>

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="Property mb-3">
                                    <p>Eating Habits</p>
                                    <div className="input-group gap-3 gap-md-2 gap-lg-3" role="group">
                                        <input type="radio" className="btn-check" id="eating_habits1" autocomplete="off"
                                            name="eating_habits" value="1" onChange={formik.handleChange}
                                        />
                                        <label for="eating_habits1"> <i className="fas fa-circle"></i> Veg </label>

                                        <input type="radio" className="btn-check" id="eating_habits2" autocomplete="off"
                                            name="eating_habits" value="2"
                                            onChange={formik.handleChange}
                                        />
                                        <label for="eating_habits2"> <i className="fas fa-circle"></i> Non-Veg </label>

                                        <input type="radio" className="btn-check" id="eating_habits3" autocomplete="off"
                                            name="eating_habits" value="3" onChange={formik.handleChange}
                                        />
                                        <label for="eating_habits3"> <i className="fas fa-circle"></i>Doesn't Matter </label>

                                    </div>
                                </div>

                            </div> */}
            </div>
          </form>
        </section>
      </body>
    </>
  );
}
