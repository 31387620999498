import React, { useReducer, useState, useEffect } from "react";
import Header from "../Common/header";
import GreenHouse from "../../Assests/Images/Green-House.png";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import TryAgain from "../../Assests/Images/try-again.png";
import CloseImage from "../../Assests/Images/Close-Image.png";
import UploadeLogo from "../../Assests/Images/uploadLogoPng.png";
import logopng from "../../Assests/Images/logo-medium.png";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import uploadFileToBlob, {
  deleteBlob,
  handlePriview,
} from "../../Utils/azureBlob";
import { button } from "bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import India from "../../Assests/Images/india.png";
import { UserProperty } from "../../context/PropertyContext";
import {
  AddUpdatePostProperty,
  saveImageApi,
} from "../../Request/post-propertyRequest";

export default function PostForm7() {
  const searchParams = new URLSearchParams(window.location.search);
  const Cities = searchParams.get("Cities");
  const PropertyForID = searchParams.get("PropertyForId");
  const BuildingType = searchParams.get("buildingType");
  const PropertyId = searchParams.get("PropertyId");
  const localityID = searchParams.get("localityID");
  const apartment_id = searchParams.get("apartment_id");
  const carpet_area = searchParams.get("carpet_area");
  const builtup_area = searchParams.get("builtup_area");
  const floor_total = searchParams.get("floor_total");
  const floor_no = searchParams.get("floor_no");
  const bhk_type = searchParams.get("bhk_type");
  const super_builtup = searchParams.get("super_builtup");
  const maintainance_cost = searchParams.get("maintainance_cost");
  const number_bathroom = searchParams.get("number_bathroom");
  const expectedYear = searchParams.get("expectedYear");
  const expectedDate = searchParams.get("expectedDate");
  const number_balconies = searchParams.get("number_balconies");
  const property_age = searchParams.get("property_age");
  const expectedBy = searchParams.get("expectedBy");
  const price_negotiable = searchParams.get("price_negotiable");
  const under_loan = searchParams.get("under_loan");
  const rent_amount = searchParams.get("rent_amount");
  const rent_negotiable = searchParams.get("rent_negotiable");
  const rent_deposite = searchParams.get("rent_deposite");
  const agreement_duration = searchParams.get("agreement_duration");
  const plot_area = searchParams.get("plot_area");
  const kitchen_type = searchParams.get("kitchen_type");
  const flooring_type = searchParams.get("flooring_type");
  const water_supply = searchParams.get("water_supply");
  const price = searchParams.get("price");
  const property_facing = searchParams.get("property_facing");
  const furnish_type = searchParams.get("furnish_type");
  const eating_habits = searchParams.get("eating_habits");
  const RentNumber_bathroom = searchParams.get("Rentnumber_bathroom");
  const Rentnumber_balconies = searchParams.get("Rentnumber_balconies");
  const prefered_tenants = searchParams.get("prefered_tenants");
  const coveredParkingCount = searchParams.get("coveredParkingCount");
  const openParkingCount = searchParams.get("openParkingCount");
  const description = searchParams.get("description");
  const amenites = searchParams.get("amenites");
  const alternate_mobile_no = searchParams.get("alternate_mobile_no");
  const who_will_show = searchParams.get("who_will_show");
  const readytomove_underconstruction = searchParams.get("readytomove_underconstruction");  

  let navigate = useNavigate();

  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    setCityID(Cities);
    setPropertyID(PropertyId);
    setPropertyForID(PropertyForID);
    setPropertyTypeID(BuildingType);
    setUpdateProperties({
      ...updateProperties,
      // form3
      locality_id: localityID,
      apartment_id,
      carpet_area,
      builtup_area,
      floor_total,
      floor_no,
      bhk_type,
      super_builtup,
      plot_area,
      // form 4
      maintainance_cost,
      number_bathroom,
      expectedYear,
      expectedDate,
      number_balconies,
      property_age,
      expectedBy,
      price_negotiable,
      under_loan,
      rent_amount,
      rent_negotiable,
      rent_deposite,
      agreement_duration,
      price,
      //form 5
      kitchen_type,
      flooring_type,
      water_supply,
      property_facing,
      furnish_type,
      eating_habits,
      RentNumber_bathroom,
      Rentnumber_balconies,
      prefered_tenants,
      coveredParkingCount,
      openParkingCount,

      //form 6
      description,
      amenites,
      alternate_mobile_no,
      who_will_show,
    });
  }, [Cities, PropertyId, BuildingType, PropertyForID]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},

    validationSchema: Yup.object({}),

    onSubmit: async (data) => {
      try {
        console.log({
          ...data,
          propertyImages: uploadedPropertyImageName,
          propertyID,
          is_draft: false,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  //   upload image
  const [loading, setLoading] = useState();

  const [uploadedPropertyImage, setUploadedPropertyImage] = useState(false);
  const [uploadedPropertyImageName, setUploadedPropertyImageName] = useState(
    []
  );

  const [selectedPropertyImages, setSelectedPropertyImages] = useState([]);
  const [selectedPropertyImageNames, setSelectedPropertyImageNames] = useState(
    []
  );
  const [uploadedPropertyImages, setUploadedPropertyImages] = useState([]);
  const [uploadButtonClicked, setUploadButtonClicked] = useState(false);
  const [selectedPropertyWaterMarkImages, setSelectedPropertyWaterMarkImages] =
    useState([]);

  const onFileChange = (e) => {
    if (e.target.name == "DocumentPropertyImageOrgClub") {
      setUploadedPropertyImage(false);
      setSelectedPropertyImages(e.target.files);

      let files = e.target.files;

      const updatedFiles = Array.from(files).map((file) => {
        const uniqueFileName = `${uuidv4()}_${file.name}`;
        setSelectedPropertyImageNames((oldArray) => [
          ...oldArray,
          uniqueFileName,
        ]);
        return new File([file], uniqueFileName, { type: file.type });
      });

      const updatedWaterMarkFiles = Array.from(updatedFiles).map((file) => {
        let watermarkedFile;
        const img = new Image();
        const logo = new Image();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const scaleFactor = Math.min(1, 300 / img.width, 300 / img.height); // Adjust the max width and height as needed
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
          const fontSize = Math.max(14, Math.min(30, canvas.width / 15)); // Adjust the min and max font size as needed
          context.font = `${fontSize}px Arial`;
          context.fillStyle = "rgba(69, 69, 69, 1)";
          context.fillText(
            "TrueSqureFeet",
            canvas.width * 0.05,
            canvas.height * 0.05
          ); // Adjust the text position as needed
          logo.src = logopng;
          logo.onload = function () {
            const logoSize = Math.min(canvas.width * 0.2, canvas.height * 0.2); // Adjust the logo size as needed
            context.drawImage(
              logo,
              canvas.width - logoSize,
              canvas.height - logoSize,
              logoSize,
              logoSize
            );
            canvas.toBlob((blob) => {
              watermarkedFile = new File([blob], file.name, {
                type: file.type,
              });
              setSelectedPropertyWaterMarkImages((oldArray) => [
                ...oldArray,
                watermarkedFile,
              ]);
            }, file.type);
          };
        };
        img.src = URL.createObjectURL(file);
        return watermarkedFile;
      });
      setSelectedPropertyImages(updatedFiles);
    }
    setTimeout(() => {
      document.getElementById('uploadbtn').click()
    }, 2000);
  };

  const onFileUpload = async () => {
    setLoading(true);

    for (
      let index = 0;
      index < selectedPropertyWaterMarkImages.length;
      index++
    ) {
      uploadFileToBlob(selectedPropertyWaterMarkImages[index]).then(() => {
        saveImageApi({
          property_id: propertyID,
          src: handlePriview(selectedPropertyWaterMarkImages[index].name),
          thumbnail: handlePriview(selectedPropertyWaterMarkImages[index].name),
          img_name: selectedPropertyWaterMarkImages[index].name,
        });

        setUploadedPropertyImages((oldArray) => [
          ...oldArray,
          selectedPropertyWaterMarkImages[index].name,
        ]);
        setUploadedPropertyImageName((oldArray) => [
          ...oldArray,
          selectedPropertyWaterMarkImages[index].name,
        ]);
      });
    }

    // Wait for all uploads to complete
    setSelectedPropertyImageNames([]);
    setSelectedPropertyImages([]);
    setUploadedPropertyImage(true);
    setLoading(false);
  };

  const onDeleteFile = (fileName) => {
    deleteBlob(fileName).then(() => {
      setUploadedPropertyImageName((prevState) => {
        const index = prevState.indexOf(fileName);
        if (index > -1) {
          prevState.splice(index, 1);
        }
        if (prevState?.length < 1) {
          setUploadedPropertyImage(false);
          setSelectedPropertyImages([]);
        }
        return prevState;
      });
    });
    forceUpdate();
  };
  // const onDeleteFile = (fileName) => {
  //   // Create a new array without the image to be deleted
  //   const updatedImages = selectedPropertyImages.filter(
  //     (image) => image.name !== fileName
  //   );

  //   // Update the state with the new array of images
  //   setSelectedPropertyImages(updatedImages);
  // };

  const handleDeleteClick = () => {
    // Handle delete logic here
  };

  const handleSubmit = async () => {
    try {
      let finalData = {
        id: propertyID,
        property_statusID: 3,
        Step: 6,
        is_draft: true,
      };
      await AddUpdatePostProperty(finalData).then((resp) => {
        setUpdateProperties(finalData);
        navigate("/");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handlefinalSubmit = async () => {
    try {
      let finalData = {
        // ...updateProperties,
        id: propertyID,
        property_statusID: 3,
        Step: 6,
        is_draft: false,
      };
      await AddUpdatePostProperty(finalData).then((resp) => {
        setUpdateProperties(finalData);
        navigate("/");
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Firsturl = `?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`;
  const secondUrl = `&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`;

  const thirdUrl = `&maintainance_cost=${maintainance_cost}&number_bathroom=${number_bathroom}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&number_balconies=${number_balconies}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}&readytomove_underconstruction=${readytomove_underconstruction}`;
  const RentthirdUrl = `&maintainance_cost=${maintainance_cost}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}&readytomove_underconstruction=${readytomove_underconstruction}`;
  const fourthUrl = (`&kitchen_type=${kitchen_type}&flooring_type=${flooring_type}&water_supply=${water_supply}&property_facing=${property_facing}&furnish_type=${furnish_type}&eating_habits=${eating_habits}`)
  const RentfourthUrl = (`&kitchen_type=${kitchen_type}&flooring_type=${flooring_type}&water_supply=${water_supply}&property_facing=${property_facing}&furnish_type=${furnish_type}&eating_habits=${eating_habits}&number_bathroom=${number_bathroom}&number_balconies=${number_balconies}&prefered_tenants=${prefered_tenants}`)

  const saleUrl = `/post-property-form6${Firsturl}${secondUrl}${thirdUrl}${fourthUrl}&description=${description}&amenites=${amenites}&alternate_mobile_no=${alternate_mobile_no}&who_will_show=${who_will_show}`;
  const rentUrl = `/post-property-form6${Firsturl}${secondUrl}${RentthirdUrl}${RentfourthUrl}&description=${description}&amenites=${amenites}&alternate_mobile_no=${alternate_mobile_no}&who_will_show=${who_will_show}`;


  return (
    <>
      <body>
        <Header />
        {/* ((((((((((((((((((((((((((((((((((((((( STEP 7 ))))))))))))))))))))))))))))))))))))))) */}
        <section class="Step-7" id="Step-7">
          <div class="left">
          <div className="Post-Property-Back" >
        <div
          className="fa fa-chevron-left postBack desktoponly"
          onClick={() => {
            navigate(propertyForID == 1 ? saleUrl : rentUrl);
          }}
        ></div>
        </div>
            <img src={ProgressBar} class="ProgressBar" alt="Progressbar" />
            <div class="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>
          <div class="right">
            {/* <div class="Hidden-Parking">
              <h1 class="Parking-Title">Parking spot</h1>
              <div
                class="Type btn-group gap-3"
                role="group"
                aria-required="true"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autocomplete="off"
                  checked
                />
                <label for="btnradio1">
                  <i class="fas fa-circle"></i> Sell
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  autocomplete="off"
                />
                <label for="btnradio2">
                  <i class="fas fa-circle"></i> Rent/Lease
                </label>
              
              </div>
              <div class="Parking-Count input-group justify-content-between">
                <h5>Parking spot...</h5>
                <div class="Counter">
                  <i class="fas fa-chevron-up up"></i>
                  <span class="Count">0</span>
                  <i class="fas fa-chevron-down down"></i>
                </div>
              </div>
              <div class="Parking-Count input-group justify-content-between">
                <h5>Parking spot...</h5>
                <div class="Counter">
                  <i class="fas fa-chevron-up up"></i>
                  <span class="Count">0</span>
                  <i class="fas fa-chevron-down down"></i>
                </div>
              </div>
            </div> */}

<div className="PostBackContainer">
            <div
              className="Post-Property-Back mobileonly"
              onClick={() => {
                navigate(propertyForID == 1 ? saleUrl : rentUrl);
              }}
            >
              <span className="fa fa-chevron-left  postBack"></span>
            </div>
            <h1
              className="Title postFormFont"
            >
              Upload Property Images
            </h1>
            </div>

            {uploadedPropertyImageName.length > 0 ? (
                <div
                  id="result"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  {uploadedPropertyImageName.map((name, index) => (
                    <div className="box thumbnail-JqfG3XK" key={index}>
                      <img
                        style={{
                          display: "block",
                          height: "20px",
                          weight: "20px",
                        }}
                        src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-close-512.png"
                        // src="http://3.109.90.125/front-assets/Images/Close-Image.png"
                        data-key="JqfG3XK"
                        className="remove-image"
                        id="Close"
                        onClick={() => onDeleteFile(name)}
                      />
                      <img
                        src={`${handlePriview(name)}`}
                        width={100}
                        height={100}
                        alt={name}
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            <div>
              <p>Add best images of your property</p>

              <div
                style={{
                  maxWidth: "422px",
                  height: "174px",
                  background: "#DF2DFC1F",
                  borderRadius: "3px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  border: "1px solid #FC67FF",
                  padding: "10px",
                }}
              >
                <img src={UploadeLogo} />
                <input
                  style={{ display: "none" }}
                  id="uploadDocumentPropertyImageOrgClub"
                  margin="dense"
                  name="DocumentPropertyImageOrgClub"
                  onChange={onFileChange}
                  type="file"
                  value={formik.values.DocumentPropertyImageOrgClub}
                  variant="outlined"
                  multiple
                />
                <button
                  className="Style Next "
                  onClick={() => {
                    document
                      .getElementById("uploadDocumentPropertyImageOrgClub")
                      .click();
                  }}
                  style={{
                    width: "108px",
                    height: "42px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                    flexDirection: "row",
                    lineHeight: "19.5px",
                    alignItems: "center",
                  }}
                >
                  + Upload
                </button>
                <div
                  style={{
                    width: "258px",
                    height: "35px",
                    fontSize: "9px",
                    lineHeight: "10.9px",
                  }}
                >
                  Upload atleast 5 images , max image size accepted is 10 mb
                  Image format should be of png, jpg, jpeg, gif, webp, heic,
                  heif.
                </div>
              </div>

              {/* {selectedPropertyImageNames?.map((fileName, index) => (
                <p key={index}>{fileName}</p>
              ))} */}
              {uploadedPropertyImage ? (
                <button
                style={{display:'none'}}  
                className="Style Next mx-3"
                  disabled
                  variant="contained"
                >
                  Uploaded &#10004;{" "}
                </button>
              ) : (
                <button
                  style={{display:'none'}}
                  id="uploadbtn"
                  className="Style Next mx-3"
                  variant="contained"
                  disabled={!selectedPropertyImages.length > 0}
                  onClick={() => {
                    onFileUpload();
                    setUploadButtonClicked(true);
                  }}
                >
                  Add
                </button>
              )}
            </div>

{uploadedPropertyImage ? 
  // <div className="w-full d-flex  float-right">
  <div style={{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginTop:"100px"
  }}>
    <h5 className="Form6_submitButton_Heading">Your images will be continue to upload in background</h5>
              <Link to={"/"}>
                <button
                  className="Style Next mx-2 "
                  // onClick={formik.handleSubmit}
                  onClick={handlefinalSubmit}
                  style={{
                    margin:"3px",
                    width:"300px"
                  }}
                >
                  Submit images
                </button> 
              </Link>
              </div> : 
              <div style={{
                display:"flex",
                flexDirection:"column",
                marginTop:"100px"

              }}>
                <h5 className="Form6_submitButton_Heading">Properties without images get very few response</h5>
                <button
                className="Style Next "
                style={{
                  margin:"3px"
                }}
                // onClick={formik.handleSubmit}
                onClick={handleSubmit}
              >
                Continue without images
              </button>
              </div>
            // </div>
}
          </div>
        </section>
      </body>
    </>
  );
}
