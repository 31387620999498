import React from 'react'
import Header from '../Components/Common/header'
import SmallReturnManageProperty from '../Components/Manage Property/smallReturn'
import AccordsManageProperty from '../Components/Manage Property/accords'

export default function ManageProperty() {
    return (
        <>
            <Header />
            <SmallReturnManageProperty />
            <AccordsManageProperty />
        </>
    )
}
