import React from 'react'
import Header from '../Components/Common/header'
import LoginSignup from '../Components/Login/login_signup'

export default function Login() {
  return (
    <>
      <Header />
      <LoginSignup />
    </>
  )
}
