import React from 'react'
import Header from '../Common/header'
import AccordsManageProperty from './accords'

const ContactedProperty = () => {
  return (
    <>
        <Header/>
        <AccordsManageProperty/>
    </>
  )
}

export default ContactedProperty
