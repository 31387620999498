import React, { useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";



export default function Contact({ userDetails }) {
  const { isUserLoggedIn, isOtpSent, onOTPVerify, setPhoneNumber,
    phoneNumber, otp, setOtp,setSentOtpFrom ,setIsOtpSent} = UserAuth();

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-containerContact",
        {
          size: "invisible",
          callback: (response) => {
            onSignupForContact(phoneNumber);
          },
          "expired-callback": () => { },
        },
        auth
      );
    }
  }

  function onSignupForContact(mobileNo) {
    setSentOtpFrom("contact")
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+91" + mobileNo;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setOtp("")
        setIsOtpSent(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    setPhoneNumber("")
  },[])
  return (
    <>
      <div id="recaptcha-containerContact"></div>

      <div
        id="contactinfo"
        className="modal custom-modal fade "
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Contact Details</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{fontSize:30 , background:'none'}}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              {isUserLoggedIn ? <div className="row">
                <div className="col-sm-6">
                  <div className="input-block">
                    <label className="col-form-label">Owner Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={userDetails.name}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6">
                  <div className="input-block">
                    <label className="col-form-label">Email</label>
                    <input
                      className="form-control"
                      type="text"
                      value={userDetails.email}
                      disabled
                    />
                  </div>
                </div> */}
                <div className="col-sm-6">
                  <div className="input-block">
                    <label className="col-form-label">Contact Number</label>
                    <input
                      className="form-control"
                      type="text"
                      value={userDetails.mobile_no}
                      disabled
                    />
                  </div>
                </div>
              </div> : <>

                <div >
                  {!isOtpSent ? <>
                    <label className="col-form-label">Please Login To See Contact</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone"
                      required
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      name='mobile_no'
                      value={phoneNumber}
                    /><br />
                    <button onClick={() => onSignupForContact(phoneNumber)} type="button" className="form-control Mobile-Loc" >
                      Send OTP
                    </button>
                  </>
                    : <>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="otp"
                        required
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        name='otp'
                        value={otp} /><br />
                      <button onClick={() => onOTPVerify(otp)} type="button" className="form-control Mobile-Loc" >
                        Verify OTP
                      </button>
                    </>}

                </div>
              </>}


            </div>
          </div>
        </div>
      </div>
    </>
  )
}