import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import Handpicked1 from "../../Assests/Images/Handpicked-1.png";
import Handpicked2 from "../../Assests/Images/Handpicked-2.png";
import Handpicked3 from "../../Assests/Images/Handpicked-3.png";
import Handpicked4 from "../../Assests/Images/Handpicked-4.png";

export default function Handpicked() {
    const [pause, setPause] = useState(false);
    const slider = [
        {
            propertyName: 'Saviour Lord Krishna Mart',
            image: Handpicked1,
            street: 'Yumna expressway',
            city: "Noida",
            price: "25.00 - 94.74 Lacs"
        },
        {
            propertyName: 'City Palace',
            image: Handpicked2,
            street: 'Raja Mansingh Road',
            city: "Jaipur",
            price: "55.78  - 120.14 Lacs"
        },
        {
            propertyName: 'Hotel Taj',
            image: Handpicked3,
            street: 'Marine Drive',
            city: "Mumbai",
            price: "45.02 - 98.20 Lacs"
        },
        {
            propertyName: 'Aflatoon',
            image: Handpicked4,
            street: 'New Delhi',
            city: "Delhi",
            price: "87.11 - 108.33 Lacs"
        },
        {
            propertyName: 'Saviour Lord Krishna Mart',
            image: Handpicked1,
            street: 'Yumna expressway',
            city: "Noida",
            price: "25.00 - 94.74 Lacs"
        },
        {
            propertyName: 'City Palace',
            image: Handpicked2,
            street: 'Raja Mansingh Road',
            city: "Jaipur",
            price: "55.78  - 120.14 Lacs"
        },
        {
            propertyName: 'Hotel Taj',
            image: Handpicked3,
            street: 'Marine Drive',
            city: "Mumbai",
            price: "45.02 - 98.20 Lacs"
        },
        {
            propertyName: 'Aflatoon',
            image: Handpicked4,
            street: 'New Delhi',
            city: "Delhi",
            price: "87.11 - 108.33 Lacs"
        },

    ];

    const [isSlidesToShow, setSlidesToShow] = useState(5)

    useEffect(() => {
        const calculateSlidesToShow = () => {
            if (window.innerWidth >= 1000) {
                setSlidesToShow(5)
            }
            else if (window.innerWidth < 1000 && window.innerWidth >= 600) {
                setSlidesToShow(3)
            }
            else {
                setSlidesToShow(2)
            }
        }
        calculateSlidesToShow();
        window.addEventListener("resize", calculateSlidesToShow)
        return () => {
            window.removeEventListener("resize", calculateSlidesToShow)
        }
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: isSlidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        pauseOnHover: true,
        beforeChange: (currentSlide, nextSlide) => {
            setPause(true);
            setTimeout(() => setPause(false), 2000); // Pause for 2 second
        },
    };

    return (
        <>
            <body className="Home-Page">
                <section className="Handpicked" id="Handpicked">
                    <h3 className="title">Handpicked Commercial Projects</h3>
                    <div className="swiper Catagory">
                        <Slider {...settings}>
                            {slider?.map((item, key) => (
                                <div key={key}>
                                    <div style={{ width: "95%" }} className="swiper-slide box">
                                        <img src={item.image} alt="img" />
                                        <div className="Content">
                                            <p>{item.propertyName}</p>
                                            <p>{item.street}, {item.city}</p>
                                            <p>₹ {item.price}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                        <div className="swiper-pagination"></div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                </section>

            </body>
        </>
    )
}
