import React, { useState } from "react";
import SearchBar2 from "../../Assests/Images/search-bar2.png";
import Icon1 from "../../Assests/Images/icon-1.png";
import Icon2 from "../../Assests/Images/icon-2.png";
import Icon3 from "../../Assests/Images/icon-3.png";
import BannerImage from "../../Assests/Images/Home-bg.png";
import { Link, useNavigate } from "react-router-dom";

export default function Home({
  selectedCityID,
  setSelectedCityID,
  selectedItems,
  setSelectedItems,
}) {
  const navigate = useNavigate();
  // const [selectedCityID, setSelectedCityID] = useState();

  const handleSelectLocallity = () => {
    let url = `/select-locality?property_for=1`;

    if (selectedCityID > 0) {
      url = url + `&Cities=${selectedCityID}`;
    }
    if (
      selectedItems
        ?.filter((item) => item.id != 0)
        ?.map((item) => item?.id)
        ?.filter((item) => item.id != 0)
        .toString().length > 0
    ) {
      url =
        url +
        `&Locality=${selectedItems
          ?.filter((item) => item.id != 0)
          ?.map((item) => item?.id)
          ?.filter((item) => item.id != 0)
          .toString()}`;
    }

    navigate(url);

  };
  const handleSearch = () => {
    console.log("Home");
    let url = `/listing-page?property_for=1&MaxPrice=1000000000&MinPrice=500000`;

    if (selectedCityID > 0) {
      url = url + `&Cities=${selectedCityID}`;
    }
    if (
      selectedItems
        ?.filter((item) => item.id != 0)
        ?.map((item) => item?.id)
        ?.filter((item) => item.id != 0)
        .toString().length > 0
    ) {
      url =
        url +
        `&Locality=${selectedItems
          ?.filter((item) => item.id != 0)
          ?.map((item) => item?.id)
          ?.filter((item) => item.id != 0)
          .toString()}`;
    }

    navigate(url);
  };

  return (
    <>
      <body className="Home-Page">
        <section
          className="Home"
          id="Home"
          style={{
            backgroundImage: `url(${BannerImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            alignItems: "center",
            backgroundSize: "cover",
          }}
        >
          <h1 className="Head">A Great Way To Buy, Sell And Rent Property</h1>
          <div className="Home-Swiper-Slides swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide Slider-1">
                <h1 className="Slider-Header">
                  A Great Way To Buy, Sell And Rent Property
                </h1>
              </div>
              <div className="swiper-slide Slider-2">
                <h1 className="Slider-Header">
                  A Great Way To Buy, Sell And Rent Property
                </h1>
              </div>
              <div className="swiper-slide Slider-3">
                <h1 className="Slider-Header">
                  A Great Way To Buy, Sell And Rent Property
                </h1>
              </div>
            </div>
          </div>

          <div className="mobileonly Small-Search btn-group justify-content-evenly align-items-center">
            {selectedItems?.length == 0 && (
              <div
                onClick={handleSelectLocallity}
                className="Search mobile-search"
                style={{ fontSize: "11px", color: "grey", paddingLeft: "10px" }}
              >
                Search for House,Flat...
              </div>
            )}

            <div className="selected-items" onClick={handleSelectLocallity}>
              {selectedItems?.map((item, index) => (
                <span
                  key={index}
                  className="selected-item"
                  style={{ fontSize: "10px" }}
                >
                  {item.id > 0 ? item.locality_name : item.city_name}

                  <span
                    className="remove-button"
                    // onClick={() => handleRemoveItem(item)}
                  >
                    &#10006;
                  </span>
                </span>
              ))}
            </div>
            <button
            style={{
              background:"none"
            }}
            disabled={selectedItems?.length == 0}
              // to="/listing-page?property_for=1"
              onClick={() => handleSearch()}
              className="text-light "
              type="button "
            >
              <span className="Btn">
                <img src={SearchBar2} alt="Search" /> Search
              </span>
            </button>
          </div>

          <div className="Buttons">
            <a href="#" className="Style">
              Buy
            </a>
            <a href="#" className="Style">
              Rent
            </a>
          </div>

          <form className="Search-Filters">
            <div className="return">
              <i className="fas fa-chevron-left"></i>
              <a href="Result-Search.html" className="Style">
                Done
              </a>
            </div>

            <div className="Form-Buttons">
              <div className="filter-title">
                <h5>I want to</h5>
                <p className="Clear-All ">Clear All</p>
              </div>
              <div className="Catagory">
                <button className="Style" type="button">
                  <i className="fas fa-circle"></i>Rent
                </button>
                <button className="Style" type="button">
                  <i className="fas fa-circle"></i>Buy
                </button>
                <button className="Style" type="button">
                  <i className="fas fa-circle"></i>Pg
                </button>
              </div>
            </div>

            <div className="Property">
              <div className="filter-title">
                <h5>Property Type</h5>
                <i className="fas fa-chevron-up"></i>
              </div>
              <div className="Catagory">
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Property1"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="Property1">
                  <img src={Icon1} alt="Images" />
                  Apartment
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="Property2"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="Property2">
                  <img src={Icon2} alt="Images" />
                  Indipendet house/Villa
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="Property3"
                  autocomplete="off"
                />
                <label className="btn btn-outline-primary" for="Property3">
                  <img src={Icon3} alt="Images" />
                  Standalone Building
                </label>
              </div>
            </div>

            <div className="Localities-City">
              <div className="filter-title">
                <h5>City</h5>
              </div>
              <div className="Catagory">
                <button
                  type="button"
                  className="form-control Mobile-Loc"
                  data-bs-toggle="modal"
                  data-bs-target="#BD"
                >
                  Localities
                </button>
              </div>
            </div>

            <div className="Pricing">
              <div className="filter-title">
                <h5>Pricing</h5>
                <i className="fas fa-chevron-up"></i>
              </div>
              <div className="Catagory">
                <div className="Range-Box">
                  <div className="values">
                    <span id="Range-input-1">15000</span>
                    <span> - </span>
                    <span id="Range-input-2">20000</span>
                  </div>
                  <div className="Range-container">
                    <div className="slider-track"></div>
                    <input
                      type="range"
                      min="0"
                      max="5000"
                      value="1000"
                      id="slider-1"
                    />
                    <input
                      type="range"
                      min="0"
                      max="5000"
                      value="4000"
                      id="slider-2"
                    />
                  </div>
                </div>

                <div className="btn-group w-100 justify-content-between mt-3">
                  <div className="dropdown">
                    <button
                      className="btn btn-light"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>Min Budget</span>
                      <i className="fa fa-chevron-down"></i>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-light"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          1000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          2000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          3000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          4000
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown">
                    <button
                      className="btn btn-light"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>Max Budget</span>
                      <i className="fa fa-chevron-down"></i>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-light"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          1000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          2000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          3000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          4000
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <button type="button" className="Read-More-btn d-block w-100">
              Read More
            </button>
            <div className="Read-More">
              <div className="BKH">
                <div className="filter-title">
                  <h5>BHK Type</h5>
                  <i className="fas fa-chevron-up"></i>
                </div>
                <div className="Catagory">
                  <button className="Style" type="button">
                    <i className="fas fa-circle"></i>1 BHK
                  </button>
                  <button className="Style" type="button">
                    <i className="fas fa-circle"></i>1 BHK
                  </button>
                  <button className="Style" type="button">
                    <i className="fas fa-circle"></i>2 BHK
                  </button>
                  <button className="Style" type="button">
                    <i className="fas fa-circle"></i>3 BHK
                  </button>
                </div>
              </div>

              <div className="Status">
                <div className="filter-title">
                  <h5>Property Status</h5>
                  <i className="fas fa-chevron-up"></i>
                </div>
                <div className="Catagory">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Ready to move
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label" for="flexCheckChecked">
                      Under Construction
                    </label>
                  </div>
                </div>
              </div>

              <div className="Only">
                <div className="filter-title">
                  <h5>Only show with photos</h5>
                  <span className="button"></span>
                </div>
              </div>

              <div className="Apply">
                <div className="filter-title">
                  <h5>Amenities</h5>
                  <i className="fas fa-chevron-up"></i>
                </div>
                <div className="Catagory">
                  <div className="left">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="One"
                      />
                      <label className="form-check-label" for="One">
                        Parking
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Two"
                      />
                      <label className="form-check-label" for="Two">
                        Lift
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Three"
                      />
                      <label className="form-check-label" for="Three">
                        Power backup
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Four"
                      />
                      <label className="form-check-label" for="Four">
                        Park
                      </label>
                    </div>
                  </div>
                  <div className="right">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Five"
                      />
                      <label className="form-check-label" for="Five">
                        Security
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Six"
                      />
                      <label className="form-check-label" for="Six">
                        Gas pipeline
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Seven"
                      />
                      <label className="form-check-label" for="Seven">
                        Club House
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Eight"
                      />
                      <label className="form-check-label" for="Eight">
                        AC Room
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Area">
                <div className="filter-title">
                  <h5>Area</h5>
                  <i className="fas fa-chevron-up"></i>
                </div>
                <div className="Catagory">
                  <div className="Range-Box">
                    <div className="values">
                      <span id="Range-input-1">15000</span>
                      <span> - </span>
                      <span id="Range-input-2">20000</span>
                    </div>
                    <div className="Range-container">
                      <div className="slider-track"></div>
                      <input
                        type="range"
                        min="0"
                        max="5000"
                        value="1000"
                        id="slider-1"
                      />
                      <input
                        type="range"
                        min="0"
                        max="5000"
                        value="4000"
                        id="slider-2"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="Furnishing">
                <div className="filter-title">
                  <h5>Furnishing Type</h5>
                  <i className="fas fa-chevron-up"></i>
                </div>
                <div className="Catagory">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Nine"
                    />
                    <label className="form-check-label" for="Nine">
                      Fully Furnished
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Ten"
                    />
                    <label className="form-check-label" for="Ten">
                      Semi Furnished
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Eleven"
                    />
                    <label className="form-check-label" for="Eleven">
                      Unfrunished
                    </label>
                  </div>
                </div>
              </div>

              <div className="BathRoom">
                <h5>Bathrooms</h5>
                <div className="Counter">
                  <i className="fas fa-chevron-down down"></i>
                  <span className="Count">0</span>
                  <i className="fas fa-chevron-up up"></i>
                </div>
              </div>

              <div className="Localities">
                <div className="filter-title">
                  <h5>Localities</h5>
                  <i className="fas fa-chevron-up"></i>
                </div>
                <div className="Catagory">
                  <div className="left">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum1"
                      />
                      <label className="form-check-label" for="LoremIpsum1">
                        Lorem Ipsum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum2"
                      />
                      <label className="form-check-label" for="LoremIpsum2">
                        Lorem Ipsum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum3"
                      />
                      <label className="form-check-label" for="LoremIpsum3">
                        Lorem Ipsum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum4"
                      />
                      <label className="form-check-label" for="LoremIpsum4">
                        Lorem Ipsum
                      </label>
                    </div>
                  </div>
                  <div className="right">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum5"
                      />
                      <label className="form-check-label" for="LoremIpsum5">
                        Lorem Ipsum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum6"
                      />
                      <label className="form-check-label" for="LoremIpsum6">
                        Lorem Ipsum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum7"
                      />
                      <label className="form-check-label" for="LoremIpsum7">
                        Lorem Ipsum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="LoremIpsum8"
                      />
                      <label className="form-check-label" for="LoremIpsum8">
                        Lorem Ipsum
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="Style Submit-Filter-btn">Submit</button>
          </form>

          <div
            className="modal fade Localities-Modal modal-static"
            id="BD"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="header">
                  <div className="Small-Return justify-content-start">
                    <i
                      className="fa fa-chevron-left"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></i>
                    <p className="m-0">Localities</p>
                  </div>
                </div>
                <div className="body">
                  <select className="form-control" id="Select-box" multiple>
                    <option value="HI">Mumbai</option>
                    <option value="HI">Hawaii</option>
                    <option value="CA">California</option>
                    <option value="NV">Nevada</option>
                    <option value="OR">Oregon</option>
                    <option value="WA">Washington</option>
                    <option value="AZ">Arizona</option>
                    <option value="CO">Colorado</option>
                    <option value="ID">Idaho</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NM">New Mexico</option>
                    <option value="ND">North Dakota</option>
                    <option value="UT">Utah</option>
                    <option value="WY">Wyoming</option>
                    <option value="AL">Alabama</option>
                    <option value="AR">Arkansas</option>
                    <option value="AR">Paris</option>
                    <option value="AR">London</option>
                    <option value="AR">Egypt</option>
                    <option value="IL">Canada</option>
                    <option value="IA">Germeen</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="OK">Oklahoma</option>
                    <option value="SD">South Dakota</option>
                    <option value="TX">Texas</option>
                    <option value="TN">Tennessee</option>
                    <option value="WI">Wisconsin</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="IN">Indiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="OH">Ohio</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WV">West Virginia</option>
                  </select>
                </div>
                <div className="footer">
                  <div className="input-group">
                    <button type="button" id="Suggestio 1" value="Suggestio 1">
                      {" "}
                      Suggestio 1{" "}
                    </button>
                    <button type="button" id="Suggestio 2" value="Suggestio 2">
                      {" "}
                      Suggestio 2{" "}
                    </button>
                    <button type="button" id="Suggestio 3" value="Suggestio 3">
                      {" "}
                      Suggestio 3{" "}
                    </button>
                    <button type="button" id="Suggestio 4" value="Suggestio 4">
                      {" "}
                      Suggestio 4{" "}
                    </button>
                    <button type="button" id="Suggestio 5" value="Suggestio 5">
                      {" "}
                      Suggestio 5{" "}
                    </button>
                    <button type="button" id="Suggestio 6" value="Suggestio 6">
                      {" "}
                      Suggestio 6{" "}
                    </button>
                    <button type="button" id="Suggestio 7" value="Suggestio 7">
                      {" "}
                      Suggestio 7{" "}
                    </button>
                    <button type="button" id="Suggestio 8" value="Suggestio 8">
                      {" "}
                      Suggestio 8{" "}
                    </button>
                    <button type="button" id="Suggestio 9" value="Suggestio 9">
                      {" "}
                      Suggestio 9{" "}
                    </button>
                    <button
                      type="button"
                      id="Suggestio 10"
                      value="Suggestio 10"
                    >
                      {" "}
                      Suggestio 10{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
