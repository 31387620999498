import React, { useEffect, useState } from "react";
import SearchBar from "../../Assests/Images/search-bar.png";
import { getCitiesLocalityListForSearch } from "../../Request/post-propertyRequest";
import { SearchProperty } from "../../context/SearchContext";

const MultipleDropdownNav = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const {state,setState,propertyLists,handleSearch}= SearchProperty()

    const { selectedItems = [], selectedCityID } = state;


    const getData = (inputText) => {
        getCitiesLocalityListForSearch({
            cityid: selectedCityID || 0,
            search: inputText,
        }).then((res) => {
            setResults(res);
        });
    };

    const handleOrder=(e)=>{
        const selectedorder = e.target.value;
        console.log(selectedorder,"dfjaskdhfijojsadoif")
        handleSearch(e.target.value)
    }

    const handleInputChange = (e) => {
        const inputText = e.target.value.toLowerCase();
        setSearchTerm(inputText);
        if (inputText.length < 2) {
            setShowDropdown(false);
            return;
        } else {
            getData(inputText);
            setShowDropdown(true);
        }
    };

    const handleSelectItem = (item) => {
        if (item == "") {
            return;
        }
        setState((prevState)=>({
            ...prevState,
            selectedItems:[...prevState.selectedItems,item],
            selectedCityID:item.city_id || prevState.selectedCityID
        }))
        setSearchTerm("");
        setShowDropdown(false);
    };
    const handleRemoveItem = (itemToRemove) => {
        console.log(itemToRemove,"dhfahwiufher")
        // console.log(selectedItems)
        setState((prevState) => {
            const updatedItems = selectedItems.filter(
              (item) => item !== itemToRemove
            );
            const dfdjf = updatedItems.map((item)=> item.locality_name == "")
            console.log(dfdjf)
            return {
              ...prevState,
              selectedItems: updatedItems,
              selectedCityID: updatedItems.length === 0 ? 0 : prevState.selectedCityID,
              Locality:dfdjf.includes(false) > 0 ? prevState.Locality   : null
            };
          });
    };

    return (
        <div style={{ width: "100%" }}>
            <div className="selected-items" style={{ marginBottom: '10px' }}>
                {selectedItems?.map((item, index) => (
                    <span key={index} className="selected-item">
                        {item.id > 0 ? item.locality_name : item.city_name}
                        <span
                            className="remove-button"
                            onClick={() => handleRemoveItem(item)}
                        >
                            &#10006;
                        </span>
                    </span>
                ))}
            </div>


            <div style={{display:'flex',alignItems:'center'}}>
                <input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Search By Location & City"
                    value={searchTerm}
                    onChange={handleInputChange}
                />
                <img src={SearchBar}  alt="search"
                    // style={{ position: "absolute", right: "0px", marginTop: "-0" }}
                    onClick={() => handleSearch()}
                />
                <div class="dropdown mx-2 desktoponly"
                // style={{ position: "absolute", right: "0px", marginTop: "-0" }}
                >
                    {/* <button class="btn btn-outline-transparent dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" data-bs-display="static">Sort By
                    </button> */}
                    {/* <ul class="dropdown-menu">
                        <li class="dropdown-item" onClick={()=>handleOrder("1")}>Low to Heigh</li>
                        <li class="dropdown-item" onClick={()=>handleOrder("2")}>height to low</li>  
                    </ul> */}
                    <select class="form-select" onChange={handleOrder}  aria-label="Default select example">
                    <option selected value="" >Sort By</option>
                    <option  value={"1"}>Low to High</option>
                    <option  value={"2"}>High to Low</option>
                </select>
                </div>
            </div>

            {(results?.length > 0) && showDropdown && (
                <div id="result-dropdown" className="scrollable-dropdown">
                    <ul>
                    {results
    ?.filter((item) => {
      let index = state.selectedItems.findIndex(item2 => item2.id === item.id);
      return index === -1;
    })
    .sort((a, b) => {
        // Check if all locality_name fields are empty
        const allLocalitiesEmpty = results.every(item => item.locality_name === "");
        if (allLocalitiesEmpty) {
          return 0; // keep the original order
        }
        if (a.locality_name === "" && b.locality_name !== "") {
          return -1; // a comes before b
        }
        if (a.locality_name !== "" && b.locality_name === "") {
          return 1; // b comes before a
        }
        return a.locality_name.localeCompare(b.locality_name); // alphabetical order
    })
    .map((result, index) => (
      <li key={index} onClick={() => handleSelectItem(result)}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <span>
            {result.id > 0 ? result.locality_name : result.city_name}
          </span>
          <span>
            {result.id > 0 ? "Locality" : "City"}
          </span>
        </div>
      </li>
    ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MultipleDropdownNav;
