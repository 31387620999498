import React, { useState, useEffect } from "react";
import Header from "../Common/header";
import GreenHouse from "../../Assests/Images/Green-House.png";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import TryAgain from "../../Assests/Images/try-again.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserProperty } from "../../context/PropertyContext";
import { AddUpdatePostProperty } from "../../Request/post-propertyRequest";

export default function UpdateForm5() {
  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();
  const searchParams = new URLSearchParams(window.location.search);
  const PropertyDetailsByParams = JSON.parse(
    searchParams.get("PropertyDetails")
  );

  let navigate = useNavigate();

  const [coveredParkingCount, setcoverd_parkingCount] = useState(0);

  const incrementCoveredPariking = () => {
    setcoverd_parkingCount(coveredParkingCount + 1);
    formik.setFieldValue("coverd_parking", coveredParkingCount + 1);
  };

  const decrementcoverd_parking = () => {
    if (coveredParkingCount > 0) {
      setcoverd_parkingCount(coveredParkingCount - 1);
      // Update Formik field when the value changes
      formik.setFieldValue("coverd_parking", coveredParkingCount - 1);
    }
  };

  const [openParkingCount, setOpenParkingCount] = useState(0);

  const incrementOpenPariking = () => {
    setOpenParkingCount(openParkingCount + 1);
    formik.setFieldValue("open_parking", openParkingCount + 1);
  };

  const decrementOpenParking = () => {
    if (openParkingCount > 0) {
      setOpenParkingCount(openParkingCount - 1);
      // Update Formik field when the value changes
      formik.setFieldValue("open_parking", openParkingCount - 1);
    }
  };

  useEffect(() => {
    setCityID(PropertyDetailsByParams.city_id);
    setPropertyID(PropertyDetailsByParams.id);
    setPropertyForID(PropertyDetailsByParams.property_types_id);
    setPropertyTypeID(PropertyDetailsByParams.building_types_id);
    setcoverd_parkingCount(PropertyDetailsByParams.coverd_parking);
    setOpenParkingCount(PropertyDetailsByParams.open_parking);
  }, [
    PropertyDetailsByParams.city_id,
    PropertyDetailsByParams.id,
    PropertyDetailsByParams.building_types_id,
    PropertyDetailsByParams.property_types_id,
  ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      furnish_type:
        updateProperties.furnish_type || PropertyDetailsByParams.furnish_type,
      coverd_parking:
        updateProperties.coverd_parking ||
        PropertyDetailsByParams.coverd_parking,
      kitchen_type:
        updateProperties.kitchen_type || PropertyDetailsByParams.kitchen_type,
      water_supply:
        updateProperties.water_supply || PropertyDetailsByParams.water_supply,
      flooring_type:
        updateProperties.flooring_type || PropertyDetailsByParams.flooring_type,
      property_facing:
        updateProperties.property_facing ||
        PropertyDetailsByParams.property_facing,
      open_parking:
        updateProperties.open_parking || PropertyDetailsByParams.open_parking,
      eating_habits:
        updateProperties.eating_habits || PropertyDetailsByParams.eating_habits,
      prefered_tenants:
        updateProperties.prefered_tenants ||
        PropertyDetailsByParams.prefered_tenants,
      number_bathroom:
        updateProperties.number_bathroom ||
        PropertyDetailsByParams.number_bathroom,
      number_balconies:
        updateProperties.number_balconies ||
        PropertyDetailsByParams.number_balconies,
    },

    validationSchema: Yup.object({
      kitchen_type: Yup.string().required("Please select an option"),
    }),

    onSubmit: async (data) => {
      try {
        let finalData;
        if (propertyForID == 1) {
          const {
            furnish_type,
            coverd_parking,
            open_parking,
            kitchen_type,
            water_supply,
            flooring_type,
            property_facing,
          } = data;
          finalData = {
            // ...updateProperties,
            // ...data,
            id: propertyID,
            property_statusID: 3,
            // coverd_parking: coveredParkingCount,
            // open_parking: openParkingCount,
            furnish_type,
            coverd_parking,
            open_parking,
            kitchen_type,
            water_supply,
            flooring_type,
            property_facing,
            Step: 4,
            is_draft: true,
          };
        } else {
          const {
            furnish_type,
            kitchen_type,
            number_bathroom,
            number_balconies,
            water_supply,
            coverd_parking,
            open_parking,
            prefered_tenants,
            eating_habits,
            flooring_type,
            property_facing,
          } = data;
          finalData = {
            // ...updateProperties,
            // ...data,
            id: propertyID || PropertyDetailsByParams.id,
            property_statusID: 3,
            // coverd_parking: coveredParkingCount,
            // open_parking: openParkingCount,
            coverd_parking,
            open_parking,
            furnish_type,
            kitchen_type,
            number_bathroom,
            number_balconies,
            water_supply,
            prefered_tenants,
            eating_habits,
            flooring_type,
            property_facing,
            Step: 4,
            is_draft: true,
          };
        }
        await AddUpdatePostProperty(finalData).then((resp) => {
          setUpdateProperties(finalData);
          const queryParams = encodeURIComponent(
            JSON.stringify(PropertyDetailsByParams)
          );
          navigate(`/update-property-form6?PropertyDetails=${queryParams}`);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <body>
        <Header />
        {/* <Link to="/post-property-form5" className="fa fa-chevron-left Post-Property-Back"></Link> */}

        {/* --------------------------- STEP 5 -------------------------------------- */}
        <section className="Step-5" id="Step-5">
          <div className="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  const queryParams = JSON.stringify(PropertyDetailsByParams);
                  navigate(
                    `/update-property-form4?PropertyDetails=${queryParams}`
                  );
                }}
              ></div>
            </div>
            <img src={ProgressBar} className="ProgressBar" alt="Progressbar" />
            <div className="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>
          <form
            className="right needs-validation"
            data-send="Post-Form6.html"
            novalidate
            onSubmit={formik.handleSubmit}
          >
            <div className="Title">
              {/* <h1 style={{ marginTop: "30px" }}>A bit more information</h1> */}
              {/* <Link className="Style" to="/post-property-form4">
                                Change</Link> */}
            </div>
            <div className="PostBackContainer">
              <div
                className="Post-Property-Back mobileonly "
                onClick={() => {
                  const queryParams = JSON.stringify(PropertyDetailsByParams);
                  navigate(
                    `/update-property-form4?PropertyDetails=${queryParams}`
                  );
                }}
              >
                <span className="fa fa-chevron-left  postBack"></span>
              </div>
              <h1 className="Title postFormFont">A Bit More Information</h1>
            </div>

            <div className="Parent">
              <div className="Property">
                <p>Furnishing Types</p>
                <div
                  className="btn-group gap-3 scroll_Class"
                  role="group"
                  aria-required="true"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    id="btnradio1"
                    autocomplete="off"
                    name="furnish_type"
                    Value="furnished"
                    // defaultValue={formik.values.furnish_type}
                    checked={formik.values.furnish_type == "furnished"}
                    onChange={formik.handleChange}
                  />
                  <label for="btnradio1" style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}>
                    <i className="fas fa-circle"></i> Furnished
                  </label>
                  <input
                    type="radio"
                    id="btnradio2"
                    className="btn-check"
                    autocomplete="off"
                    name="furnish_type"
                    Value="semifurnished"
                    checked={formik.values.furnish_type == "semifurnished"}
                    onChange={formik.handleChange}
                  />
                  <label for="btnradio2" style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}>
                    <i className="fas fa-circle"></i> Semi&nbsp;Furnished
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="furnish_type"
                    id="btnradio3"
                    autocomplete="off"
                    Value="unfurnished"
                    checked={formik.values.furnish_type == "unfurnished"}
                    onChange={formik.handleChange}
                  />
                  <label for="btnradio3" style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}>
                    <i className="fas fa-circle"></i> Unfurnished
                  </label>
                </div>
              </div>

              {(propertyForID || PropertyDetailsByParams.property_types_id) ==
              2 ? (
                <div className="Property mb-3">
                  <p>Kitchen Type</p>
                  <div
                    className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                    role="group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn1"
                      autocomplete="off"
                      name="kitchen_type"
                      value="modular"
                      onChange={formik.handleChange}
                      checked={formik.values.kitchen_type == "modular"}
                    />
                    <label
                      for="kitchen_typebtn1"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Modular{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn2"
                      autocomplete="off"
                      name="kitchen_type"
                      value="coveredshelves"
                      onChange={formik.handleChange}
                      checked={formik.values.kitchen_type == "coveredshelves"}
                    />
                    <label
                      for="kitchen_typebtn2"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Covered&nbsp;Shelves{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn3"
                      autocomplete="off"
                      name="kitchen_type"
                      value="openshelves"
                      onChange={formik.handleChange}
                      checked={formik.values.kitchen_type == "openshelves"}
                    />
                    <label
                      for="kitchen_typebtn3"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i>Open&nbsp;Shelves{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="Property">
                <p>Reserved parking</p>

                <div
                  className="p1-form col-12 d-flex parking-parent"
                  style={{
                    width: "214.54px",
                    height: "17px",
                    fontSize: "14px",
                    margin: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="couter-css">Open parking</span>
                  <div
                    className="Counter parking-child"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={decrementOpenParking}
                      className="Decreament"
                      data-name="open_parking"
                    >
                      -
                    </div>
                    <input
                      type="hidden"
                      name="open_parking"
                      value={formik.values.open_parking}
                    />
                    <span
                      className="Count open_parking"
                      style={{ margin: "0 5px", width: "8px" }}
                    >
                      <b>{formik.values.open_parking}</b>
                    </span>
                    <div
                      onClick={incrementOpenPariking}
                      className="Increament"
                      data-name="open_parking"
                    >
                      +
                    </div>
                  </div>
                </div>

                <div
                  className="p1-form col-12 d-flex parking-parent"
                  style={{
                    width: "214.54px",
                    height: "17px",
                    fontSize: "14px",
                    margin: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="couter-css">Covered parking</span>
                  <div
                    className="Counter parking-child"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      onClick={decrementcoverd_parking}
                      className="Decreament"
                      data-name="covered_parking"
                    >
                      -
                    </div>
                    <input
                      type="hidden"
                      name="covered_parking"
                      value={formik.values.coverd_parking}
                    />
                    <span
                      className="Count covered_parking"
                      style={{ margin: "0 5px", width: "8px" }}
                    >
                      <b>{formik.values.coverd_parking}</b>
                    </span>
                    <div
                      onClick={incrementCoveredPariking}
                      className="Increament"
                      data-name="covered_parking"
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>

              {(propertyForID || PropertyDetailsByParams.property_types_id) !=
              2 ? (
                <div className="Property mb-3">
                  <p>Kitchen Type</p>
                  <div
                    className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                    role="group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn1"
                      autocomplete="off"
                      name="kitchen_type"
                      value="modular"
                      onChange={formik.handleChange}
                      checked={formik.values.kitchen_type == "modular"}
                    />
                    <label
                      for="kitchen_typebtn1"
                      style={{
                        borderRadius: "66px",
                        display: "flex",
                        alignItems: "center",
                        height: "40px",
                      }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Modular{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn2"
                      autocomplete="off"
                      name="kitchen_type"
                      value="coveredshelves"
                      onChange={formik.handleChange}
                      checked={formik.values.kitchen_type == "coveredshelves"}
                    />
                    <label
                      for="kitchen_typebtn2"
                      style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i> Covered&nbsp;Shelves{" "}
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="kitchen_typebtn3"
                      autocomplete="off"
                      name="kitchen_type"
                      value="openshelves"
                      onChange={formik.handleChange}
                      checked={formik.values.kitchen_type == "openshelves"}
                    />
                    <label
                      for="kitchen_typebtn3"
                      style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                    >
                      {" "}
                      <i className="fas fa-circle"></i>Open&nbsp;Shelves{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}

              {(propertyForID || PropertyDetailsByParams.property_types_id) ==
                2 && (
                <>
                  <div className="Property mb-3">
                    <p>No. of Bathrooms</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="1"
                        autocomplete="off"
                        name="number_bathroom"
                        value="1"
                        onChange={formik.handleChange}
                        checked={formik.values.number_bathroom == 1}
                      />
                      <label
                        for="1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> One{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="2"
                        autocomplete="off"
                        name="number_bathroom"
                        value="2"
                        onChange={formik.handleChange}
                        checked={formik.values.number_bathroom == 2}
                      />
                      <label
                        for="2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Two{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="3"
                        autocomplete="off"
                        name="number_bathroom"
                        value="3"
                        onChange={formik.handleChange}
                        checked={formik.values.number_bathroom == 3}
                      />
                      <label
                        for="3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Three{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn4"
                        autocomplete="off"
                        name="number_bathroom"
                        value="4"
                        onChange={formik.handleChange}
                        checked={formik.values.number_bathroom == 4}
                      />
                      <label
                        for="btn4"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Four{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn5"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5"
                        onChange={formik.handleChange}
                        checked={formik.values.number_bathroom == 5}
                      />
                      <label
                        for="btn5"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {}
                        <i className="fas fa-circle"></i> Five{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="btn5+"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5+"
                        onChange={formik.handleChange}
                        checked={formik.values.number_bathroom == "5+"}
                      />
                      <label
                        for="btn5+"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five+{" "}
                      </label>
                    </div>
                  </div>
                  <div className="Property mb-3">
                    <p>No. of Balconies</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn0"
                        autocomplete="off"
                        name="number_balconies"
                        value="0"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == 0}
                      />
                      <label
                        for="number_balconiesbtn0"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Zero{" "}
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn1"
                        autocomplete="off"
                        name="number_balconies"
                        value="1"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == 1}
                      />
                      <label
                        for="number_balconiesbtn1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> One{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn2"
                        autocomplete="off"
                        name="number_balconies"
                        value="2"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == 2}
                      />
                      <label
                        for="number_balconiesbtn2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Two{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn3"
                        autocomplete="off"
                        name="number_balconies"
                        value="3"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == 3}
                      />
                      <label
                        for="number_balconiesbtn3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Three{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn4"
                        autocomplete="off"
                        name="number_balconies"
                        value="4"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == 4}
                      />
                      <label
                        for="number_balconiesbtn4"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Four{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn5"
                        autocomplete="off"
                        name="number_balconies"
                        value="5"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == 5}
                      />
                      <label
                        for="number_balconiesbtn5"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="number_balconiesbtn5+"
                        autocomplete="off"
                        name="number_bathroom"
                        value="5+"
                        onChange={formik.handleChange}
                        checked={formik.values.number_balconies == "5+"}
                      />
                      <label
                        for="number_balconiesbtn5+"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Five+{" "}
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div className="Property mb-3">
                <p>Water Supply</p>
                <div
                  className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                  role="group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    id="btn1"
                    autocomplete="off"
                    name="water_supply"
                    // value={formik.values.water_supply}
                    value="corporation"
                    onChange={formik.handleChange}
                    checked={formik.values.water_supply == "corporation"}
                    // defaultChecked
                  />
                  <label
                    for="btn1"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> Corporation{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="btn2"
                    autocomplete="off"
                    name="water_supply"
                    // value={formik.values.water_supply}
                    value="boring"
                    onChange={formik.handleChange}
                    checked={formik.values.water_supply == "boring"}
                  />
                  <label
                    for="btn2"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> Boring{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="btn3"
                    autocomplete="off"
                    name="water_supply"
                    // value={formik.values.water_supply}
                    value="both"
                    onChange={formik.handleChange}
                    checked={formik.values.water_supply == "both"}
                  />
                  <label
                    for="btn3"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> Both{" "}
                  </label>
                </div>
              </div>

              {/* ################################## Rent ###################################### */}

              {(propertyForID || PropertyDetailsByParams.property_types_id) ==
                2 && (
                <>
                  <div className="Facing">
                    <p>Prefered Tenant</p>
                    <select
                      onChange={formik.handleChange}
                      className="form-select"
                      id="prefered_tenants"
                      name="prefered_tenants"
                      fdprocessedid="01d1u"
                    >
                      <option checked={formik.values.water_supply == "family"}>
                        Family{" "}
                      </option>
                      <option
                        checked={formik.values.water_supply == "bachelors"}
                      >
                        Bachelor's{" "}
                      </option>
                      <option checked={formik.values.water_supply == "company"}>
                        Company{" "}
                      </option>
                      <option
                        checked={formik.values.water_supply == "doesntmatter"}
                      >
                        Doesn't&nbsp;matter{" "}
                      </option>
                    </select>
                  </div>

                  <div className="Property mb-3">
                    <p>Eating Habits</p>
                    <div
                      className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                      role="group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        id="eating_habits1"
                        autocomplete="off"
                        name="eating_habits"
                        value="veg"
                        onChange={formik.handleChange}
                        checked={formik.values.eating_habits == "veg"}
                      />
                      <label
                        htmlFor="eating_habits1"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Veg{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="eating_habits2"
                        autocomplete="off"
                        name="eating_habits"
                        value="nonveg"
                        onChange={formik.handleChange}
                        checked={formik.values.eating_habits == "nonveg"}
                      />
                      <label
                        htmlFor="eating_habits2"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i> Non-Veg{" "}
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        id="eating_habits3"
                        autocomplete="off"
                        name="eating_habits"
                        value="doesntmatter"
                        onChange={formik.handleChange}
                        checked={formik.values.eating_habits == "doesntmatter"}
                      />
                      <label
                        htmlFor="eating_habits3"
                        style={{
                          borderRadius: "66px",
                          display: "flex",
                          alignItems: "center",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <i className="fas fa-circle"></i>Doesn't&nbsp;Matter{" "}
                      </label>
                    </div>
                  </div>
                </>
              )}

              <div className="Flooring">
                <p>Flooring Type</p>
                <select
                  className="form-select"
                  id="Flooring-Box"
                  // multiple
                  name="flooring_type"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.flooring_type}
                >
                  <option selected>Select Options</option>
                  <option
                    // value={formik.values.flooring_type}
                    value="marbel"
                    checked={formik.values.flooring_type == "marbel"}
                  >
                    Marbel
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="concrete"
                    checked={formik.values.flooring_type == "concrete"}
                  >
                    Concrete
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="granite"
                    checked={formik.values.flooring_type == "granite"}
                  >
                    Granite
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="ceramic"
                    checked={formik.values.flooring_type == "ceramic"}
                  >
                    Ceramic
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="mosaic"
                    checked={formik.values.flooring_type == "mosaic"}
                  >
                    Mosaic
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="cement"
                    checked={formik.values.flooring_type == "cement"}
                  >
                    Cement
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="stone"
                    checked={formik.values.flooring_type == "stone"}
                  >
                    Stone
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="vinyl"
                    checked={formik.values.flooring_type == "vinyl"}
                  >
                    Vinyl
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="wood"
                    checked={formik.values.flooring_type == "wood"}
                  >
                    Wood
                  </option>
                  <option
                    // value={formik.values.flooring_type}
                    value="virtified"
                    checked={formik.values.flooring_type == "virtified"}
                  >
                    Virtified
                  </option>
                </select>
              </div>

              <div className="Facing">
                <p>Property Facing</p>
                <select
                  className="form-select"
                  id="Facing-Box"
                  // multiple
                  name="property_facing"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.property_facing} // Set the value to Formik's value
                >
                  <option disabled>Select Property Facing</option>
                  <option value="east">East</option>
                  <option value="west">West</option>
                  <option value="north">North</option>
                  <option value="south">South</option>
                  <option value="northeast">North East</option>
                  <option value="southeast">South East</option>
                  <option value="southwest">South West</option>
                  <option value="idontknow">I dont know</option>
                </select>
              </div>

              {/* ################################## Rent ######################################

                            {propertyForID == 2 && (
                                <>
                                    <div className="Facing">
                                        <p>Prefered Tenant</p>
                                        <select
                                            onChange={formik.handleChange}
                                            className="form-select"
                                            id="prefered_tenants"
                                            name="prefered_tenants"
                                            fdprocessedid="01d1u">
                                            <option value="1">Family </option>
                                            <option value="2">Bachelor's </option>
                                            <option value="3">Company </option>
                                            <option value="4">Doesn't matter </option>
                                        </select>
                                    </div>

                                    <div className="Property mb-3">
                                        <p>Eating Habits</p>
                                        <div className="input-group gap-3 gap-md-2 gap-lg-3" role="group">
                                            <input type="radio" className="btn-check" id="eating_habits1" autocomplete="off"
                                                name="eating_habits" value="1" onChange={formik.handleChange}
                                            />
                                            <label for="eating_habits1"> <i className="fas fa-circle"></i> Veg </label>

                                            <input type="radio" className="btn-check" id="eating_habits2" autocomplete="off"
                                                name="eating_habits" value="2"
                                                onChange={formik.handleChange}
                                            />
                                            <label for="eating_habits2"> <i className="fas fa-circle"></i> Non-Veg </label>

                                            <input type="radio" className="btn-check" id="eating_habits3" autocomplete="off"
                                                name="eating_habits" value="3" onChange={formik.handleChange}
                                            />
                                            <label for="eating_habits3"> <i className="fas fa-circle"></i>Doesn't Matter </label>

                                        </div>
                                    </div>
                                </>)} */}

              <button
                type="submit"
                className="Style Next"
                data-bs-target="#ModalID"
                // pop-up validation model
                // data-bs-toggle="modal"
                style={{ float: "right" }}
              >
                {/* <Link className="Style" style={{ margin: 0, padding: 0 }}
                                    to="/post-property-form6">
                                    Next</Link> */}
                Next
              </button>
              <div
                className="modal fade"
                id="ModalID"
                tabindex="-1"
                aria-labelledby="S-Button"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLabel"
                      >
                        Number Verified Failed
                      </h5>
                      <img
                        src={TryAgain}
                        alt="Close-Image"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      Please check the number or try it after sometime.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light text-dark d-block w-100 p-2"
                        data-bs-dismiss="modal"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </body>
    </>
  );
}
