import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import Pic from "../../Assests/Images/pic.png";

export default function Reviews() {


    const [pause, setPause] = useState(false);


    const [isSlidesToShow, setSlidesToShow] = useState(5)

    useEffect(() => {
        const calculateSlidesToShow = () => {
            if (window.innerWidth >= 1000) {
                setSlidesToShow(5)
            }
            else if (window.innerWidth < 1000 && window.innerWidth >= 800) {
                setSlidesToShow(3)
            }
            else if (window.innerWidth < 800 && window.innerWidth >= 500) {
                setSlidesToShow(2)
            }
            else {
                setSlidesToShow(1)
            }
        }
        calculateSlidesToShow();
        window.addEventListener("resize", calculateSlidesToShow)
        return () => {
            window.removeEventListener("resize", calculateSlidesToShow)
        }
    }, [])

    const slides = [
        {
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec.",
            name: "Rohan Khanna",
            status: "Owner",
            city: "Mumbai"
        },
        {
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec.",
            name: "Tulsi Das",
            status: "Builder",
            city: "Pune"
        },
        {
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec.",
            name: "Mumtaz Khan",
            status: "Owner",
            city: "Mumbai"
        },
        {
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec.",
            name: "Kabir Singh",
            status: "Owner",
            city: "Delhi"
        },
        {
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec.",
            name: "Sultan",
            status: "Owner",
            city: "Haryana"
        },
        {
            description: " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh eu purus vulputate donec.",
            name: "Kabir Khan",
            status: "Owner",
            city: "Delhi"
        }
    ]

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: isSlidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        beforeChange: (currentSlide, nextSlide) => {
            setPause(true);
            setTimeout(() => setPause(false), 2000); // Pause for 2 second
        },
    };
    return (
        <>
            <body className="Home-Page">
                <section className="Reviews" id="Reviews">
                    <h3 className="title">Don’t take it from us, Hear it from our users.</h3>
                    <div className="swiper Catagory">
                        <Slider {...settings}>
                            {slides?.map((item, key) => (
                                <div key={key}>
                                    <div className="swiper-slide box">
                                        <div style={{ width: 243 }} className="parag">
                                            <p>
                                                <i className="fa fa-quote-left"></i>
                                                {item.description}
                                                <i className="fa fa-quote-right"></i>
                                            </p>
                                        </div>
                                        <div style={{ width: 243 }} className="footer">
                                            <div className="img">
                                                <img src={Pic} alt="pic" />
                                            </div>
                                            <div className="Content">
                                                <h6>{item.name}</h6>
                                                <p>{item.status}, {item.city}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <br />
                </section>

            </body>
        </>
    )
}
