import React, { useState } from 'react';
import Slider from 'react-slick';

const sliderData = [
  {
    imgSrc: require("../../Assests/Images/Handpicked-1.png"),
    title: "Saviour Lord Krishna Mart",
    location: "Yamuna Expressway, Noida",
    price: "₹ 25.0 - 94.74 Lacs",
  },
  {
    imgSrc: require("../../Assests/Images/Handpicked-2.png"),
    title: "Saviour Lord Krishna Mart",
    location: "Yamuna Expressway, Noida",
    price: "₹ 25.0 - 94.74 Lacs",
  },
  {
    imgSrc: require("../../Assests/Images/Handpicked-3.png"),
    title: "Saviour Lord Krishna Mart",
    location: "Yamuna Expressway, Noida",
    price: "₹ 25.0 - 94.74 Lacs",
  },
  {
    imgSrc: require("../../Assests/Images/Handpicked-4.png"),
    title: "Saviour Lord Krishna Mart",
    location: "Yamuna Expressway, Noida",
    price: "₹ 25.0 - 94.74 Lacs",
  },
];

export default function SliderComponent() {
  const [pause, setPause] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    beforeChange: (currentSlide, nextSlide) => {
      setPause(true);
      setTimeout(() => setPause(false), 2000); // Pause for 2 seconds
    },
  };

  const imageStyle = {
    maxHeight: '200px', // Adjust the max height as needed
    marginBottom: '10px', // Adjust the margin as needed
  };

  return (
    <Slider {...settings}>
      {sliderData.map((item, index) => (
        <div key={index}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: '10px' }}>
          <img src={item.imgSrc} alt="img" style={imageStyle} />
          <div className="Content">
            <p>{item.title}</p>
            <p>{item.location}</p>
            <p>{item.price}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
}
