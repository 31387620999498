import React, { useState, useEffect } from "react";
import Amenities1 from "../../Assests/Images/Amenities-1.png";
import PhoneIcon from "../../Assests/Images/phone-ico.png";
import No_More_images from "../../Assests/Images/No_more_images.png";
import {
  PopertiesDetails,
  SavePropertyViewer,
  UserDetailsByID,
} from "../../Request/search-propertyRequests";
import Contact from "./contact";
import {
  getAmenitiesListByPropertyId,
  getSimilarProperty,
} from "../../Request/post-propertyRequest";
import "./amenities.css";
import { useNavigate } from "react-router-dom";
import Tag from "../../Assests/Images/tag.png";
import { Button } from "react-bootstrap";
import SimilarProperties from "../Landing Page/SimilarProperties";

export default function ListingPage2Amenities({ setPropertyID, propertyID , setUserDetails , userDetails  }) {
  const searchParams = new URLSearchParams(window.location.search);
  const userIdParam = searchParams.get("propertyid");

  const [propertyDetails, setPropertyDetails] = useState([]);
  // const [userDetails, setUserDetails] = useState([]);
  const [contactModal, setContactModal] = useState(false);
  const [getAminites, setGetAmanities] = useState([]);
  const [similarProperty, setSimilarProperty] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let data = {
      id: userIdParam,
    };

    PopertiesDetails(data)?.then((res) => {
      setPropertyDetails(res);
    });
  }, [propertyID]);

  // GEtting Amaneties List
  useEffect(() => {
    let property = {
      propertyID: userIdParam,
    };

    getAmenitiesListByPropertyId(property).then((res) => {
      setGetAmanities(res);
      console.log(res,"amanaties")
    });

    getSimilarProperty({ propertyid: userIdParam }).then((res) => {
      console.log(res,"Similar Property")
      setSimilarProperty(res);
    });
  }, [propertyID]);

  let userIDS = localStorage.getItem("userID");
  const handleContactDetils = (id) => {
    let data = {
      user_id: userIDS,
      property_id: userIdParam,
    };
    UserDetailsByID(id).then((res) => {
      setUserDetails(res);
      if (userIDS != res.id) {
        SavePropertyViewer(data);
      }
    });
  };

  function handlepagenavigate(id) {
    setPropertyID(id);
    window.open(`/listing-page2?propertyid=${id}`, "_blank");
  }

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = price / 10000000;
      return `₹${inCrores} crore`;
    } else if (price >= 100000) {
      const inLakhs = price / 100000;
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };

  return (
    <>
      <body>
        <section className="Amenities my-2" >
          <h3 className="title">Amenities</h3>
          {Array.isArray(getAminites) && getAminites?.length > 0 ? (
  <div className="container" style={{marginLeft:-50}}>
    <div  >
      <ul className="row"> {/* Wrap your list items in a <ul> or <ol> element */}
        {getAminites.map((p, i) => (
          <li key={i} className="col-auto m-2 amanatiesSzing">
            {/* <img src={Amenities1} alt="Amenities" /> */}
            <span className={p.amenity_icon}></span> {/* Assuming p.amenity_icon contains a class */}
            <span className="m-2">{p.amenity_name}</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
          ) : (
            <>
              {/* <p>No amenities available</p> */}
              <div className="grid-container">
                <div className="grid-item">
                  <img className="m-2" src={Amenities1} alt="Amenities" />
                  <span>King</span>
                </div>
                <div className="grid-item">
                  <img className="m-2" src={Amenities1} alt="Amenities" />
                  <span>Demo Amaneti</span>
                </div>
                <div className="grid-item">
                  <img className="m-2"  src={Amenities1} alt="Amenities" />
                  <span>King-Bed</span>
                </div>
                <div className="grid-item">
                  <img className="m-2"  src={Amenities1} alt="Amenities" />
                  <span>Wifi</span>
                </div>
                <div className="grid-item">
                  <img className="m-2" src={Amenities1} alt="Amenities" />
                  <span>swiming Pool</span>
                </div>
                <div className="grid-item">
                  <img className="m-2" src={Amenities1} alt="Amenities" />
                  <span>Pet Allowed</span>
                </div>
                <div className="grid-item">
                  <img className="m-2"  src={Amenities1} alt="Amenities" />
                  <span>Air Conditioner</span>
                </div>
              </div>
            </>
          )}

{/* <div class="Description">
    <h3 class="title">Description</h3>
    <div>
        <p>{propertyDetails.description}</p>
    </div>
</div> */}


          <h3 className="title">Similar Properties</h3>
         <SimilarProperties similarProperty={similarProperty} setPropertyID={setPropertyID}/>
          <div
            className="Properties"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              // height:560
            }}
          >
            <div
              style={{ width: "100%",  display:"flex" , alignItems:"center" , justifyContent: "space-evenly" }}
              className="swiper-slide box my-4 "
            >
              <button
              style={{ display: "none" }}
              type="button"
              className="Style"
            ></button>
            
            <button
              type="button"
              className="Style ms-3"
              data-bs-toggle="modal"
              data-bs-target="#contactinfo"
              onClick={() => handleContactDetils(propertyDetails.user_id)}
              style={{borderRadius:30}}
            >
              <img src={PhoneIcon} alt="phone-ico" />
              <a>Contact Owner</a>
            </button>
            </div>
          </div>
        </section>

        <Contact userDetails={userDetails} />
      </body>
    </>
  );
}
