import React, { useEffect, useState } from "react";
import Logo from "../../Assests/Images/Logo.png";
import Cursor from "../../Assests/Images/cursor.png";
import { Link } from "react-router-dom";
import { getInitials } from "../../Utils/utility";
import { UserAuth } from "../../context/AuthContext";
import "./header.css";

export default function Header() {
  const { handleLogOut, userName } = UserAuth();

  return (
    <>
      <header>
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          {/* <div className="dropdown">
            <button
              className="btn btn-transparent text-light dropdown-toggle"
              type="button"
              id="Header-Dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-display="static"
            >
              All India
            </button>
            <ul
              className="dropdown-menu dropdown-menu-light"
              aria-labelledby="Header-Dropdown"
            >
              <li className="dropdown-item">Mumbai 1</li>
              <li className="dropdown-item">Mumbai 2</li>
              <li className="dropdown-item">Mumbai 3</li>
              <li className="dropdown-item">Mumbai 4</li>
            </ul>
          </div> */}
        </div>

        <ul className="NavBar">
          <i className="fa fa-times"></i>
          {/* <li className="Main">
            <div className="title">
              <p>
                <img src={Cursor} alt="Cursor" />
                Buyers
              </p>
              <i className="fas fa-plus"></i>
            </div>
            <ul className="Nested-List">
              <li>
                <a style={{ color: "white" }}>For Buyers 1</a>
              </li>
              <li>
                <a tyle={{ color: "white" }}>
                  <Link to="/listing-page">For Buyers 2 </Link>
                </a>
              </li>
              <li>
                <a style={{ color: "white" }}>
                  <Link to="/listing-page2">For Buyers 3 </Link>
                </a>
              </li>
              <li>
                {/* <a style={{ color: "white" }}>For Buyers 4</a> 
                <a style={{ color: "white" }}>
                  <Link to="/manageproperty">For Buyers 4 </Link>
                </a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Buyers 5</a>
              </li>
            </ul>
          </li> 
          */}

          {/* <li className="Main">
            <div className="title">
              <p>
                <img src={Cursor} alt="Cursor" />
                Tenants
              </p>
              <i className="fas fa-plus"></i>
            </div>
            <ul className="Nested-List">
              <li>
                <a style={{ color: "white" }}>For Tenants 1</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Tenants 2</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Tenants 3</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Tenants 4</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Tenants 5</a>
              </li>
            </ul>
          </li>
          <li className="Main">
            <div className="title">
              <p>
                <img src={Cursor} alt="Cursor" />
                Owners
              </p>
              <i className="fas fa-plus"></i>
            </div>
            <ul className="Nested-List">
              <li>
                <a style={{ color: "white" }}>For Owners 1</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Owners 2</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Owners 3</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Owners 4</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Owners 5</a>
              </li>
            </ul>
          </li>
          <li className="Main">
            <div className="title">
              <p>
                <img src={Cursor} alt="Cursor" />
                Dealers / Builers
              </p>
              <i className="fas fa-plus"></i>
            </div>
            <ul className="Nested-List">
              <li>
                <a style={{ color: "white" }}>For Dealers / Builers 1</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Dealers / Builers 2</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Dealers / Builers 3</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Dealers / Builers 4</a>
              </li>
              <li>
                <a style={{ color: "white" }}>For Dealers / Builers 5</a>
              </li>
            </ul>
          </li> */}

          <li className="li Main">
            <Link className="Style" to="/post-property-form1">
              Post Property
            </Link>
          </li>

          <li className="Main">
            <div className="title">
              {userName ? (
                <p className="border border-white rounded-circle mx-3">
                  <img src={Cursor} alt="Cursor" />
                  {getInitials(userName)}
                </p>
              ) : (
                " "
              )}
              <i className="fas fa-plus"></i>
            </div>
            {userName ? (
              <>
                <ul
                  style={{
                    width: "300px",
                    textAlign: "start",
                    top: "110%",
                    left: "-90px",
                    height: "200px",
                  }}
                  className="Nested-List"
                >
                  <li>
                    <Link to="/manageproperty">Manage Properties</Link>
                  </li>

                  <li>
                    <Link to="/contactedproperty">Contacted Properties</Link>
                  </li>
                  <li
                    style={{ color: "white", paddingLeft: "25px" }}
                    onClick={handleLogOut}
                  >
                    Logout
                  </li>
                </ul>
              </>
            ) : (
              <>
                <li>
                  <Link to="/login-signup">Login / Signup</Link>
                </li>
              </>
            )}
          </li>

          {/* <li className="Main-Login-Desktop">
            {userName ? <>
              <button
                // className='Style-Initial btn btn-transparent text-light dropdown-toggle'
                className=' Style-Initial '
                type="button"
                id="User-Header-Dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-display="static"
              >{getInitials(userName)}</button>
              <ul
                className="dropdown-menu dropdown-menu-light"
                aria-labelledby="User-Header-Dropdown"
              >
                <li className="dropdown-item" onClick={handleLogOut}>Logout</li>
              </ul>
            </>
              : <li><Link to="/login-signup">Login / Signup</Link></li>}
          </li> */}

          <div className="Mobile btn-group w-100 justify-content-evenly">
            <li className="Main-Login-Mobile">
              <a style={{ color: "white" }}>Login</a>
            </li>
            <li className="Main-Login-Mobile">
              <a style={{ color: "white" }}>Sign-Up</a>
            </li>
          </div>
        </ul>

        <button
          className="btn btn-transparent"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i className="fa fa-bars"></i>
        </button>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          style={{ width: "200px", backgroundColor: "#8b3d88" }}
        >
          <div className="offcanvas-header ">
            <h5 id="offcanvasRightLabel"> </h5>
            <button
              type="button"
              className="btn-close btn-close-white text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{ color: "white" }}
            ></button>
          </div>
          <div className="offcanvas-body">
            <div>
              <li className="li Main" style={{ marginBottom: 20 }}>
                <Link style={{ color: "white" }} to="/post-property-form1">
                  Post Property
                </Link>
              </li>
              <li className="li Main" style={{ marginBottom: 20 }}>
                <Link style={{ color: "white" }} to="/manageproperty">
                  Manage Property
                </Link>
              </li>
              <li className="li Main" style={{ marginBottom: 20 }}>
                <Link style={{ color: "white" }} to="/contactedproperty">
                  Contacted Property
                </Link>
              </li>

              <li className="li Main">
                {userName ? (
                  <>
                    <li style={{ color: "white" }} onClick={handleLogOut}>
                      Logout
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link style={{ color: "white" }} to="/login-signup">
                        Login / Signup
                      </Link>
                    </li>
                  </>
                )}
              </li>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
