import React, { useEffect, useState } from "react";
import SideBar from "./sideBar";
import MainSection from "./mainSection";
import SearchNavbar from "./searchNavbar";
import SortScreen from "./sortScreen";


export default function SideBar_MainSection({
  selectedCityID, 
  setSelectedCityID,
  selectedItems,
  setSelectedItems,
  Showfilter,
  toggleShowFilter,
  showSort,
  setShowSort,
  toggleSort
}) {




  // const [PropertyForID, setPropertyForID] = useState("");
  // const [PropertyType, setPropertyType] = useState("");
  // const [Cities, setCities] = useState("");
  // const [Locality, setLocality] = useState("");
  // const [BHKType, setBHKType] = useState("");
  // const [Ready_Underconstruction, setReady_Underconstruction] = useState("");
  // const [PropertyFacing, setPropertyFacing] = useState("");
  // const [MinPrice, setMinPrice] = useState();
  // const [MaxPrice, setMaxPrice] = useState();
  // const [propertyLists, setPropertyLists] = useState([]);
  // const [FurnishingType,setFurnishingType] = useState("")
  // const [preTenent,setpreTenent]= useState("")

  // const [selectedCityID, setSelectedCityID] = useState(0);

  // const states = {
  //   PropertyForID,
  //   PropertyType,
  //   Cities,
  //   Locality,
  //   BHKType,
  //   Ready_Underconstruction,
  //   PropertyFacing,
  //   MinPrice,
  //   MaxPrice,
  //   preTenent,
  //   FurnishingType,
  //   setFurnishingType,
  //   setpreTenent,
  //   setMaxPrice,
  //   setMinPrice,
  //   setPropertyForID,
  //   setPropertyType,
  //   setCities,
  //   setLocality,
  //   setBHKType,
  //   setReady_Underconstruction,
  //   setPropertyFacing,
  // };

  // const handleSearch = (orderBy) => {
  //   if (selectedCityID > 0) {
  //     console.log(selectedCityID)
  //     setCities(selectedCityID);
  //   }
  //   // setCities(selectedCityID > 0 ? selectedCityID : states.Cities)
  //   //       console.log(Cities)
  //   if (
  //     selectedItems
  //       ?.filter((item) => item.id != 0)
  //       ?.map((item) => item?.id)
  //       ?.filter((item) => item.id != 0)
  //       .toString().length > 0
  //   ) {
  //     setLocality(
  //       selectedItems
  //         ?.filter((item) => item.id != 0)
  //         ?.map((item) => item?.id)
  //         ?.filter((item) => item.id != 0)
  //         .toString()
  //     );
  //   }

  //   let data = {
  //     PropertyType: states.PropertyType || PropertyType,
  //     Cities: states.Cities || selectedCityID,
  //     Locality: states.Locality || Locality,
  //     BHKType: states.BHKType || BHKType,
  //     MinPrice: states.MinPrice || MinPrice,
  //     MaxPrice: states.MaxPrice || MaxPrice,
  //     Ready_Underconstruction:
  //       states.Ready_Underconstruction || Ready_Underconstruction,
  //     PropertyFacing: states.PropertyFacing || PropertyFacing,
  //     PropertyFor: states.PropertyForID || PropertyForID,
  //     furnish_type:states.FurnishingType || FurnishingType,
  //     prefered_tenants:states.preTenent ||  preTenent
  //   };
  //   getSearchPropertyList(data,orderBy);
  // };

  // const getSearchPropertyList = (data,orderBy ) => {
  //   console.log(data)
  //   searchProperty(data).then((res) => {
  //     if (orderBy === "1") {  
  //       const LowestToHighestData = res.sort((a, b) => (a.price - b.price) || (a.rent_amount - b.rent_amount));
  //       setPropertyLists(LowestToHighestData);
  //   } else if (orderBy === "2") {
  //       const HighestToLowData = res.sort((a, b) => b.price - a.price || b.rent_amount - a.rent_amount);
  //       setPropertyLists(HighestToLowData);
  //   } 
  //   // else {
  //   //     setPropertyLists(res);
  //   // }
  //   setPropertyLists(res);
  //     console.log(res,"Property resefsd")
  //     // setCities("")
  //   });
  // };


  return (
    <>
      <SearchNavbar
      />
      <body class="Result-Search">
        <section class="row g-0 justify-content-center">
          <SideBar
            Showfilter={Showfilter}
            toggleShowFilter={toggleShowFilter}
          />
          {!Showfilter &&
           <MainSection/>
            }

          <SortScreen 
       showSort ={showSort}
       toggleSort={toggleSort}
       setShowSort = {setShowSort} />
        </section>
      </body>
    </>
  );
}
