import React from "react";
import LoginComponent from "./login-component";
import Registration from "./registration";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";


export default function LoginSignup() {

  const navigate = useNavigate();
  const { isUserExist } = UserAuth();

  

  return (
    <>
      <div id="recaptcha-container"></div>
      <LoginComponent

      />

      {!isUserExist && <Registration

      />}
    </>
  );
}
