import React from 'react';
import Banner5 from "../../Assests/Images/Banner-5.png";
import { useState } from 'react';
import {PopertiesDetails} from '../../Request/search-propertyRequests'
import { useEffect } from 'react';
import Map from '../MapComponent/DisplayMap';


export default function LandingPage2Map(propertyID) {
    const searchParams = new URLSearchParams(window.location.search);
    const userIdParam = searchParams.get('propertyid');
    const [propertyDetails,setPropertyDetails]=useState([])
    const [latitude,setLatitude]=useState(null)
    const [longitude,setLongitude]=useState(null)

    useEffect(()=>{
        let data = {
            id:userIdParam,
        }

        PopertiesDetails(data)?.then((res)=>{
            setPropertyDetails(res)
            setLatitude(res.latitude);
            setLongitude(res.longitude)
        })
    },[propertyID])

    return (
        <>
            <body>
                <div className="Map">
                    {/* <iframe
                        // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d27291.668836738772!2d30.008736700000004!3d31.235725499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1642733851962!5m2!1sen!2seg"
                        src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d27291.668836738772!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1642733851962!5m2!1sen!2seg`}
                        allowfullscreen="" loading="lazy">
                    </iframe> */}
                    <div className='MapHeading'>Property Location map</div>
            
                    {latitude && <Map lat={parseFloat(latitude)} lng={parseFloat(longitude)} width={"100%"} height={"70vh"}/>}
                    {/* <h3>x26 People contacted this property owner</h3> */}
                </div>
            </body>
        </>
    )
}
