import React from 'react';
import SearchBar2 from "../../Assests/Images/search-bar2.png";
import MultipleDropdownNav from '../Common/multipleDropdownNav';

export default function SearchNavbar() {
    const searchParams = new URLSearchParams(window.location.search);
    const Cities = searchParams.get('Cities');
    const PropertyForID = searchParams.get('property_for');


    return (
        <>
            <body class="Result-Search">

                {/* ============================================= Search-Navbar ====================================== */}
                <section class="Search-Navbar" id="Search-Navbar">
                    <div class="SearchBar btn-group w-100 p-3">
                        {/* <div class="dropdown">
                            <button class="btn btn-outline-transparent dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false" data-bs-display="static">Mumbai
                            </button>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Action 1</a></li>
                                <li><a class="dropdown-item" href="#">Action 2</a></li>
                                <li><a class="dropdown-item" href="#">Action 3</a></li>
                                <li><a class="dropdown-item" href="#">Action 4</a></li>
                            </ul>
                        </div> */}

                        {/* <div class="dropdown">
                            <button class="btn btn-outline-transparent dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-expanded="false" data-bs-display="static">{ PropertyForID ==1 ? "Buy":"Rent" }
                            </button>
                             <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Action 1</a></li>
                                <li><a class="dropdown-item" href="#">Action 2</a></li>
                                <li><a class="dropdown-item" href="#">Action 3</a></li>
                                <li><a class="dropdown-item" href="#">Action 4</a></li>
                            </ul> 
                        </div> */}

                        <div class="btn-group w-100 align-items-center">
                           <MultipleDropdownNav />
                        </div>
                        

                    </div>
                </section>

            </body>
        </>
    )
}
