import React from "react";
import Header from "../Common/header";
import GreenHouse from "../../Assests/Images/Green-House.png";
import ProgressBar from "../../Assests/Images/Progressbar.png";
import India from "../../Assests/Images/india.png";
import TryAgain from "../../Assests/Images/try-again.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserProperty } from "../../context/PropertyContext";
import { AddUpdatePostProperty,getAmenitiesListByPropertyId } from "../../Request/post-propertyRequest";
import { useState, useEffect } from "react";
import {
  getAllAmanatiesList,
  saveAmaneties,
} from "../../Request/post-propertyRequest";

export default function PostForm6() {
  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();
  const [allAmanatiesList, setAllAmanatiesList] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const Cities = searchParams.get("Cities");
  const PropertyForID = searchParams.get("PropertyForId");
  const BuildingType = searchParams.get("buildingType");
  const PropertyId = searchParams.get("PropertyId");
  const localityID = searchParams.get("localityID");
  const apartment_id = searchParams.get("apartment_id");
  const carpet_area = searchParams.get("carpet_area");
  const builtup_area = searchParams.get("builtup_area");
  const floor_total = searchParams.get("floor_total");
  const floor_no = searchParams.get("floor_no");
  const bhk_type = searchParams.get("bhk_type");
  const super_builtup = searchParams.get("super_builtup");
  const maintainance_cost = searchParams.get("maintainance_cost");
  const number_bathroom = searchParams.get("number_bathroom");
  const expectedYear = searchParams.get("expectedYear");
  const expectedDate = searchParams.get("expectedDate");
  const number_balconies = searchParams.get("number_balconies");
  const property_age = searchParams.get("property_age");
  const expectedBy = searchParams.get("expectedBy");
  const price_negotiable = searchParams.get("price_negotiable");
  const under_loan = searchParams.get("under_loan");
  const rent_amount = searchParams.get("rent_amount");
  const rent_negotiable = searchParams.get("rent_negotiable");
  const rent_deposite = searchParams.get("rent_deposite");
  const agreement_duration = searchParams.get("agreement_duration");
  const plot_area = searchParams.get("plot_area");
  const kitchen_type = searchParams.get("kitchen_type");
  const flooring_type = searchParams.get("flooring_type");
  const water_supply = searchParams.get("water_supply");
  const property_facing = searchParams.get("property_facing");
  const furnish_type = searchParams.get("furnish_type");
  const eating_habits = searchParams.get("eating_habits");
  const prefered_tenants = searchParams.get("prefered_tenants");
  const coveredParkingCount = searchParams.get("coverd_parking");
  const openParkingCount = searchParams.get("open_parking");
  const price = searchParams.get("price");
  const description = searchParams.get("description");
  const amenites = searchParams.get("amenites");
  const alternate_mobile_no = searchParams.get("alternate_mobile_no");
  const who_will_show = searchParams.get("who_will_show");
  const readytomove_underconstruction = searchParams.get(
    "readytomove_underconstruction"
  );

  let navigate = useNavigate();

  const [SelectedAmanateis,setSelectedAmanates]= useState([])  
  const [amanaties, setAmanaties] = useState([]);

  useEffect(() => {
    getAllAmanatiesList().then((res) => {
      setAllAmanatiesList(res);
    });
  }, []);

  const Firsturl = `?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`;
  const secondUrl = `&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`;

  const thirdUrl = `&maintainance_cost=${maintainance_cost}&number_bathroom=${number_bathroom}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&number_balconies=${number_balconies}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}&readytomove_underconstruction=${readytomove_underconstruction}`;
  const RentthirdUrl = `&maintainance_cost=${maintainance_cost}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}&readytomove_underconstruction=${readytomove_underconstruction}`;
  const fourthUrl = `&kitchen_type=${kitchen_type}&flooring_type=${flooring_type}&water_supply=${water_supply}&property_facing=${property_facing}&furnish_type=${furnish_type}&eating_habits=${eating_habits}&coverd_parking=${coveredParkingCount}&open_parking=${openParkingCount}`;
  const RentfourthUrl = `&kitchen_type=${kitchen_type}&flooring_type=${flooring_type}&water_supply=${water_supply}&property_facing=${property_facing}&furnish_type=${furnish_type}&eating_habits=${eating_habits}&number_bathroom=${number_bathroom}&number_balconies=${number_balconies}&prefered_tenants=${prefered_tenants}&coverd_parking=${coveredParkingCount}&open_parking=${openParkingCount}`;


  const handleCheckboxChange = (e) => {
    const id = parseInt(e.target.value, 10); // Convert value to integer
    setAmanaties(prevAmanaties => {
      if (prevAmanaties.includes(id)) {
        return prevAmanaties.filter(amenityId => amenityId !== id);
      } else {
        return [...prevAmanaties, id];
      }
    });
  };

  useEffect(() => {
    setCityID(Cities);
    setPropertyID(PropertyId);
    setPropertyForID(PropertyForID);
    setPropertyTypeID(BuildingType);
    setUpdateProperties({
      ...updateProperties,
      // form3
      locality_id: localityID,
      apartment_id,
      carpet_area,
      builtup_area,
      floor_total,
      floor_no,
      bhk_type,
      super_builtup,
      plot_area,
      // form 4
      maintainance_cost,
      number_bathroom,
      expectedYear,
      expectedDate,
      number_balconies,
      property_age,
      expectedBy,
      price_negotiable,
      under_loan,
      rent_amount,
      rent_negotiable,
      rent_deposite,
      agreement_duration,
      price,
      //form 5
      kitchen_type,
      flooring_type,
      water_supply,
      property_facing,
      furnish_type,
      eating_habits,
      prefered_tenants,
      coveredParkingCount,
      openParkingCount,
    });
  }, [Cities, PropertyId, BuildingType, PropertyForID]);


  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const res = await getAmenitiesListByPropertyId({ propertyID: PropertyId });
        if(res == "No Record Found."){
          setSelectedAmanates([]);
        }else{
          let SelectedAmanateisID = res.map(amenity => amenity.amenity_id )
          setSelectedAmanates(res)
          setAmanaties(SelectedAmanateisID)

          console.log(res, "selected Amannaties");
        }
      } catch (error) {
        console.error("Error fetching amenities:", error);
      }
    };

    fetchAmenities();
  }, [propertyID]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amenites: amanaties || [],
      alternate_mobile_no: alternate_mobile_no || "",
      who_will_show: who_will_show || [],
      description: description || "",
    },

    validationSchema: Yup.object({
      description: Yup.string().required("Please select an option"),

      description: Yup.string()
        .required("Description is required")
        .min(30, "Description must be at least 30 characters"),
    }),

    onSubmit: async (data) => {
      try {
        const { amenites, who_will_show, alternate_mobile_no, description } =
          data;
        let finalData = {
          // ...updateProperties,
          // ...data,
          id: propertyID || PropertyId,
          amenites,
          description,
          who_will_show,
          alternate_mobile_no,
          property_statusID: 3,
          Step: 5,
          is_draft: false,
        };
        console.log(finalData);
        await AddUpdatePostProperty(finalData).then((resp) => {
          amanaties?.map((amenityId) => {
            saveAmaneties({
              property_id: propertyID || PropertyId,
              amenity_id: amenityId,
            });
          });

          setUpdateProperties(finalData);

          const saleUrl = `/post-property-form7${Firsturl}${secondUrl}${thirdUrl}${fourthUrl}&description=${data.description}&amenites=${data.amenites}&alternateNumber=${data.alternate_mobile_no}&alternate_mobile_no=${data.alternate_mobile_no}&who_will_show=${data.who_will_show}`;
          const RentUrl = `/post-property-form7${Firsturl}${secondUrl}${RentthirdUrl}${RentfourthUrl}&description=${data.description}&amenites=${data.amenites}&alternateNumber=${data.alternate_mobile_no}&alternate_mobile_no=${data.alternate_mobile_no}&who_will_show=${data.who_will_show}`;
          navigate(propertyForID == 1 ? saleUrl : RentUrl);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const NavigateFirst = `?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`;
  const NavigatesecondUrl = `&localityID=${localityID}&apartment_id=${apartment_id}&carpet_area=${carpet_area}&builtup_area=${builtup_area}&floor_total=${floor_total}&floor_no=${floor_no}&bhk_type=${bhk_type}&super_builtup=${super_builtup}&plot_area=${plot_area}`;

  const saleUrl = `/post-property-form5${NavigateFirst}${NavigatesecondUrl}&maintainance_cost=${maintainance_cost}&number_bathroom=${number_bathroom}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&number_balconies=${number_balconies}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}`;
  const rentUrl = `/post-property-form5${NavigateFirst}${NavigatesecondUrl}&maintainance_cost=${maintainance_cost}&price=${price}&expectedYear=${expectedYear}&expectedDate=${expectedDate}&property_age=${property_age}&expectedBy=${expectedBy}&price_negotiable=${price_negotiable}&under_loan=${under_loan}&rent_amount=${rent_amount}&rent_negotiable=${rent_negotiable}&rent_deposite=${rent_deposite}&agreement_duration=${agreement_duration}`;


  return (
    <>
      <body className="Post-Property-6">
        <Header />

        {/* <Link to="/post-property-form6" className="fa fa-chevron-left Post-Property-Back"></Link> */}

        {/* =============================== STEP 6 ========================================= */}
        <section className="Step-6" id="Step-6">
          <div className="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  navigate(
                    propertyForID == 1
                      ? `/post-property-form5${Firsturl}${secondUrl}${thirdUrl}${fourthUrl}`
                      : `/post-property-form5${Firsturl}${secondUrl}${RentthirdUrl}${RentfourthUrl}`
                  );
                }}
              ></div>
            </div>
            <img src={ProgressBar} className="ProgressBar" alt="Progressbar" />
            <div className="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>
          <form
            className="right needs-validation"
            // novalidate
            onSubmit={formik.handleSubmit}
          >
            <div className="PostBackContainer">
              <div
                className="Post-Property-Back mobileonly"
                onClick={() => {
                  // navigate(propertyForID == 1 ? saleUrl : rentUrl);
                  navigate(
                    propertyForID == 1
                      ? `/post-property-form5${Firsturl}${secondUrl}${thirdUrl}${fourthUrl}`
                      : `/post-property-form5${Firsturl}${secondUrl}${RentthirdUrl}${RentfourthUrl}`
                  );
                }}
              >
                <span className="fa fa-chevron-left  postBack"></span>
              </div>
              <h1 className="Title  postFormFont">Amenities & Description</h1>
            </div>

            <p className="Title">Add Amenities of property</p>
            <div className="Parent">
              <div
                className="Checks-boxes"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1px",
                }}
              >
                {allAmanatiesList?.map((a, i) => (
                  <div className="form-check form-check-inline ">
                    {" "}
                    {/* style={{width:100}} */}
                    <input
                      className="form-check-input"
                      style={{
                        border: "0.79px solid #8B3D88",
                      }}
                      type="checkbox"
                      id={`Checkbox${a.id}`}
                      name="amenites"
                      value={a.id}
                      checked={amanaties.includes(a.id)}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      for={`Checkbox${a.id}`}
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {a.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="Description">
                <p>Property Description</p>
                <textarea
                  className="form-control"
                  placeholder="Write description which best describes your property and its feature."
                  required
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                ></textarea>
                <span className="formik-validation">
                  {formik.touched.description && formik.errors.description}
                </span>
              </div>
              <div className="Phone">
                <p className="pt-4">Secondary Number</p>
                <div className="btn-group w-100">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter 10 digits"
                    required
                    name="alternate_mobile_no"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.alternate_mobile_no}
                    onInput={(e) => {
                      if (e.target.value.length > 10)
                        e.target.value = e.target.value.slice(0, 10);
                    }}
                  />

                  <span className="input-group-text">
                    <img src={India} />
                  </span>
                </div>
              </div>
              <div className="House">
                <p>Who will show the house</p>
                <select
                  className="form-select dropdown-toggle"
                  id="House-Box"
                  // multiple
                  name="who_will_show"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.who_will_show}
                >
                  <option value="">Select Options</option>
                  <option value="myself">Myself</option>
                  <option value="Neighbour">Neighbour</option>
                  <option value="Securityguard">Security Guard</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {/* <div className="House ">
                <p>Who will show the house</p>
                <div className="btn-group w-100">
                  <input
                    type="text"
                    className="form-control"
                    id="House-Box"
                    name="who_will_show"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.who_will_show}
                    placeholder="Enter who will show the house"
                  />
                </div>
              </div> */}

              <button
                type="submit"
                className="Style Next"
                // pop-up validation model
                // data-bs-toggle="modal"
                data-bs-target="#ModalID"
                style={{ float: "right" }}
              >
                {/* <Link classNameName="Style" style={{ margin: 0, padding: 0, color: 'white' }}
                                    to="/post-property-form7">
                                    Next</Link> */}
                Next
              </button>
              <div
                className="modal fade"
                id="ModalID"
                tabindex="-1"
                aria-labelledby="S-Button"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLabel"
                      >
                        Number Verified Failed
                      </h5>
                      <img
                        src={TryAgain}
                        alt="Close-Image"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      Please check the number or try it after sometime.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light text-dark d-block w-100 p-2"
                        data-bs-dismiss="modal"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </body>
    </>
  );
}
