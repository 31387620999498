import React from 'react';
import PostForm1 from '../Components/Post-Property/post-form1';

export default function PostProperty() {
  return (
    <>
    <PostForm1/>
    </>
  )
}
