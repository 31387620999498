import React from "react";

const NearByPlacesCont = ({nearByPropertyList}) => {
  
  return (
    <>
    {
      (nearByPropertyList && (nearByPropertyList?.length != 0)) ?
      (nearByPropertyList?.map((nbp, index) => {
        const distance = parseFloat(nbp.distance);

        // Display distance in kilometers if greater than or equal to 1 km, otherwise in meters
        let distanceText;
        if (distance > 1) {
          distanceText = (distance).toFixed(2) + "km";
        } else {
          distanceText = (distance * 1000).toFixed(0) + "m";
        }
        return( <div key={index} className="box swiper-slide">
           <h6>{distanceText}</h6>
           <p>{nbp.title}</p>
         </div>);
       })) : 
       <>
        <div className="box swiper-slide">
        <h6>2.5km</h6>
        <p>From School</p>
      </div>
      <div className="box swiper-slide">
        <h6>1.5km</h6>
        <p>From railway station</p>
      </div>
      <div className="box swiper-slide">
        <h6>2.5km</h6>
        <p>From School</p>
      </div>
      <div className="box swiper-slide">
        <h6>1.5km</h6>
        <p>From railway station</p>
      </div>
      <div className="box swiper-slide">
        <h6>2.5km</h6>
        <p>From School</p>
      </div>
      <div className="box swiper-slide">
        <h6>1.5km</h6>
        <p>From railway station</p>
      </div>
      <div className="box swiper-slide">
        <h6>2.5km</h6>
        <p>From School</p>
      </div> 
      </> 
    }

     
    </>
  );
};

export default NearByPlacesCont;
