import React, { useEffect, useState } from "react";
import Icon1 from "../../Assests/Images/icon-1.png";
import Icon2 from "../../Assests/Images/icon-2.png";
import Icon3 from "../../Assests/Images/icon-3.png";
import { propTypes } from "react-bootstrap/esm/Image";
import { SearchProperty } from "../../context/SearchContext";
import { useNavigate } from "react-router-dom";

export default function SideBar({
  Showfilter,
  toggleShowFilter,
}) {
  
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [isOpen4, setIsOpen4] = useState(true);
  const [isOpen5, setIsOpen5] = useState(true);
  const [isOpen6, setIsOpen6] = useState(true);
  const [isOpen7, setIsOpen7] = useState(true);
  const [isOpen8, setIsOpen8] = useState(true);
  const [isOpen9, setIsOpen9] = useState(true);
  const [minArea2, setMinArea2] = useState(0);
  const [maxArea2, setMaxArea2] = useState(100);

  const [MinPrice, setMinPrice] = useState(0);
  const [MaxPrice, setMaxPrice] = useState(10000);
  const [display, setDisplay] = useState("");
  const [isOpenFurnishing, setIsOpenFurnishing] = useState(true);
  const [isOpenTenant, setisOpenTenant] = useState(true);

  const {state,setState}= SearchProperty()


  const toggleFilterFurnishing = () => {
    setIsOpenFurnishing(!isOpenFurnishing);
  };
  const toggleFilterTenant = () => {
    setisOpenTenant(!isOpenTenant);
  };

  const navigate = useNavigate();
  const handleNavigateTo = ()=>{
    console.log("Services");

    let url = `/listing-page?property_for=${state.selectedPropertyFor}`;

    if (state.selectedTypes?.length > 0) {
      url = url + `&PropertyType=${state.selectedTypes.toString()}`;
    }
    if (state.selectedBHKTypes?.length > 0) {
      url = url + `&BHKType=${state.selectedBHKTypes.toString()}`;
    }
    if (state.selectedType != "") {
      url = url + `&Ready_Underconstruction=${state.selectedType}`;
    }
    if (state.selectedFacing != "") {
      url = url + `&PropertyFacing=${state.selectedFacing}`;
    }
    if (state.selectedCityID > 0) {
      url = url + `&Cities=${state.selectedCityID}`;
    }
    if (state.furnishingType != "") {
      url = url + `&furnishingType=${state.furnishingType}`;
    }
    if (state.preTenent != "") {
      url = url + `&preferedtenent=${state.preTenent}`;
    }
    if (
      state.selectedItems
        ?.filter((item) => item.id != 0)
        ?.map((item) => item?.id)
        ?.filter((item) => item.id != 0)
        .toString().length > 0
    ) {
      url =
        url +
        `&Locality=${state.selectedItems
          ?.filter((item) => item.id != 0)
          ?.map((item) => item?.id)
          ?.filter((item) => item.id != 0)
          .toString()}`;
    }

    if (state.MaxPrice > 0) {
      url = url + `&MaxPrice=${state.MaxPrice}`;
    }
    url = url + `&MinPrice=${state.MinPrice}`;

    navigate(url);
  }

  const hanldeDoneBtn =()=>{
    toggleShowFilter()
    handleNavigateTo()
  }

  // getting Values from params

  const handleMinPriceChange = (event) => {
    const newMinPriceChange = parseInt(event.target.value);
    if (newMinPriceChange <= state.MaxPrice) {
      // setState.MinPrice(newMinPriceChange);
      setState((preState)=>({
        ...preState,MinPrice:newMinPriceChange
      }))
      updateSliderTrack(newMinPriceChange,state.MaxPrice)
    }
  };

  const handleMaxPriceChange = (event) => {
    const newMaxPriceChange = parseInt(event.target.value);
    if (newMaxPriceChange >= state.MinPrice) {
      // setState.MaxPrice(newMaxPriceChange);
      setState((preState)=>({
        ...preState,MaxPrice:newMaxPriceChange
      }))
      updateSliderTrack(state.MinPrice,newMaxPriceChange)
    }
  };

  const updateSliderTrack = (minValue, maxValue) => {
    const minInput = document.querySelector('input[type="range"]:first-of-type');
    const maxInput = document.querySelector('input[type="range"]:last-of-type');
    const track = document.querySelector('.slider-track');
  
    const minPercent = ((minValue - minInput.min) / (minInput.max - minInput.min)) * 100;
    const maxPercent = ((maxValue - minInput.min) / (minInput.max - minInput.min)) * 100;
  
    track.style.left = `${minPercent}%`;
    track.style.width = `${maxPercent - minPercent}%`;
  };

  const handleMinAreaChange2 = (e) => {
    setMinArea2(parseInt(e.target.value));
  };

  const handleMaxAreaChange2 = (e) => {
    setMaxArea2(parseInt(e.target.value));
  };

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };
  const toggleFilter2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleFilter3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleFilter4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleFilter5 = () => {
    setIsOpen5(!isOpen5);
  };
  const toggleFilter6 = () => {
    setIsOpen6(!isOpen6);
  };
  const toggleFilter7 = () => {
    setIsOpen7(!isOpen7);
  };
  const toggleFilter8 = () => {
    setIsOpen8(!isOpen8);
  };
  const toggleFilter9 = () => {
    setIsOpen9(!isOpen9);
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = (price / 10000000).toFixed(2);
      return `₹${inCrores} cr`;
    } else if (price >= 100000) {
      const inLakhs = (price / 100000).toFixed(2);
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };


  const handlePropertyForChange = (value, minPrice, maxPrice) => {
    setState((prevState) => ({
      ...prevState,
      selectedPropertyFor: value,
      MinPrice: minPrice,
      MaxPrice: maxPrice,
    }));
  };

  useEffect(() => {
    setDisplay(Showfilter ? "inline-block" : "");
  }, [Showfilter]);
  // const handleApplyFilter = () => {
  //   handleSearch()
  //   // let data = {
  //   //   PropertyType: states.PropertyType,
  //   //   Cities: states.Cities,
  //   //   Locality: states.Locality,
  //   //   BHKType: states.BHKType,
  //   //   MinPrice: states.MinPrice,
  //   //   MaxPrice: states.MaxPrice,
  //   //   Ready_Underconstruction: states.Ready_Underconstruction,
  //   //   PropertyFacing: states.PropertyFacing,
  //   //   PropertyFor: states.PropertyForID,
  //   //   furnish_type: states.FurnishingType,
  //   //   prefered_tenants: states.preTenent,
  //   // };
  //   // getSearchPropertyList(data);
  // };
  return (
    <>
      <aside
        class="Search-Filters col-lg-3 col-md-4 g-0 my-2 shadow-lg rounded-4 mt-4"
        style={{ display: display, top: "40px" }}
      >
        
          <div class="Apply mobileonly" onClick={hanldeDoneBtn}>
            <div class="filter-title">
              <h5></h5>
              <p class="Clear-All ">Done</p>
            </div>
          </div>
      
        {/* Propert For start */}
        <div className={`Property ${isOpen9 ? "open" : ""}`}>
          <div className="filter-title" onClick={toggleFilter9}>
            <h5 style={{ marginBottom: 0 }}>Property For</h5>
            <i
              className={`fas ${isOpen9 ? "fa-chevron-down" : "fa-chevron-up"}`}
              style={{ marginLeft: "10px" }}
            ></i>
          </div>

          {isOpen9 && (
            <div className="Catagory">
              <input
                type="checkbox"
                className="btn-check"
                id="PropertyFor1"
                autoComplete="off"
                checked={state.selectedPropertyFor === "1"}
                onChange={() => handlePropertyForChange("1", 500000, 1000000000)}
        
              />
              <label className="btn btn-outline-primary" htmlFor="PropertyFor1">
                <img src={Icon1} alt="Images" />
                Sale
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="PropertyFor2"
                autoComplete="off"
                checked={state.selectedPropertyFor === "2"}
                onChange={() => handlePropertyForChange("2", 2000, 2000000)}
              />
              <label className="btn btn-outline-primary" htmlFor="PropertyFor2">
                <img src={Icon2} alt="Images" />
                Rent
              </label>
            </div>
          )}
        </div>
        {/* Propert For ends */}

        {/* property Type starts */}
        <div className={`Property ${isOpen ? "open" : ""}`}>
          <div className="filter-title" onClick={toggleFilter}>
            <h5 style={{ marginBottom: 0 }}>Property Type</h5>
            <i
              className={`fas ${isOpen ? "fa-chevron-down" : "fa-chevron-up"}`}
              style={{ marginLeft: "10px" }}
            ></i>
          </div>

          {isOpen && (
            <div className="Catagory" style={{
              display: "flex",
              flexWrap: "nowrap",
              overflow: "scroll",
              overflowX: "auto",
              scrollbarWidth: "none", // Hide scrollbar for Firefox
              "-ms-overflow-style": "none",
            }}>
              <input
                type="checkbox"
                className="btn-check"
                id="Property1"
                autoComplete="off"
                checked={state.selectedTypes?.includes(1)}
                onClick={() => {
                  state.selectedTypes?.includes(1) ?
                  setState((prevState)=>({
                    ...prevState,
                    selectedTypes :null
                  })) :
                  setState((prevState)=>({
                    ...prevState,
                    selectedTypes : "1"
                  }))
                }}
              />

              <label className="btn btn-outline-primary" htmlFor="Property1">
                <img src={Icon1} alt="Images" />
                Apartment
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="Property2"
                autoComplete="off"
                checked={state.selectedTypes?.includes(2)}
                onClick={() => {
                  state.selectedTypes?.includes(2) ?
                  setState((prevState)=>({
                    ...prevState,
                    selectedTypes :null
                  })) :
                  setState((prevState)=>({
                    ...prevState,
                    selectedTypes : "2"
                  }))
                }}
              />
              <label className="btn btn-outline-primary" htmlFor="Property2">
                <img src={Icon2} alt="Images" />Independent&nbsp;house/Villa
              </label>

              <input
                type="checkbox"
                className="btn-check"
                id="Property3"
                autoComplete="off"
                checked={state.selectedTypes?.includes(3)}
                onClick={() => {
                  state.selectedTypes?.includes(3) ?
                  setState((prevState)=>({
                    ...prevState,
                    selectedTypes :null
                  })) :
                  setState((prevState)=>({
                    ...prevState,
                    selectedTypes : "3"
                  }))
                }}
              />
              <label className="btn btn-outline-primary" htmlFor="Property3">
                <img src={Icon3} alt="Images" />
                Standalone&nbsp;Building
              </label>
            </div>
          )}
        </div>
        {/* property Type Ends */}


        {/* BHK Filters start*/}
        <div className={`Property ${isOpen3 ? "open" : ""}`}>
          <div className="filter-title" onClick={toggleFilter3}>
            <h5>BHK Type</h5>
            <i
              className={`fas ${isOpen3 ? "fa-chevron-down" : "fa-chevron-up"}`}
            ></i>
          </div>
          {isOpen3 && (
            <div className="Catagory" style={{
              display: "flex",
              flexWrap: "nowrap",
              overflow: "scroll",
              overflowX: "auto",
              scrollbarWidth: "none", // Hide scrollbar for Firefox
              "-ms-overflow-style": "none",
            }}>
              <input
                type="checkbox"
                className="btn-check"
                id="Bhk_Type1"
                autoComplete="off"
                checked={state.selectedBHKTypes?.includes("1RK")}
                onClick={() => {
                  state.selectedBHKTypes?.includes("1RK") ?
                    // ? setState.selectedBHKTypes(null)
                    // : setState.selectedBHKTypes("1RK");
                    setState((preveState)=>({
                      ...preveState,
                      selectedBHKTypes:""
                    })):
                    setState((preveState)=>({
                      ...preveState,
                      selectedBHKTypes:"1RK"
                    }))
                }}
              />
              <label
                className="btn btn-outline-primary rounded-4"
                htmlFor="Bhk_Type1"
              >
                1&nbsp;RK
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="Bhk_Type2"
                autoComplete="off"
                checked={state.selectedBHKTypes?.includes("1BHK")}
                onClick={() => {
                  state.selectedBHKTypes?.includes("1BHK")
                  ?
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:""
                  })):
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:"1BHK"
                  }))
                }}
              />
              <label
                className="btn btn-outline-primary rounded-4"
                htmlFor="Bhk_Type2"
              >
                1&nbsp;BHK
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="Bhk_Type3"
                autoComplete="off"
                checked={state.selectedBHKTypes?.includes("2BHK")}
                onClick={() => {
                  state.BHKType?.includes("2BHK")
                  ?
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:""
                  })):
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:"2BHK"
                  }))
                }}
              />
              <label
                className="btn btn-outline-primary rounded-4"
                htmlFor="Bhk_Type3"
              >
                2&nbsp;BHK
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="Bhk_Type4"
                autoComplete="off"
                // checked={BHKType?.includes(4)}
                checked={state.selectedBHKTypes?.includes("3BHK")}
                onClick={() => {
                  state.selectedBHKTypes?.includes("3BHK")
                  ?
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:""
                  })):
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:"3BHK"
                  }))
                }}
              />
              <label
                className="btn btn-outline-primary rounded-4"
                htmlFor="Bhk_Type4"
              >
                3&nbsp;BHK
              </label>
              <input
                type="checkbox"
                className="btn-check"
                id="Bhk_Type5"
                autoComplete="off"
                checked={state.selectedBHKTypes?.includes("4BHK")}
                onClick={() => {
                  state.selectedBHKTypes?.includes("4BHK")
                  ?
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:""
                  })):
                  setState((preveState)=>({
                    ...preveState,
                    selectedBHKTypes:"4BHK"
                  }))
                }}
              />
              <label
                className="btn btn-outline-primary rounded-4"
                htmlFor="Bhk_Type5"
              >
                4&nbsp;BHK
              </label>
            </div>
          )}
        </div>
          {/* BHK Filters Ends*/}


          {/* Pricing Filter */}
        <div className={`Pricing ${isOpen2 ? "open" : ""}`}>
          <div className="filter-title" onClick={toggleFilter2}>
            <h5>Pricing</h5>
            <i
              className={`fas ${isOpen2 ? "fa-chevron-down" : "fa-chevron-up"}`}
            ></i>
          </div>
          {isOpen2 && (
            <div className="Catagory">
              <div className="Range-Box">
                <div className="values">
                  <span>{formatPrice(state.MinPrice)}</span>
                  <span> - </span>
                  <span>{formatPrice(state.MaxPrice)}</span>
                </div>
                <div className="Range-container" style={{
                    background:"#EFE4EE",
                    height:"13px",
                    borderRadius:"32px"
                  }}>
                  <div
                    className="slider-track"
                    style={{ background: "purple",
                      height:"13px"
                     }}
                  ></div>
                  {/* <input
                                        type="range"
                                        min="0"
                                        max="5000"
                                        value={minBudget}
                                        onChange={()=> handleMinBudgetChange}
                                        // onChange={(e)=> states.minBudget(MinPrice) ? states.setMinBudget(""): states.setMinBudget(e.target.value)}
                                    />
                                    <input
                                        type="range"
                                        min="0"
                                        max="5000"
                                        value={maxBudget}
                                        onChange={()=>handleMaxBudgetChange}
                                    /> */}
                  {state.selectedPropertyFor == 1 ? (
                    <>
                      <input
                        type="range"
                        min="500000"
                        max="1000000000"
                        value={state.MinPrice}
                        onChange={handleMinPriceChange}
                        step="500000"
                      />
                      <input
                        type="range"
                        min="0"
                        max="1000000000"
                        value={state.MaxPrice}
                        onChange={handleMaxPriceChange}
                        step="50000"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="range"
                        min="2000"
                        max="2000000"
                        value={state.MinPrice}
                        onChange={handleMinPriceChange}
                        step="1000"
                      />
                      <input
                        type="range"
                        min="2000"
                        max="2000000"
                        value={state.MaxPrice}
                        onChange={handleMaxPriceChange}
                        step="1000"
                      />
                    </>
                  )}
                </div>
              </div>
              {/* <div className="btn-group w-100 justify-content-between mt-3">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>Min Budget</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownMenuButton2">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                {minBudget}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>Max Budget</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownMenuButton2">
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                {maxBudget}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
            </div>
          )}
        </div>
          {/* Pricing Filter ends */}

         {/* Furnishing Type starts */}
        {state.selectedPropertyFor == 2 && (
          <>
            <div className={`Property ${isOpenFurnishing ? "open" : ""}`}>
              <div className="filter-title" onClick={toggleFilterFurnishing}>
                <h5>Furnishing Type</h5>
                <i
                  className={`fas ${
                    isOpenFurnishing ? "fa-chevron-down" : "fa-chevron-up"
                  }`}
                ></i>
              </div>
              {isOpenFurnishing && (
                <div className="Catagory" style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflow: "scroll",
                  overflowX: "auto",
                  scrollbarWidth: "none", // Hide scrollbar for Firefox
                  "-ms-overflow-style": "none",
                }}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Furnishing_Type1"
                    autoComplete="off"
                    checked={state.furnishingType == "furnished"}
                    onClick={() => {
                      state.furnishingType?.includes("furnished")
                        // ? setState.furnishingType(null)
                        // : setState.furnishingType("furnished");
                        ? setState((prevState)=>({
                          ...prevState,
                          furnishingType:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          furnishingType:"furnished"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Furnishing_Type1"
                  >
                    Furnished
                  </label>

                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Furnishing_Type2"
                    autoComplete="off"
                    checked={state.furnishingType == "unfurnished"}
                    onClick={() => {
                      state.furnishingType?.includes("unfurnished")
                        // ? setState.furnishingType(null)
                        // : setState.furnishingType("unfurnished");
                        ? setState((prevState)=>({
                          ...prevState,
                          furnishingType:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          furnishingType:"unfurnished"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Furnishing_Type2"
                  >
                    Unfurnished
                  </label>

                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Furnishing_Type3"
                    autoComplete="off"
                    checked={state.furnishingType == "semifurnished"}
                    onClick={() => {
                      state.furnishingType?.includes("semifurnished")
                        // ? setState.furnishingType(null)
                        // : setState.furnishingType("semifurnished");
                        ? setState((prevState)=>({
                          ...prevState,
                          furnishingType:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          furnishingType:"semifurnished"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Furnishing_Type3"
                  >
                    Semi&nbsp;Furnished
                  </label>
                </div>
              )}
            </div>
            <div className={`Property ${isOpenTenant ? "open" : ""}`}>
              <div className="filter-title" onClick={toggleFilterTenant}>
                <h5>Preferred Tenant</h5>
                <i
                  className={`fas ${
                    isOpenTenant ? "fa-chevron-down" : "fa-chevron-up"
                  }`}
                ></i>
              </div>
              {isOpenTenant && (
                <div className="Catagory"  style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflow: "scroll",
                  overflowX: "auto",
                  scrollbarWidth: "none", // Hide scrollbar for Firefox
                  "-ms-overflow-style": "none",
                }}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Tenant_Type1"
                    autoComplete="off"
                    checked={state.preTenent == "Bachelor's"}
                    onClick={() => {
                      state.preTenent?.includes("Bachelor's")
                        // ? setState.preTenent(null)
                        // : setState.preTenent("Bachelor's");
                        ? setState((prevState)=>({
                          ...prevState,
                          preTenent:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          preTenent:"Bachelor's"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Tenant_Type1"
                  >
                    Bachelor's
                  </label>

                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Tenant_Type2"
                    autoComplete="off"
                    checked={state.preTenent == "family"}
                    onClick={() => {
                      state.preTenent?.includes("family")
                        // ? setState.preTenent(null)
                        // : setState.preTenent("family");
                        ? setState((prevState)=>({
                          ...prevState,
                          preTenent:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          preTenent:"family"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Tenant_Type2"
                  >
                    Family
                  </label>

                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Tenant_Type3"
                    autoComplete="off"
                    checked={state.preTenent == "company"}
                    onClick={() => {
                      state.preTenent?.includes("company")
                        // ? setState.preTenent(null)
                        // : setState.preTenent("company");
                        ? setState((prevState)=>({
                          ...prevState,
                          preTenent:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          preTenent:"company"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Tenant_Type3"
                  >
                    Company
                  </label>
                  
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Tenant_Type4"
                    autoComplete="off"
                    checked={state.preTenent == "Doesn't matter"}
                    onClick={() => {
                      state.preTenent?.includes("Doesn't matter")
                        // ? setState.preTenent(null)
                        // : setState.preTenent("Doesn't matter");
                        ? setState((prevState)=>({
                          ...prevState,
                          preTenent:null
                        })):
                         setState((prevState)=>({
                          ...prevState,
                          preTenent:"Doesn't matter"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="Tenant_Type4"
                  >
                    Doesn't&nbsp;matter
                  </label>
                </div>
              )}
            </div>
          </>
        )}
          {/* Furnishing Type ends */}


          

          {/* Property Status Ready To Move Or UnderConstruction */}
        {state.selectedPropertyFor== 1 && (
          <div className={`Property ${isOpen4 ? "open" : ""}`}>
            <div className="filter-title" onClick={toggleFilter4}>
              <h5>Property Status</h5>
              <i
                className={`fas ${
                  isOpen4 ? "fa-chevron-down" : "fa-chevron-up"
                }`}
              ></i>
            </div>
            {isOpen4 && (
              <div className="Catagory" >
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="flexCheckDefault"
                    checked={state.Ready_Underconstruction?.includes("1")}
                    onClick={() => {
                      state.Ready_Underconstruction?.includes("1")
                        // ? setState.setReady_Underconstruction(null)
                        // : setState.setReady_Underconstruction("1");
                        ? setState((preveState)=>({
                          ...preveState,
                          Ready_Underconstruction:null
                        }))
                        : setState((preveState)=>({
                          ...preveState,
                          Ready_Underconstruction:"1"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="flexCheckDefault"
                  >
                    Ready to move
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="flexCheckChecked"
                    checked={state.Ready_Underconstruction?.includes("2")}
                    onClick={() => {
                      state.Ready_Underconstruction?.includes("2")
                        // ? setState.setReady_Underconstruction(null)
                        // : setState.setReady_Underconstruction("2");
                        ? setState((preveState)=>({
                          ...preveState,
                          Ready_Underconstruction:null
                        }))
                        : setState((preveState)=>({
                          ...preveState,
                          Ready_Underconstruction:"2"
                        }))
                    }}
                  />
                  <label
                    className="btn btn-outline-primary rounded-4"
                    htmlFor="flexCheckChecked"
                  >
                    Under Construction
                  </label>
                </div>
            )}
          </div>
        )}
        {/* Property Status Ready To Move Or UnderConstruction ends*/}


        {/* <div class="Only">
                    <div class="filter-title">
                        <h5>Only show with photos</h5>
                        <span class="button"></span>
                    </div>
                </div> */}

        {/* <div className={`Select-Amenities ${isOpen5 ? 'open' : ''}`}>
                    <div className="filter-title" onClick={toggleFilter5}>
                        <h5>Amenities</h5>
                        <i className={`fas ${isOpen5 ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                    </div>
                    {isOpen5 && (
                        <div className="Catagory">
                            <div className="left">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="One" />
                                    <label className="form-check-label" htmlFor="One">
                                        Parking
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Two" />
                                    <label className="form-check-label" htmlFor="Two">
                                        Lift
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Three" />
                                    <label className="form-check-label" htmlFor="Three">
                                        Power backup
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Four" />
                                    <label className="form-check-label" htmlFor="Four">
                                        Park
                                    </label>
                                </div>
                            </div>
                            <div className="right">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Five" />
                                    <label className="form-check-label" htmlFor="Five">
                                        Security
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Six" />
                                    <label className="form-check-label" htmlFor="Six">
                                        Gas pipeline
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Seven" />
                                    <label className="form-check-label" htmlFor="Seven">
                                        Club House
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="Eight" />
                                    <label className="form-check-label" htmlFor="Eight">
                                        AC Room
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                </div> */}

        {/* <div className={`Area ${isOpen6 ? 'open' : ''}`}>
                    <div className="filter-title" onClick={toggleFilter6}>
                        <h5>Area</h5>
                        <i className={`fas ${isOpen6 ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                    </div>
                    {isOpen6 && (
                        <div className="Catagory">
                            <div className="Range-Box">
                                <div className="values">
                                    <span id="Range-input-1">{minArea2}</span>
                                    <span> - </span>
                                    <span id="Range-input-2">{maxArea2}</span>
                                </div>
                                <div className="Range-container">
                                    <div className="slider-track"></div>
                                    <input
                                        type="range"
                                        min="0"
                                        max="5000"
                                        value={minArea2}
                                        onChange={handleMinAreaChange2}
                                        id="slider-1"
                                    />
                                    <input
                                        type="range"
                                        min="0"
                                        max="5000"
                                        value={maxArea2}
                                        onChange={handleMaxAreaChange2}
                                        id="slider-2"
                                    />
                                </div>
                            </div>
                            <div className="btn-group w-100 justify-content-between mt-3">
                                <div className="dropdown">
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="dropdownMinArea"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>Min Area</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-light"
                                        aria-labelledby="dropdownMinArea"
                                    >
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                1000
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                2000
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                3000
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                4000
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="dropdownMaxArea"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span>Max Area</span>
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-light"
                                        aria-labelledby="dropdownMaxArea"
                                    >
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                1000
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                2000
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                3000
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                4000
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div> */}

        {/* <div className={`Furnishing ${isOpen7 ? 'open' : ''}`}>
                    <div className="filter-title" onClick={toggleFilter7}>
                        <h5>Furnishing Type</h5>
                        <i className={`fas ${isOpen7 ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                    </div>
                    {isOpen7 && (
                        <div className="Catagory">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Nine" />
                                <label className="form-check-label" for="Nine">
                                    Fully Furnished
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Ten" />
                                <label className="form-check-label" for="Ten">
                                    Semi Furnished
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Eleven" />
                                <label className="form-check-label" for="Eleven">
                                    Unfurnished
                                </label>
                            </div>
                        </div>
                    )}
                </div> */}

        {/* <div class="BathRoom">
                    <h5>Bathrooms</h5>
                    <div class="Counter">
                        <i class="fas fa-chevron-down down"></i>
                        <span class="Count">0</span>
                        <i class="fas fa-chevron-up up"></i>
                    </div>
                </div> */}

        {/* <div className={`Localities ${isOpen8 ? 'open' : ''}`}>
                    <div className="filter-title" onClick={toggleFilter8}>
                        <h5>Localities</h5>
                        <i className={`fas ${isOpen8 ? 'fa-chevron-down' : 'fa-chevron-up'}`}></i>
                    </div>
                    {isOpen8 && (
                        <div className="Catagory">
                            <div className="left">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="LoremIpsum1" />
                                    <label className="form-check-label" for="LoremIpsum1">
                                        Lorem Ipsum
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="LoremIpsum2" />
                                    <label className="form-check-label" for="LoremIpsum2">
                                        Lorem Ipsum
                                    </label>
                                </div>
                            </div>
                            <div className="right">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="LoremIpsum5" />
                                    <label className="form-check-label" for="LoremIpsum5">
                                        Lorem Ipsum
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="LoremIpsum6" />
                                    <label className="form-check-label" for="LoremIpsum6">
                                        Lorem Ipsum
                                    </label>
                                </div>
                               
                            </div>
                        </div>
                    )}
                </div> */}
      </aside>
    </>
  );
}
