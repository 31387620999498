import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";

function Map({ lat, lng, setPlaceCordinates, width, height }) {
  const containerStyle = {
    width,
    height,
  };
  const [markerPosition, setMarkerPosition] = useState({ lat, lng });

  const onMarkerDragEnd = (e) => {
    setPlaceCordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDz9QjCNiIHxRGhbb0NaEDOV0vm30Nc-A4", // Add your API key
  });

  return (
    <>
      {/* <LoadScript googleMapsApiKey="AIzaSyDz9QjCNiIHxRGhbb0NaEDOV0vm30Nc-A4"> */}
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={markerPosition}
          zoom={10}
        >
          <MarkerF
            key="AIzaSyDz9QjCNiIHxRGhbb0NaEDOV0vm30Nc-A4"
            position={markerPosition}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
            icon={{
              url: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
              scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
            }}
          />
        </GoogleMap>
      )}
      {/* </LoadScript> */}
    </>
  );
}

export default Map;
