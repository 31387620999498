import React, { useEffect, useState } from "react";
import ListingPage2Slider from "../Components/Listing Pages 2/slider";
import Header from "../Components/Common/header";
import ListingPage2Banner from "../Components/Listing Pages 2/banner";
import ListingPage2Apartment from "../Components/Listing Pages 2/apartment";
import LandingPage2Map from "../Components/Listing Pages 2/map";
import ListingPage2Amenities from "../Components/Listing Pages 2/amenities";
import ListingPage2Accords from "../Components/Listing Pages 2/accords";
import {
  PopertiesDetails,
  UserDetailsByID,
  SavePropertyViewer,
} from "../Request/search-propertyRequests";
import filter from "../Assests/Images/contactOwner.png";
import PhoneIcon from "../Assests/Images/phone-ico.png";
import Contact from "../Components/Listing Pages 2/contact";

export default function ListingPage2() {
  const searchParams = new URLSearchParams(window.location.search);
  const userIdParam = searchParams.get("propertyid");
  const [propertyID, setPropertyID] = useState(userIdParam || "");
  const [userDetails, setUserDetails] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [Showfilter, setShowFilter] = useState(false);

  const toggleShowFilter = () => setShowFilter(!Showfilter);

  useEffect(() => {
    if (propertyID) {
      let data = { id: propertyID };
      PopertiesDetails(data).then((res) => {
        setPropertyDetails(res);
        console.log(res, "propertyDetails propertyDetails propertyDetails");
      });
    }
  }, [propertyID]);

  let userIDS = localStorage.getItem("userID");

  const handleContactDetils = (id) => {
    let data = {
      user_id: userIDS,
      property_id: propertyID,
    };
    UserDetailsByID(id).then((res) => {
      setUserDetails(res);
      if (userIDS !== res.id) {
        SavePropertyViewer(data);
      }
    });
  };

  return (
    <>
      <Header />
      {/* <ListingPage2Slider /> */}
      <ListingPage2Banner propertyID={propertyID} />

      <ListingPage2Apartment propertyID={propertyID}  handleContactDetils={handleContactDetils}/>
      <>
        <img
          src={filter}
          alt="filter"
          data-bs-toggle="modal"
          data-bs-target="#contactinfo"
          style={{
            position: "fixed",
            position: "sticky",
            top: 100,
            left: 250,
            zIndex: 10,
            bottom: 10,
            width: 150,
            marginTop: -920,
          }}
          onClick={() => handleContactDetils(propertyDetails.user_id)}
          className="mobileonly"
        />
      </>
      <ListingPage2Accords propertyID={propertyID} />
      {/* <>Nav tabs</> <>Tab panel</> */}
      <LandingPage2Map propertyID={propertyID} />
      <ListingPage2Amenities setPropertyID={setPropertyID} setUserDetails={setUserDetails} userDetails={userDetails} />
      <Contact userDetails={userDetails} />
    </>
  );
}
