import React, { useEffect, useState } from "react";
import Header from "../Common/header";
import Progressbar from "../../Assests/Images/Progressbar.png";
import GreenHouse from "../../Assests/Images/Green-House.png";
import TryAgain from "../../Assests/Images/try-again.png";
import { Link, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AddUpdatePostProperty,
  addAppartment,
  addLocality,
  getApartmentListByLocalityID,
  getLocalitiesByCityID,
} from "../../Request/post-propertyRequest";
import { useNavigate } from "react-router-dom";
import { UserProperty } from "../../context/PropertyContext";
import Map from "../MapComponent/Map";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
// import { addNearByPlacesFunction } from "../../Utils/addNearByPlace";
import { SaveNearByPlaces } from "../../Request/userRequest";

export default function PostForm3() {
  const searchParams = new URLSearchParams(window.location.search);
  const Cities = searchParams.get("Cities");
  const PropertyForID = searchParams.get("PropertyForId");
  const BuildingType = searchParams.get("buildingType");
  const PropertyId = searchParams.get("PropertyId");
  const localityIDprams = searchParams.get("localityID");
  const apartment_id = searchParams.get("apartment_id");
  const carpet_area = searchParams.get("carpet_area");
  const builtup_area = searchParams.get("builtup_area");
  const floor_total = searchParams.get("floor_total");
  const floor_no = searchParams.get("floor_no");
  const bhk_type = searchParams.get("bhk_type");
  const super_builtup = searchParams.get("super_builtup");
  const plot_area = searchParams.get("plot_area");

  const {
    cityID,
    setCityID,
    propertyID,
    setPropertyID,
    propertyTypeID,
    setPropertyTypeID,
    propertyForID,
    setPropertyForID,
    updateProperties,
    setUpdateProperties,
  } = UserProperty();

  let navigate = useNavigate();

  const [number, setNumber] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setCityID(Cities);
    setPropertyID(PropertyId);
    setPropertyForID(PropertyForID);
    setPropertyTypeID(BuildingType);
  }, [Cities, PropertyId, BuildingType, PropertyForID]);

  // Function to handle changes in the number input field
  const handleNumberChange = (event) => {
    const value = event.target.value;
    setNumber(value);

    // Generate options based on the input number
    const newOptions = [];
    for (let i = 1; i <= value; i++) {
      newOptions.push(i);
    }
    setOptions(newOptions);
  };

  const url = `?PropertyId=${PropertyId}&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      carpet_area: carpet_area || "",
      builtup_area: builtup_area || "",
      floor_total: floor_total || "",
      floor_no: floor_no || "",
      apartment_id: apartment_id || "",

      bhk_type: bhk_type || "1RK",
      super_builtup: super_builtup || "",
      plot_area: plot_area || "",
    },

    validationSchema: Yup.object({
      // carpet_area: Yup.number()
      //     .min(400, 'Carpet area not less than 400 sq/feet')
      //     .required('Carpet area is required'),
      // builtup_area: Yup.number()
      //     .min(Yup.ref('carpet_area'), 'Built-up area must be greater than carpet area')
      //     .required('Built-up area is required'),
      // floor_total: Yup.number()
      //     .required('Total number of floors is required'),
      // floor_no: Yup.number()
      //     .required('Floor number is required')
      //     .max(Yup.ref('floor_total'), 'Not possible')
    }),

    onSubmit: async (data) => {
      try {
        const {
          bhk_type,
          carpet_area,
          builtup_area,
          super_builtup,
          apartment_id,
          plot_area,
          floor_no,
          floor_total,
        } = data;
        let finalData = {
          // ...updateProperties,
          // ...data,
          // cityID,
          // propertyID,
          // propertyTypeID,
          // propertyForID,
          bhk_type,
          carpet_area,
          builtup_area,
          super_builtup,
          apartment_id,
          plot_area,
          floor_no,
          floor_total,
          id: propertyID || PropertyId,
          locality_id: localityID,
          apartment_id: selectedApartmentArray[0]?.id,
          property_statusID: 3,
          Step: 2,
          latitude: getPlaceCordinates?.lat,
          longitude: getPlaceCordinates?.lng,
          is_draft: true,
        };
        SaveNearByPlaces({
          property_id: propertyID,
          latitude: getPlaceCordinates?.lat,
          longitude: getPlaceCordinates?.lng,
        });
        await AddUpdatePostProperty(finalData).then((resp) => {
          setUpdateProperties(finalData);
          navigate(
            `/post-property-form4${url}&localityID=${localityID}&apartment_id=${selectedApartmentArray[0]?.id}&carpet_area=${data.carpet_area}&builtup_area=${data.builtup_area}&floor_total=${data.floor_total}&floor_no=${data.floor_no}&bhk_type=${data.bhk_type}&super_builtup=${data.super_builtup}&plot_area=${data.plot_area}`
          );
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [localities, setLocalities] = useState();

  const getLocalities = () => {
    getLocalitiesByCityID({ city_id: cityID || Cities }).then((res) => {
      // console.log(res,"localitys")
      setLocalities(res);
    });
  };

  const [apartmentList, setApartmentList] = useState();
  const [localityID, setLocalityID] = useState("");

  const getApartmentList = () => {
    getApartmentListByLocalityID({ city_id: cityID || Cities }).then((res) => {
      // console.log(res,"apartment List")
      const filterdArry = res.filter((item) => {
        return (
          item.usertypeid == 1 ||
          item.status_id == 1 ||
          item.userid == localStorage.getItem("userID")
        );
      });
      // console.log(filterdArry,"filterdArry")
      setApartmentList(filterdArry);
    });
  };

  const [showSecondDropdown, setShowSecondDropdown] = useState(false);

  const [showLocalityMap, setShowLocalityMap] = useState(false);
  const [showAppartmentMap, setShowAppartmentMap] = useState(false);
  const [showAppartmentField, setShowAppartmentField] = useState(false);
  const [latitude, setLatitude] = useState(21.1458);
  const [longitude, setLongitude] = useState(79.0882);
  const [getPlaceCordinates, setPlaceCordinates] = useState();

  const [locationName, setLocationName] = useState("");
  const [apartmentName, setApartmentName] = useState("");

  const [selectedLocalityArray, setSelectedLocalityArray] = useState([]);

  const handleLocalityChange = (event) => {
    setSelectedLocalityArray(event);
    if (event.length == 0) {
      setShowAppartmentMap(false);
      setShowSecondDropdown(false);
    } else {
      setLatitude(parseInt(event[0]?.latitude));
      setLongitude(parseInt(event[0]?.longitude));
      const selectedValue = event[0]?.id;
      setLocalityID(selectedValue);
      setShowSecondDropdown(selectedValue !== "");
      if (selectedValue === "create") {
        setShowLocalityMap(true);
        setShowSecondDropdown(false);
      } else {
        setShowLocalityMap(false);
      }
    }
  };
  const handleAppartmentChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "create") {
      setShowAppartmentField(true);
    } else {
      setShowAppartmentField(false);
    }
  };

  const [floorChange, setFloorChange] = useState("");
  const [showFloorList, setShowFloorList] = useState(false);
  const [localityAddress, setLocalityAddress] = useState("");
  const [apartmentAddress, setApartmentAddress] = useState("");
  const [showMap, setShowMap] = useState(true);

  const handlefloorChange = (event) => {
    const selectedValue = event.target.value;
    setFloorChange(selectedValue);
    setShowFloorList(selectedValue !== "");
  };

  const handleSaveLocation = async () => {
    try {
      // console.log("save Locality")
      await addLocality({
        city_id: cityID || Cities,
        locality_name: locationName,
        latitude: getPlaceCordinates?.lat,
        longitude: getPlaceCordinates?.lng,
        // "radius": 5.0,
        address: localityAddress,
      }).then((resp) => {
        setShowLocalityMap(false);
        getLocalities();
        setPlaceCordinates(null);
        setLocationName("");
        setLocalityAddress("");
        formik.values.localities = resp.id;
        setShowSecondDropdown(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (getPlaceCordinates) {
      AddressLocation();
    }
  }, [getPlaceCordinates]);

  const AddressLocation = async () => {
    // ++++++++++++++++++++++++++++++ On CLick Address +++++++++++++++++++++++++++++++++++++++++++++++++++
    const apiKey = "AIzaSyDz9QjCNiIHxRGhbb0NaEDOV0vm30Nc-A4"; // Replace with your Google Maps API key.

    // Define the API endpoint for reverse geocoding.
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${getPlaceCordinates?.lat},${getPlaceCordinates?.lng}&key=${apiKey}`;

    // Send a GET request to the API using Axios.
    const response = await axios.get(apiUrl);
    const data = response.data;

    // Handle the response data.
    let formattedAddress;
    if (data.status === "OK" && data.results.length > 0) {
      formattedAddress = data.results[0].formatted_address;
    } else {
      console.error("Reverse geocoding failed");
    }

    let formadd = formattedAddress.replace(/,/g, "");
    setLocalityAddress(formadd);
  };

  useEffect(() => {
    if (getPlaceCordinates) {
      ApartmentLocation();
    }
  }, [getPlaceCordinates]);

  const ApartmentLocation = async () => {
    // ++++++++++++++++++++++++++++++ On CLick Address +++++++++++++++++++++++++++++++++++++++++++++++++++
    const apiKey = "AIzaSyDz9QjCNiIHxRGhbb0NaEDOV0vm30Nc-A4"; // Replace with your Google Maps API key.

    // Define the API endpoint for reverse geocoding.
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${getPlaceCordinates.lat},${getPlaceCordinates.lng}&key=${apiKey}`;

    // Send a GET request to the API using Axios.
    const response = await axios.get(apiUrl);
    const data = response.data;

    // Handle the response data.
    let formattedAddress;
    if (data.status === "OK" && data.results.length > 0) {
      formattedAddress = data.results[0].formatted_address;
    } else {
      console.error("Reverse geocoding failed");
    }

    let formadd = formattedAddress.replace(/,/g, "");
    setApartmentAddress(formadd);
  };

  const handleSaveAppartment = async () => {
    if (!showAppartmentMap) {
      if (apartmentName.length != 0) {
        setShowAppartmentMap(true);
      }
    } else {
      try {
        const today = new Date();
        const formattedDate = today.toISOString();
        await addAppartment({
          address: localityAddress,
          city_id: cityID || Cities,
          latitude: getPlaceCordinates?.lat,
          longitude: getPlaceCordinates?.lng,
          address: apartmentAddress,
          created_at: formattedDate,
          name: apartmentName,
          locality_id: localityID,
          status_id: 1,
          userid: localStorage.getItem("userID"),
        }).then((resp) => {
          // console.log(resp);
          setShowLocalityMap(false);
          getApartmentList();
          setApartmentName("");
          setApartmentAddress("");
          // formik.values.localities = resp.id
        });
      } catch (error) {
        console.log(error);
      }
    }

    // alert(
    //   `${apartmentName} ${getPlaceCordinates?.lat} ${getPlaceCordinates?.lng}  `
    // );
  };

  useEffect(() => {
    getLocalities();
    getApartmentList();
  }, []);

  const [selectedApartmentArray, setSelectedApartmentArray] = useState([]);
  const [adressView, setAddressView] = useState([]);
  const handleApartmentChange = (e) => {
    if (e[0]?.customOption) {
      setApartmentName(e[0].label);
      setShowAppartmentField(true);
    } else {
      setShowAppartmentField(false);
      setApartmentName("");
      setShowAppartmentMap(false);
    }
    setSelectedApartmentArray(e);
    if (e[0]?.longitude != undefined) {
      setAddressView(e[0]);
      setPlaceCordinates({ lat: e[0]?.latitude, lng: e[0]?.longitude });
    }
  };

  const handleGoBack = () => {
    setShowAppartmentField(false);
    setShowAppartmentMap(false);
    setSelectedApartmentArray([]);
  };
  return (
    <>
      {/* const [propertyTypeID, setPropertyTypeID] = useState(); */}

      <body className="Post-Property-3">
        <Header />

        {/* -------------------------------------STEP 3 ----------------------------------------------------- */}
        <section className="Step-3" id="Step-3">
          <div className="left">
            <div className="Post-Property-Back">
              <div
                className="fa fa-chevron-left postBack desktoponly"
                onClick={() => {
                  navigate(`/post-property-form1${url}`);
                }}
                // onClick={() => {
                //   navigate(`/post-property-form1?PropertyId=${
                //     PropertyId
                //     }&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`);
                // }}
              ></div>
            </div>
            <img src={Progressbar} className="ProgressBar" alt="Progressbar" />
            <div className="Content">
              <p>REACHED UPTO 42 LACS BUYERS</p>
              <h1>Be easy to get access</h1>
              <img src={GreenHouse} alt="Step-3" />
            </div>
          </div>

          <form
            className="right needs-validation"
            onSubmit={formik.handleSubmit}
          >
            <div className="Title">
              {/* <h1 style={{ marginTop: "30px" }}>A bit more information</h1> */}
              {/* <Link className="Style"
                                to="/post-property-form1">
                                Change</Link> */}
            </div>
            {/* if propertyForID = Independent House / Villa then show Map other wise show this location Details*/}
            {/* {formik.values.propertyForID && ()} */}
            <div className="PostBackContainer">
              <div
                className="Post-Property-Back mobileonly "
                // onClick={() => {
                //   navigate(`/post-property-form1?PropertyId=${
                //     PropertyId
                //     }&Cities=${Cities}&PropertyForId=${PropertyForID.toString()}&buildingType=${BuildingType.toString()}`);
                // }}
                onClick={() => {
                  navigate(`/post-property-form1${url}`);
                }}
              >
                <span className="fa fa-chevron-left  postBack"></span>
              </div>
              <h1 className="Title  postFormFont">Basic Property details</h1>
            </div>
            <div className="Parent">
              {propertyTypeID || BuildingType ? (
                <div className="Locality mb-3">
                  <p>Choose property locality</p>
                  {/* <Form.Select
                   label="Localities"
                   name="localities"
                   value={formik.values.localities}
                   onChange={(e) => {
                     formik.handleChange(e);
                     handleLocalityChange(e);
                    
                   }}
                   aria-label="Default select example"
                 >
                   <option defaultChecked value="">
                     Select
                   </option>
                   {localities &&
                     localities?.map((item, key) => (
                       <option value={item.id} key={key}>
                         {item.locality_name}
                       </option>
                     ))}
                   <option value="create">Create Locality</option>
                 </Form.Select> */}

                  <Typeahead
                    id="basic-example"
                    onChange={(e) => {
                      // handleCityChange(e)
                      handleLocalityChange(e);
                    }}
                    minLength={1}
                    options={localities}
                    placeholder="Select Locality"
                    selected={selectedLocalityArray}
                    labelKey={(option) => `${option.locality_name}`}
                  />
                </div>
              ) : (
                <div>
                  <button
                    type="submit"
                    className="Style Next"
                    data-bs-target="#ModalID"
                    // pop-up validation model
                    // data-bs-toggle="modal"
                    style={{ float: "right" }}
                    onClick={() => setShowMap(!showMap)}
                  >
                    {!showMap ? "show" : "hide"}
                  </button>
                  {showMap && (
                    <div>
                      <Map
                        lat={latitude}
                        lng={longitude}
                        setPlaceCordinates={setPlaceCordinates}
                        width={400}
                        height={200}
                      />
                    </div>
                  )}
                </div>
              )}

              {showLocalityMap && (
                <div>
                  <Map
                    lat={latitude}
                    lng={longitude}
                    setPlaceCordinates={setPlaceCordinates}
                    width={400}
                    height={200}
                  />
                  {getPlaceCordinates && (
                    <div>
                      {/* <p>latitude :{getPlaceCordinates?.lat}</p>
                      <p>longitude :{getPlaceCordinates?.lng}</p> */}
                      <p>Near By Location: {localityAddress}</p>
                      <input
                        placeholder="Enter Locality Name"
                        onChange={(e) => setLocationName(e.target.value)}
                      />
                      <button
                        className="Style Next"
                        onClick={handleSaveLocation}
                      >
                        Save Locality
                      </button>
                    </div>
                  )}
                </div>
              )}

              {showSecondDropdown && (propertyTypeID || BuildingType) == 1 ? (
                <div className="Apartment-Input mb-3">
                  {/* <Form.Select
                    className="form-select dropdown-toggle"
                    id="ApartMent-Select"
                    label="Apartment"
                    name="apartment"
                    value={formik.values.apartment}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleAppartmentChange(e);
                    }}
                    aria-label="Default select example"
                  >
                    <option defaultChecked value="">Select</option>

                    {apartmentList &&
                      apartmentList?.map((item, key) => (
                        <option value={item.id} key={key}>
                          {item.name}
                        </option>
                      ))}

                    <option value="create">Create Apartment</option>
                  </Form.Select> */}
                  <p> Whats your apartment name?</p>

                  <Typeahead
                    id="apartment-typeahead"
                    onChange={(e) => {
                      handleApartmentChange(e);
                    }}
                    minLength={1}
                    allowNew={true}
                    options={apartmentList}
                    placeholder="Choose Apartment..."
                    selected={selectedApartmentArray}
                    labelKey={(option) => `${option.name} `}
                    style={{ marginBottom: "10px" }}
                    newSelectionPrefix="Add a new Apartment: "
                  />
                  <div>{adressView.address}</div>
                </div>
              ) : (
                ""
              )}
              {showSecondDropdown && (propertyTypeID || BuildingType) != 1 ? (
                <div>
                  {showMap && (
                    <div>
                      <Map
                        lat={latitude}
                        lng={longitude}
                        setPlaceCordinates={setPlaceCordinates}
                        width={400}
                        height={200}
                      />
                    </div>
                  )}
                  <button
                    type="submit"
                    className="Style Next"
                    data-bs-target="#ModalID"
                    // pop-up validation model
                    // data-bs-toggle="modal"
                    style={{ float: "right" }}
                    onClick={() => setShowMap(!showMap)}
                  >
                    {!showMap ? "show" : "hide"} Map
                  </button>
                </div>
              ) : (
                ""
              )}

              {showAppartmentField && (
                <button
                  className="Style Next"
                  onClick={handleGoBack}
                  style={{ float: "right" }}
                >
                  {" "}
                  go back{" "}
                </button>
              )}

              {showAppartmentField && (
                <div>
                  <p> Add Apartment </p>
                  <input
                    placeholder="Enter Appartment Name"
                    value={apartmentName}
                    onChange={(e) => setApartmentName(e.target.value)}
                  />
                </div>
              )}
              {showAppartmentMap && (
                <>
                  <p>Choose Location </p>
                  <div style={{ marginTop: "12px", marginLeft: "12px" }}>
                    <Map
                      lat={latitude}
                      lng={longitude}
                      setPlaceCordinates={setPlaceCordinates}
                      width={400}
                      height={200}
                    />
                    {getPlaceCordinates && (
                      <p>Near By Location: {apartmentAddress}</p>
                    )}
                  </div>
                </>
              )}
              {showAppartmentField && (
                <div>
                  <button className="Style Next" onClick={handleSaveAppartment}>
                    Save Appartment
                  </button>
                </div>
              )}

              <div className="Property mb-3">
                <p>BHK Type</p>
                <div
                  className="input-group gap-3 gap-md-2 gap-lg-3 scroll_Class"
                  role="group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    id="BHKbtn1rk"
                    autocomplete="off"
                    name="bhk_type"
                    value="1RK"
                    onChange={formik.handleChange}
                    defaultChecked={bhk_type == "1RK"}
                  />
                  <label
                    for="BHKbtn1rk"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i>1&nbsp;Rk
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    id="BHKbtn1"
                    autocomplete="off"
                    name="bhk_type"
                    value="1BHK"
                    onChange={formik.handleChange}
                    defaultChecked={bhk_type == "1BHK"}
                  />
                  <label
                    for="BHKbtn1"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> 1&nbsp;BHK{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="BHKbtn2"
                    autocomplete="off"
                    name="bhk_type"
                    value="2BHK"
                    onChange={formik.handleChange}
                    defaultChecked={bhk_type == "2BHK"}
                  />
                  <label
                    for="BHKbtn2"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> 2&nbsp;BHK{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="BHKbtn3"
                    autocomplete="off"
                    name="bhk_type"
                    value="3BHK"
                    onChange={formik.handleChange}
                    defaultChecked={bhk_type == "3BHK"}
                  />
                  <label
                    for="BHKbtn3"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> 3&nbsp;BHK{" "}
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="BHKbtn4"
                    autocomplete="off"
                    name="bhk_type"
                    value="4BHK"
                    onChange={formik.handleChange}
                    defaultChecked={bhk_type == "4BHK"}
                  />
                  <label
                    for="BHKbtn4"
                    style={{
                      borderRadius: "66px",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    {" "}
                    <i className="fas fa-circle"></i> 4&nbsp;BHK{" "}
                  </label>
                </div>
              </div>
              {(propertyTypeID || BuildingType) == "2" && (
                <div className="Builtup mb-3">
                  <p>Plot Area</p>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter in digits"
                    name="plot_area"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.plot_area}
                    required
                  />
                  <span className="formik-validation">
                    {formik.touched.plot_area && formik.errors.plot_area}
                  </span>
                  <div className="valid-feedback"> Good! </div>
                  <div className="invalid-feedback">
                    {" "}
                    Please Enter Your Plot Area.{" "}
                  </div>
                </div>
              )}
              <div className="Carpet mb-3">
                <p>Carpet Area</p>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter in digits"
                  required
                  name="carpet_area"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.carpet_area}
                />
                <span className="formik-validation">
                  {formik.touched.carpet_area && formik.errors.carpet_area}
                </span>

                <div className="valid-feedback"> Good! </div>
                <div className="invalid-feedback">
                  {" "}
                  Please Enter Your Carpet Area.{" "}
                </div>
              </div>
              <div className="Builtup mb-3">
                <p>Builtup Area</p>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter in digits"
                  name="builtup_area"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.builtup_area}
                  required
                />
                <span className="formik-validation">
                  {formik.touched.builtup_area && formik.errors.builtup_area}
                </span>
                <div className="valid-feedback"> Good! </div>
                <div className="invalid-feedback">
                  {" "}
                  Please Enter Your Builtup Area.{" "}
                </div>
              </div>

              {propertyForID && (
                <div className="Builtup mb-3">
                  <p>Super Built-up Area</p>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter in digits"
                    name="super_builtup"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.super_builtup}
                    required
                  />
                  <span className="formik-validation">
                    {formik.touched.super_builtup &&
                      formik.errors.super_builtup}
                  </span>
                  <div className="valid-feedback"> Good! </div>
                  <div className="invalid-feedback">
                    {" "}
                    Please Enter Your Super Builtup Area.{" "}
                  </div>
                </div>
              )}

              {/* {propertyTypeID == "2" && (
                <div className="Builtup mb-3">
                  <p>Plot Area</p>
                  <input
                    type="number"
                    className="form-control"
                     placeholder="ft only (Only Numbers)"
                    name="plot_area"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.plot_area}
                    required
                  />
                  <span className="formik-validation">
                    {formik.touched.plot_area && formik.errors.plot_area}
                  </span>
                  <div className="valid-feedback"> Good! </div>
                  <div className="invalid-feedback">
                    {" "}
                    Please Enter Your Plot Area.{" "}
                  </div>
                </div>
              )} */}

              <div className="Floors mb-3">
                <p>Total Floors in building?</p>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter in digits"
                  name="floor_total"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handlefloorChange(e);
                    handleNumberChange(e);
                  }}
                  value={formik.values.floor_total}
                  required
                />
                <span className="formik-validation">
                  {formik.touched.floor_total && formik.errors.floor_total}
                </span>
                <div className="valid-feedback"> Good! </div>
                <div className="invalid-feedback">
                  {" "}
                  Please Enter Your Total Floors.{" "}
                </div>
              </div>

              {(propertyTypeID || BuildingType) !== "2" && showFloorList && (
                <div className="Last mb-3">
                  <p>Property on floor?</p>
                  <select
                    id="dropdown"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="floor_no"
                    value={formik.values.floor_no}
                    className="form-control"
                    placeholder="Choose your floor"
                    required
                  >
                    <option defaultChecked value="">
                      Select
                    </option>
                    <option value="1">Under-Ground</option>
                    <option value="2">Lower-Ground</option>
                    <option value="3">Ground</option>

                    {options.map((option) => (
                      <option key={option} value={3 + option}>
                        Floor No {option}
                      </option>
                    ))}
                  </select>
                  <span className="formik-validation">
                    {formik.touched.floor_no && formik.errors.floor_no}
                  </span>
                  <div className="valid-feedback">Good!</div>
                  <div className="invalid-feedback">
                    Please Enter Your Builtup Area.
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="Style Next"
                data-bs-target="#ModalID"
                // pop-up validation model
                // data-bs-toggle="modal"
                style={{ float: "right" }}
              >
                {/* <Link className="Style"
                                    style={{ margin: 0, padding: 0 }}
                                    to="/post-property-form4">
                                    Next</Link> */}
                Next
              </button>

              <div
                className="modal fade"
                id="ModalID"
                tabindex="-1"
                aria-labelledby="S-Button"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-danger"
                        id="exampleModalLabel"
                      >
                        Number Verified Failed
                      </h5>
                      <img
                        src={TryAgain}
                        alt="Close-Image"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      Please check the number or try it after sometime.
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light text-dark d-block w-100 p-2"
                        data-bs-dismiss="modal"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div
            className="modal fade Localities-Modal modal-static"
            id="BD"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="header">
                  <div className="Small-Return justify-content-start">
                    <i
                      className="fa fa-chevron-left"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></i>
                    <p className="m-0">Localities</p>
                  </div>
                </div>
                <div className="body">
                  <select
                    className="form-control"
                    id="Select-box"
                    name="states['Shehab', 'Ayman']"
                    multiple
                  >
                    <option value="AK">Alaska</option>
                    <option value="HI">Hawaii</option>
                    <option value="CA">California</option>
                    <option value="NV">Nevada</option>
                    <option value="OR">Oregon</option>
                    <option value="WA">Washington</option>
                    <option value="AZ">Arizona</option>
                    <option value="CO">Colorado</option>
                    <option value="ID">Idaho</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NM">New Mexico</option>
                    <option value="ND">North Dakota</option>
                    <option value="UT">Utah</option>
                    <option value="WY">Wyoming</option>
                    <option value="AL">Alabama</option>
                    <option value="AR">Arkansas</option>
                    <option value="AR">Paris</option>
                    <option value="AR">London</option>
                    <option value="AR">Egypt</option>
                    <option value="IL">Canada</option>
                    <option value="IA">Germeen</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="OK">Oklahoma</option>
                    <option value="SD">South Dakota</option>
                    <option value="TX">Texas</option>
                    <option value="TN">Tennessee</option>
                    <option value="WI">Wisconsin</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="IN">Indiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="OH">Ohio</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WV">West Virginia</option>
                  </select>
                </div>
                <div className="footer">
                  <div className="input-group">
                    <button type="button" id="Suggestio 1" value="Suggestio 1">
                      {" "}
                      Suggestio 1{" "}
                    </button>
                    <button type="button" id="Suggestio 2" value="Suggestio 2">
                      {" "}
                      Suggestio 2{" "}
                    </button>
                    <button type="button" id="Suggestio 3" value="Suggestio 3">
                      {" "}
                      Suggestio 3{" "}
                    </button>
                    <button type="button" id="Suggestio 4" value="Suggestio 4">
                      {" "}
                      Suggestio 4{" "}
                    </button>
                    <button type="button" id="Suggestio 5" value="Suggestio 5">
                      {" "}
                      Suggestio 5{" "}
                    </button>
                    <button type="button" id="Suggestio 6" value="Suggestio 6">
                      {" "}
                      Suggestio 6{" "}
                    </button>
                    <button type="button" id="Suggestio 7" value="Suggestio 7">
                      {" "}
                      Suggestio 7{" "}
                    </button>
                    <button type="button" id="Suggestio 8" value="Suggestio 8">
                      {" "}
                      Suggestio 8{" "}
                    </button>
                    <button type="button" id="Suggestio 9" value="Suggestio 9">
                      {" "}
                      Suggestio 9{" "}
                    </button>
                    <button
                      type="button"
                      id="Suggestio 10"
                      value="Suggestio 10"
                    >
                      {" "}
                      Suggestio 10{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
