
// const MAIN_URL = "https://truesquarefeetbackend.azurewebsites.net/"
const MAIN_URL = "http://13.201.33.60:8080/"
// export const ADMIN_URL = "https://truesquarefeetadmin.azurewebsites.net"
export const ADMIN_URL = "http://13.201.33.60:3000"
export const XAPIKEY = "3iW8i1vmmzzLE3XKg1CHgw==eH6n28KneZc0dlke"
export const access_key = "f8e4f63e2f6b108096b49ad5812044e2"
export default MAIN_URL

    // if (origin === "http://localhost:3000") {
    //     return "http://localhost:8080";
    // }

    // else if (origin === "https://tsf-staging.azurewebsites.net") {
    //     return "https://tsf-backend-staging.azurewebsites.net/";
    // }

    // else {
    //     // return "https://tsf-backend-latest.azurewebsites.net";
    //     return "https://tsf-backend-dev.azurewebsites.net";
    // }
