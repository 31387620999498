import React, { useEffect, useState } from "react";
import {
  getCitiesLocalityListForSearch,
  getLocalitiesByCityID,
} from "../../Request/post-propertyRequest";
import { SearchProperty } from "../../context/SearchContext";
import Icon1 from "../../Assests/Images/icon-1.png";
import Icon2 from "../../Assests/Images/icon-2.png";
import Icon3 from "../../Assests/Images/icon-3.png";

const MultipleDropdownNavMoblie = ({ handleSearch, state2, Setstate2 }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [facingOpen, setFacingOpen] = useState(true);

  const { state, setState } = SearchProperty();

  const { selectedItems = [], selectedCityID } = state;

  const toggleFilterFurnishing = () => {
    setIsOpenFurnishing(!isOpenFurnishing);
  };
  const toggleFilterTenant = () => {
    setisOpenTenant(!isOpenTenant);
  };

  const toggleFilterForFacing = () => {
    setFacingOpen(!facingOpen);
  };

  const [mainToggle, setMainToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [isOpen4, setIsOpen4] = useState(true);
  const [isOpen5, setIsOpen5] = useState(true);
  const [isOpen6, setIsOpen6] = useState(true);
  const [isOpen7, setIsOpen7] = useState(true);
  const [isOpen8, setIsOpen8] = useState(true);
  const [isOpen9, setIsOpen9] = useState(true);
  const [isOpenFurnishing, setIsOpenFurnishing] = useState(true);
  const [isOpenTenant, setisOpenTenant] = useState(true);
  const [localities, setLocalities] = useState();

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };
  const toggleFilter2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleFilter3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleFilter4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleFilter5 = () => {
    setIsOpen5(!isOpen5);
  };
  const toggleFilter9 = () => {
    setIsOpen9(!isOpen9);
  };

  const getData = async (inputText) => {
    try {
      const res = await getCitiesLocalityListForSearch({
        cityid: selectedCityID || 0,
        search: inputText,
      });
      setResults(res);
    } catch (error) {
      console.error("Failed to fetch locality list:", error);
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value.toLowerCase();
    setSearchTerm(inputText);
    if (inputText.length < 2) {
      setShowDropdown(false);
      return;
    } else {
      getData(inputText);
      setShowDropdown(true);
    }
  };

  const handleSelectItem = (item) => {
    if (!item) return;
    setState((prevState) => ({
      ...prevState,
      selectedItems: [...prevState.selectedItems, item],
      selectedCityID: item.city_id || prevState.selectedCityID,
    }));
    setSearchTerm("");
    setShowDropdown(false);
  };

  const handleRemoveItem = (itemToRemove) => {
    setState((prevState) => {
      const updatedItems = prevState.selectedItems.filter(
        (item) => item !== itemToRemove
      );
      return {
        ...prevState,
        selectedItems: updatedItems,
        selectedCityID:
          updatedItems.length === 0 ? 0 : prevState.selectedCityID,
      };
    });
    // setLocalities("")
    setLocalities(null);
  };

  const handlePropertyForChange = (value, minPrice, maxPrice) => {
    Setstate2((preState) => ({
      ...preState,
      selectedPropertyFor: value,
      MinPrice: minPrice,
      MaxPrice: maxPrice,
    }));
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = (price / 10000000).toFixed(2);
      return `₹${inCrores} cr`;
    } else if (price >= 100000) {
      const inLakhs = (price / 100000).toFixed(2);
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };

  const handleMinPriceChange = (event) => {
    const newMinPriceChange = parseInt(event.target.value);
    if (newMinPriceChange <= state2.MaxPrice) {
      Setstate2((prevState) => ({
        ...prevState,
        MinPrice: newMinPriceChange,
      }));
      updateSliderTrack(newMinPriceChange, state2.MaxPrice);
    }
  };

  const handleMaxPriceChange = (event) => {
    const newMaxPriceChange = parseInt(event.target.value);
    if (newMaxPriceChange >= state2.MinPrice) {
      Setstate2((prevState) => ({
        ...prevState,
        MaxPrice: newMaxPriceChange,
      }));
      updateSliderTrack(state2.MinPrice, newMaxPriceChange);
    }
  };

  const updateSliderTrack = (minValue, maxValue) => {
    const minInput = document.querySelector(
      'input[type="range"]:first-of-type'
    );
    const maxInput = document.querySelector('input[type="range"]:last-of-type');
    const track = document.querySelector(".slider-track");

    const minPercent =
      ((minValue - minInput.min) / (minInput.max - minInput.min)) * 100;
    const maxPercent =
      ((maxValue - minInput.min) / (minInput.max - minInput.min)) * 100;

    track.style.left = `${minPercent}%`;
    track.style.width = `${maxPercent - minPercent}%`;
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      const getLocalities = () => {
        getLocalitiesByCityID({ city_id: selectedItems[0]?.city_id }).then(
          (res) => {
            console.log(res, "localitys");
            const filterRes = res.sort((a, b) => a.priority - b.priority);
            const secondArrayLocalities = selectedItems.map((item) => item.id);
            const filteredArray = filterRes.filter(
              (item) => !secondArrayLocalities.includes(item.id)
            );
            setLocalities(filteredArray);
            // setLocalities(filterRes);
          }
        );
      };
      getLocalities();
    }
    // console.log(selectedItems)
  }, [selectedItems]);

  const handleLocalitySelection = (locality) => {
    // const updatedLocalities = localities.filter((loca)=> loca.locality_name != locality.locality_name )
    // console.log(selectedItems)
    setState((preState) => ({
      ...preState,
      selectedItems: [...preState.selectedItems, locality],
    }));
    // setLocalities(updatedLocalities)
  };

  return (
    <div className="Search-Filters2" style={{ width: "100%" }}>
      <div className="Catagory">
        <input
          type="checkbox"
          className="btn-check"
          id="PropertyFor1"
          autoComplete="off"
          // onChange={() => handlePropertyForChange("1", 500000, 1000000000)}
          checked={state2.selectedPropertyFor == "1"}
          onClick={() =>
            state2.selectedPropertyFor == "1"
              ? Setstate2((preState) => ({
                  ...preState,
                  selectedPropertyFor: null,
                  MinPrice: 500000,
                  MaxPrice: 1000000000,
                }))
              : Setstate2((preState) => ({
                  ...preState,
                  selectedPropertyFor: "1",
                  MinPrice: 500000,
                  MaxPrice: 1000000000,
                }))
          }
        />
        <label
          className={state2.selectedPropertyFor == "1" ? "btn_new" : ""}
          htmlFor="PropertyFor1"
        >
          {/* <img src={Icon1} alt="Images" /> */}
          <div
            className={
              state2.selectedPropertyFor == "1"
                ? "innerDot whiteBac"
                : "innerDot "
            }
          ></div>
          Buy
        </label>

        <input
          type="checkbox"
          className="btn-check"
          id="PropertyFor2"
          autoComplete="off"
          // onChange={() => handlePropertyForChange("2", 2000, 2000000)}
          checked={state2.selectedPropertyFor == "2"}
          onClick={() =>
            state2.selectedPropertyFor == "2"
              ? Setstate2((preState) => ({
                  ...preState,
                  selectedPropertyFor: null,
                  MinPrice: 2000,
                  MaxPrice: 2000000,
                }))
              : Setstate2((preState) => ({
                  ...preState,
                  selectedPropertyFor: "2",
                  MinPrice: 2000,
                  MaxPrice: 2000000,
                }))
          }
        />
        <label
          className={state2.selectedPropertyFor == "2" ? "btn_new" : ""}
          htmlFor="PropertyFor2"
        >
          {/* <img src={Icon2} alt="Images" /> */}
          <div
            className={
              state2.selectedPropertyFor == "2"
                ? "innerDot whiteBac"
                : "innerDot "
            }
          ></div>
          Rent
        </label>
      </div>

      <div className="mainHeagingInMobileSearch">Your Location Details</div>
      <div
        style={{
          display: "flex",
          background: "#F5F5F5",
          padding: "9px",
          borderRadius:
            selectedItems.length > 0 ? "6.04px 6.04px 0px 0px" : "38.04px",
          // borderRadius: "6.04px 6.04px 0px 0px",
          boxShadow:
            selectedItems.length > 0 ? "0px 4px 4px 0px #00000040" : "none",
        }}
      >
        <input
          style={{
            width: "100%",
            background: "#F5F5F5",
            color: "#2D2C2C", // Text color of input
            "::placeholder": { color: "red" }, // Placeholder color
            fontSize: "10px",
            fontWeight: "400",
            lineHeight: "12.19px",
            letterSpacing: "-2%",
          }}
          type="text"
          placeholder="Search City & Locality"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>

      <div
        className="selected-items"
        style={{
          marginBottom: "10px",
          background: "#F5F5F5",
          boxShadow: "0px 4px 4px 0px #00000040",
        }}
      >
        {selectedItems?.map((item, index) => (
          <span key={index} className="selected-item">
            {item.id > 0 ? item.locality_name : item.city_name}
            <span
              className="remove-button"
              onClick={() => handleRemoveItem(item)}
            >
              &#10006;
            </span>
          </span>
        ))}
      </div>

      {results?.length > 0 && showDropdown && (
        <div id="result-dropdown" className="scrollable-dropdown">
          <ul>
            {results
              ?.filter((item) => {
                let index = selectedItems.findIndex(
                  (item2) => item2.id === item.id
                );
                return index === -1;
              })
              .sort((a, b) => {
                // Check if all locality_name fields are empty
                const allLocalitiesEmpty = results.every(
                  (item) => item.locality_name === ""
                );
                if (allLocalitiesEmpty) {
                  return 0; // keep the original order
                }
                if (a.locality_name === "" && b.locality_name !== "") {
                  return -1; // a comes before b
                }
                if (a.locality_name !== "" && b.locality_name === "") {
                  return 1; // b comes before a
                }
                return a.locality_name.localeCompare(b.locality_name); // alphabetical order
              })
              .map((result, index) => (
                <li key={index} onClick={() => handleSelectItem(result)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "0.5px solid #C4C4C4",
                      padding: "5px",
                      fontSize: "10px",
                    }}
                  >
                    <span>
                      {result.id > 0 ? result.locality_name : result.city_name}
                    </span>
                    <span>{result.id > 0 ? "Locality" : "City"}</span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )}
      <>
        {localities ? (
          <div>
            <div className="localityHeading">
              Popular Localities in <b>{selectedItems[0]?.city_name} </b>
            </div>
            <ul className="localityWrapper">
              {localities?.map((locality, i) => (
                <li>
                  <button
                    className="localityBtn"
                    onClick={() => handleLocalitySelection(locality)}
                  >
                    {locality.locality_name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </>

      <div
        className="MainFilterToggleBtn"
        onClick={() => setMainToggle(!mainToggle)}
      >
        <span className="MainFilterContent">
          <i
            className={`fas ${
              mainToggle ? "fa-chevron-up" : "fa-chevron-down"
            }`}
            style={{ marginLeft: "10px", fontSize: "14px" }}
          ></i>
          More Filter
        </span>
      </div>
      {mainToggle && (
        <div>
          <div className={`Property ${isOpen ? "open" : ""}`}>
            <div
              className="filter-title"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onClick={toggleFilter}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17.07px",
                }}
              >
                Property Type
              </div>
              {/* <i
                className={`fas ${isOpen ? "fa-chevron-down" : "fa-chevron-up"
                  }`}
                style={{ marginLeft: "10px" }}
              ></i> */}
            </div>

            {isOpen && (
              <div
                className="Catagory"
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflow: "scroll",
                  overflowX: "auto",
                  scrollbarWidth: "none", // Hide scrollbar for Firefox
                  "-ms-overflow-style": "none",
                }}
              >
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Property1"
                  autoComplete="off"
                  checked={state2.selectedTypes?.includes(1)}
                  onClick={() => {
                    state2.selectedTypes?.includes(1)
                      ? Setstate2((prevState) => ({
                          ...prevState,
                          selectedTypes: null,
                        }))
                      : Setstate2((prevState) => ({
                          ...prevState,
                          selectedTypes: "1",
                        }));
                  }}
                />

                <label
                  className={state2.selectedTypes == "1" ? "btn_new" : ""}
                  htmlFor="Property1"
                >
                  <img
                    className={
                      state2.selectedTypes == "1" ? "settleImage m-1" : "m-1"
                    }
                    src={Icon1}
                    alt="Images"
                  />
                  Apartment
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="Property2"
                  autoComplete="off"
                  checked={state2.selectedTypes?.includes(2)}
                  onClick={() => {
                    state2.selectedTypes?.includes(2)
                      ? Setstate2((prevState) => ({
                          ...prevState,
                          selectedTypes: null,
                        }))
                      : Setstate2((prevState) => ({
                          ...prevState,
                          selectedTypes: "2",
                        }));
                  }}
                />

                <label
                  className={state2.selectedTypes == "2" ? "btn_new" : ""}
                  htmlFor="Property2"
                >
                  <img
                    className={
                      state2.selectedTypes == "2" ? "settleImage m-1" : "m-1"
                    }
                    src={Icon2}
                    alt="Images"
                  />
                  Independent&nbsp;house/Villa
                </label>

                <input
                  type="checkbox"
                  className="btn-check"
                  id="Property3"
                  autoComplete="off"
                  checked={state2.selectedTypes?.includes(3)}
                  onClick={() => {
                    state2.selectedTypes?.includes(3)
                      ? Setstate2((prevState) => ({
                          ...prevState,
                          selectedTypes: null,
                        }))
                      : Setstate2((prevState) => ({
                          ...prevState,
                          selectedTypes: "3",
                        }));
                  }}
                />
                <label
                  className={state2.selectedTypes == "3" ? "btn_new" : ""}
                  htmlFor="Property3"
                >
                  <img
                    className={
                      state2.selectedTypes == "3" ? "settleImage m-1" : "m-1"
                    }
                    src={Icon3}
                    alt="Images"
                  />
                  Standalone&nbsp;Building
                </label>
              </div>
            )}
          </div>
          <div className={`Property ${isOpen3 ? "open" : ""}`}>
            <div
              className="filter-title"
              style={{
                display: "flex",
                flexDirection: "row",
                overflowInline: "auto",
              }}
              onClick={toggleFilter3}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17.07px",
                }}
              >
                BHK Type
              </div>
              {/* <i
                className={`fas ${isOpen3 ? "fa-chevron-down" : "fa-chevron-up"
                  }`}
              ></i> */}
            </div>
            {isOpen3 && (
              <div
                className="Catagory"
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflow: "scroll",
                  overflowX: "auto",
                  scrollbarWidth: "none", // Hide scrollbar for Firefox
                  "-ms-overflow-style": "none",
                }}
              >
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Bhk_Type1"
                  autoComplete="off"
                  checked={state2.selectedBHKTypes?.includes("1RK")}
                  onClick={() => {
                    state2.selectedBHKTypes?.includes("1RK")
                      ? // ? setState.selectedBHKTypes(null)
                        // : setState.selectedBHKTypes("1RK");
                        Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "",
                        }))
                      : Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "1RK",
                        }));
                  }}
                />
                <label
                  className={
                    state2.selectedBHKTypes?.includes("1RK") ? "btn_new" : ""
                  }
                  htmlFor="Bhk_Type1"
                >
                  <div
                    className={
                      state2.selectedBHKTypes?.includes("1RK")
                        ? "innerDot whiteBac"
                        : "innerDot "
                    }
                  ></div>{" "}
                  1&nbsp;RK
                </label>
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Bhk_Type2"
                  autoComplete="off"
                  checked={state2.selectedBHKTypes?.includes("1BHK")}
                  onClick={() => {
                    state2.selectedBHKTypes?.includes("1BHK")
                      ? Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "",
                        }))
                      : Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "1BHK",
                        }));
                  }}
                />
                <label
                  className={
                    state2.selectedBHKTypes?.includes("1BHK") ? "btn_new" : ""
                  }
                  htmlFor="Bhk_Type2"
                >
                  <div
                    className={
                      state2.selectedBHKTypes?.includes("1BHK")
                        ? "innerDot whiteBac"
                        : "innerDot "
                    }
                  ></div>
                  1&nbsp;BHK
                </label>
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Bhk_Type3"
                  autoComplete="off"
                  checked={state2.selectedBHKTypes?.includes("2BHK")}
                  onClick={() => {
                    state2.selectedBHKTypes?.includes("2BHK")
                      ? Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "",
                        }))
                      : Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "2BHK",
                        }));
                  }}
                />
                <label
                  className={
                    state2.selectedBHKTypes?.includes("2BHK") ? "btn_new" : ""
                  }
                  htmlFor="Bhk_Type3"
                >
                  <div
                    className={
                      state2.selectedBHKTypes?.includes("2BHK")
                        ? "innerDot whiteBac"
                        : "innerDot "
                    }
                  ></div>
                  2&nbsp;BHK
                </label>
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Bhk_Type4"
                  autoComplete="off"
                  // checked={BHKType?.includes(4)}
                  checked={state2.selectedBHKTypes?.includes("3BHK")}
                  onClick={() => {
                    state2.selectedBHKTypes?.includes("3BHK")
                      ? Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "",
                        }))
                      : Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "3BHK",
                        }));
                  }}
                />
                <label
                  className={
                    state2.selectedBHKTypes?.includes("3BHK") ? "btn_new" : ""
                  }
                  htmlFor="Bhk_Type4"
                >
                  <div
                    className={
                      state2.selectedBHKTypes?.includes("3BHK")
                        ? "innerDot whiteBac"
                        : "innerDot "
                    }
                  ></div>
                  3&nbsp;BHK
                </label>
                <input
                  type="checkbox"
                  className="btn-check"
                  id="Bhk_Type5"
                  autoComplete="off"
                  checked={state2.selectedBHKTypes?.includes("4BHK")}
                  onClick={() => {
                    state2.selectedBHKTypes?.includes("4BHK")
                      ? Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "",
                        }))
                      : Setstate2((preveState) => ({
                          ...preveState,
                          selectedBHKTypes: "4BHK",
                        }));
                  }}
                />
                <label
                  className={
                    state2.selectedBHKTypes?.includes("4BHK") ? "btn_new" : ""
                  }
                  htmlFor="Bhk_Type5"
                >
                  <div
                    className={
                      state2.selectedBHKTypes?.includes("4BHK")
                        ? "innerDot whiteBac"
                        : "innerDot "
                    }
                  ></div>
                  4&nbsp;BHK
                </label>
              </div>
            )}
          </div>
          <div className={`Pricing ${isOpen2 ? "open" : ""}`}>
            <div
              className="filter-title"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onClick={toggleFilter2}
            >
              <h5
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "17.07px",
                }}
              >
                Pricing
              </h5>
              {/* <i
                className={`fas ${isOpen2 ? "fa-chevron-down" : "fa-chevron-up"
                  }`}
              ></i> */}
            </div>
            {isOpen2 && (
              <div className="Catagory">
                <div className="Range-Box">
                  <div className="values">
                    <span>{formatPrice(state2.MinPrice)}</span>
                    <span> - </span>
                    <span>{formatPrice(state2.MaxPrice)}</span>
                  </div>
                  <div
                    className="Range-container"
                    style={{
                      background: "#EFE4EE",
                      height: "13px",
                      borderRadius: "32px",
                    }}
                  >
                    <div
                      className="slider-track"
                      style={{ background: "purple", height: "13px" }}
                    ></div>
                    {state2.selectedPropertyFor == 1 ? (
                      <>
                        <input
                          type="range"
                          min="500000"
                          max="1000000000"
                          value={state2.MinPrice}
                          onChange={handleMinPriceChange}
                          step="500000"
                        />
                        <input
                          type="range"
                          min="0"
                          max="1000000000"
                          value={state2.MaxPrice}
                          onChange={handleMaxPriceChange}
                          step="50000"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="range"
                          min="2000"
                          max="2000000"
                          value={state2.MinPrice}
                          onChange={handleMinPriceChange}
                          step="1000"
                        />
                        <input
                          type="range"
                          min="2000"
                          max="2000000"
                          value={state2.MaxPrice}
                          onChange={handleMaxPriceChange}
                          step="1000"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {state2.selectedPropertyFor == 1 && (
            <div className={`Property ${isOpen4 ? "open" : ""}`}>
              <div
                className="filter-title"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={toggleFilter4}
              >
                <h5
                  style={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "17.07px",
                  }}
                >
                  Property Status
                </h5>
                {/* <i
                  className={`fas ${isOpen4 ? "fa-chevron-down" : "fa-chevron-up"
                    }`}
                ></i> */}
              </div>
              {isOpen4 && (
                <div className="Catagory">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="flexCheckDefault"
                    checked={state2.selectedType?.includes("1")}
                    onClick={() => {
                      state2.selectedType?.includes("1")
                        ? Setstate2((preveState) => ({
                            ...preveState,
                            selectedType: null,
                          }))
                        : Setstate2((preveState) => ({
                            ...preveState,
                            selectedType: "1",
                          }));
                    }}
                  />
                  <label
                    className={state2.selectedType == "1" ? "btn_new" : ""}
                    htmlFor="flexCheckDefault"
                  >
                    <div
                      className={
                        state2.selectedType == "1"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>
                    Ready to move
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="flexCheckChecked"
                    checked={state2.selectedType?.includes("2")}
                    onClick={() => {
                      state2.selectedType?.includes("2")
                        ? Setstate2((preveState) => ({
                            ...preveState,
                            selectedType: null,
                          }))
                        : Setstate2((preveState) => ({
                            ...preveState,
                            selectedType: "2",
                          }));
                    }}
                  />
                  <label
                    className={state2.selectedType == "2" ? "btn_new" : ""}
                    htmlFor="flexCheckChecked"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedType == "2"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>
                    Under Construction
                  </label>
                </div>
              )}
            </div>
          )}

          {/* Furnishing Type starts */}
          {state2.selectedPropertyFor == 2 && (
            <>
              <div className={`Property ${isOpenFurnishing ? "open" : ""}`}>
                <div
                  className="filter-title"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={toggleFilterFurnishing}
                >
                  <h5
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                  >
                    Furnishing Type
                  </h5>
                  {/* <i
                    className={`fas ${isOpenFurnishing ? "fa-chevron-down" : "fa-chevron-up"
                      }`}
                  ></i> */}
                </div>
                {isOpenFurnishing && (
                  <div
                    className="Catagory"
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflow: "scroll",
                      overflowX: "auto",
                      scrollbarWidth: "none", // Hide scrollbar for Firefox
                      "-ms-overflow-style": "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Furnishing_Type1"
                      autoComplete="off"
                      // checked={state2.furnishingType == "furnished"}
                      onClick={() => {
                        state2.furnishingType == "furnished"
                          ? Setstate2((prevState) => ({
                              ...prevState,
                              furnishingType: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              furnishingType: "furnished",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.furnishingType == "furnished" ? "btn_new" : ""
                      }
                      htmlFor="Furnishing_Type1"
                    >
                      {" "}
                      <div
                        className={
                          state2.furnishingType == "furnished"
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      Furnished
                    </label>

                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Furnishing_Type2"
                      autoComplete="off"
                      // checked={state2.furnishingType == "unfurnished"}
                      onClick={() => {
                        state2.furnishingType == "unfurnished"
                          ? Setstate2((prevState) => ({
                              ...prevState,
                              furnishingType: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              furnishingType: "unfurnished",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.furnishingType == "unfurnished" ? "btn_new" : ""
                      }
                      htmlFor="Furnishing_Type2"
                    >
                      {" "}
                      <div
                        className={
                          state2.furnishingType == "unfurnished"
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      Unfurnished
                    </label>

                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Furnishing_Type3"
                      autoComplete="off"
                      // checked={state2.furnishingType == "semifurnished"}
                      onClick={() => {
                        state2.furnishingType == "semifurnished"
                          ? Setstate2((prevState) => ({
                              ...prevState,
                              furnishingType: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              furnishingType: "semifurnished",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.furnishingType == "semifurnished"
                          ? "btn_new"
                          : ""
                      }
                      htmlFor="Furnishing_Type3"
                    >
                      {" "}
                      <div
                        className={
                          state2.furnishingType == "semifurnished"
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      Semi&nbsp;Furnished
                    </label>
                  </div>
                )}
              </div>
              <div className={`Property ${isOpenTenant ? "open" : ""}`}>
                <div
                  className="filter-title"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  onClick={toggleFilterTenant}
                >
                  <h5
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                  >
                    Preferred Tenant
                  </h5>
                  {/* <i
                    className={`fas ${isOpenTenant ? "fa-chevron-down" : "fa-chevron-up"
                      }`}
                  ></i> */}
                </div>
                {isOpenTenant && (
                  <div
                    className="Catagory"
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflow: "scroll",
                      overflowX: "auto",
                      scrollbarWidth: "none", // Hide scrollbar for Firefox
                      "-ms-overflow-style": "none",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Tenant_Type1"
                      autoComplete="off"
                      checked={state2.preTenent == "Bachelor's"}
                      onClick={() => {
                        state2.preTenent?.includes("Bachelor's")
                          ? Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: "Bachelor's",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.preTenent?.includes("Bachelor's")
                          ? "btn_new"
                          : ""
                      }
                      htmlFor="Tenant_Type1"
                    >
                      {" "}
                      <div
                        className={
                          state2.preTenent?.includes("Bachelor's")
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      Bachelor's
                    </label>

                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Tenant_Type2"
                      autoComplete="off"
                      checked={state2.preTenent == "family"}
                      onClick={() => {
                        state2.preTenent?.includes("family")
                          ? // ? setState.preTenent(null)
                            // : setState.preTenent("family");
                            Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: "family",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.preTenent?.includes("family") ? "btn_new" : ""
                      }
                      htmlFor="Tenant_Type2"
                    >
                      {" "}
                      <div
                        className={
                          state2.preTenent?.includes("family")
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      Family
                    </label>

                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Tenant_Type3"
                      autoComplete="off"
                      checked={state2.preTenent == "company"}
                      onClick={() => {
                        state2.preTenent?.includes("company")
                          ? // ? setState.preTenent(null)
                            // : setState.preTenent("company");
                            Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: "company",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.preTenent?.includes("company") ? "btn_new" : ""
                      }
                      htmlFor="Tenant_Type3"
                    >
                      {" "}
                      <div
                        className={
                          state2.preTenent?.includes("company")
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      Company
                    </label>

                    <input
                      type="checkbox"
                      className="btn-check"
                      id="Tenant_Type4"
                      autoComplete="off"
                      checked={state2.preTenent == "Doesn't matter"}
                      onClick={() => {
                        state2.preTenent?.includes("Doesn't matter")
                          ? // ? setState.preTenent(null)
                            // : setState.preTenent("Doesn't matter");
                            Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: null,
                            }))
                          : Setstate2((prevState) => ({
                              ...prevState,
                              preTenent: "Doesn't matter",
                            }));
                      }}
                    />
                    <label
                      className={
                        state2.preTenent?.includes("Doesn't matter")
                          ? "btn_new"
                          : ""
                      }
                      htmlFor="Tenant_Type4"
                    >
                      <div
                        className={
                          state2.preTenent?.includes("Doesn't matter")
                            ? "innerDot whiteBac"
                            : "innerDot "
                        }
                      ></div>{" "}
                      All
                    </label>
                  </div>
                )}
              </div>
            </>
          )}
          {/* Furnishing Type ends */}
          {state2.selectedPropertyFor == 1 && (
            <div className={`Property ${facingOpen ? "open" : ""}`}>
              <div
                className="filter-title"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={toggleFilterForFacing}
              >
                <h5
                  style={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "17.07px",
                  }}
                >
                  Property Facing
                </h5>
                {/* <i
                  className={`fas ${facingOpen ? "fa-chevron-down" : "fa-chevron-up"
                    }`}
                ></i> */}
              </div>
              {facingOpen && (
                <div
                  className="Catagory"
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflow: "scroll",
                    overflowX: "auto",
                    scrollbarWidth: "none", // Hide scrollbar for Firefox
                    "-ms-overflow-style": "none",
                  }}
                >
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type1"
                    autoComplete="off"
                    checked={state2.selectedFacing == "East"}
                    onClick={() => {
                      state2.selectedFacing?.includes("East")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "East",
                          }));
                    }}
                  />
                  <label
                    className={state2.selectedFacing == "East" ? "btn_new" : ""}
                    htmlFor="Facing_Type1"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedFacing == "East"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    East
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type2"
                    autoComplete="off"
                    checked={state2.selectedFacing == "West"}
                    onClick={() => {
                      state2.selectedFacing?.includes("West")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "West",
                          }));
                    }}
                  />
                  <label
                    className={state2.selectedFacing == "West" ? "btn_new" : ""}
                    htmlFor="Facing_Type2"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedFacing == "West"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    West
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type3"
                    autoComplete="off"
                    checked={state2.selectedFacing == "North"}
                    onClick={() => {
                      state2.selectedFacing?.includes("North")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "North",
                          }));
                    }}
                  />
                  <label
                    className={
                      state2.selectedFacing == "North" ? "btn_new" : ""
                    }
                    htmlFor="Facing_Type3"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedFacing == "North"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    North
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type4"
                    autoComplete="off"
                    checked={state2.selectedFacing == "South"}
                    onClick={() => {
                      state2.selectedFacing?.includes("South")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "South",
                          }));
                    }}
                  />
                  <label
                    className={
                      state2.selectedFacing == "South" ? "btn_new" : ""
                    }
                    htmlFor="Facing_Type4"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedFacing == "South"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    South
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type5"
                    autoComplete="off"
                    checked={state2.selectedFacing == "North East"}
                    onClick={() => {
                      state2.selectedFacing?.includes("North East")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "North East",
                          }));
                    }}
                  />
                  <label
                    className={
                      state2.selectedFacing == "North East" ? "btn_new" : ""
                    }
                    htmlFor="Facing_Type5"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedFacing == "North East"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>
                    North&nbsp;East
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type6"
                    autoComplete="off"
                    checked={state2.selectedFacing == "South East"}
                    onClick={() => {
                      state2.selectedFacing?.includes("South East")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "South East",
                          }));
                    }}
                  />
                  <label
                    className={
                      state2.selectedFacing == "South East" ? "btn_new" : ""
                    }
                    htmlFor="Facing_Type6"
                  >
                    {" "}
                    <div
                      className={
                        state2.selectedFacing == "South East"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    South&nbsp;East
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type7"
                    autoComplete="off"
                    checked={state2.selectedFacing == "South West"}
                    onClick={() => {
                      state2.selectedFacing?.includes("South West")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "South West",
                          }));
                    }}
                  />
                  <label
                    className={
                      state2.selectedFacing == "South West" ? "btn_new" : ""
                    }
                    htmlFor="Facing_Type7"
                  >
                    <div
                      className={
                        state2.selectedFacing == "South West"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    South&nbsp;West
                  </label>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="Facing_Type8"
                    autoComplete="off"
                    checked={state2?.selectedFacing == "North West"}
                    onClick={() => {
                      state2.selectedFacing?.includes("North West")
                        ? Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: null,
                          }))
                        : Setstate2((prevState) => ({
                            ...prevState,
                            selectedFacing: "North West",
                          }));
                    }}
                  />
                  <label
                    className={
                      state2.selectedFacing == "North West" ? "btn_new" : ""
                    }
                    htmlFor="Facing_Type8"
                  >
                    <div
                      className={
                        state2.selectedFacing == "North West"
                          ? "innerDot whiteBac"
                          : "innerDot "
                      }
                    ></div>{" "}
                    North&nbsp;West
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <button
        style={{
          width: "310px",
          height: "48px",
          background: selectedItems.length > 0 ? "#8B3D88" : "#B5B5B5",
          color: "white",
          borderRadius: "87.11px",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "24px",
        }}
        disabled={selectedItems.length == 0}
        onClick={() => handleSearch()}
      >
        Search
      </button>
    </div>
  );
};

export default MultipleDropdownNavMoblie;
