import axios from "axios";
import MAIN_URL from "./apiConfig";
 
// AddUpdate Post Property
export const UpdateProprties = async (data) => {

    try {
        const res = await axios.post(`${MAIN_URL}/Re_Properties/GetRe_PropertiesDetails`, data, {
            headers: {
                //   Authorization: "Bearer " + token,
            },
        });
        return res.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};