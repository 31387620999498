import React, { useState } from 'react';
import Header from '../Components/Common/header';
import SearchNavbarMobile from '../Components/Listing Pages/searchNavbarMobile';
import { useNavigate } from 'react-router-dom';
import { SearchProperty } from '../context/SearchContext';


export default function SelectLocalityPage() {
  // const [selectedPropertyFor, setSelectedPropertyFor] = useState(1);
  // const [selectedTypes, setSelectedTypes] = useState([]);
  // const [selectedType, setSelectedType] = useState("");
  // const [selectedFacing, setSelectedFacing] = useState("");
  // const [furnishingType,setFurhishingType] = useState("")
  // const [preTenent,setpreTenent]= useState("")
  // const [selectedBHKTypes, setSelectedBHKTypes] = useState([]);
  // const [MinPrice, setMinPrice] = useState(500000);
  // const [MaxPrice, setMaxPrice] = useState(1000000000);



  const {state,}= SearchProperty()


  // const [selectedCityID, setSelectedCityID] = useState(0);
  const navigate = useNavigate();

  const [state2,setState2]=useState({
    selectedPropertyFor:1,
    selectedTypes: [],
    selectedType:"",
    selectedFacing:"",
    furnishingType:"",
    preTenent:"",
    selectedBHKTypes:[],
    MinPrice :500000,
    MaxPrice:1000000000,
  })


  const handleSearch = () => {
    console.log("SelectLocalityPage");
    let url = `/listing-page?property_for=${state2.selectedPropertyFor}`;

    if (state2.selectedTypes?.length > 0) {
      url = url + `&PropertyType=${state2.selectedTypes.toString()}`;
    }
    if (state2.selectedBHKTypes?.length > 0) {
      url = url + `&BHKType=${state2.selectedBHKTypes.toString()}`;
    }
    if (state2.selectedType != "") {
      url = url + `&Ready_Underconstruction=${state2.selectedType}`;
    }
    if (state2.selectedFacing != "") {
      url = url + `&PropertyFacing=${state2.selectedFacing}`;
    }
    if (state.selectedCityID > 0) {
      url = url + `&Cities=${state.selectedCityID}`;
    }
    if (state2.furnishingType != "") {
      url = url + `&furnishingType=${state2.furnishingType}`;
    }
    if (state2.preTenent != "") {
      url = url + `&preferedtenent=${state2.preTenent}`;
    }
    if (
      state.selectedItems
        ?.filter((item) => item.id != 0)
        ?.map((item) => item?.id)
        ?.filter((item) => item.id != 0)
        .toString().length > 0
    ) {
      url =
        url +
        `&Locality=${state.selectedItems
          ?.filter((item) => item.id != 0)
          ?.map((item) => item?.id)
          ?.filter((item) => item.id != 0)
          .toString()}`;
    }

    if (state2.MaxPrice > 0) {
      url = url + `&MaxPrice=${state2.MaxPrice}`;
    }
    url = url + `&MinPrice=${state2.MinPrice}`;

    navigate(url);
  };


  return (
    <>
      <Header />
      <SearchNavbarMobile handleSearch={handleSearch} state2={state2} Setstate2={setState2} />
    </>
  )
}
