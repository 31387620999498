import React, { useState, useEffect } from 'react';
import { SearchProperty } from '../../context/SearchContext';

const SortScreen = ({ showSort,toggleSort }) => {
  const [display, setDisplay] = useState("");

  const {handleSearch}= SearchProperty()


  useEffect(() => {
    setDisplay(showSort ? "inline-block" : "");
  }, [showSort]);

  const handleOrder=(e)=>{
    const selectedorder = e.target.value;
    console.log(selectedorder,"dfjaskdhfijojsadoif")
    handleSearch(e.target.value)
}

  return (
    <aside
    class="Search-Filters col-lg-3 col-md-4 g-0 my-2 mobileonly shadow-lg rounded-4"
    style={{ display: display, top: "40px",zIndex:'1' }}
  >
    {showSort &&
      <div class="Apply" onClick={toggleSort}>
        <div class="filter-title">
          <h5></h5>
          <p class="Clear-All ">Done</p>
        </div>
      </div>
   }
                <div class="dropdown mx-2 w-75 ">
                    <select class="form-select" onChange={handleOrder}  aria-label="Default select example">
                    <option selected value="" >Sort By</option>
                    <option  value={"1"}>Low to High</option>
                    <option  value={"2"}>High to Low</option>
                    </select>
                </div>
  </aside>
  );
}

export default SortScreen;
