import React, { useEffect, useState } from "react";
import ContactIcon1 from "../../Assests/Images/contact-ico-1.png";
import Tag from "../../Assests/Images/tag.png";
import Cursor from "../../Assests/Images/cursor.png";
import { format } from "date-fns";
import ContactLabel2 from "../../Assests/Images/Contact-Label-2.png";
import ContactLabel3 from "../../Assests/Images/Contact-Label-3.png";
import ContactIcon3 from "../../Assests/Images/contact-ico-3.png";
import ContactIcon2 from "../../Assests/Images/contact-ico-2.png";
import carpet_area from "../../Assests/Images/Carpet_area_png.png";
import { PopertiesDetails } from "../../Request/search-propertyRequests";
import { Helmet } from "react-helmet";
import PhoneIcon from "../../Assests/Images/phone-ico.png";
import bedroomIcon from '../../Assests/Images/bedroom icon.png'

export default function ListingPage2Apartment({
  propertyID,
  handleContactDetils,
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const userIdParam = searchParams.get("propertyid");
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [bedroom,setBedRooms] = useState()

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = price / 10000000;
      return `₹${inCrores} crore`;
    } else if (price >= 100000) {
      const inLakhs = price / 100000;
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };

  useEffect(() => {
    let data = {
      id: userIdParam,
    };
    PopertiesDetails(data).then((res) => {
      setPropertyDetails(res);
      if(res.bhk_type == "1RK"){
        setBedRooms("1")
      }
      if(res.bhk_type == "2BHK"){
        setBedRooms("2")
      }
      if(res.bhk_type == "3BHK"){
        setBedRooms("3")
      }
      if(res.bhk_type == "4BHK"){
        setBedRooms("4")
      }
      console.log(res);
    });
  }, [propertyID]);

  return (
    <>
      <Helmet>
        <title>{`${propertyDetails?.bhk_type} ${propertyDetails?.building_type} ${propertyDetails?.property_type} in ${propertyDetails?.locality} ${propertyDetails?.city}`}</title>
        <meta
          name="description"
          content={`${propertyDetails?.bhk_type} ${propertyDetails?.building_type} ${propertyDetails?.property_type} in ${propertyDetails?.locality} ${propertyDetails?.city}`}
        />
      </Helmet>

      <body>
        <section className="Apartment" id="Apartment">
          <div className="Intro d-flex btn-group justify-content-between w-100">
            <div className="left">
              <div className="mainContianer">
                <h1 className="me-3">
                  {propertyDetails.property_types_id == 1
                    ? formatPrice(propertyDetails.price)
                    : formatPrice(propertyDetails.rent_amount)}
                  {propertyDetails.property_types_id != 1 && (
                    <span
                      style={{
                        fontSize: "21px",
                        fontWeight: "600px",
                        lineHeight: "25.6px",
                        color: "#757575",
                      }}
                    >
                      /Months
                    </span>
                  )}
                </h1>
                <div className="d-flex">
                  <div
                    className="heading_Buttons_brokrage_div me-3"
                    style={{ background: "#F6E6F5" }}
                  >
                    <h5
                      className="heading_Buttons_brokrage_text"
                      style={{ color: "#8E258F" }}
                    >
                      Zero Brokerage{" "}
                    </h5>
                  </div>
                  <div
                    className="heading_Buttons_brokrage_div"
                    style={{ background: "#DEF0FF" }}
                  >
                    <h5
                      className="heading_Buttons_brokrage_text"
                      style={{ color: "#000000" }}
                    >
                      {propertyDetails.furnish_type}{" "}
                    </h5>
                  </div>
                </div>
              </div>
              <h3 className="Name">
                {`${propertyDetails?.bhk_type || " "} ${
                  propertyDetails?.building_type || " "
                } ${propertyDetails?.property_type || " "} in ${
                  propertyDetails?.locality || " "
                } ${propertyDetails?.city || " "}`}                   
                <img src={Tag} alt="tag" />
              </h3>
              <p className="Cursor">
                <img src={Cursor} alt="Cursor" />{" "}
                {propertyDetails.locality_Address} {propertyDetails.locality}
              </p>
            </div>
            <div className="right text-center">
              <div className="Parts input-group gap-1" style={{
                flexWrap:"nowrap"
              }}>
                <div className="box">
                  <img src={carpet_area} alt="contact-icon" />
                  <p className="ResultSections_Items">
                    {propertyDetails.carpet_area} Sqft{" "}
                  </p>
                  <span className="ResultSections_Items_li">(Carpet Area)</span>
                </div>
                <div className="box">
                  <img src={bedroomIcon} alt="Contact-Label" />
                  <p className="ResultSections_Items">
                    {bedroom} Bedrooms
                  </p>
                </div>
                <div className="box">
                  <img src={ContactLabel2} alt="Contact-Label" />
                  <p className="ResultSections_Items">
                    {propertyDetails.number_bathroom} Bathroom
                  </p>
                </div>
                <div className="box">
                  <img src={ContactLabel3} alt="Contact-Label" />
                  <p className="ResultSections_Items">
                    {propertyDetails.number_balconies} Balcony
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="Style ms-3 desktoponly "
                  data-bs-toggle="modal"
                  data-bs-target="#contactinfo"
                  onClick={() => handleContactDetils(propertyDetails.user_id)}
                >
                  <img src={PhoneIcon} alt="phone-ico" />
                  <a>Contact Owner</a>
                </button>
              </div>
            </div>
          </div>
          <div className="Details">
            <div className="d-flex justify-content-between ">
              <h3 className="title">Property Details</h3>
            </div>
            {/* <div className="Properties row flex-wrap">
              <div className="box col-lg-3 col-sm-6">
                <h3>Particulars Details</h3>
                <div className="Grid row">
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon2} alt="contact-ico" />
                    <p>{propertyDetails.number_bathroom}-Bathrooms</p>
                  </div>
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon3} alt="contact-ico" />
                    <p>{propertyDetails.number_balconies}-Balcony</p>
                  </div>
                </div>
              </div>

              <div className="box col-lg-3 col-sm-6">
                <h3>Area</h3>
                <div className="Grid row">
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon1} alt="contact-ico" />
                    <p>Carpet Area- {propertyDetails.carpet_area}</p>
                  </div>
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon2} alt="contact-ico" />
                    <p>BuiltUp Area- {propertyDetails.builtup_area}</p>
                  </div>
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon3} alt="contact-ico" />
                    <p>Cover Parking- {propertyDetails.coverd_parking}</p>
                  </div>
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon3} alt="contact-ico" />
                    <p>Open Parking- {propertyDetails.open_parking}</p>
                  </div>
                </div>
              </div>

              <div className="box col-lg-3 col-sm-6">
                <h3>Cost</h3>
                <div className="Grid">
                  {propertyDetails.property_types_id == 1 ? (
                    <div className="btn-group align-items-center">
                      <img src={ContactIcon1} alt="contact-ico" />
                      <p>Price - {formatPrice(propertyDetails.price)} Rs/-</p>
                    </div>
                  ) : (
                    <div className="btn-group align-items-center">
                      <img src={ContactIcon1} alt="contact-ico" />
                      <p>
                        Rent Amount - {formatPrice(propertyDetails.rent_amount)}{" "}
                        Rs/-
                      </p>
                    </div>
                  )}
                  <div className="btn-group align-items-center">
                    <img src={ContactIcon2} alt="contact-ico" />
                    <p>
                      Maintenance Cost -{" "}
                      {formatPrice(propertyDetails.maintainance_cost)} Rs/-
                    </p>
                  </div>
                </div>
              </div>
              <div className="box col-lg-3 col-sm-6">
                <h3>Furnishing</h3>
                <div className="btn-group align-items-center">
                  <p>{propertyDetails.furnish_type}</p>
                </div>
              </div>
            </div> */}
            <div class="listingPage2_Container">
              <div class="listPage_Left">
                <div class="listPage_Left_Items">
                  {propertyDetails.property_types_id == 1 ? (
                    <>
                      <label class="listPage_Left_Item_label">
                        Transaction type :
                      </label>
                      <span class="listPage_Left_Item_value">Resale</span>
                    </>
                  ) : (
                    <>
                      <label class="listPage_Left_Item_label">
                        Maintenance :
                      </label>
                      <span class="listPage_Left_Item_value">
                        {propertyDetails.maintainance_cost}/Months
                      </span>
                    </>
                  )}
                </div>
                {propertyDetails.property_types_id == 1 ? (
                  <div class="listPage_Left_Items">
                    <label class="listPage_Left_Item_label">Status :</label>
                    <span class="listPage_Left_Item_value">
                      {propertyDetails.readytomove_underconstruction == "1"
                        ? "Ready To Move"
                        : "Under Construction"}
                    </span>
                  </div>
                ) : (
                  <div class="listPage_Left_Items">
                    <label class="listPage_Left_Item_label">
                      Available For :
                    </label>
                    <span class="listPage_Left_Item_value">
                      {propertyDetails.prefered_tenants == null
                        ? "NA"
                        : propertyDetails.prefered_tenants}{" "}
                    </span>
                  </div>
                )}

                <div class="listPage_Left_Items">
                  <label class="listPage_Left_Item_label">Floor :</label>
                  <span class="listPage_Left_Item_value">
                    {propertyDetails.floor_no}st of{" "}
                    {propertyDetails.floor_total} floors
                  </span>
                </div>
                <div class="listPage_Left_Items">
                  <label class="listPage_Left_Item_label">Property age :</label>
                  <span class="listPage_Left_Item_value">
                    {propertyDetails.property_age == null
                      ? "NA"
                      : `${propertyDetails.property_age} Years`}
                  </span>
                </div>
                <div class="listPage_Left_Items">
                  <label class="listPage_Left_Item_label">Facing :</label>
                  <span class="listPage_Left_Item_value">
                    {propertyDetails.property_facing == null
                      ? "NA"
                      : propertyDetails.property_facing}{" "}
                  </span>
                </div>
                {propertyDetails.property_types_id == 1 ? (
                  <div class="listPage_Left_Items">
                    <label class="listPage_Left_Item_label">Possession :</label>
                    <span class="listPage_Left_Item_value">
                      {propertyDetails.possestion_date == null
                        ? "NA"
                        : propertyDetails.possestion_date}
                    </span>
                  </div>
                ) : (
                  <div class="listPage_Left_Items">
                    <label class="listPage_Left_Item_label">
                      Available from :
                    </label>
                    <span class="listPage_Left_Item_value">
                      {propertyDetails.available_from == null
                        ? "NA"
                        : `${format(new Date(propertyDetails.available_from), "yyyy-MM-dd")}`}
                        
                    </span>
                  </div>
                )}
                <div class="listPage_Left_Items">
                  <label class="listPage_Left_Item_label">Parking :</label>
                  <span class="listPage_Left_Item_value">
                    {propertyDetails.coverd_parking} coverd{" "}
                    {propertyDetails.open_parking} Open
                  </span>
                </div>
                <div class="listPage_Left_Items">
                  <label class="listPage_Left_Item_label">
                    Price Negotiable :
                  </label>
                  <span class="listPage_Left_Item_value">
                    {propertyDetails.price_negotiable == 1 ? "Yes" : "NO"}
                  </span>
                </div>
                {propertyDetails.property_types_id == 2 ? (
                  <div class="listPage_Left_Items">
                    <label class="listPage_Left_Item_label">Agreement :</label>
                    <span class="listPage_Left_Item_value">
                      {propertyDetails.agreement_duration}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="listPage_Right">
                {propertyDetails.property_types_id == 2 ? (
                  <>
                    <div class="listPage_Right_Items">
                      <label class="listPage_Right_Item_label">Deposit :</label>
                      <span class="listPage_Right_Item_value">
                        {propertyDetails.rent_deposite == null
                          ? "NA"
                          : `${propertyDetails.rent_deposite}/-`}
                      </span>
                    </div>
                    <div class="listPage_Right_Items">
                      <label class="listPage_Right_Item_label">
                        Eating Habits :
                      </label>
                      <span class="listPage_Right_Item_value">
                        {propertyDetails.eating_habits == null
                          ? "NA"
                          : propertyDetails.eating_habits}
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">
                    Kitchen Type :
                  </label>
                  <span class="listPage_Right_Item_value">
                    {propertyDetails.kitchen_type}
                  </span>
                </div>
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">Tiles :</label>
                  <span class="listPage_Right_Item_value">
                    {propertyDetails.flooring_type}
                  </span>
                </div>
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">
                    Water supply :
                  </label>
                  <span class="listPage_Right_Item_value">
                    {propertyDetails.water_supply}
                  </span>
                </div>
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">Locality :</label>
                  <span class="listPage_Right_Item_value">
                    {propertyDetails.locality}
                  </span>
                </div>
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">Built-up :</label>
                  <span class="listPage_Right_Item_value">
                    {propertyDetails.builtup_area} sqft
                  </span>
                </div>
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">
                    Super builtup :
                  </label>
                  <span class="listPage_Right_Item_value">
                    {propertyDetails.super_builtup} sqft
                  </span>
                </div>
                <div class="listPage_Right_Items">
                  <label class="listPage_Right_Item_label">Plot Size :</label>
                  <span class="listPage_Right_Item_value">NA</span>
                </div>
                {propertyDetails.property_types_id == 1 ? (
                  <div class="listPage_Right_Items">
                    <label class="listPage_Right_Item_label">
                      Under loan :
                    </label>
                    <span class="listPage_Right_Item_value">
                      {propertyDetails.under_loan == 1 ? "Yes" : "NO"}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div class="Description">
              <h3 class="BannerDesk">Description :</h3>
              <div>
                <p className="deskpara">{propertyDetails.description}</p>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
}
