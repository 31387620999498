import React, { useEffect, useState } from 'react';
import Big1 from "../../Assests/Images/big-1.png";
import Banner2 from "../../Assests/Images/Banner-2.png";
import Banner3 from "../../Assests/Images/Banner-3.png";
import Banner4 from "../../Assests/Images/Banner-4.png";
import Sqft from "../../Assests/Images/fluent_crop-24-regular.png";
import bedDesignLogo from "../../Assests/Images/BedLogo.png";
import bedDesignLogo2 from "../../Assests/Images/bedDesignLogo2.png";
import PhoneIcon from "../../Assests/Images/phone-ico.png";
import Tag from "../../Assests/Images/tag.png";
import Cursor from "../../Assests/Images/cursor.png";
import Life from "../../Assests/Images/life.png";
import Bed from "../../Assests/Images/bed.png";
import Pool from "../../Assests/Images/pool.png";
import BathTube from "../../Assests/Images/bath-tube.png";
import Owner from "../../Assests/Images/owner.png";
import { useNavigate } from 'react-router-dom';
import ImageContainer from './imageContainer';
import AmanatiesContainer from './AmanatiesContainer';
import NearByPlacesCont from './NearByPlacesCont';
import Feature from './feature'
import {UserDetailsByID,  SavePropertyViewer,
} from '../../Request/search-propertyRequests'
import Contact from '../Listing Pages 2/contact';
import { SearchProperty } from '../../context/SearchContext';

export default function OneMainSection() {

    const [modelOpen,setModelOpen] = useState(false)
    const [userDetails, setUserDetails] = useState([]);
    const {state,setState,propertyLists,handleSearch}= SearchProperty()

   
    const navigate = useNavigate();

    const handlepagenavigate = (id) => {
        window.open(`/listing-page2?propertyid=${id}`, '_blank');
    }

    const formatPrice = (price) => {
        if (price >= 10000000) {
          const inCrores = price / 10000000;
          return `₹${inCrores} crore`;
        } else if (price >= 100000) {
          const inLakhs = price / 100000;
          return `₹${inLakhs} lac`;
        } else {
          return `₹${price}`;
        }
      };

    //   let userIDS = localStorage.getItem("userID");
    //   const handleContactDetils = (id) => {
    //     let data = {
    //       user_id: userIDS,
    //       property_id: propertyLists.id,
    //     };
    //     UserDetailsByID(id).then((res) => {
    //       setUserDetails(res);
    //       if (userIDS != res.id) {
    //         SavePropertyViewer(data);
    //       }
    //     });
    //   };

    
    return (
        <>
    { (propertyLists.filter(res => res.property_statusID == 1 && res.is_draft != true)).map((rese) => (
        <section class="One my-4 rounded-4 rounded" id="One">
            <div class="left">
                <ImageContainer res={rese} images={rese?.image || [
                    { src: Banner2 },
                    { src: Banner3 },
                    { src: Banner4 }
                ]} />
            </div>
            <div class="right" >
                <div onClick={() => handlepagenavigate(rese.id)}>                
                <div class="Sponsered btn-group justify-content-between w-100">
                    {rese.property_types_id == 1 ?  <p>{formatPrice(rese?.price)}</p>  :   <p>{formatPrice(rese?.rent_amount)}</p> }
                </div>
                <h3 class="Name">{`${rese?.bhk_type} ${rese?.building_type} ${rese?.property_type} in ${rese?.locality || " "} ${rese?.city || " "}`}
                    <img src={Tag} alt="tag" />
                </h3>
                <p class="cursor">
                    <img src={Cursor} alt="cursor" /> {rese.locality_Address !== null ? rese.locality_Address : "Demo Locality Address"}
                </p>
                {/* {
                    rese.ameneties?.length > 0 ? (
                        <AmanatiesContainer amanatie={rese?.ameneties} />
                    ) : (
                        <div class="type">
                            <div class="tag btn-group align-items-center">
                                <img src={Life} alt="life" />
                                <p>Lift</p>
                            </div>
                            <div class="tag btn-group align-items-center">
                                <img src={Bed} alt="bed" />
                                <p>King bed</p>
                            </div>
                            <div class="tag btn-group align-items-center">
                                <img src={Pool} alt="Pool" />
                                <p>Swimming Pool</p>
                            </div>
                            <div class="tag btn-group align-items-center">
                                <img src={BathTube} alt="bath" />
                                <p>Bath Tub</p>
                            </div>
                        </div>
                    )
                } */}
                <div className='assentials_container type' style={{
                    display:'flex',
                    flexDirection:"row",
                    alignItems:"center",
                    justifyContent:"space-between"
                }}>
                    <div className='result_list_essentials_items_container'>
                        <div className='result_list_essentials_items' >
                            <div className='items_img'><img src={Sqft}/></div>
                            <div className='result_list_essentials_items_text'>{rese.carpet_area} Sqft</div>
                        </div>
                        <div className='result_list_essentials_items_text_spans'>Carpet Area</div>
                    </div>
                    {rese.property_types_id ==1 ? <div className='result_list_essentials_items_container'>
                        <div className='result_list_essentials_items' >
                            <div className='items_img'><img src={bedDesignLogo}/></div>
                            <div className='result_list_essentials_items_text'>{rese.readytomove_underconstruction == "1" ? " Ready To Move" : " Under Construction"}</div>
                        </div>
                        <div className='result_list_essentials_items_text_spans'>Status</div>
                    </div> :
                    <div className='result_list_essentials_items_container'>
                    <div className='result_list_essentials_items' >
                        <div className='items_img'><img src={bedDesignLogo}/></div>
                        <div className='result_list_essentials_items_text'>{rese.furnish_type}</div>
                    </div>
                    <div className='result_list_essentials_items_text_spans'>Status</div>
                </div>
                    }
                    <div className='result_list_essentials_items_container'>
                        <div className='result_list_essentials_items' >
                        <div className='items_img'><img src={bedDesignLogo2}/></div>
                            <div className='result_list_essentials_items_text'>{rese.bhk_type}</div>
                        </div>
                        <div className='result_list_essentials_items_text_spans'>{rese.number_bathroom} Bath & {rese.number_balconies} Balcony</div>
                    </div>
                    
                </div>
                <h3 class="nearbyPlaceHeading my-2">Nearby Places</h3>
                <div class="Distance swiper">
                    <div class="swiper-wrapper">
                        <NearByPlacesCont proprty_id={rese.id} nearByPropertyList={rese.nearby} />
                    </div>
                </div>
                <button
              type="button"
              className="ListingPageContact"
            //   style={{borderRadius:30}}
            >
              <img className='ListingpageContact_img' src={PhoneIcon} alt="phone-ico" />
              <span className='ListingPageContact_text'>Contact Owner</span>
            </button>
                </div>
                
            </div>
        </section>
    ))}
<div
              style={{ width: "100%",  display:"flex" , alignItems:"center" , justifyContent: "space-evenly" }}
              className=" box my-4 "
            >
            
            <button
              type="button"
              style={{                       
                backgroundColor: "#8B3D88",
                color: "white",
                padding: "10px",
                borderRadius: 30,
              }}
              data-bs-toggle="modal" data-bs-target="#staticBackdrop"
            >
              <a>Need Help</a>
            </button>
            </div>
            <Feature/>
            {/* <Contact userDetails={userDetails} /> */}
        </>

    )
}
