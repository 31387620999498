// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import Cities1 from "../../Assests/Images/Cities-1.png";
import Cities2 from "../../Assests/Images/Cities-2.png";
import Cities3 from "../../Assests/Images/Cities-3.png";
import Cities4 from "../../Assests/Images/Cities-4.png";
import { FaArrowLeft } from "react-icons/fa";
import Dots from "../../Assests/Images/dots.png";
import Cursor from "../../Assests/Images/cursor.png";
import Rent from "../../Assests/Images/rent.png";
import ArrowUp from "../../Assests/Images/arrow-up.png";
import ArrowDown from "../../Assests/Images/arrow-down.png";
import Setting from "../../Assests/Images/setting.png";
import { getPropertiesUserWiseList } from "../../Request/post-propertyRequest";
import { getViewersPropertyWiseList } from "../../Request/post-propertyRequest";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { UpdateProprties } from "../../Request/update-propertyRequest";
import { UserProperty } from "../../context/PropertyContext";
import { UserDetailsByID } from "../../Request/search-propertyRequests";

export default function Accord1() {
  const { updateProperties, setUpdateProperties } = UserProperty();
  const [userData, setUserData] = useState([]);
  const [proprtyWiseList, setPropertyWiseList] = useState([]);
  const [viewerDetails, setViewerDetails] = useState([]);
  const [toggleTable, setToggleTable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let userId = localStorage.getItem("userID");

    getPropertiesUserWiseList({ user_id: userId })
      .then((res) => {
        if (res == "No data found for selected user.") {
          return;
        } else {
          setUserData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleContactDetils = (id) => {
    UserDetailsByID(id)?.then((res) => {
      setViewerDetails(res);
      setToggleTable(!toggleTable);
    });
  };

  const handleInquary = (id) => {
    getViewersPropertyWiseList({ property_id: id })
      ?.then((res) => {
        if (res == "No data found for selected Property.") {
          return;
        } else {
          setPropertyWiseList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let navigate = useNavigate();

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = price / 10000000;
      return `₹${inCrores} crore`;
    } else if (price >= 100000) {
      const inLakhs = price / 100000;
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };

  const handleDateFormat = (apiDate) => {
    const parsedDate = new Date(apiDate);
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    return formattedDate;
  };

  const handleUpdateForm = (id) => {
    UpdateProprties({
      id: id,
    }).then((res) => {
      setUpdateProperties(res);
      const data = {
        Step: res.Step,
        agreement_duration: res.agreement_duration,
        alternate_mobile_no: res.alternate_mobile_no,
        ameneties: res.ameneties,
        apartment_id: res.apartment_id,
        approved_at: res.approved_at,
        available_from: res.available_from,
        bhk_type: res.bhk_type,
        building_type: res.building_type,
        building_types_id: res.building_types_id,
        builtup_area: res.builtup_area,
        carpet_area: res.carpet_area,
        city: res.city,
        city_id: res.city_id,
        coverd_parking: res.coverd_parking,
        created_at: res.created_at,
        description: res.description,
        eating_habits: res.eating_habits,
        email: res.email,
        expected_deposit: res.expected_deposit,
        expire_date: res.expire_date,
        featured_bestdeal: res.featured_bestdeal,
        floor_no: res.floor_no,
        floor_total: res.floor_total,
        flooring_type: res.flooring_type,
        furnish_type: res.furnish_type,
        id: res.id,
        image: res.image,
        is_draft: res.is_draft,
        kitchen_type: res.kitchen_type,
        latitude: res.latitude,
        locality: res.locality,
        locality_Address: res.locality_Address,
        locality_id: res.locality_id,
        longitude: res.longitude,
        maintainance_cost: res.maintainance_cost,
        mobile_no: res.mobile_no,
        nearby: res.nearby,
        new_expire_date: res.new_expire_date,
        number_balconies: res.number_balconies,
        number_bathroom: res.number_bathroom,
        number_bedroom: res.number_bedroom,
        open_parking: res.open_parking,
        plot_area: res.plot_area,
        possestion_date: res.passestion_date,
        prefered_tenants: res.prefered_tenants,
        price: res.price,
        price_negotiable: res.price_negotiable,
        property_age: res.property_age,
        property_facing: res.property_facing,
        property_status: res.property_status,
        property_statusID: res.property_statusID,
        property_type: res.property_type,
        property_types_id: res.property_types_id,
        readytomove_underconstruction: res.readytomove_underconstruction,
        rent_amount: res.rent_amount,
        rent_deposite: res.rent_deposite,
        rent_negotiable: res.rent_negotiable,
        super_builtup: res.super_builtup,
        token_amount: res.token_amount,
        under_loan: res.under_loan,
        updated_at: res.updated_at,
        user_id: res.user_id,
        water_supply: res.water_supply,
        who_will_show: res.who_will_show,
      };
      const queryParams = JSON.stringify(data);
      navigate(
        `/update-property-form4?propertyId=${id}&PropertyDetails=${queryParams}`
      );
    });
  };

  const handleUploadBtn = (id) => {
    UpdateProprties({
      id: id,
    }).then((res) => {
      setUpdateProperties(res);
      const data = {
        Step: res.Step,
        agreement_duration: res.agreement_duration,
        alternate_mobile_no: res.alternate_mobile_no,
        ameneties: res.ameneties,
        apartment_id: res.apartment_id,
        approved_at: res.approved_at,
        available_from: res.available_from,
        bhk_type: res.bhk_type,
        building_type: res.building_type,
        building_types_id: res.building_types_id,
        builtup_area: res.builtup_area,
        carpet_area: res.carpet_area,
        city: res.city,
        city_id: res.city_id,
        coverd_parking: res.coverd_parking,
        created_at: res.created_at,
        description: res.description,
        eating_habits: res.eating_habits,
        email: res.email,
        expected_deposit: res.expected_deposit,
        expire_date: res.expire_date,
        featured_bestdeal: res.featured_bestdeal,
        floor_no: res.floor_no,
        floor_total: res.floor_total,
        flooring_type: res.flooring_type,
        furnish_type: res.furnish_type,
        id: res.id,
        image: res.image,
        is_draft: res.is_draft,
        kitchen_type: res.kitchen_type,
        latitude: res.latitude,
        locality: res.locality,
        locality_Address: res.locality_Address,
        locality_id: res.locality_id,
        longitude: res.longitude,
        maintainance_cost: res.maintainance_cost,
        mobile_no: res.mobile_no,
        nearby: res.nearby,
        new_expire_date: res.new_expire_date,
        number_balconies: res.number_balconies,
        number_bathroom: res.number_bathroom,
        number_bedroom: res.number_bedroom,
        open_parking: res.open_parking,
        plot_area: res.plot_area,
        possestion_date: res.passestion_date,
        prefered_tenants: res.prefered_tenants,
        price: res.price,
        price_negotiable: res.price_negotiable,
        property_age: res.property_age,
        property_facing: res.property_facing,
        property_status: res.property_status,
        property_statusID: res.property_statusID,
        property_type: res.property_type,
        property_types_id: res.property_types_id,
        readytomove_underconstruction: res.readytomove_underconstruction,
        rent_amount: res.rent_amount,
        rent_deposite: res.rent_deposite,
        rent_negotiable: res.rent_negotiable,
        super_builtup: res.super_builtup,
        token_amount: res.token_amount,
        under_loan: res.under_loan,
        updated_at: res.updated_at,
        user_id: res.user_id,
        water_supply: res.water_supply,
        who_will_show: res.who_will_show,
      };
      const queryParams = JSON.stringify(data);
      navigate(
        `/update-property-form7?propertyId=${id}&PropertyDetails=${queryParams}`
      );
    });
  };

  // const list = userData?.filter((res) => res.is_draft != "true");
  // console.log(userData, "List Of Propertyies");

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return (
    <>
      <div className="tab-pane fade active show" id="Accord-1">
        {userData
          ?.filter((res) => res.is_draft != "true")
          .map((res) => (
            <div className="Catagory">
              <div className="swiper">
                {/* <span className="Label">Ads Active</span> */}
                <div className="swiper-wrapper">
                <div className="swiper-slide">
                    {res.image.length !== 0 ?(
                    res.image.map((img, index) => (
                      <div className="swiper-slide">
                        <img src={img.src} alt={img.img_name} />
                      </div>
                    ))
                  ):(
                     <div className="d-flex justify-content-center flex-column align-items-center w-100 h-100">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <button
                          className="btn btn-primary "
                          onClick={() => handleUploadBtn(res.id)}
                        >
                          Upload
                        </button>
                      </div>
                      <div className="text-center">
                        <p>properties with an image get more reach </p>
                      </div>
                    </div>
                    
                  )}
                  <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "19px",
                        backgroundColor: "#8B3D88",
                        color: "white",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    >
                      {res.property_types_id == 1 ? "For Sale" : "For Rent"}
                    </div>
                    {/* <img src={res.image[0]} alt="One" /> */}
                  </div>
                  {/* {res.image.length !== 0 ? (
                    res.image.map((img, index) => (
                      <div className="swiper-slide">
                        <img src={img.src} alt={img.img_name} />
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center flex-column align-items-center w-100">
                      <div
                        style={{
                          position: "absolute",
                          top: "12px",
                          right: "13px",
                          backgroundColor: "#8B3D88",
                          color: "white",
                          padding: "2px",
                          borderRadius: "5px",
                        }}
                      >
                        {res.property_types_id == 1
                          ? "For Sale"
                          : res.property_types_id == 2
                          ? "For Rent"
                          : null}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <button
                          className="btn btn-primary "
                          onClick={() => handleUploadBtn(res.id)}
                        >
                          Upload
                        </button>
                      </div>
                      <div className="text-center">
                        <p>properties with an image get more reach </p>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="swiper-pagination"></div>
              </div>

              {isModalOpen && (
                <div
                  className="modal"
                  style={{
                    width: "400px",
                    height: "200px",
                  }}
                >
                  <div className="modal-content">
                    <span
                      className="close"
                      onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                      &times;
                    </span>
                    <p>Modal content goes here.</p>
                  </div>
                </div>
              )}

              <div className="Content">
                <div className="Title">
                  <h3 className="Name btn-group w-100">
                    {`${res.bhk_type} ${res.building_type} for ${
                      res.property_types_id === 1 ? "Sale" : "Rent"
                    } in ${res.city}`}

                    <span className="Post ">
                      <div></div>
                    </span>
                  </h3>
                  <p className="cursor d-flex align-items-center justify-content-between">
                    <div>
                    <img src={Cursor} alt="cursor" />
                    <span>{res.locality_Address}</span>
                      </div>
                    <span className="float-start" style={{
                      paddingRight:'10%'
                    }}>
                      {}
                    Created At : {handleDateFormat(res.created_at)}
                    </span>
                  </p>
                  {/*  */}
                  {res.property_types_id == 1 ? (
                    <p className="Badget">
                      <span className=" font-monospace">Sale Price:</span>
                      {formatPrice(res.price)}
                    </p>
                  ) : (
                    <p className="Badget">
                      {" "}
                      <span className=" font-monospace">
                        {" "}
                        Rent Amount:
                      </span>{" "}
                      {formatPrice(res.rent_amount)}
                    </p>
                  )}
                  <div className="Labels btn-group w-100 gap-2">
                    {res.property_statusID == 1 ? (
                      <button
                        type="button"
                        // className="btn-primary rounded-3 p-1"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => handleInquary(res.id)}
                      >
                        <p>Inquiry</p>
                      </button>
                    ) : (
                      <button type="button">
                        <p>Pending</p>
                      </button>
                    )}
                    <button
                      type="button"
                      // className="btn-primary rounded-3 p-1"
                      onClick={() => handleUpdateForm(res.id)}
                    >
                      <p></p>
                      <p>Update Property</p>
                    </button>

                    <div></div>

                    {/*----------------------- Enquary-Model--------------------- */}
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      style={{ marginTop: 80 }}
                    >
                      <div class="modal-dialog ">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">
                              {!toggleTable ? (
                                <>
                                  <p
                                    onClick={() => setToggleTable(!toggleTable)}
                                  >
                                    <FaArrowLeft />
                                  </p>
                                </>
                              ) : (
                                <h4>Inquiry List</h4>
                              )}
                            </h1>

                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body ">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {toggleTable ? (
                                <table className="table table-responsive">
                                  <thead>
                                    <tr>
                                      {/* <th scope="col">Name</th> */}
                                      <th scope="col">At</th>
                                      <th scope="col">Contact</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {proprtyWiseList?.map((p, index) => (
                                      <tr>
                                        {/* <td>{p.username}</td> */}
                                        <td>
                                          {new Date(
                                            p.TimeStamp
                                          ).toLocaleDateString(
                                            "en-US",
                                            options
                                          )}{" "}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-primary p-1"
                                            onClick={() =>
                                              handleContactDetils(p.user_id)
                                            }
                                          >
                                            Contact Viewer
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : (
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">User Name</th>
                                      <th scope="col">Phone</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      <tr>
                                        <td>{viewerDetails.name}</td>
                                        <td>{viewerDetails.mobile_no}</td>
                                      </tr>
                                    }
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <span
                  className="Setting"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleUpdateForm(res.id)}
                >
                  <img src={Setting} alt="Update Property" />
                  Update Property
                </span> */}
              </div>
            </div>
          ))}
        {/* <div className="Catagory Catagory-Two">
          <div className="swiper">
            <span className="Label">Ads Active</span>
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped bg-success"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    %25
                  </div>
                </div>
                <img src={Cities1} alt="One" />
              </div>
              <div className="swiper-slide">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped bg-success"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    %50
                  </div>
                </div>
                <img src={Cities2} alt="One" />
              </div>
              <div className="swiper-slide">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped bg-success"
                    role="progressbar"
                    style={{ width: "75%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    %75
                  </div>
                </div>
                <img src={Cities3} alt="One" />
              </div>
              <div className="swiper-slide">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped bg-success"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    %100
                  </div>
                </div>
                <img src={Cities4} alt="One" />
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
          <div className="Content">
            <div className="Title">
              <h3 className="Name btn-group w-100 justify-content-between align-items-center">
                2 BKH Apartment at Knight Heights
                <span className="Post d-flex align-items-center">
                  Posting Date: 22-12-21
                  <div className="dropdown">
                    <button
                      className="btn text-light dropdown-toggle"
                      type="button"
                      id="b2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-display="static"
                    >
                      <img src={Dots} alt="pic" />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="b2"
                    >
                      <a className="dropdown-item" href="#">
                        Preview Property
                      </a>
                      <a className="dropdown-item" href="#">
                        Edit Listing
                      </a>
                      <a className="dropdown-item" href="#">
                        Check Responses
                      </a>
                      <a className="dropdown-item" href="#">
                        Deactive Property
                      </a>
                      <a className="dropdown-item" href="#">
                        Upgrade Listing
                      </a>
                    </ul>
                  </div>
                </span>
              </h3>
              <p className="cursor">
                <img src={Cursor} alt="cursor" />
                Vile Parle, No 321
              </p>
              <p className="Badget">₹20,000</p>
              <div className="Labels btn-group w-100 gap-2">
                <p>
                  <img src={Rent} alt="rent" /> Rent
                </p>
                <p>
                  <img src={ArrowDown} alt="arrow" />
                  Active
                </p>
                <p>50Inquiries</p>
                <p className="Setting">
                  <img src={Setting} alt="Setting" />
                  Setting
                </p>
              </div>
            </div>
            <span className="Setting">
              <img src={Setting} alt="setting" />
              Setting
            </span>
          </div>
        </div> */}
      </div>
    </>
  );
}
