import React from "react";
import India from "../../Assests/Images/india.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserAuth } from "../../context/AuthContext";
import { addUser } from "../../Request/userRequest";



export default function Registration({  }) {


    const { phoneNumber, isUserExist, setIsUserExist, onSignup,userName,setEmail ,email,setUserName} = UserAuth();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userName: "",
            email: "",
            mobile_no: phoneNumber
        },

        validationSchema: Yup.object({
            userName: Yup.string().max(100).required("User Name is required"),
            email: Yup.string().max(100).required("User Email is required"),

        }),

        onSubmit: async (data) => {
            try {
                onSignup(phoneNumber)
            } catch (error) {
                console.log(error);
            }
        },
    });


    return (
        <>
            {!isUserExist &&
                <body className="Register">
                    <section className="SignUp" id="SignUp">

                        <form onSubmit={formik.handleSubmit}
                            className="needs-validation" novalidate>

                            <div className="UserName">
                                <p>User Name</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="User Name"
                                    required
                                    name='userName'
                                    onBlur={formik.handleBlur}
                                    onChange={(e)=>{
                                        setUserName(e.target.value)
                                        formik.handleChange(e)}}
                                    value={formik.values.userName}
                                />
                                <div className="valid-feedback"> Good! </div>
                                <div className="invalid-feedback"> Please Enter Your Name. </div>
                            </div>

                            <div hidden={true} className="Phone">
                                <p>My Phone Number is....</p>
                                <div className="btn-group w-100">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Phone"
                                        // required
                                        name='mobile_no'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.mobile_no}
                                    />
                                    <span className="input-group-text">
                                        <img src={India} />
                                    </span>
                                </div>
                            </div>

                            <div className="Email">
                                <p>Email</p>
                                <input
                                    type="email"
                                    className="form-control mb-3"
                                    placeholder="Email"
                                    required
                                    name='email'
                                    onBlur={formik.handleBlur}
                                    onChange={(e)=>{
                                        setEmail(e.target.value)
                                        formik.handleChange(e)}}
                                    value={formik.values.email}
                                />
                                <div className="valid-feedback"> Good! </div>
                                <div className="invalid-feedback"> Please Enter Your Email. </div>
                            </div>
                            <div className="Check">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="invalidCheck"
                                        required
                                    />
                                    <label className="form-check-lanel" for="invalidCheck">
                                        I Accept The Terms & Condition And Privecy Policy
                                    </label>
                                    <div className="valid-feedback"> Good! </div>
                                    <div className="invalid-feedback">
                                        {" "}
                                        You must agree before submitting.{" "}
                                    </div>
                                </div>
                            </div>
                            <div className="btn">
                                <button type="submit" className="Style">
                                    {" "}
                                    Register{" "}
                                </button>
                            </div>
                        </form>
                    </section>
                </body>
            }
        </>
    );
}
