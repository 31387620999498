import Banner2 from "../../Assests/Images/Banner-2.png";
import React, { useState, useEffect, useRef } from 'react';


export default function ImageContainer({ images,res }) {
    const [selectedImage, setSelectedImage] = useState(images.length > 0 ? images[0].src : Banner2);
  

  
    return (
        <>
<div className="Big-Images">
  <img
    className='rounded-4'
    style={{overflowClipMargin:"content-box",
            overflow:"clip"}} 

    src={selectedImage}
    alt="Big"
  />
  <div className="sale-tag" style={{
                        width:"69px",
                        height:"22px",
                        position: 'absolute',
                        backgroundColor: '#F6E6F5',
                        color: '#8E258F',
                        padding: '5px',
                        marginTop:"10px",
                        marginLeft:"10px",
                        borderRadius: '5px',
                        fontSize:"12px",
                        fontWeight:"500px",
                        display:"flex",
                        lineHeight:"14.63px",
                        alignContent:"center",
                        justifyContent:"center"
                    }}>
                       {res.property_types_id == 1 ? "For Sale" : "For Rent"}
                    </div>
</div>
{images.length > 1 && (
                <div className="Small-Images">
                    {images.slice(0, 3).map((banner, index) => (
                        <div
                            key={index}
                            onClick={() => setSelectedImage(banner.src)}
                            style={{
                                width: selectedImage === banner.src ? "23.59px" : '8.58px',
                                padding: "5px",
                                margin: "2px",
                                height: '10.23px',
                                borderRadius: selectedImage === banner.src ? "17px" : '50%',
                                backgroundColor: selectedImage === banner.src ? '#FCF4FF' : '#FFFFFF',
                                cursor: 'pointer',
                                border: 'none',
                                opacity: selectedImage === banner.src ? "100%" : "50%"
                            }}
                        ></div>
                    ))}
                </div>
            )}
            </>
    )
}
