import React, { useState, useEffect } from "react";
import Cities1 from "../../Assests/Images/Cities-1.png";
import Cities2 from "../../Assests/Images/Cities-2.png";
import Cities3 from "../../Assests/Images/Cities-3.png";
import Cities4 from "../../Assests/Images/Cities-4.png";
import { format } from "date-fns";
import Dots from "../../Assests/Images/dots.png";
import Cursor from "../../Assests/Images/cursor.png";
import Rent from "../../Assests/Images/rent.png";
import ArrowDown from "../../Assests/Images/arrow-down.png";
import Basket from "../../Assests/Images/basket.png";
import { getPropertiesUserWiseList } from "../../Request/post-propertyRequest";
import { Link, useNavigate } from "react-router-dom";
import { UpdateProprties } from "../../Request/update-propertyRequest";
import { UserProperty } from "../../context/PropertyContext";

export default function Accord2() {
  const { updateProperties, setUpdateProperties } = UserProperty();

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    let userId = localStorage.getItem("userID");

    getPropertiesUserWiseList({ user_id: userId })
      .then((res) => {
        if (res) {
          setUserData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let navigate = useNavigate();

  const handleUpdateForm = (id) => {
    UpdateProprties({
      id: id,
    }).then((res) => {
      setUpdateProperties(res);
      const data = {
        Step: res.Step,
        agreement_duration: res.agreement_duration,
        alternate_mobile_no: res.alternate_mobile_no,
        ameneties: res.ameneties,
        apartment_id: res.apartment_id,
        approved_at: res.approved_at,
        available_from: res.available_from,
        bhk_type: res.bhk_type,
        building_type: res.building_type,
        building_types_id: res.building_types_id,
        builtup_area: res.builtup_area,
        carpet_area: res.carpet_area,
        city: res.city,
        city_id: res.city_id,
        coverd_parking: res.coverd_parking,
        created_at: res.created_at,
        description: res.description,
        eating_habits: res.eating_habits,
        email: res.email,
        expected_deposit: res.expected_deposit,
        expire_date: res.expire_date,
        featured_bestdeal: res.featured_bestdeal,
        floor_no: res.floor_no,
        floor_total: res.floor_total,
        flooring_type: res.flooring_type,
        furnish_type: res.furnish_type,
        id: res.id,
        image: res.image,
        is_draft: res.is_draft,
        kitchen_type: res.kitchen_type,
        latitude: res.latitude,
        locality: res.locality,
        locality_Address: res.locality_Address,
        locality_id: res.locality_id,
        longitude: res.longitude,
        maintainance_cost: res.maintainance_cost,
        mobile_no: res.mobile_no,
        nearby: res.nearby,
        new_expire_date: res.new_expire_date,
        number_balconies: res.number_balconies,
        number_bathroom: res.number_bathroom,
        number_bedroom: res.number_bedroom,
        open_parking: res.open_parking,
        plot_area: res.plot_area,
        possestion_date: res.passestion_date,
        prefered_tenants: res.prefered_tenants,
        price: res.price,
        price_negotiable: res.price_negotiable,
        property_age: res.property_age,
        property_facing: res.property_facing,
        property_status: res.property_status,
        property_statusID: res.property_statusID,
        property_type: res.property_type,
        property_types_id: res.property_types_id,
        readytomove_underconstruction: res.readytomove_underconstruction,
        rent_amount: res.rent_amount,
        rent_deposite: res.rent_deposite,
        rent_negotiable: res.rent_negotiable,
        super_builtup: res.super_builtup,
        token_amount: res.token_amount,
        under_loan: res.under_loan,
        updated_at: res.updated_at,
        user_id: res.user_id,
        water_supply: res.water_supply,
        who_will_show: res.who_will_show,
      };
      const queryParams = JSON.stringify(data);
      if (res.Step == 1)
        navigate(
          `/update-property-form3?propertyId=${id}&PropertyDetails=${queryParams}`
        );
      if (res.Step == 2)
        navigate(
          `/update-property-form4?propertyId=${id}&PropertyDetails=${queryParams}`
        );
      if (res.Step == 3)
        navigate(
          `/update-property-form5?propertyId=${id}&PropertyDetails=${queryParams}`
        );
      if (res.Step == 4)
        navigate(
          `/update-property-form6?propertyId=${id}&PropertyDetails=${queryParams}`
        );
      if (res.Step == 6)
        navigate(
          `/update-property-form7?propertyId=${id}&PropertyDetails=${queryParams}`
        );
      else {
        console.log("error");
      }
    });
  };

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      const inCrores = price / 10000000;
      return `₹${inCrores} crore`;
    } else if (price >= 100000) {
      const inLakhs = price / 100000;
      return `₹${inLakhs} lac`;
    } else {
      return `₹${price}`;
    }
  };

  return (
    <>
      <div className="tab-pane fade " id="Accord-2">
        {/* {userData.filter((res)=>res.is_draft == "true").map((res) => ( */}
        {userData
          ?.filter((res) => res.is_draft == "true")
          .map((res) => (
            <div className="Catagory" onClick={() => handleUpdateForm(res.id)}>
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    {res.image.length !== 0 ? (
                      res.image.map((img, index) => (
                        <div className="swiper-slide">
                          <img src={img.src} alt={img.img_name} />
                        </div>
                      ))
                    ) : (
                      <div className="w-100 h-100 d-flex align-items-center justify-content-center ">
                        <p className=" align-self-center">
                          No Images Available
                        </p>
                      </div>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "19px",
                        backgroundColor: "#8B3D88",
                        color: "white",
                        padding: "2px",
                        borderRadius: "5px",
                      }}
                    >
                      {res.building_types_id === 1
                        ? "For Sale"
                        : res.building_types_id === 2
                        ? "For Rent"
                        : null}
                    </div>
                    {res.Step == 1 && (
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped bg-success"
                          role="progressbar"
                          style={{ width: "0%" }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          %0
                        </div>
                      </div>
                    )}
                    {res.Step == 2 && (
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped bg-success"
                          role="progressbar"
                          style={{ width: "25%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          %25
                        </div>
                      </div>
                    )}
                    {res.Step == 3 && (
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped bg-success"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          50%
                        </div>
                      </div>
                    )}
                    {res.Step == 4 && (
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped bg-success"
                          role="progressbar"
                          style={{ width: "75%" }}
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          %75
                        </div>
                      </div>
                    )}
                    {/* <img src={res.image[0]} alt="One" /> */}
                  </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
              <div className="Content">
                <div className="Title">
                  <h3 className="Name btn-group w-100 justify-content-between align-items-center">
                    {`${res.bhk_type || ""}  ${res.building_type || ""} for ${
                      res.building_types_id === 1 ? "Sale" : "Rent"
                    } in ${res.city}`}
                    <div className="dropdown">
                      {/* <button
                        className="btn  text-light dropdown-toggle"
                        type="button"
                        id="b2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-display="static"
                      >
                        <img src={Dots} alt="pic" />
                      </button> */}
                      <ul className="dropdown-menu" aria-labelledby="b2">
                        <a className="dropdown-item" href="#">
                          Preview Property
                        </a>
                        <a className="dropdown-item" href="#">
                          Edit Listing
                        </a>
                        <a className="dropdown-item" href="#">
                          Check Responses
                        </a>
                        <a className="dropdown-item" href="#">
                          Deactive Property
                        </a>
                        <a className="dropdown-item" href="#">
                          Upgrade Listing
                        </a>
                      </ul>
                    </div>
                  </h3>
                  <span>{format(new Date(res.created_at), "yyyy-MM-dd")}</span>
                  <p className="cursor">
                    <img src={Cursor} alt="cursor" />
                    {res.locality_Address}
                  </p>
                  {res.property_types_id == 1 ? (
                    <p className="Badget">
                      <span className=" font-monospace">Sale Price:</span>
                      {formatPrice(res.price || "No Amount ")}
                    </p>
                  ) : (
                    <p className="Badget">
                      {" "}
                      <span className=" font-monospace">
                        {" "}
                        Rent Amount:
                      </span>{" "}
                      {formatPrice(res.rent_amount || "No Amount")}
                    </p>
                  )}
                  <div className="Labels btn-group w-100 gap-2"  style={{ cursor: 'pointer' }}>
                    <p>
                      {/* <img src={Basket} alt="basket" /> */}
                      Continue Posting
                    </p>
                  </div>
                </div>
                {/* <span className="Posting">Continue Posting</span> */}
              </div>
            </div>
            // <div className="Catagory Catagory-Two">
            //   <div className="swiper">
            //     <span className="Label">Ads Active</span>
            //     <div className="swiper-wrapper">
            //       <div className="swiper-slide">
            //         <div className="progress">
            //           <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
            //             style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">%25
            //           </div>
            //         </div>
            //         <img src={Cities1} alt="One" />
            //       </div>
            //       <div className="swiper-slide">
            //         <div className="progress">
            //           <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
            //             style={{ width: "50%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">%50
            //           </div>
            //         </div>
            //         <img src={Cities2} alt="One" />
            //       </div>
            //       <div className="swiper-slide">
            //         <div className="progress">
            //           <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
            //             style={{ width: "75%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">%75
            //           </div>
            //         </div>
            //         <img src={Cities3} alt="One" />
            //       </div>
            //       <div className="swiper-slide">
            //         <div className="progress">
            //           <div className="progress-bar progress-bar-striped bg-success" role="progressbar"
            //             style={{ width: "100%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
            //             %100
            //           </div>
            //         </div>
            //         <img src={Cities4} alt="One" />
            //       </div>
            //     </div>
            //     <div className="swiper-pagination"></div>
            //   </div>
            //   <div className="Content">
            //     <div className="Title">
            //       <h3 className="Name btn-group w-100 justify-content-between align-items-center">
            //         2 BKH Apartment at Knight Heights
            //         <div className="dropdown">
            //           <button className="btn   text-light dropdown-toggle" type="button" id="b2"
            //             data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
            //             <img src={Dots} alt="pic" />
            //           </button>
            //           <ul className="dropdown-menu" aria-labelledby="b2">
            //             <a className="dropdown-item" href="#">Preview Property</a>
            //             <a className="dropdown-item" href="#">Edit Listing</a>
            //             <a className="dropdown-item" href="#">Check Responses</a>
            //             <a className="dropdown-item" href="#">Deactive Property</a>
            //             <a className="dropdown-item" href="#">Upgrade Listing</a>
            //           </ul>
            //         </div>
            //       </h3>
            //       <p className="cursor"><img src={Cursor} alt="cursor" />Vile Parle, No 321</p>
            //       <p className="Badget">₹20,000</p>
            //       <div className="Labels btn-group w-100 gap-2">
            //         <p><img src={Rent} alt="rent" /> Rent</p>
            //         <p><img src={ArrowDown} alt="arrow" />Active</p>
            //         <p>50Inquiries</p>
            //         <p>Continue Posting</p>
            //       </div>
            //     </div>
            //     <span className="Posting">Continue Posting</span>
            //   </div>
            // </div>
          ))}
      </div>
    </>
  );
}
