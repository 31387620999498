import React, { useState } from 'react';
import CetiesBg1 from "../../Assests/Images/Ceties-bg-1.png";
import CetiesBg2 from "../../Assests/Images/Ceties-bg-2.png";
import Accord1 from './accord1';
import Accord2 from './accord2';
import Accord3 from './accord3';
import ContactPersonDetails from './contactPersonDetails';



export default function AccordsManageProperty() {
    
        const [accord , setAccord] = useState(1)

    return (
        <>
            <body className="Manage-Properties">
                <section className="Menage-Properties" id="Menage-Properties">
                    <h1 className="title">Manage Properties</h1>
                    <ul className="btns nav nav-tabs" role="tablist">
                        <li className="btn nav-item">
                            <a className="nav-link dropdown-toggle active" data-bs-toggle="tab" href="#Accord-1">
                                Fully Posted Property
                            </a>
                        </li>
                        <li className="btn nav-item">
                            <a className="nav-link dropdown-toggle" data-bs-toggle="tab" href="#Accord-2">
                                Incomplete posting
                            </a>
                        </li>
                        {/* <li className="btn nav-item">
                            <a className="nav-link dropdown-toggle" data-bs-toggle="tab" href="#Accord-3">
                                Responses for property
                            </a>
                        </li> */}
                        {/* <li className="btn nav-item">
                            <a className="nav-link dropdown-toggle" data-bs-toggle="tab" onClick={()=>{setAccord(4)}} href="#Accord-3">
                                Visited Property
                            </a>
                        </li> */}
                    </ul>

                    <div className="tab-content">
                        <Accord1 />
                        <Accord2 />
                        {/* <Accord3/> */}

                    </div>

                    <div className="bg">
                        <img src={CetiesBg1} alt="Ceties-bg" />
                        <img src={CetiesBg2} alt="Ceties-bg" />
                    </div>

                </section>
                <ContactPersonDetails />
            </body>
        </>
    )
}
