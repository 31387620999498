import React, { useEffect, useState } from 'react';
import Post1 from "../../Assests/Images/Basic-Post-1.png";
import PostIcon1 from "../../Assests/Images/Post-ico-1.png";
import PostIcon2 from "../../Assests/Images/Post-ico-2.png";
import PostIcon3 from "../../Assests/Images/Post-ico-3.png";
import TryAgain from "../../Assests/Images/try-again.png";
import India from "../../Assests/Images/india.png";
import Edit from "../../Assests/Images/Edit.png";
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Common/header';
import { UserAuth } from '../../context/AuthContext';
import { useFormik } from "formik";
import * as Yup from "yup";
import { auth } from '../../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { AddUpdatePostProperty, getAllCitiesList, getPropertyFor } from '../../Request/post-propertyRequest';
import { UserProperty } from '../../context/PropertyContext';
import { Typeahead } from 'react-bootstrap-typeahead';



export default function UpdateForm1() {

    const { isUserExist, isUserLoggedIn, checkUser, setPhoneNumber,
        phoneNumber, setSentOtpFrom } = UserAuth();

    const { propertyForID, setPropertyForID, setPropertyTypeID, setPropertyID,
        propertyTypeID, cityID, setCityID ,updateProperties } = UserProperty()
        const searchParams = new URLSearchParams(window.location.search);
        const PropertyDetailsByParams =JSON.parse(searchParams.get('PropertyDetails'))

    let navigate = useNavigate();

    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(855236255);
    const [propertyFor, setPropertyFor] = useState();

    const [citiesList, setCitiesList] = useState();

    useEffect(() => {
        setPropertyTypeID(PropertyDetailsByParams.building_types_id || "");
        console.log(PropertyDetailsByParams.building_types_id,"khjdfhsdjkf")
      }, [PropertyDetailsByParams.building_types_id]);

    useEffect(() => {
        getAllCitiesList().then((res) => {
            setCitiesList(res)
        })

        getPropertyFor().then((res) => {
            setPropertyFor(res)
        })

    }, [])

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setText(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_name: "",
            email: "",
            mobile_no: localStorage.getItem("phoneNo") || "",
            property_types_id: updateProperties.property_types_id || PropertyDetailsByParams.building_types_id ,
            propertyFor: updateProperties.propertyFor ||PropertyDetailsByParams.property_types_id ,
            city_id: updateProperties.city_id || PropertyDetailsByParams.city_id ,
        },

        validationSchema: Yup.object({
            user_name: Yup.string().max(100).required("User Name is required"),
            email: Yup.string().max(100).required("User Email is required"),

        }),

        onSubmit: async (data, { resetForm }) => {
            try {
                onSignup(data.mobile_no)
              


                // if (formik.values.user_name.length >= 3) {
                //     navigate("/verify-otp",
                //         {
                //             state: {
                //                 user_name: formik.values.user_name
                //             }
                //         });
                // }

            } catch (error) {
                console.log(error);
            }
        },
    });


    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-containerform1",
                {
                    size: "invisible",
                    callback: (response) => {
                        onSignup();
                    },
                    "expired-callback": () => { },
                },
                auth
            );
        }
    }

    function onSignup(mobileNo) {
        setSentOtpFrom("form1")
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        const formatPh = "+91" + mobileNo;

        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                navigate('/verify-otp');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleLogInUserNavigateToNextForm = () => {
        AddUpdatePostProperty(
            {
                ...updateProperties,
                id:PropertyDetailsByParams.id,
                city_id: cityID,
                property_for: propertyForID,
                type_id: propertyTypeID,
                user_id: localStorage.getItem("userID"),
                property_statusID: 3,
                Step: 1,
            }
        ).then((res) => {
            setPropertyID(res.id)
            const queryParams = JSON.stringify(PropertyDetailsByParams);
            navigate(`/update-property-form3?PropertyDetails=${queryParams}`)
        })
    }

    useEffect(()=>{
        if (PropertyDetailsByParams.city_id && citiesList?.length > 0) {
          const predefinedCities = citiesList.filter(city => city.id == PropertyDetailsByParams.city_id)
          setSelectedCityArray(predefinedCities);
          setCityID(predefinedCities[0].id)
          console.log(predefinedCities,"sjdhfkjsdh")
        }
      },[PropertyDetailsByParams.city_id,citiesList])


    const [selectedCityArray, setSelectedCityArray] = useState([])
    const handleCityChange = (e) => {
        setSelectedCityArray(e)
        setCityID(e[0]?.id)
    }

    return (
        <>
            <Header />
            <div id="recaptcha-containerform1"></div>
            <body className="Post-Property-1">
                {/* <a href="../Pages/index.html" className="fa fa-chevron-left Post-Property-Back"></a> */}
                <section className="Step-1" id="2">
                    <div className="left">
                        <p>REACHED UPTO 42 LACS BUYERS</p>
                        <h1>Sell or Rent your Property on Quarter</h1>
                        <img src={Post1} alt="Basic" />
                    </div>

                    <form onSubmit={formik.handleSubmit} className="right needs-validation">
                    <div className="PostBackContainer">
            <div
              className="Post-Property-Back mobileonly "
              onClick={() => {
                navigate("/");
              }}
            >
              <span className="fa fa-chevron-left  postBack"></span>
            </div>
            <h1 className="Title postFormFont">Post Your Property</h1>
            </div>
                        <div className="Parent">
                            <div className="Property">
                                <p className="First-Of-Type">I’m looking to</p>
                                <div className="btn-group gap-3" role="group" aria-required="true">

                                    {propertyFor &&
                                        propertyFor?.map((item, key) => {
                                            return(
                                            <>
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="propertyFor"
                                                    id={`btnradio${key}`}
                                                    autocomplete="off"
                                                    value={item.id}
                                                    onChange={(e) => {
                                                        setPropertyForID(e.target.value)
                                                        formik.handleChange(e)
                                                    }}
                                                    defaultChecked={key===0}
                                                />
                                                <label key={key} for={`btnradio${key}`}>
                                                    <i className="fas fa-circle"></i> {item.name}
                                                </label>
                                            </>
                                        )})
                                    }

                                </div>
                            </div>
                            <div className="Type">
                                <p>What’s the type of your property?</p>
                                <div className="input-group gap-3 gap-md-2 gap-lg-3" role="group">
                                    <input type="radio" className="btn-check" id="btncheck1" autocomplete="off"
                                        name="property_types_id" 
                                        defaultChecked= {PropertyDetailsByParams.building_types_id == "1"}
                                        value="1" onChange={(e) => {
                                            setPropertyTypeID(e.target.value)
                                            formik.handleChange(e)
                                        }}
                                    />
                                    <label for="btncheck1" style={{
                      borderRadius: "66px",
                    }}>                  
                                        <img src={PostIcon1} /> Apartment
                                    </label>

                                    <input type="radio" className="btn-check"
                                        name="property_types_id" value="2" onChange={(e) => {
                                            setPropertyTypeID(e.target.value)
                                            formik.handleChange(e)
                                        }}
                                        defaultChecked= {PropertyDetailsByParams.building_types_id == "2"}
                                        id="btncheck2" autocomplete="off" />
                                    <label for="btncheck2" style={{
                      borderRadius: "66px",
                    }}>
                                        <img src={PostIcon2} /> Independent House / Villa
                                    </label>

                                    <input type="radio" className="btn-check"
                                        name="property_types_id" value="3" onChange={(e) => {
                                            setPropertyTypeID(e.target.value)
                                            formik.handleChange(e)
                                        }}
                                        defaultChecked= {PropertyDetailsByParams.building_types_id == "3"}
                                        id="btncheck3" autocomplete="off" />
                                    <label for="btncheck3" style={{
                      borderRadius: "66px",
                    }}>
                                        <img src={PostIcon3} /> Standalone Building
                                    </label>
                                </div>
                            </div>
                            <div className="City">
                                <p>City</p>
                                {/* <select
                                    className="form-control labelUI" id="Select-box"
                                    label="city"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={(e) => {
                                        setCityID(e.target.value)
                                        formik.handleChange(e)
                                    }}
                                    aria-label="Default select example"
                                >
                                    <option defaultChecked value="">
                                        Select
                                    </option>
                                    {citiesList &&
                                        citiesList?.map((item, key) => (
                                            <option value={item.id} key={key}>{item.name}, {item.state_name}</option>
                                        ))
                                    }

                                </select> */}
                                <Typeahead
                                    id="basic-example"
                                    onChange={(e) => {
                                        handleCityChange(e)

                                    }}
                                    minLength={1}
                                    options={citiesList}
                                    placeholder="Choose a City..."
                                    selected={selectedCityArray}
                                    labelKey={option => `${option.name}, ${option.state_name}`}
                                    style={{marginBottom: "10px"}}
                                    value={formik.values.city_id}
                                />
                                {/* <button type="button" className="form-control Mobile-Loc" data-bs-toggle="modal" data-bs-target="#BD">
                                    Localities
                                </button> */}
                            </div>

                            {/* <div
                                style={{ border: "2px solid red", marginTop: " 10px" }}
                            >
                                {isEditing ? (
                                    <>
                                        <div className="Phone">
                                            <div className="btn-group w-100">
                                                <input
                                                    className="form-control"
                                                    placeholder="Phoned"
                                                    required
                                                    name='mobile_no'

                                                    type="number"
                                                    value={text}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="Phone">
                                            <div className="btn-group w-100">
                                                <label>{text}
                                                    <img
                                                        src={Edit}
                                                        onClick={handleClick}
                                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div> */}


                            {!isUserLoggedIn ? <div className="Phone">
                                <p>My Phone Number is....</p>

                                <div className="btn-group w-100">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Phoned"
                                        required
                                        onChange={(e) => {
                                            checkUser(e.target.value);
                                            setPhoneNumber(e.target.value);
                                            formik.handleChange(e)
                                        }}
                                        name='mobile_no'
                                        onBlur={formik.handleBlur}
                                    // value={formik.values.mobile_no}
                                    />
                                    <span className="input-group-text">
                                        <img src={India} />
                                    </span>
                                </div>
                                {isUserExist ? <>
                                    <button
                                        type="submit"
                                        class="Submit Style"
                                        data-bs-target="#ModalID"
                                        onClick={() => onSignup(phoneNumber)}
                                    >
                                        Login
                                    </button>
                                </> : <>
                                    <div class="UserName">
                                        <p>Name</p>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Name"
                                            required
                                            className="form-control"
                                            name='user_name'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.user_name}
                                        />
                                        <div class="valid-feedback">
                                            Good!
                                        </div>
                                        <div class="invalid-feedback">
                                            Please Enter Your Name.
                                        </div>
                                    </div>

                                    <div class="Email">
                                        <p>Email</p>
                                        <input
                                            type="email"
                                            class="form-control mb-3"
                                            placeholder="Email"
                                            required
                                            name='email'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        <div class="valid-feedback">
                                            Good!
                                        </div>
                                        <div class="invalid-feedback">
                                            Please Enter Your Email.
                                        </div>
                                    </div>

                                    <div class="Check">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="invalidCheck" required />
                                            <label class="form-check-label" for="invalidCheck"> Agree to terms and conditions </label>
                                            <div class="valid-feedback"> Good! </div>
                                            <div class="invalid-feedback"> You must agree before submitting. </div>
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        class="Submit Style"
                                        data-bs-target="#ModalID"
                                    // data-bs-toggle="modal"
                                    >
                                        Continue
                                    </button>

                                </>}

                            </div> : <div className="Phone ">
                                <p style={{ marginBottom: "10px" }}>My Phone Number is....</p>
                                {/* <label className="form-control labelUI">
                                    {localStorage.getItem("phoneNo")}
                                </label> */}
                                <div
                                // style={{ border: "2px solid red", marginTop: " 10px" }}
                                >
                                    {isEditing ? (
                                        <>
                                            <div className="Phone">
                                                <div className="btn-group w-100">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Phoned"
                                                        required
                                                        name='mobile_no'
                                                        autoFocus={true}
                                                        type="number"
                                                        value={formik.values.mobile_no}
                                                        onChange={formik.handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="Phone">
                                                <div className="btn-group w-100">
                                                    <label className="form-control labelUI">
                                                        {formik.values.mobile_no}
                                                        <img
                                                            src={Edit}
                                                            onClick={handleClick}
                                                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <button
                                    class="Submit Style"
                                    onClick={
                                        handleLogInUserNavigateToNextForm
                                    }
                                >
                                    Next
                                </button>

                            </div>}

                            {/* {isUserExist ? <>
                                <button
                                    type="submit"
                                    class="Submit Style"
                                    data-bs-target="#ModalID"
                                    // data-bs-toggle="modal"
                                    onClick={() => onSignup(phoneNumber)}
                                // onSubmit={formik.handleSubmit}
                                >
                                    Login
                                </button>
                            </> : <><>

                                <div class="UserName">
                                    <p>Name</p>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Name"
                                        required
                                        className="form-control"
                                        name='user_name'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.user_name}
                                    />
                                    <div class="valid-feedback">
                                        Good!
                                    </div>
                                    <div class="invalid-feedback">
                                        Please Enter Your Name.
                                    </div>
                                </div>

                                <div class="Email">
                                    <p>Email</p>
                                    <input
                                        type="email"
                                        class="form-control mb-3"
                                        placeholder="Email"
                                        required
                                        name='email'
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                    <div class="valid-feedback">
                                        Good!
                                    </div>
                                    <div class="invalid-feedback">
                                        Please Enter Your Email.
                                    </div>
                                </div>

                                <div class="Check">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="invalidCheck" required />
                                        <label class="form-check-label" for="invalidCheck"> Agree to terms and conditions </label>
                                        <div class="valid-feedback"> Good! </div>
                                        <div class="invalid-feedback"> You must agree before submitting. </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    class="Submit Style"
                                    data-bs-target="#ModalID"
                                // data-bs-toggle="modal"
                                >
                                    Add User and Continue
                                </button>
                            </></>} */}


                            <div className="modal fade" id="ModalID" tabindex="-1" aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title text-danger" id="exampleModalLabel">Number Verified Failed</h5>
                                            <img src={TryAgain} alt="Close-Image" data-bs-dismiss="modal" />
                                        </div>
                                        <div className="modal-body">
                                            Please check the number or try it after sometime.
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-light text-dark d-block w-100 p-2"
                                                data-bs-dismiss="modal">
                                                Try Again
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="modal fade Localities-Modal modal-static" id="BD" data-bs-backdrop="static" data-bs-keyboard="false"
                        tabindex="-1" aria-labelledby="staticBackdrop" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="header">
                                    <div className="Small-Return justify-content-start">
                                        <i className="fa fa-chevron-left" data-bs-dismiss="modal" aria-label="Close"></i>
                                        <p className="m-0">Localities</p>
                                    </div>
                                </div>
                                <div className="body">
                                    <select className="form-control" id="Select-box" name="states['Shehab', 'Ayman']" multiple>
                                        <option value="AK">Alaska</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="CA">California</option>
                                        <option value="NV">Nevada</option>
                                        <option value="OR">Oregon</option>
                                        <option value="WA">Washington</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="CO">Colorado</option>
                                        <option value="ID">Idaho</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="UT">Utah</option>
                                        <option value="WY">Wyoming</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="AR">Paris</option>
                                        <option value="AR">London</option>
                                        <option value="AR">Egypt</option>
                                        <option value="IL">Canada</option>
                                        <option value="IA">Germeen</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TX">Texas</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="IN">Indiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="OH">Ohio</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WV">West Virginia</option>
                                    </select>
                                </div>
                                <div className="footer">
                                    <div className="input-group">
                                        <button type="button" id="Suggestio 1" value="Suggestio 1"> Suggestio 1 </button>
                                        <button type="button" id="Suggestio 2" value="Suggestio 2"> Suggestio 2 </button>
                                        <button type="button" id="Suggestio 3" value="Suggestio 3"> Suggestio 3 </button>
                                        <button type="button" id="Suggestio 4" value="Suggestio 4"> Suggestio 4 </button>
                                        <button type="button" id="Suggestio 5" value="Suggestio 5"> Suggestio 5 </button>
                                        <button type="button" id="Suggestio 6" value="Suggestio 6"> Suggestio 6 </button>
                                        <button type="button" id="Suggestio 7" value="Suggestio 7"> Suggestio 7 </button>
                                        <button type="button" id="Suggestio 8" value="Suggestio 8"> Suggestio 8 </button>
                                        <button type="button" id="Suggestio 9" value="Suggestio 9"> Suggestio 9 </button>
                                        <button type="button" id="Suggestio 10" value="Suggestio 10"> Suggestio 10 </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </body >
        </>
    )
}
